const parseBodyUrlEncoded = (datas) => {
    let formBody = [];
    for (const property in datas) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(datas[property]);
        formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');

    return formBody;
};

export {
    parseBodyUrlEncoded
};
