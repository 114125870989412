import { connect } from "react-redux";
import DataGrid, {
  Editing,
  Column,
  Button,
  Pager,
  Paging,
  Lookup,
  Grouping,
} from "devextreme-react/data-grid";
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import { ADVERTISEMENT_TYPE, FILE_URL, WEBSITE_URL } from "../utils/constants";
import { VideoMV, VideoVM } from "../model/Video";
import VideoSelectPopup from "../components/file/VideoSelectPopup";
import VideoElement from "../components/VideoElement";
import useWindowDimensions from "../hook/useWindowDimensions";
import CustomStore from "devextreme/data/custom_store";

loadMessages(dictionary);
const pageSizes = [20, 25, 50, 100];

const NewsContainer = ({
  accessToken,
  languages,
  currentLanguage,
  currentWebsite,
}) => {
  locale(currentLanguage.substring(0, 2));
  const dataGridRef = useRef(null);
  const URL = `${WEBSITE_URL}Advertisements`;
  const { height } = useWindowDimensions();

  const getList = () => {
    return fetch(`${URL}/get-all/${currentWebsite}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return {
            data: json.map((data) => VideoVM(data, languages, currentLanguage)),
          };
        }
        throw json.Message;
      })
    );
  };

  const insertVideo = (values) => {
    return fetch(`${URL}/${currentWebsite}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Accept-Language": currentLanguage,
      },
      body: JSON.stringify(VideoMV(values)),
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return json.data;
        }
        throw json.Message;
      })
    );
  };

  const updateVideo = (key, values) => {
    return fetch(`${URL}/${key}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return fetch(`${URL}/${currentWebsite}/${key}`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "Accept-Language": currentLanguage,
            },
            body: JSON.stringify(VideoMV({ ...json.data, values })),
          }).then((result2) =>
            result2.json().then((json2) => {
              if (result2.ok) {
                return { ...json.data, values };
              }
              throw json2.Message;
            })
          );
        }
        throw json.Message;
      })
    );
  };

  const deleteVideo = (key) => {
    return fetch(`${URL}/${currentWebsite}/${key}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return {};
        }
        throw json.Message;
      })
    );
  };

  const videoStore = new CustomStore({
    key: "id",
    load: getList,
    remove: deleteVideo,
    update: updateVideo,
    insert: insertVideo,
  });

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push(
      {
        location: "center",
        text: "Danh sách Video và Nhạc",
      },
      {
        location: "before",
        widget: "dxButton",
        options: {
          icon: "refresh",
          onClick: () => dataGridRef.current.instance.refresh(),
        },
      }
    );
  };

  const urlCellRender = (data) => {
    return (
      <VideoElement
        src={`${FILE_URL}${data.value}`}
        height={data.data.type === 2 ? "50px" : "200px"}
      />
    );
  };

  const urlEditCellRender = (cell) => {
    return (
      <VideoSelectPopup
        value={cell.value}
        onValueChanged={(value) => cell.setValue(value)}
        type={cell.data.type}
      />
    );
  };

  const urlTxtCellRender = (data) => {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={`${FILE_URL}${data.value}`}
      >{`${FILE_URL}${data.value}`}</a>
    );
  };

  return (
    <div
      className="form-container"
      style={{ overflow: "auto", height: height - 56, paddingBottom: 20 }}
    >
      <DataGrid
        ref={dataGridRef}
        keyExpr="id"
        dataSource={videoStore}
        allowColumnReordering={true}
        selection={{ mode: "single" }}
        showBorders={true}
        autoEx
        onToolbarPreparing={onToolbarPreparing}
      >
        <Grouping autoExpandAll={false} />
        <Editing
          mode="form"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
        />

        <Column
          dataField="type"
          dataType="number"
          caption={formatMessage("type")}
          groupIndex={0}
        >
          <Lookup
            dataSource={ADVERTISEMENT_TYPE}
            valueExpr="id"
            displayExpr={`name-${currentLanguage}`}
          />
        </Column>

        <Column
          dataField="url"
          dataType="string"
          caption={formatMessage("url")}
          cellRender={urlCellRender}
          editCellRender={urlEditCellRender}
        />

        <Column
          dataField="urlTxt"
          dataType="string"
          caption={formatMessage("url")}
          formItem={{ visible: false }}
          cellRender={urlTxtCellRender}
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button name="edit" />
        </Column>

        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={20} />
      </DataGrid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

NewsContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
  currentWebsiteLanguage: PropTypes.string,
};

export default connect(mapStateToProps, null)(NewsContainer);
