import React, { useState, useEffect, useRef } from "react";
import { WEBSITE_URL } from "../utils/constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import NewsDetailContainer from "./website/NewsDetailContainer";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Editing,
  Button,
  Selection,
} from "devextreme-react/data-grid";
import { formatMessage } from "devextreme/localization";
import ScrollView from "devextreme-react/scroll-view";
import CustomStore from "devextreme/data/custom_store";
import Toolbar, { Item } from "devextreme-react/toolbar";
const pageSizes = [10, 25, 50, 100];

function SickDetailContainer({
  currentWebsite,
  accessToken,
  websiteLanguages,
  currentLanguage,
  dataSource,
  onChangeValue,
  updateId,
}) {
  const dataGridRef = useRef(null);
  const [listData, setListData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [keyword, setKeyword] = useState(null);
  const [newsSelected, setNewsSelected] = useState([]);
  const [newsSelectedRow, setNewsSelectedRow] = useState([]);
  const [showItemPopup, setShowItemPopup] = useState(false);
  const [showNewsPopup, setShowNewsPopup] = useState(false);
  useEffect(() => {
    setListData(dataSource);
  }, [dataSource]);

  useEffect(() => {
    setNewsData(
      new CustomStore({
        key: "id",
        load: () => loadRequest(),
      })
    );
  }, [page, size, keyword]);

  const loadRequest = () => {
    return fetch(
      `${WEBSITE_URL}News/${currentWebsite}/${currentLanguage}?${
        keyword ? `keyword=${keyword}&` : ""
      }page=${page}&pageSize=${size}&type=0&isActive=true`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((response) =>
      response.json().then((json) => {
        if (response.ok) {
          return {
            data: json.data,
            totalCount: json.totalRows,
          };
        }
      })
    );
  };

  const hideItemDetailPopup = (data) => {
    dataGridRef.current.instance.refresh();
    setNewsSelected((prevState) => [...prevState, data]);
    setShowItemPopup(false);
  };
  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            setShowNewsPopup(true);
          },
        };
      }
    });
  };
  const onAddToolbar = {
    icon: "plus",
    onClick: () => {
      setShowItemPopup(true);
    },
  };
  const dataGridOnSelectionChanged = (e) => {
    setNewsSelectedRow(e.selectedRowsData);
    setNewsSelected((e.selectedRowKeys.length && e.selectedRowKeys) || []);
  };
  const onSaveData = () => {
    const dataKey = listData.map((item) => item.id);
    const newRow = newsSelectedRow.filter((item) => !dataKey.includes(item.id));
    const newData = [...listData, ...newRow];
    setListData(newData);
    onChangeValue(newData);
    setShowNewsPopup(false);
    setNewsSelectedRow([]);
    setNewsSelected([]);
  };
  const popupOptions = {
    text: "Save",
    onClick: () => onSaveData(),
  };
  const backOptions = {
    text: "Close",
    onClick: () => {
      onClosePopup();
    },
  };
  const closeOptions = {
    text: "Close",
    onClick: () => setShowItemPopup(false),
  };
  const onClosePopup = () => {
    setKeyword(null);
    setPage(1);
    setSize(10);
    setShowNewsPopup(false);
    setNewsSelected([]);
  };
  const removeItem = (e) => {
    const value = listData.filter((item) => item.id !== e);
    setListData(value);
    onChangeValue(value);
  };
  const onOptionChanged = (e) => {
    if (e.fullName === "paging.pageIndex") {
      setPage(e.value + 1);
    } else if (e.fullName === "paging.pageSize") {
      setPage(1);
      setSize(e.value);
    }
  };
  const searchOption = {
    placeholder: "Nhập từ khóa tìm kiếm",
    value: keyword,
    width: 200,
    onValueChanged: (e) => setKeyword(e.value),
  };
  return (
    <div>
      <DataGrid
        dataSource={listData}
        keyExpr="id"
        allowColumnReordering={true}
        selection={{ mode: "single" }}
        showBorders={true}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Editing
          mode="row"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
        />
        <Column dataField="title" />
        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" onClick={(e) => removeItem(e.row.key)} />
        </Column>
        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
      </DataGrid>

      <div></div>
      {showNewsPopup && (
        <Popup
          visible={showNewsPopup}
          onHiding={() => onClosePopup()}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showCloseButton={false}
          showTitle={false}
          container=".dx-viewport"
          width={800}
          height={600}
        >
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={popupOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={backOptions}
          />
          <Toolbar>
            <Item location="before" widget="dxTextBox" options={searchOption} />
            <Item location="center" text={"Danh sách tin tức"} />
            <Item location="after" widget="dxButton" options={onAddToolbar} />
          </Toolbar>
          <ScrollView width="100%" height="100%">
            <DataGrid
              ref={dataGridRef}
              dataSource={newsData}
              keyExpr="id"
              allowColumnReordering={true}
              remoteOperations={true}
              showBorders={true}
              selectedRowKeys={newsSelected}
              onSelectionChanged={dataGridOnSelectionChanged}
              onOptionChanged={onOptionChanged}
            >
              <Selection mode="multiple" />
              <Column dataField="title" />
              <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
              <Paging defaultPageSize={10} />
            </DataGrid>
          </ScrollView>
        </Popup>
      )}

      {showItemPopup && (
        <Popup
          visible={showItemPopup}
          onHiding={() => setShowItemPopup(false)}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showCloseButton={false}
          showTitle={false}
          container=".dx-viewport"
          width={"90vw"}
          height={"90vh"}
        >
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={closeOptions}
          />
          <ScrollView width="100%" height="100%">
            <NewsDetailContainer
              accessToken={accessToken}
              currentLanguage={currentLanguage}
              currentWebsite={currentWebsite}
              websiteLanguages={websiteLanguages}
              isPopup={true}
              sickId={updateId || null}
              hidePopup={hideItemDetailPopup}
            />
          </ScrollView>
        </Popup>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

SickDetailContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
  dataSource: PropTypes.array,
  onChangeValue: PropTypes.func,
  updateId: PropTypes.string,
};

export default connect(mapStateToProps, null)(SickDetailContainer);
