import { useState, useEffect, useCallback } from 'react';
import { WEBSITE_URL } from "../../../utils/constants";
import notify from "devextreme/ui/notify";
import moment from "moment";

const previosMonth = moment().subtract(1, 'month');
const PaymentData = ({ accessToken, currentLanguage, selectedId, setShowPaymentPopup }) => {
  const [unpaidList, setUnpaidList] = useState(null);
  const [paymentSelect, setPaymentSelect] = useState([]);
  const [isSelectDate, setIsSelectDate] = useState(true);
  const [selectDate, setSelectDate] = useState(moment().subtract(1, 'day').toDate());
  const [originalList, setOriginalList] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectFile, setSelectFile] = useState(null);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    if (checkAll) setPaymentSelect(unpaidList.map((o) => o.id));
    else setPaymentSelect([]);
  }, [checkAll])

  useEffect(() => {
    if (selectedId) get()
  }, [selectedId, accessToken])

  useEffect(() => {
    setPaymentSelect([])
    setCheckAll(false)
  }, [isSelectDate])

  useEffect(() => {
    if (unpaidList) {
      if (unpaidList.length > 0) {
        const filter = unpaidList.filter(o => paymentSelect.includes(o.id));
        const map = filter.map(o => o.point)
        if (map.length === 0) return setTotalAmount(0)

        const result = map.reduce((a, c) => { return a + c })
        setTotalAmount(result)
      } else {
        setTotalAmount(0)
      }

    }
  }, [paymentSelect, unpaidList])

  useEffect(() => {
    if (originalList) {
      const tmp = originalList.filter(x => {
        const date1 = moment(selectDate).format("DD-MM-YYYY");
        const date2 = moment(x.ngayDenKham).format("DD-MM-YYYY");

        const isPreviosMonth = moment(x.ngayDenKham).isSame(previosMonth.toDate(), 'month');
        return isSelectDate ? date1 === date2 : isPreviosMonth
      });

      setUnpaidList(tmp);
    }
  }, [originalList, selectDate, isSelectDate])

  const onChangePaymentSelect = (id) => {
    let tmp = [...paymentSelect];

    if (tmp.includes(id)) tmp = tmp.filter((val) => val !== id);
    else tmp.push(id)

    setPaymentSelect(tmp)
  }

  const update = useCallback(() => {
    const payload = {
      "paymentType": isSelectDate ? 0 : 1,
      "paymentTime": selectDate.toISOString(),
      "month": previosMonth.month() + 1, // previos month
      "year": previosMonth.year(),
      "url": selectFile ? selectFile.url : '',
      "ids": paymentSelect
    };

    return fetch(`${WEBSITE_URL}DoiNhom/payment-commission/${selectedId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    })
      .then(result => result.json())
      .then((json) => {
        notify(
          {
            message: json.message,
            position: {
              my: "center top",
              at: "center top",
            },
          },
          "success",
          3000
        );
        setShowPaymentPopup()
      })
      .catch((err) => {
        notify(
          {
            message: err.message || err,
            position: {
              my: "center top",
              at: "center top",
            },
          },
          "error",
          3000
        );
        throw (err.message || err);
      })
  }, [paymentSelect, selectFile, selectDate, isSelectDate]);

  const get = () => {
    return fetch(`${WEBSITE_URL}DoiNhom/get-unpaid/${selectedId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    })
      .then(result => result.json())
      .then((json) => {
        if (json) {
          setOriginalList(json)
        } else {
          console.log(json)
          throw json.Message;
        }
      })
      .catch((err) => {
        notify(
          {
            message: err.message || err,
            position: {
              my: "center top",
              at: "center top",
            },
          },
          "error",
          3000
        );
        throw (err.message || err);
      })
  }

  return {
    unpaidList,
    onChangePaymentSelect,
    paymentSelect,
    selectDate,
    setSelectDate,
    isSelectDate,
    setIsSelectDate,
    totalAmount,
    update,
    selectFile,
    setSelectFile,
    setCheckAll,
    checkAll
  }
}

export default PaymentData;