import {
  WEBSITE_CHANGE,
  WEBSITE_INIT,
  WEBSITE_GETALL_FAILURE,
  WEBSITE_GETALL_REQUEST,
  WEBSITE_GETALL_SUCCESS,
} from "../actions/websiteActions";
import { mock } from "../data/mock";

const INITIAL_STATE = {
  data: {
    currentWebsite: "4388393b-6b61-4400-a1da-69ae9aad726d",
    languages: [],
    websites: [],
  },
  error: null,
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WEBSITE_CHANGE: {
      localStorage.setItem("currentWebsite", action.payload.websiteId);

      return {
        ...state,
        data: {
          ...state.data,
          currentWebsite: action.payload.websiteId,
        },
      };
    }

    case WEBSITE_INIT: {
      const currentWebsite = localStorage.getItem("currentWebsite");
      const websites = localStorage.getItem("websites");
      const languages = localStorage.getItem("websiteLanguages");

      return {
        ...state,
        data: {
          currentWebsite: currentWebsite || mock.CURRENT_WEBSITE,
          languages: languages ? JSON.parse(languages) : mock.WEBSITE_LANGUAGES,
          websites: websites ? JSON.parse(websites) : mock.WEBSITES,
        },
      };
    }

    case WEBSITE_GETALL_REQUEST: {
      return {
        ...state,
        data: {
          ...state.data,
          websites: [],
        },
        error: null,
        loading: true,
      };
    }
    case WEBSITE_GETALL_SUCCESS: {
      const defaultWebsite = action.payload.find((x) => x.isDefault);
      localStorage.setItem("currentWebsite", defaultWebsite.id);
      localStorage.setItem("websites", JSON.stringify(action.payload));
      localStorage.setItem(
        "websiteLanguages",
        JSON.stringify(defaultWebsite.websiteLanguages)
      );

      return {
        ...state,
        data: {
          ...state.data,
          websites: action.payload,
          currentWebsite: defaultWebsite.id,
        },
        error: null,
        loading: false,
      };
    }
    case WEBSITE_GETALL_FAILURE: {
      return {
        ...state,
        data: {
          ...state.data,
          websites: [],
        },
        error: action.payload.error,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
