import React, { useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// devextreme
import DataGrid, {
  Column,
  Pager,
  Paging,
  Editing,
  Button
} from "devextreme-react/data-grid";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
// 
import useWindowDimensions from "../../../hook/useWindowDimensions";
import dictionary from "../../../data/dictionary";
import CustomerData from './enhance';
import CustomerDetailPopup from './detailPopup';
import moment from "moment";

loadMessages(dictionary);

const selectionChanged = (e) => {
  e.component.collapseAll(-1);
  e.component.expandRow(e.currentSelectedRowKeys[0]);
}

const CustomerList = ({ accessToken, currentLanguage }) => {
  locale(currentLanguage.substring(0, 2));
  const {
    onToolbarPreparing,
    statusCellRender,
    data,
    onRefresh,
    setSelectedId,
    selectedId,
    setShowDeclinePopup,
    totalRows,
    pageSizes,
    showDeclinePopup } = CustomerData({ accessToken, currentLanguage })
  const dataGridRef = useRef(null);

  const { height } = useWindowDimensions();

  const memoTotalPage = useMemo(() => {
    if (totalRows) return totalRows > pageSizes[pageSizes.length - 1] ? [...pageSizes, totalRows] : pageSizes
    return pageSizes
  }, [totalRows])

  const imgCellRender = ({ value }) => {
    const data = moment(value).format('HH:mm DD-MM-YYYY');
    return (
      <div>{ data }</div>
    );
  };

  return (
    <div
      className="form-container"
      style={{ overflow: "auto", height: height - 56, paddingBottom: 20 }}
    >
      <DataGrid
        keyExpr="id"
        ref={dataGridRef}
        dataSource={data}
        wordWrapEnabled={true}
        allowColumnReordering={true}
        showBorders={true}
        remoteOperations={true}
        onToolbarPreparing={onToolbarPreparing}
        onSelectionChanged={selectionChanged}
        errorRowEnabled={false}
      >
        <Editing mode="row" useIcons={true} />
        <Column
          dataField="fullName"
          dataType="string"
          caption={'Tên bệnh nhân'}
        />

        <Column
          dataField="phoneNumber"
          dataType="string"
          caption={formatMessage("phoneNumber")}
        />

        <Column
          dataField="userFullName"
          dataType="string"
          caption={'Cộng tác viên'}
        />

        <Column
          dataField="status"
          dataType="string"
          caption={formatMessage("approvedStatus")}
          cellRender={statusCellRender}
        />

        {/* <Column
          dataField="createTime"
          dataType="time"
          caption={'Ngày tạo'}
        /> */}
        <Column dataField="createTime" cellRender={imgCellRender} caption={'Ngày tạo'} />
        <Pager allowedPageSizes={memoTotalPage} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button
            hint="Info"
            icon="info"
            onClick={({ row: { data } }) => {
              setSelectedId(data.id)
              setShowDeclinePopup(true)
            }}
          />
        </Column>
      </DataGrid>

      {(showDeclinePopup) && <CustomerDetailPopup
        setShowDeclinePopup={setShowDeclinePopup}
        selectedId={selectedId}
        onRefresh={onRefresh}
        accessToken={accessToken}
        currentLanguage={currentLanguage} />}
    </div>
  );
}



const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
  };
};

CustomerList.propTypes = {
  accessToken: PropTypes.string,
  currentLanguage: PropTypes.string,
};

export default connect(mapStateToProps, null)(CustomerList);