import { connect } from "react-redux";
import DataGrid, {
  Editing,
  Column,
  Button,
  Pager,
  Paging,
  Grouping,
} from "devextreme-react/data-grid";
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import { FILE_URL, WEBSITE_URL } from "../utils/constants";
import useWindowDimensions from "../hook/useWindowDimensions";
import CustomStore from "devextreme/data/custom_store";
import { AdvertisementMV, AdvertisementVM } from "../model/Advertisement";
import { useHistory } from "react-router-dom";
import FileSelectPopup from "../components/file/FileSelectPopup";
import notify from "devextreme/ui/notify";

loadMessages(dictionary);
const pageSizes = [20, 25, 50, 100];

const AdvertisementContainer = ({
  accessToken,
  languages,
  currentLanguage,
  currentWebsite,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const dataGridRef = useRef(null);
  const URL = `${WEBSITE_URL}Advertisements/title`;
  const { height } = useWindowDimensions();

  const getList = () => {
    return fetch(`${URL}/get-all/${currentWebsite}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return {
            data: json.map((data) =>
              AdvertisementVM(data, languages, currentLanguage)
            ),
          };
        }
        throw json.Message;
      })
    );
  };

  const insertAdvertisement = (values) => {
    return fetch(`${URL}/${currentWebsite}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Accept-Language": currentLanguage,
      },
      body: JSON.stringify(AdvertisementMV(values)),
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return json.data;
        }
        throw json.Message;
      })
    );
  };

  const deleteAdvertisement = (key) => {
    return fetch(`${URL}/${currentWebsite}/${key}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return {};
        }
        throw json.Message;
      })
    );
  };

  const videoStore = new CustomStore({
    key: "id",
    load: getList,
    remove: deleteAdvertisement,
    insert: insertAdvertisement,
  });

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push(
      {
        location: "center",
        text: "Danh sách Màn hình chờ",
      },
      {
        location: "before",
        widget: "dxButton",
        options: {
          icon: "refresh",
          onClick: () => dataGridRef.current.instance.refresh(),
        },
      }
    );
  };

  const bgCellRender = (data) => {
    return (
      <img
        alt="GHM Image"
        src={`${FILE_URL}/${data.value}`}
        style={{ height: 50 }}
      />
    );
  };

  const bgEditCellRender = (cell) => {
    return (
      <FileSelectPopup
        value={cell.value}
        onValueChanged={(value) => {
          cell.setValue(value);
        }}
      />
    );
  };

  const gotoDetailPage = (advertisementId) => {
    history.push(`/advertisement/${advertisementId}`);
  };

  return (
    <div
      className="form-container"
      style={{ overflow: "auto", height: height - 56, paddingBottom: 20 }}
    >
      <DataGrid
        ref={dataGridRef}
        keyExpr="id"
        dataSource={videoStore}
        allowColumnReordering={true}
        selection={{ mode: "single" }}
        showBorders={true}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Grouping autoExpandAll={false} />
        <Editing
          mode="form"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={false}
        />

        <Column
          dataField="title"
          dataType="string"
          caption={formatMessage("title")}
        />

        <Column
          dataField="floor"
          dataType="string"
          caption={formatMessage("floor")}
          groupIndex={0}
        />

        <Column
          dataField="area"
          dataType="string"
          caption={formatMessage("area")}
        />

        <Column
          dataField="background"
          dataType="string"
          caption={formatMessage("background")}
          cellRender={bgCellRender}
          editCellRender={bgEditCellRender}
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => gotoDetailPage(e.row.key)}
          />
        </Column>

        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={20} />
      </DataGrid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

AdvertisementContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
  currentWebsiteLanguage: PropTypes.string,
};

export default connect(mapStateToProps, null)(AdvertisementContainer);
