const SickVM = (data) => {
  const returnData = Object.assign({}, data);

  if (returnData.sickTranslations)
    returnData.sickTranslations.forEach((element) => {
      returnData[`sickTranslations-${element.languageId}-alphabet`] =
        element.alphabet;
      returnData[`sickTranslations-${element.languageId}-content`] =
        element.content;
      returnData[`sickTranslations-${element.languageId}-description`] =
        element.description;
      returnData[`sickTranslations-${element.languageId}-name`] = element.name;
      returnData[`sickTranslations-${element.languageId}-seoLink`] =
        element.seoLink;
      returnData[`sickTranslations-${element.languageId}-languageId`] =
        element.languageId;
    });
  returnData["sickNews"] = data.sickNews
    ? data.sickNews.map((x) => x.newsId)
    : [];
  returnData["listNews"] = data.sickNews
    ? data.sickNews.map((x) => {
        return { ...x, id: x.newsId };
      })
    : [];
  return returnData;
};

const SickMV = (data, languages) => {
  const _sickTranslations = [];

  languages.forEach((language) => {
    _sickTranslations.push({
      languageId: language.languageId,
      alphabet: data[`sickTranslations-${language.languageId}-alphabet`],
      name: data[`sickTranslations-${language.languageId}-name`],
      description: data[`sickTranslations-${language.languageId}-description`],
      seoLink: data[`sickTranslations-${language.languageId}-seoLink`],
      content: data[`sickTranslations-${language.languageId}-content`],
    });
  });
  const _sickNews = data.sickNews || [];
  return {
    image: data.image,
    url: data.url,
    seoLink: data.seoLink,
    isActive: data.isActive,
    isPrioritize: data.isPrioritize,
    concurrencyStamp: data.concurrencyStamp,
    sickTranslations: _sickTranslations,
    sickNews: _sickNews.map((x, index) => {
      return {
        newsId: x,
        isDefault: index === 0 ? true : false,
      };
    }),
  };
};

export { SickVM, SickMV };
