import React from 'react';
import { Popup } from "devextreme-react/popup";
import PropTypes from 'prop-types';
import PaymentData from './data';
import { CheckBox, RadioGroup } from 'devextreme-react';
import Transformer from "../../../utils/transformer";
import { Button } from 'devextreme-react/button';
import moment from 'moment';
import DateBox from 'devextreme-react/date-box';
import FileSelectPopup from "../../../components/file/FileSelectPopup";
import ScrollView from 'devextreme-react/scroll-view';

const radioValue = ['Chọn theo ngày', 'Chọn tháng trước']
const PaymentForm = ({ setShowPaymentPopup, currentLanguage, accessToken, selectedId }) => {
  const {
    unpaidList,
    paymentSelect,
    onChangePaymentSelect,
    selectDate,
    setSelectDate,
    isSelectDate,
    setIsSelectDate,
    totalAmount,
    update,
    selectFile,
    setSelectFile,
    setCheckAll,
    checkAll
  } = PaymentData({ currentLanguage, accessToken, selectedId, setShowPaymentPopup });

  return <Popup
    visible={true}
    onHiding={() => setShowPaymentPopup(false)}
    dragEnabled={false}
    closeOnOutsideClick={true}
    showCloseButton={false}
    showTitle={true}
    title="Thanh toán"
    container=".dx-viewport"
    width={700}
    height={640}
  >
    <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'space-between' }}>
      <RadioGroup
        items={radioValue}
        defaultValue={radioValue[0]}
        layout={'horizontal'}
        onValueChange={e => setIsSelectDate(e === radioValue[0])}
      />
    </div>
    {isSelectDate && <DateBox
      type={'date'}
      style={{ marginBottom: 20 }}
      useMaskBehavior={false}
      onValueChanged={(e) => setSelectDate(e.value)}
      placeholder={'Chọn ngày'}
      showClearButton={false}
      value={selectDate}
      width={200}
    />}
    <ScrollView style={{ height: 300 }}>
      <div>
        {unpaidList && unpaidList.length > 0 && <div key={'checkAll'} style={{ display: 'flex', justifyContent: 'start', marginBottom: '20px', alignItems: 'center' }}>
          <CheckBox
            style={{ marginRight: '10px' }}
            value={checkAll}
            onValueChanged={() => setCheckAll(!checkAll)}
          />
          <div style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column' }} onClick={() => setCheckAll(!checkAll)}>
            Chọn tất cả
          </div>
        </div>}
        {unpaidList && unpaidList.map((item) => {
          return <div key={item.id} style={{ display: 'flex', justifyContent: 'start', marginBottom: '20px', alignItems: 'center' }}>
            <CheckBox
              style={{ marginRight: '10px' }}
              value={paymentSelect.includes(item.id)}
              onValueChanged={() => onChangePaymentSelect(item.id)}
            />
            <div style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column' }} onClick={() => onChangePaymentSelect(item.id)}>
              <span>Tên: {item.fullName}</span>
              <span>Ngày khám: {moment(item.ngayDenKham).format("DD-MM-YYYY")}</span>
              {/* <span>Số điện thoại: {item.phoneNumber}</span> */}
              <span>Số tiền: {Transformer.convertCurrency(item.point)}</span>
            </div>
          </div>
        })}
      </div>
    </ScrollView>
    <div style={{ height: 100, marginTop: '10px' }}>
      {selectFile && <span style={{ marginBottom: '10px' }}>{selectFile.name}</span>}
      <FileSelectPopup
        value={null}
        onValueChanged={() => null}
        getAllValueChanged={(e) => setSelectFile(e)}
      />
    </div>
    <div style={{ height: 100 }}>
      <div>
        <span>Tổng số tiền: {Transformer.convertCurrency(totalAmount)}</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button icon='check' type='success' text='Thanh toán' onClick={update} />
      </div>
    </div>
  </Popup>
}

PaymentForm.propTypes = {
  accessToken: PropTypes.string,
  currentLanguage: PropTypes.string,
  setShowPaymentPopup: PropTypes.func,
  selectedId: PropTypes.number
};

export default PaymentForm;