import Form, {
    SimpleItem, GroupItem, ButtonItem,
    Label
} from 'devextreme-react/form';
import React, { useState, useEffect } from 'react';
import dictionary from '../../data/dictionary';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useHistory, useParams } from 'react-router-dom';
import 'devextreme-react/text-area';
import 'devextreme-react/tag-box';
import notify from 'devextreme/ui/notify';
import useJSON from '../../hook/useJSON';
import { WEBSITE_URL } from '../../utils/constants';

loadMessages(dictionary);

const NewsCategoryVM = (data, languages) => {
    const returnData = useJSON(data, languages);
    return returnData;
};

const BookingMV = (data, languages) => {
    const nameObj = {}; const descriptionObj = {}; const metaTitleObj = {}; const metaDescriptionObj = {};
    languages.forEach(language => {
        nameObj[[language.languageId]] = data[`name-${language.languageId}`];
        descriptionObj[[language.languageId]] = data[`description-${language.languageId}`];
        metaTitleObj[[language.languageId]] = data[`metaTitle-${language.languageId}`];
        metaDescriptionObj[[language.languageId]] = data[`metaDescription-${language.languageId}`];
    });
    const _tags = data.tags || [];

    return JSON.stringify({
        parentId: data.parentId,
        name: JSON.stringify(nameObj),
        description: JSON.stringify(descriptionObj),
        metaTitle: JSON.stringify(metaTitleObj),
        metaDescription: JSON.stringify(metaDescriptionObj),
        seoLink: data.seoLink,
        bannerImages: data.bannerImages,
        bannerImagesMobile: data.bannerImagesMobile,
        altImages: data.altImages,
        isHomePage: data.isHomePage,
        isHot: data.isHot,
        order: data.order,
        concurrencyStamp: data.concurrencyStamp,
        isActive: data.isActive,
        tags: _tags.map(x => { return ({ name: x }); })
    });
};

const WebsiteBookingDetailContainer = ({ accessToken, currentLanguage, currentWebsite, websiteLanguages }) => {
    locale(currentLanguage.substring(0, 2));
    const [booking, setBooking] = useState({});
    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        if (!params.id) return;

        fetch(`${WEBSITE_URL}Bookings/${currentWebsite}/${params.id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setBooking(NewsCategoryVM(json.data, websiteLanguages));
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    }, [accessToken, websiteLanguages, currentWebsite, params.categoryId, history]);

    const updateNewsCategory = () => {
        const _method = booking.id ? 'PUT' : 'POST';
        const _url = `${WEBSITE_URL}Bookings/${currentWebsite}${booking.id ? `/${booking.id}` : ''}`;

        return fetch(_url, {
            method: _method,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': currentLanguage
            },
            body: BookingMV(booking, websiteLanguages)
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    history.push('/booking');
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateNewsCategory();
    };

    const backButtonOptions = {
        type: 'back',
        onClick: () => {
            history.push('/booking');
        }
    };

    const saveButtonOptions = {
        text: 'Update',
        type: 'success',
        useSubmitBehavior: true
    };

    return (
        <React.Fragment>
            <Toolbar>
                <Item location="before"
                    widget="dxButton"
                    options={backButtonOptions} />
                <Item location="center"
                    text={'Chi tiết đặt hẹn từ Website'} />
            </Toolbar>

            <form action="your-action" onSubmit={handleSubmit}>
                <Form
                    formData={booking}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                    validationGroup="customerData"
                >
                    <GroupItem caption="Thông tin đặt hẹn" colCount={2}>
                        <SimpleItem dataField={'customerName'} editorType="dxTextBox" editorOptions={{ readOnly: true }}>
                            <Label text={formatMessage('customerName')} />
                        </SimpleItem>
                        <SimpleItem dataField={'customerEmail'} editorType="dxTextBox" editorOptions={{ readOnly: true }}>
                            <Label text={formatMessage('customerEmail')} />
                        </SimpleItem>
                        <SimpleItem dataField={'customerPhone'} editorType="dxTextBox" editorOptions={{ readOnly: true }}>
                            <Label text={formatMessage('customerPhone')} />
                        </SimpleItem>
                        <SimpleItem dataField={'appointmentDate'} editorType="dxDateBox" editorOptions={{ readOnly: true }}>
                            <Label text={formatMessage('appointmentDate')} />
                        </SimpleItem>
                        <SimpleItem dataField={'startTime'} editorType="dxDateBox" editorOptions={{ readOnly: true, type: 'time' }}>
                            <Label text={formatMessage('startTime')} />
                        </SimpleItem>
                        <SimpleItem dataField={'endTime'} editorType="dxDateBox" editorOptions={{ readOnly: true, type: 'time' }}>
                            <Label text={formatMessage('endTime')} />
                        </SimpleItem>
                    </GroupItem>

                    <ButtonItem horizontalAlignment="center"
                        buttonOptions={saveButtonOptions}
                    />
                </Form>
            </form>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.data.token,
        currentLanguage: state.setting.currentLanguage,
        languages: state.setting.languages,
        currentWebsite: state.website.data.currentWebsite,
        websiteLanguages: state.website.data.languages
    };
};

WebsiteBookingDetailContainer.propTypes = {
    accessToken: PropTypes.string,
    languages: PropTypes.array,
    currentLanguage: PropTypes.string,
    websiteId: PropTypes.string,
    websiteLanguages: PropTypes.array,
    currentWebsite: PropTypes.string
};

export default connect(mapStateToProps, null)(WebsiteBookingDetailContainer);
