import React, { useEffect, useState } from 'react';
import DataGrid, {
    Column,
    Lookup,
    Editing,
    RequiredRule
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CORE_URL, WEBSITE_URL } from '../../utils/constants';

const UserViewModel = (data) => {
    return data;
};

const WebsiteUsersList = ({ accessToken, websiteId }) => {
    const [users, setUsers] = useState([]);
    const history = useHistory();
    const URL = `${WEBSITE_URL}Websites/user`;

    useEffect(() => {
        fetch(`${CORE_URL}useraccounts/get-all-employee`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setUsers(json);
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    }, [accessToken, history]);

    const sendRequest = (url, method, data) => {
        method = method || 'GET';
        data = data || {};

        if (method === 'GET') {
            return fetch(url, {
                method: method,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Access-Control-Allow-Origin': '*'
                }
            }).then(result => result.json()
                .then(json => {
                    if (result.ok) return json.map((data) => UserViewModel(data));
                    throw json.Message;
                })
                .catch(() => {
                    if (result.status === 401) {
                        history.push('/login');
                    }
                })
            );
        }

        return fetch(url, {
            method: method,
            body: JSON.stringify(data.values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }).then(result => {
            if (result.ok) {
                return result.text().then(text => text && JSON.parse(text));
            } else {
                return result.json().then(json => {
                    throw json.message;
                });
            }
        });
    };

    const languages = new CustomStore({
        key: 'userId',
        load: () => sendRequest(`${URL}/get-all/${websiteId}`),
        insert: (values) => sendRequest(`${URL}/${websiteId}/${values.userId}`, 'POST', {}),
        remove: (key) => sendRequest(`${URL}/${websiteId}/${key}`, 'DELETE', {
            key: key
        })
    });

    return (
        <React.Fragment>
            <DataGrid
                dataSource={languages}
                allowColumnReordering={true}
                showBorders={true}
            >
                <Editing
                    mode="cell"
                    useIcons={true}
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={false} />
                <Column dataField="userId" dataType="string" >
                    <RequiredRule />
                    <Lookup dataSource={users} valueExpr="id" displayExpr="fullName" />
                </Column>
            </DataGrid>
        </React.Fragment>
    );
};

WebsiteUsersList.propTypes = {
    accessToken: PropTypes.string,
    websiteId: PropTypes.string
};

export default WebsiteUsersList;
