import { useCallback, useState, useEffect, useMemo } from 'react'
import notify from "devextreme/ui/notify";
import { WEBSITE_URL } from "../../../../utils/constants";

const isApprove = 1
const CustomerDetail = ({ selectedId, accessToken, currentLanguage, setShowDeclinePopup, onRefresh }) => {
    const [declineForm, setDeclineForm] = useState(null);


    useEffect(() => {
        if (selectedId) get()
    }, [selectedId, accessToken])

    const get = () => {
        return fetch(`${WEBSITE_URL}DoiNhom/ttbn-detail/${selectedId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Access-Control-Allow-Origin": "*",
                "Accept-Language": currentLanguage,
            },
        })
            .then(result => result.json())
            .then((json) => {
                if (json) {
                    setDeclineForm(json.data)
                } else {
                    console.log(json)
                    throw json.Message;
                }
            })
            .catch((err) => {
                notify(
                    {
                        message: err.message || err,
                        position: {
                            my: "center top",
                            at: "center top",
                        },
                    },
                    "error",
                    3000
                );
                throw (err.message || err);
            })
    }

    const update = useCallback(() => {
        const payload = { ...declineForm };
        if (payload['appointmentDate'] && typeof payload['appointmentDate'] !== 'string') payload['appointmentDate'] = payload.appointmentDate.toISOString()
        return fetch(`${WEBSITE_URL}DoiNhom/ttbn-update/${selectedId}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Access-Control-Allow-Origin": "*",
                "Accept-Language": currentLanguage,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload)
        })
            .then(result => result.json())
            .then((json) => {
                onRefresh(true)
                notify(
                    {
                        message: json.message,
                        position: {
                            my: "center top",
                            at: "center top",
                        },
                    },
                    "success",
                    3000
                );
                setShowDeclinePopup(false);
            })
            .catch((err) => {
                notify(
                    {
                        message: err.message || err,
                        position: {
                            my: "center top",
                            at: "center top",
                        },
                    },
                    "error",
                    3000
                );
                throw (err.message || err);
            })
    }, [selectedId, declineForm]);

    const statusOptions = useMemo(() => {
        return {
            items: [{ value: 0, text: 'Tiếp nhận' }, { value: 2, text: 'Không liên lạc' }, { value: 3, text: 'Huỷ khám' }, {value: 4, text: 'Đặt lịch hẹn khám'}],
            displayExpr: 'text',
            valueExpr: 'value',
            readOnly: declineForm ? declineForm.status === isApprove : true,
            value: declineForm ? declineForm.status === isApprove ? 'Hoàn thành' : declineForm.status : 0,
        }
    }, [declineForm])

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        update()
    }, [selectedId, declineForm]);

    return {
        statusOptions,
        handleSubmit,
        declineForm
    }
}

export default CustomerDetail