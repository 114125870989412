import Transformer from "../utils/transformer";

const PhotoVM = (data, languages) => {
  const returnData = Object.assign({}, data);

  languages.forEach((element) => {
    returnData[`title-${element.languageId}`] = Transformer.parseJSON(
      data.title,
      element.languageId
    );
    returnData[`description-${element.languageId}`] = Transformer.parseJSON(
      data.description,
      element.languageId
    );
    returnData[`alt-${element.languageId}`] = Transformer.parseJSON(
      data.alt,
      element.languageId
    );
  });

  return returnData;
};

const PhotoMV = (data, languages) => {
  const titleObj = {};
  const descriptionObj = {};
  const altObj = {};

  languages.forEach((language) => {
    titleObj[[language.languageId]] = data[`title-${language.languageId}`];
    descriptionObj[[language.languageId]] =
      data[`description-${language.languageId}`];
    altObj[[language.languageId]] = data[`alt-${language.languageId}`];
  });
  return JSON.stringify({
    title: JSON.stringify(titleObj),
    description: JSON.stringify(descriptionObj),
    alt: JSON.stringify(altObj),
    images: data.images,
    imagesMobile: data.imagesMobile,
    url: data.url,
    order: data.order,
    concurrencyStamp: data.concurrencyStamp,
    isActive: data.isActive,
  });
};

export { PhotoVM, PhotoMV };
