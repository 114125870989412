import { useCallback, useState, useMemo, useEffect } from 'react';
import { stringify } from 'querystring';
import { WEBSITE_URL } from "../../../utils/constants";
import notify from "devextreme/ui/notify";
import CustomStore from "devextreme/data/custom_store";

const pageSizes = [10, 25, 50, 100];
const searchParams = { page: 1, pageSize: 10 };
const statusNames = ['Tiếp nhận', 'Hoàn thành', 'Không liên lạc', 'Huỷ khám', 'Đặt lịch hẹn khám']

const Customer = ({ currentLanguage, accessToken }) => {
  const [totalRows, setTotalRows] = useState(0);
  const [userSearch, setUserSearch] = useState(null);

  const [status, setStatus] = useState()
  const [keyword, setKeyword] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [userId, setUserId] = useState('');

  const [selectedId, setSelectedId] = useState(null);
  const [showDeclinePopup, setShowDeclinePopup] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const isNotEmpty = (value) => {
    return value !== undefined && value !== null && value !== "";
  }

  const startDateBoxOption = {
    value: startDate,
    width: 200,
    placeholder: 'Ngày bắt đầu',
    onValueChanged: (e) => setStartDate(e.value)
  };
  const endDateBoxOption = {
    value: endDate,
    width: 200,
    placeholder: 'Ngày kết thúc',
    onValueChanged: (e) => setEndDate(e.value)
  };

  useEffect(() => {
    getUserSearch()
  }, [])

  const onRefresh = useCallback((is) => {
    setIsRefresh(is)
  }, [])

  const getList = useCallback((loadOptions) => {
    const page =
      isNotEmpty(loadOptions.skip) && isNotEmpty(loadOptions.take)
        ? loadOptions.skip / loadOptions.take + 1
        : searchParams.page;
    const pageSize = isNotEmpty(loadOptions.take)
      ? loadOptions.take
      : searchParams.pageSize;

    return fetch(`${WEBSITE_URL}DoiNhom/ttbn-search?${stringify({
      keyword,
      status,
      page,
      startDate: startDate ? startDate.toISOString(): '',
      endDate: endDate ? endDate.toISOString(): '',
      pageSize,
      userId: userId ? userId: 0
    })}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    })
      .then(result => result.json())
      .then((json) => {
        if (json.data) {
          setTotalRows(json.totalRows)
          return {
            data: json.data,
            totalCount: json.totalRows
          }
        } else {
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "error",
            3000
          );
          throw json.Message;
        }
      })
      .catch((err) => {
        notify(
          {
            message: err.message || err,
            position: {
              my: "center top",
              at: "center top",
            },
          },
          "error",
          3000
        );
        throw (err.message || err);
      })
  }, [accessToken, currentLanguage, keyword, status, userId, startDate, endDate, onRefresh]);

  const getUserSearch = useCallback(() => {
    return fetch(`${WEBSITE_URL}DoiNhom/export?${stringify({
      approvedStatus: 2,
    })}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    })
      .then(result => result.json())
      .then((json) => {
        if (json) {
          setUserSearch(json)
        } else {
          console.log(json)
          throw json?.Message ?? "Lỗi server";
        }
      })
      .catch((err) => {
        notify(
          {
            message: err.message || err,
            position: {
              my: "center top",
              at: "center top",
            },
          },
          "error",
          3000
        );
        throw (err.message || err);
      })
  }, [accessToken]);

  const data = useMemo(() => {
    setIsRefresh(false)
    return new CustomStore({
      key: "id",
      load: getList
    })
  }, [keyword, status, userId, startDate, endDate, isRefresh]);

  const onToolbarPreparing = useCallback((e) => {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.push(
      {
        location: "after",
        widget: "dxTextBox",
        options: {
          placeholder: "Nhập từ khóa tìm kiếm",
          value: keyword,
          width: 200,
          onValueChanged: (e) => setKeyword(e.value),
        },
      },
      {
        location: "after",
        widget: "dxSelectBox",
        options: {
          placeholder: "Cộng tác viên",
          value: userId,
          width: 200,
          displayExpr: "fullName",
          valueExpr: "id",
          showClearButton: true,
          items: userSearch,
          onValueChanged: (e) => setUserId(e.value),
        },
      },
      {
        location: 'after',
        widget: 'dxDateBox',
        options: startDateBoxOption
      },
      {
        location: 'after',
        widget: 'dxDateBox',
        options: endDateBoxOption
      },
      {
        location: "after",
        widget: "dxSelectBox",
        options: {
          placeholder: "Trạng thái phê duyệt",
          value: status,
          width: 200,
          displayExpr: "text",
          valueExpr: "value",
          showClearButton: true,
          items: [{ value: 0, text: 'Tiếp nhận' }, { value: 1, text: 'Hoàn thành' }, { value: 2, text: 'Không liên lạc' }, { value: 3, text: 'Huỷ khám' }, {value: 4, text: 'Đặt lịch hẹn khám'}],
          onValueChanged: (e) => setStatus(e.value),
        },
      }
    );
  }, [setKeyword, setStatus, userSearch]);

  const statusCellRender = ({ data: { status } }) => {
    if (statusNames[status]) {
      return statusNames[status]
    }
  };

  return {
    getList,
    data,
    onToolbarPreparing,
    pageSizes,
    totalRows,
    statusCellRender,
    selectedId,
    setSelectedId,
    showDeclinePopup,
    setShowDeclinePopup,
    onRefresh
  }
}

export default Customer;