import { createStore, applyMiddleware } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';
import reducers from './reducers';

const createStoreWithMiddleware = applyMiddleware(apiMiddleware, thunk)(createStore);

export default function configureStore(initialState = {}) {
    return createStoreWithMiddleware(reducers, initialState);
}
