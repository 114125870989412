import React from "react";
import { DataGrid, Column } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import PropTypes from "prop-types";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import { FILE_URL, WEBSITE_URL } from "../../utils/constants";
import { BannerItemVM } from "../../model/Banner";

loadMessages(dictionary);

const BannerItemsMasterView = ({
  masterData,
  currentWebsite,
  currentLanguage,
  accessToken,
  languages,
}) => {
  locale(currentLanguage.substring(0, 2));
  const URL = `${WEBSITE_URL}Banners/item/get-all/${currentWebsite}/${masterData.data.key}`;

  const loadRequest = (url) => {
    return fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return {
            data: json.map((image) => BannerItemVM(image, languages)),
          };
        }
        throw json.Message;
      })
    );
  };

  const bannerItemsData = new CustomStore({
    key: "id",
    load: () => loadRequest(`${URL}`),
  });

  const imgCellRender = (data) => {
    return (
      <img
        alt="GHM Image"
        src={`${FILE_URL}/${data.value}`}
        style={{ height: 100 }}
      />
    );
  };

  return (
    <React.Fragment>
      <DataGrid
        dataSource={bannerItemsData}
        showBorders={true}
        selection={{ mode: "single" }}
        columnAutoWidth={true}
      >
        <Column dataField="images" cellRender={imgCellRender} />
        <Column dataField="imagesMobile" cellRender={imgCellRender} />
        <Column dataField="url" />
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`name-${item.languageId}`}
            dataType="string"
            caption={formatMessage("name")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`description-${item.languageId}`}
            dataType="string"
            caption={formatMessage("description")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`alt-${item.languageId}`}
            dataType="string"
            caption={formatMessage("alt")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        <Column
          dataField="order"
          dataType="string"
          caption={formatMessage("order")}
        />
        <Column
          dataField="isActive"
          dataType="boolean"
          caption={formatMessage("isActive")}
        />
      </DataGrid>
    </React.Fragment>
  );
};

BannerItemsMasterView.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  currentWebsite: PropTypes.string,
  masterData: PropTypes.object,
};

export default BannerItemsMasterView;
