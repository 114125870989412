import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import React, { useState, useRef, useEffect } from "react";
import ScrollView from "devextreme-react/scroll-view";
import FileMgr from "./FileMgr";
import { Button } from "devextreme-react/button";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import notify from "devextreme/ui/notify";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import { FILE_URL } from "../../utils/constants";
import VideoElement from "../VideoElement";
import Box, { Item } from "devextreme-react/box";

loadMessages(dictionary);

const VideoSelectPopup = ({
  accessToken,
  value,
  onValueChanged,
  currentLanguage,
  type,
  maxFileSize
}) => {
  locale(currentLanguage.substring(0, 2));
  const [url, setUrl] = useState(value);
  const [popupVisible, setPopupVisible] = useState(false);
  const fileRef = useRef(null);

  useEffect(() => {
    if (value && value !== "") {
      setUrl(value);
    } else {
      setUrl("");
    }
  }, [value]);

  const selectFiles = () => {
    const selectFiles = fileRef.current.instance.getSelectedItems();
    if (selectFiles && selectFiles.length > 0) {
      setPopupVisible(false);
      const _value = selectFiles[0].dataItem.url;
      setUrl(_value);
      onValueChanged(_value);
    } else {
      notify("Please select file", "error", 500);
    }
  };

  const selectButtonOptions = {
    icon: "save",
    text: "Save",
    onClick: () => selectFiles(),
  };

  const closeButtonOptions = {
    text: "Close",
    onClick: () => hideInfo(),
  };

  const hideInfo = () => {
    setPopupVisible(false);
  };

  const showInfo = () => {
    setPopupVisible(true);
  };

  const renderPopup = () => {
    return (
      <React.Fragment>
        <ScrollView width="100%" height="100%" showScrollbar={"never"}>
          <FileMgr

            selectionMode={"single"}
            ref={fileRef}
            allowedFileExtensions={[".mp3", ".mp4"]}
            accessToken={accessToken}
            maxFileSize={maxFileSize}
          />
        </ScrollView>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div style={{ marginBottom: "10px" }}>
        <a
          target="_blank"
          rel="noreferrer"
          href={`${FILE_URL}${url}`}
        >{`${FILE_URL}${url}`}</a>
      </div>

      <Box direction="row" width="100%">
        <Item ratio={2}>
          <VideoElement
            src={`${FILE_URL}${url}`}
            height={type === 2 ? "50px" : "200px"}
            width={"300px"}
          />
        </Item>

        <Item ratio={0} baseSize={20}></Item>

        <Item ratio={1}>
          <Button
            className="button-info"
            text={formatMessage("selectFile")}
            icon="photo"
            onClick={showInfo}
          />
        </Item>
      </Box>
      {popupVisible && (
        <Popup
          visible={true}
          onHiding={() => console.log("on hidding")}
          dragEnabled={false}
          closeOnOutsideClick={false}
          showCloseButton={false}
          showTitle={true}
          title={"Select File"}
          container=".dx-viewport"
          width={800}
          height={670}
          // maxHeight={'80%'}
          // maxWidth={'80%'}
          contentRender={renderPopup}
        >
          <Position at="center" my="center" />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="before"
            options={selectButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={closeButtonOptions}
          />
        </Popup>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
  };
};

VideoSelectPopup.propTypes = {
  accessToken: PropTypes.string,
  value: PropTypes.string,
  onValueChanged: PropTypes.func,
  currentLanguage: PropTypes.string,
  type: PropTypes.number,
  maxFileSize: PropTypes.number
};

export default connect(mapStateToProps, null)(VideoSelectPopup);
