import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Grouping,
  Button,
} from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import { useHistory } from "react-router-dom";
import { FILE_URL, WEBSITE_URL } from "../../../utils/constants";
import dictionary from "../../../data/dictionary";
import { Popup } from "devextreme-react/popup";
import PhotoDetail from "./PhotoDetail";
import ScrollView from "devextreme-react/scroll-view";

loadMessages(dictionary);

const parseJSON = (str, languageId) => {
  let _strObj;
  try {
    _strObj = JSON.parse(str);
  } catch (e) {
    return str || "";
  }
  return _strObj ? _strObj[languageId] || "" : "";
};

const PhotoVM = (data, languages) => {
  const returnData = Object.assign({}, data);

  languages.forEach((element) => {
    returnData[`title-${element.languageId}`] = parseJSON(
      data.title,
      element.languageId
    );
    returnData[`description-${element.languageId}`] = parseJSON(
      data.description,
      element.languageId
    );
    returnData[`alt-${element.languageId}`] = parseJSON(
      data.alt,
      element.languageId
    );
  });

  return returnData;
};

const PhotosList = ({
  accessToken,
  currentLanguage,
  websiteId,
  albumId,
  languages,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const [photos, setPhotos] = useState([]);
  const [showPhotoPopup, setShowPhotoPopup] = useState(false);
  const [photoId, setPhotoId] = useState(null);

  const imgCellRender = (data) => {
    return (
      <img
        style={{ objectFit: "cover", height: 50 }}
        src={`${FILE_URL}${data.value}`}
      />
    );
  };

  const refreshList = () => {
    fetch(`${WEBSITE_URL}Albums/photo/get-all/${websiteId}/${albumId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setPhotos(json.map((photo) => PhotoVM(photo, languages)));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/expiredToken");
          }
        })
    );
  };

  useEffect(() => {
    refreshList();
  }, []);

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            showPhotoDetailPopup();
          },
        };
      }
    });

    toolbarItems.unshift({
      location: "center",
      text: "Danh sách Ảnh",
    });
  };

  const showPhotoDetailPopup = (_photoId = null) => {
    setPhotoId(_photoId);
    setShowPhotoPopup(true);
  };

  const hidePhotoDetailPopup = () => {
    refreshList();
    setShowPhotoPopup(false);
    setPhotoId(null);
  };

  return (
    <React.Fragment>
      <DataGrid
        id="grid"
        showBorders={true}
        dataSource={photos}
        repaintChangesOnly={true}
        selection={{ mode: "single" }}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Grouping autoExpandAll={true} />
        <Editing
          refreshMode={"reshape"}
          mode="form"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
        />

        <Scrolling rowRenderingMode="virtual" />
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`title-${item.languageId}`}
            dataType="string"
            caption={formatMessage("title")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`description-${item.languageId}`}
            dataType="string"
            caption={formatMessage("description")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`alt-${item.languageId}`}
            dataType="string"
            caption={formatMessage("alt")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}

        <Column
          dataField="url"
          caption={formatMessage("image")}
          allowSorting={false}
          cellRender={imgCellRender}
          // editCellRender={imgEditCellRender}
        />
        <Column
          dataField="order"
          caption={formatMessage("order")}
          dataType="number"
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => showPhotoDetailPopup(e.row.data.id)}
          />
          <Button name="delete" />
        </Column>
      </DataGrid>

      <Popup
        visible={showPhotoPopup}
        onHiding={() => setShowPhotoPopup(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showCloseButton={false}
        showTitle={false}
        container=".dx-viewport"
      >
        <ScrollView width="100%" heigh="100%">
          <PhotoDetail
            accessToken={accessToken}
            currentLanguage={currentLanguage}
            currentWebsite={websiteId}
            websiteLanguages={languages}
            id={photoId}
            albumId={albumId}
            hidePopup={hidePhotoDetailPopup}
          />
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
};

PhotosList.propTypes = {
  accessToken: PropTypes.string,
  websiteId: PropTypes.string,
  currentLanguage: PropTypes.string,
  albumId: PropTypes.string,
  languages: PropTypes.array,
};

export default PhotosList;
