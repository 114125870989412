import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Grouping,
  Button,
} from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import React from "react";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import CustomStore from "devextreme/data/custom_store";
import dictionary from "../../data/dictionary";
import { useHistory } from "react-router-dom";
import notify from "devextreme/ui/notify";
import { FILE_URL, WEBSITE_URL } from "../../utils/constants";
import { BranchContactVM } from "../../model/BranchContact";

loadMessages(dictionary);

const BranchContactsList = ({
  currentLanguage,
  currentWebsite,
  accessToken,
  languages,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const URL = `${WEBSITE_URL}BranchContacts/${currentWebsite}`;

  const loadRequest = (url) => {
    return fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok)
            return json.data.map((data) => BranchContactVM(data, languages));
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const deleteRequest = (url) => {
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        notify(
          {
            message: json.message,
            position: {
              my: "center top",
              at: "center top",
            },
          },
          "success",
          3000
        );
      })
    );
  };

  const branchContactsData = new CustomStore({
    key: "id",
    load: () => loadRequest(`${URL}`),
    remove: (key) => deleteRequest(`${URL}/${key}`),
  });

  const imgCellRender = (data) => {
    return (
      <img
        alt="GHM Image"
        style={{ objectFit: "cover", height: 30 }}
        src={`${FILE_URL}${data.value}`}
      />
    );
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            // Implement custom save logic here
            history.push("/branchContact/add");
          },
        };
      }
    });

    toolbarItems.unshift({
      location: "center",
      text: "Danh sách BranchContacts",
    });
  };

  const gotoDetailPage = (branchContactId) => {
    history.push(`/branchContact/${branchContactId}`);
  };

  return (
    <React.Fragment>
      <DataGrid
        id="grid"
        showBorders={true}
        dataSource={branchContactsData}
        repaintChangesOnly={true}
        selection={{ mode: "single" }}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Grouping autoExpandAll={true} />
        <Editing
          refreshMode={"reshape"}
          mode="form"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
        />

        <Scrolling rowRenderingMode="virtual" />

        <Column
          dataField={`name-${currentLanguage}`}
          caption={formatMessage("name")}
          dataType="string"
        />
        <Column
          dataField={`workTime-${currentLanguage}`}
          caption={formatMessage("workTime")}
          dataType="string"
        />
        <Column
          dataField="logo"
          caption={formatMessage("logo")}
          allowSorting={false}
          cellRender={imgCellRender}
        />
        <Column
          dataField={`address-${currentLanguage}`}
          caption={formatMessage("address")}
          dataType="string"
        />
        <Column
          dataField={`description-${currentLanguage}`}
          caption={formatMessage("description")}
          dataType="string"
        />

        <Column
          dataField="googleMap"
          caption={formatMessage("googleMap")}
          dataType="string"
        />
        <Column
          dataField="link"
          caption={formatMessage("link")}
          dataType="string"
        />
        <Column
          dataField="order"
          caption={formatMessage("order")}
          dataType="number"
        />
        <Column
          dataField="isActive"
          caption={formatMessage("isActive")}
          dataType="boolean"
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => gotoDetailPage(e.row.key)}
          />
        </Column>
      </DataGrid>
    </React.Fragment>
  );
};

BranchContactsList.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentWebsite: PropTypes.string,
  currentLanguage: PropTypes.string,
};

export default BranchContactsList;
