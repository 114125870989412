import { connect } from 'react-redux';
import DataGrid, {
    Editing,
    Column,
    Pager,
    Paging,
    SearchPanel, Button
} from 'devextreme-react/data-grid';
import React from 'react';
import PropTypes from 'prop-types';
import CustomStore from 'devextreme/data/custom_store';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import dictionary from '../../data/dictionary';
import { useHistory } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
import { WEBSITE_URL } from '../../utils/constants';

loadMessages(dictionary);
const pageSizes = [10, 25, 50, 100];

const parseJSON = (str, languageId) => {
    let _strObj;
    try {
        _strObj = JSON.parse(str);
    } catch (e) {
        return str || '';
    }
    return _strObj ? _strObj[languageId] || '' : '';
};

const FeedbackVM = (data, languages) => {
    const returnData = Object.assign({}, data);

    languages.forEach(element => {
        returnData[`title-${element.languageId}`] = parseJSON(data.title, element.languageId);
        returnData[`content-${element.languageId}`] = parseJSON(data.content, element.languageId);
        returnData[`description-${element.languageId}`] = parseJSON(data.description, element.languageId);
    });

    return returnData;
};

const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';
const searchParams = { page: 1, pageSize: 20 };

const FeedbackContainer = ({ accessToken, currentLanguage, websiteLanguages, currentWebsite }) => {
    locale(currentLanguage.substring(0, 2));
    const history = useHistory();
    const URL = `${WEBSITE_URL}Feedbacks/${currentWebsite}`;

    const loadRequest = (url, loadOptions) => {
        const _page = (isNotEmpty(loadOptions.skip) && isNotEmpty(loadOptions.take)) ? (loadOptions.skip / loadOptions.take + 1) : searchParams.page;
        const _pageSize = isNotEmpty(loadOptions.take) ? loadOptions.take : searchParams.pageSize;

        return fetch(`${url}?page=${_page}&pageSize=${_pageSize}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': currentLanguage
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    return {
                        data: json.data.map((data) => FeedbackVM(data, websiteLanguages)),
                        totalCount: json.totalRows
                    };
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const onToolbarPreparing = (e) => {
        const toolbarItems = e.toolbarOptions.items;

        toolbarItems.push({
            location: 'center',
            text: 'Danh sách Phản hồi'
        });
    };

    const deleteRequest = (url) => {
        return fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'Accept-Language': currentLanguage
            }
        }).then(result => {
            if (result.ok) {
                return result.text().then(text => {
                    notify({
                        message: JSON.parse(text).message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                }
                );
            } else {
                return result.json().then(json => {
                    throw json.Message;
                });
            }
        });
    };

    const newsData = new CustomStore({
        key: 'id',
        load: (loadOptions) => loadRequest(`${URL}`, loadOptions),
        remove: (key) => deleteRequest(`${URL}/${key}`)
    });

    const gotoDetailPage = (feedbackId) => {
        history.push(`/feedback/${feedbackId}`);
    };

    return (
        <React.Fragment>
            <DataGrid
                keyExpr="id"
                dataSource={newsData}
                allowColumnReordering={true}
                remoteOperations={true}
                selection={{ mode: 'single' }}
                showBorders={true}
                onToolbarPreparing={onToolbarPreparing}
            >
                <Editing
                    useIcons={true}
                    allowDeleting={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />

                <Column dataField="email" dataType="string" caption={formatMessage('email')} />
                <Column dataField="fullName" dataType="string" caption={formatMessage('fullName')} />
                <Column dataField="phoneNumber" dataType="string" caption={formatMessage('phoneNumber')} />
                {websiteLanguages.map((item, index) => (
                    <Column key={index} dataField={`title-${item.languageId}`}
                        dataType="string" caption={formatMessage('title')}
                        visible={item.languageId === currentLanguage} />
                ))}

                {websiteLanguages.map((item, index) => (
                    <Column key={index} dataField={`content-${item.languageId}`}
                        dataType="string" caption={formatMessage('content')}
                        visible={item.languageId === currentLanguage} />
                ))}

                {websiteLanguages.map((item, index) => (
                    <Column key={index} dataField={`description-${item.languageId}`}
                        dataType="string" caption={formatMessage('description')}
                        visible={item.languageId === currentLanguage} />
                ))}
                <Column dataField="serviceName" dataType="string" caption={'Tên dịch vụ'} />

                <Column dataField="isView" dataType="boolean" caption={formatMessage('isView')} />


                <Column type="buttons" caption={formatMessage('actionCol')}>
                    <Button name="delete" />
                    <Button hint="Info" icon="info" onClick={(e) => gotoDetailPage(e.row.key)} />
                </Column>

                <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                <Paging defaultPageSize={10} />
            </DataGrid>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        accessToken: state.auth.data.token,
        currentLanguage: state.setting.currentLanguage,
        languages: state.setting.languages,
        currentWebsite: state.website.data.currentWebsite,
        websiteLanguages: state.website.data.languages
    };
};

FeedbackContainer.propTypes = {
    accessToken: PropTypes.string,
    languages: PropTypes.array,
    currentLanguage: PropTypes.string,
    websiteId: PropTypes.string,
    websiteLanguages: PropTypes.array,
    currentWebsite: PropTypes.string,
    currentWebsiteLanguage: PropTypes.string
};

export default connect(mapStateToProps, null)(FeedbackContainer);
