import React, { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import './user-panel.scss';
import PropsType from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../actions/authActions';
import { FILE_URL, CORE_URL, FIREAPI_URL } from '../utils/constants';
import notify from "devextreme/ui/notify";

const UserPanel = ({ menuMode, logout, user, token, logoutError }) => {
    const history = useHistory();
    const [currentUserId, setCurrentUserId] = useState(null);

    function navigateToProfile() {
        history.push('/profile');
    }

    useEffect(() => {
        getCurrentUser().then(id => setCurrentUserId(id))
    }, [])

    const getCurrentUser = () => {
        return fetch(`${CORE_URL}AppSettings`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
                "Accept-Language": 'vi-VN',
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    return json.currentUser.id;
                }
                throw json.Message;
            })
            .catch(() => {
                notify(
                    {
                        message: result.message || result,
                        position: {
                            my: "right top",
                            at: "right top",
                        },
                    },
                    "error",
                    3000
                );
                if (result.status === 401) {
                    history.push('/expiredToken');
                }
            })
        );


    }

    const deleteFirebaseToken = (token, userId) => {
        return fetch(`${FIREAPI_URL}UserTokens/user/${userId}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        })
            .then(result => result.json())
            .then((json) => {
                if (json) {
                    console.log(json)
                } else {
                    console.log(json)
                    throw json.Message;
                }
            })
            .catch((err) => {
                notify(
                    {
                        message: err.message || err,
                        position: {
                            my: "right top",
                            at: "right top",
                        },
                    },
                    "error",
                    3000
                );
                throw (err.message || err);
            })
    }

    const menuItems = useMemo(() => ([
        {
            text: 'Profile',
            icon: 'user',
            onClick: navigateToProfile
        },
        {
            text: 'Logout',
            icon: 'runner',
            onClick: async () => {
               if (currentUserId) deleteFirebaseToken(token, currentUserId).then(() => logout(token))
               else logout(token)
            }
        }
    ]), [currentUserId]);

    useEffect(() => {
        if (logoutError) {
            history.push('/expiredToken');
        }
    }, [logoutError]);

    return (
        <div className={'user-panel'}>
            <div className={'user-info'}>
                <div className={'image-container'}>
                    <div
                        style={{
                            background: `url(${FILE_URL}${user.avatar}) no-repeat #fff`,
                            backgroundSize: 'cover'
                        }}
                        className={'user-image'} />
                </div>
                <div className={'user-name dx-theme-text-color'}>{user.fullName}</div>
            </div>

            {menuMode === 'context' && (
                <ContextMenu
                    items={menuItems}
                    target={'.user-button'}
                    showEvent={'dxclick'}
                    width={210}
                    cssClass={'user-menu'}
                >
                    <Position my={'top center'} at={'bottom center'} />
                </ContextMenu>
            )}
            {menuMode === 'list' && (
                <List className={'dx-toolbar-menu-action'} items={menuItems} />
            )}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        user: {
            email: "admin@ghmsoft.vn",
            fullName: "GHMSoft Admin"
        },
        token: state.auth.data.token,
        logoutError: state.auth.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (token) => {
            return dispatch(logout(token));
        }
    };
};

UserPanel.propTypes = {
    menuMode: PropsType.string,
    logout: PropsType.func,
    user: PropsType.object,
    token: PropsType.string,
    logoutError: PropsType.object,
    accessToken: PropsType.string
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel);
