import Form, {
  SimpleItem,
  GroupItem,
  TabbedItem,
  ButtonItem,
  Label,
  Tab,
  RangeRule,
  RequiredRule,
} from "devextreme-react/form";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { useHistory } from "react-router-dom";
import "devextreme-react/text-area";
import "devextreme-react/tag-box";
import notify from "devextreme/ui/notify";
import FileSelectPopup from "../../file/FileSelectPopup";
import dictionary from "../../../data/dictionary";
import { WEBSITE_URL } from "../../../utils/constants";
import { PhotoMV, PhotoVM } from "../../../model/Photo";

loadMessages(dictionary);

const tabsMock = [
  {
    id: 0,
    text: "vi-VN",
    icon: "globe",
    content: "Vietnamese",
  },
];

const notesEditorOptions = { height: 100 };

const PhotoDetail = ({
  accessToken,
  currentLanguage,
  currentWebsite,
  websiteLanguages,
  albumId,
  id,
  hidePopup,
}) => {
  locale(currentLanguage.substring(0, 2));
  const [tabs, setTabs] = useState(tabsMock);
  const [photo, setPhoto] = useState({ order: 0, isActive: true });
  const history = useHistory();

  useEffect(() => {
    if (!id) {
      setPhoto({ order: 0, isActive: true });
      return;
    }

    fetch(`${WEBSITE_URL}Albums/photo/${currentWebsite}/${albumId}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setPhoto(PhotoVM(json.data, websiteLanguages));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [id, websiteLanguages, currentWebsite, albumId, accessToken, history]);

  const updateBannerItem = () => {
    const _method = photo.id ? "PUT" : "POST";
    const _url = `${WEBSITE_URL}Albums/photo/${currentWebsite}/${albumId}${
      photo.id ? `/${photo.id}` : ""
    }`;

    return fetch(_url, {
      method: _method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
      body: PhotoMV(photo, websiteLanguages),
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
            setPhoto({ order: 0 });
            hidePopup();
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateBannerItem();
  };

  useEffect(() => {
    const _tabs = websiteLanguages.map((item, index) => ({
      id: index,
      languageId: item.languageId,
      text: item.name,
      icon: "globe",
      content: "User tab content",
    }));
    setTabs(_tabs);
  }, [websiteLanguages]);

  const updateUrl = (e) => {
    setPhoto((prevState) => ({ ...prevState, url: e }));
  };

  const saveButtonOptions = {
    text: "Update",
    type: "success",
    useSubmitBehavior: true,
  };

  return (
    <React.Fragment>
      <Toolbar>
        <Item location="center" text={"Chi tiết Photo"} />
      </Toolbar>

      <form action="your-action" onSubmit={handleSubmit}>
        <Form
          formData={photo}
          readOnly={false}
          showColonAfterLabel={true}
          showValidationSummary={true}
          validationGroup="bannerItemData"
        >
          <GroupItem colCount={2}>
            <TabbedItem>
              {tabs.map((item) => {
                return (
                  <Tab key={item.id} title={item.text} icon={item.icon}>
                    <SimpleItem
                      dataField={`title-${item.languageId}`}
                      editorType="dxTextBox"
                    >
                      <Label text={formatMessage("title")} />
                      <RequiredRule />
                    </SimpleItem>
                    <SimpleItem
                      dataField={`description-${item.languageId}`}
                      editorType="dxTextArea"
                      editorOptions={notesEditorOptions}
                    >
                      <Label text={formatMessage("description")} />
                    </SimpleItem>

                    <SimpleItem
                      dataField={`alt-${item.languageId}`}
                      editorType="dxTextBox"
                    >
                      <Label text={formatMessage("alt")} />
                    </SimpleItem>
                  </Tab>
                );
              })}
            </TabbedItem>

            <GroupItem>
              <SimpleItem colSpan={2}>
                <Label text={formatMessage("url")} />
                <FileSelectPopup
                  value={photo.url}
                  imgHeight={100}
                  onValueChanged={updateUrl}
                />
                <RequiredRule />
              </SimpleItem>

              <SimpleItem dataField={"order"} editorType="dxNumberBox">
                <Label text={formatMessage("order")} />
                <RangeRule
                  min={0}
                  message={`${formatMessage("order")} ${formatMessage(
                    "errMesInvalidNum"
                  )}`}
                />
              </SimpleItem>
            </GroupItem>
          </GroupItem>

          <ButtonItem
            colSpan={3}
            horizontalAlignment="center"
            validationGroup="bannerItemData"
            buttonOptions={saveButtonOptions}
          />
        </Form>
      </form>
    </React.Fragment>
  );
};

PhotoDetail.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
  albumId: PropTypes.string,
  id: PropTypes.string,
  hidePopup: PropTypes.func,
};

export default PhotoDetail;
