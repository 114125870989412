import React from "react";
import PropTypes from "prop-types";

export default function VideoElement({ src, width, height }) {
  return (
    <div>
      <video
        style={{
          width: width || "500px",
          height: height || "50px",
          maxWidth: "100%",
        }}
        controls
        autoPlay={false}
        src={src}
      />
    </div>
  );
}

VideoElement.propTypes = {
  src: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
};
