import { ALBUM_TYPE } from "../utils/constants";
import Transformer from "../utils/transformer";

const AlbumVM = (data, languages, currentLanguage) => {
  const returnData = Object.assign({}, data);

  languages.forEach((element) => {
    returnData[`name-${element.languageId}`] = Transformer.parseJSON(
      data.name,
      element.languageId
    );
    returnData[`description-${element.languageId}`] = Transformer.parseJSON(
      data.description,
      element.languageId
    );
    returnData[`metaTitle-${element.languageId}`] = Transformer.parseJSON(
      data.metaTitle,
      element.languageId
    );
    returnData[`metaKeyword-${element.languageId}`] = Transformer.parseJSON(
      data.metaKeyword,
      element.languageId
    );
    returnData[`metaDescription-${element.languageId}`] = Transformer.parseJSON(
      data.metaDescription,
      element.languageId
    );
  });
  returnData["typeName"] = Transformer.parseJSON(
    ALBUM_TYPE[data.type].name,
    currentLanguage
  );

  return returnData;
};

const AlbumMV = (data, languages) => {
  const multiLangData = {
    name: {},
    description: {},
    metaTitle: {},
    metaDescription: {},
    metaKeyword: {},
  };

  languages.forEach((element) => {
    multiLangData["description"] = {
      ...multiLangData["description"],
      [element.languageId]: data[`description-${element.languageId}`],
    };
    multiLangData["name"] = {
      ...multiLangData["name"],
      [element.languageId]: data[`name-${element.languageId}`],
    };

    multiLangData["metaTitle"] = {
      ...multiLangData["metaTitle"],
      [element.languageId]: data[`metaTitle-${element.languageId}`],
    };
    multiLangData["metaDescription"] = {
      ...multiLangData["metaDescription"],
      [element.languageId]: data[`metaDescription-${element.languageId}`],
    };
    multiLangData["metaKeyword"] = {
      ...multiLangData["metaKeyword"],
      [element.languageId]: data[`metaKeyword-${element.languageId}`],
    };
  });

  return  JSON.stringify({
    name: JSON.stringify(multiLangData.name),
    description: JSON.stringify(multiLangData.description),
    seoLink: data.seoLink,
    metaTitle: JSON.stringify(multiLangData.metaTitle),
    metaDescription: JSON.stringify(multiLangData.metaDescription),
    metaKeyword: JSON.stringify(multiLangData.metaKeyword),
    ...data
  });
};

export { AlbumVM, AlbumMV };
