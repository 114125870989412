const AdvertisementVM = (data) => {
  const returnData = Object.assign({}, data);

  return returnData;
};

const AdvertisementMV = (data) => {
  return {
    title: data.title,
    background: data.background,
    floor: data.floor,
    area: data.area,
  };
};

const AdvertisementFileVM = (data) => {
  const returnData = Object.assign({}, data);

  returnData["urlTxt"] = data.url;
  return returnData;
};

const AdvertisementFileMV = (data) => {
  return {
    url: data.url,
    type: data.type,
    floor: data.floor,
    area: data.area,
  };
};

export {
  AdvertisementMV,
  AdvertisementVM,
  AdvertisementFileVM,
  AdvertisementFileMV,
};
