import React, { useState } from "react";
import DataGrid, { Editing, Column, Button } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import PropTypes from "prop-types";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../../data/dictionary";
import notify from "devextreme/ui/notify";
import { Popup } from "devextreme-react/popup";
import WebsiteServiceFAQDetail from "./WebsiteServiceFAQDetail";
import { FILE_URL, WEBSITE_URL } from "../../../utils/constants";
import ScrollView from "devextreme-react/scroll-view";

loadMessages(dictionary);

const parseJSON = (str, languageId) => {
  let _strObj;
  try {
    _strObj = JSON.parse(str);
  } catch (e) {
    return "";
  }
  return _strObj ? _strObj[languageId] || "" : "";
};

const ServiceFAQVM = (data, languages) => {
  const returnData = Object.assign({}, data);

  languages.forEach((element) => {
    returnData[`question-${element.languageId}`] = parseJSON(
      data.question,
      element.languageId
    );
    returnData[`answer-${element.languageId}`] = parseJSON(
      data.answer,
      element.languageId
    );
  });

  return returnData;
};

const WebsiteServiceFAQsList = ({
  serviceId,
  currentWebsite,
  currentLanguage,
  accessToken,
  languages,
}) => {
  locale(currentLanguage.substring(0, 2));
  const URL = `${WEBSITE_URL}Services/faq/get-all/${currentWebsite}/${serviceId}`;
  const [showItemPopup, setShowItemPopup] = useState(false);
  const [serviceFAQId, setServiceFAQId] = useState(null);

  const loadRequest = (url) => {
    return fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return {
            data: json.map((image) => ServiceFAQVM(image, languages)),
          };
        }
        throw json.Message;
      })
    );
  };

  const removeRequest = (url) => {
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          if (text) {
            const _response = JSON.parse(text);
            notify(
              {
                message: _response.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );

            return _response;
          }
        });
      } else {
        return result.json().then((json) => {
          throw json.Message;
        });
      }
    });
  };

  const serviceItemsData = new CustomStore({
    key: "id",
    load: () => loadRequest(`${URL}`),
    remove: (key) => removeRequest(`${URL}/${key}`),
  });

  const imgCellRender = (data) => {
    return (
      <img
        alt="GHM Image"
        src={`${FILE_URL}/${data.value}`}
        style={{ height: 100 }}
      />
    );
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            showServiceFAQDetail();
          },
        };
      }
    });

    toolbarItems.push({
      location: "center",
      text: "Danh sách Service FAQs",
    });
  };

  const showServiceFAQDetail = (_serviceFAQId) => {
    setServiceFAQId(_serviceFAQId);
    setShowItemPopup(true);
  };

  const hideItemDetailPopup = () => {
    setShowItemPopup(false);
    setServiceFAQId(null);
  };

  return (
    <React.Fragment>
      <DataGrid
        dataSource={serviceItemsData}
        showBorders={true}
        columnAutoWidth={true}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Editing
          mode="form"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
        />

        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`question-${item.languageId}`}
            dataType="string"
            caption={formatMessage("question")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`answer-${item.languageId}`}
            dataType="string"
            caption={formatMessage("answer")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        <Column dataField="image" cellRender={imgCellRender} />
        <Column
          dataField="alt"
          dataType="string"
          caption={formatMessage("alt")}
        />
        <Column
          dataField="order"
          dataType="number"
          caption={formatMessage("order")}
        />
        <Column
          dataField="isActive"
          dataType="boolean"
          caption={formatMessage("isActive")}
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => showServiceFAQDetail(e.row.key)}
          />
        </Column>
      </DataGrid>

      <Popup
        id="serviceFAQPopup"
        visible={showItemPopup}
        onHiding={() => setShowItemPopup(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showCloseButton={false}
        showTitle={false}
        container=".dx-viewport"
      >
        <ScrollView width="100%" height="100%">
          <WebsiteServiceFAQDetail
            accessToken={accessToken}
            currentLanguage={currentLanguage}
            currentWebsite={currentWebsite}
            websiteLanguages={languages}
            serviceId={serviceId}
            id={serviceFAQId}
            hidePopup={hideItemDetailPopup}
          />
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
};

WebsiteServiceFAQsList.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  currentWebsite: PropTypes.string,
  items: PropTypes.array,
  serviceId: PropTypes.string,
};

export default WebsiteServiceFAQsList;
