import React, { useState } from "react";
import DataGrid, { Editing, Column, Button } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import PropTypes from "prop-types";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../../data/dictionary";
import notify from "devextreme/ui/notify";
import { Popup } from "devextreme-react/popup";
import WebsiteServiceTitleDetail from "./WebsiteServiceTitleDetail";
import { FILE_URL, WEBSITE_URL } from "../../../utils/constants";
import ScrollView from "devextreme-react/scroll-view";
import { ServiceTitleVM } from "../../../model/ServiceTitle";

loadMessages(dictionary);

const WebsiteServiceTitlesList = ({
  serviceId,
  currentWebsite,
  currentLanguage,
  accessToken,
  languages,
}) => {
  locale(currentLanguage.substring(0, 2));
  const URL = `${WEBSITE_URL}Services/title/get-all/${currentWebsite}/${serviceId}`;
  const [showItemPopup, setShowItemPopup] = useState(false);
  const [serviceTitleId, setServiceTitleId] = useState(null);

  const loadRequest = (url) => {
    return fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return {
            data: json.map((image) => ServiceTitleVM(image, languages)),
          };
        }
        throw json.Message;
      })
    );
  };

  const removeRequest = (url) => {
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          if (text) {
            const _response = JSON.parse(text);
            notify(
              {
                message: _response.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );

            return _response;
          }
        });
      } else {
        return result.json().then((json) => {
          throw json.Message;
        });
      }
    });
  };

  const serviceItemsData = new CustomStore({
    key: "id",
    load: () => loadRequest(`${URL}`),
    remove: (key) =>
      removeRequest(
        `${WEBSITE_URL}Services/title/${currentWebsite}/${serviceId}/${key}`
      ),
  });

  const imgCellRender = (data) => {
    return (
      <img
        alt="GHM Image"
        src={`${FILE_URL}/${data.value}`}
        style={{ height: 50 }}
      />
    );
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: () => showServiceTitleDetail(),
        };
      }
    });

    toolbarItems.push({
      location: "center",
      text: "Danh sách Service Titles",
    });
  };

  const showServiceTitleDetail = (_serviceTitleId) => {
    setServiceTitleId(_serviceTitleId);
    setShowItemPopup(true);
  };

  const hideItemDetailPopup = () => {
    setShowItemPopup(false);
    setServiceTitleId(null);
  };

  return (
    <React.Fragment>
      <DataGrid
        dataSource={serviceItemsData}
        showBorders={true}
        selection={{ mode: "single" }}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Editing
          mode="form"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
        />

        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`title-${item.languageId}`}
            dataType="string"
            caption={formatMessage("title")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`description-${item.languageId}`}
            dataType="string"
            caption={formatMessage("description")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        <Column dataField="image" cellRender={imgCellRender} />
        <Column
          dataField="alt"
          dataType="string"
          caption={formatMessage("alt")}
        />
        <Column
          dataField="order"
          dataType="number"
          caption={formatMessage("order")}
        />
        <Column
          dataField="isActive"
          dataType="boolean"
          caption={formatMessage("isActive")}
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => showServiceTitleDetail(e.row.key)}
          />
        </Column>
      </DataGrid>

      <Popup
        id="serviceTitleId"
        visible={showItemPopup}
        onHiding={() => setShowItemPopup(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showCloseButton={false}
        showTitle={false}
        container=".dx-viewport"
      >
        <ScrollView width="100%" height="100%">
          <WebsiteServiceTitleDetail
            accessToken={accessToken}
            currentLanguage={currentLanguage}
            currentWebsite={currentWebsite}
            websiteLanguages={languages}
            serviceId={serviceId}
            id={serviceTitleId}
            hidePopup={hideItemDetailPopup}
          />
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
};

WebsiteServiceTitlesList.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  currentWebsite: PropTypes.string,
  items: PropTypes.array,
  serviceId: PropTypes.string,
};

export default WebsiteServiceTitlesList;
