import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import LoginContainer from '../containers/LoginContainer';
import PublicRoute from './PublicRoute';

const UnAuthenticatedContent = () => {
    return (
        <Switch>
            <PublicRoute path='/login' component={LoginContainer} />
            <Redirect to={'/login'} />
        </Switch>
    );
};

export default UnAuthenticatedContent;
