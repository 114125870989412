import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Grouping,
  Button,
} from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import CustomStore from "devextreme/data/custom_store";
import { useHistory } from "react-router-dom";
import dictionary from "../../data/dictionary";
import { Popup } from "devextreme-react/popup";
import HardContentItemDetail from "./HardContentItemDetail";
import notify from "devextreme/ui/notify";
import { FILE_URL, WEBSITE_URL } from "../../utils/constants";
import { HardContentItemVM } from "../../model/HardContent";

loadMessages(dictionary);

const HardContentItemsList = ({
  currentLanguage,
  currentWebsite,
  languages,
  accessToken,
  hardContentId,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const URL = `${WEBSITE_URL}HardContents`;
  const [showItemPopup, setShowItemPopup] = useState(false);
  const [hardContentItemId, setHardContentItemId] = useState(null);

  const loadRequest = () => {
    return fetch(`${URL}/${currentWebsite}/${hardContentId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok)
            return json.data.hardContentDetails.map((data) =>
              HardContentItemVM(data, languages)
            );
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const removeRequest = (url) => {
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          if (text) {
            const _response = JSON.parse(text);
            notify(
              {
                message: _response.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );

            return _response;
          }
        });
      } else {
        return result.json().then((json) => {
          throw json.Message;
        });
      }
    });
  };

  const branchContactsData = new CustomStore({
    key: "id",
    load: () => loadRequest(),
    remove: (key) =>
      removeRequest(`${URL}/detail/${currentWebsite}/${hardContentId}/${key}`),
  });

  const imgCellRender = (data) => {
    return (
      <img
        alt="GHM Image"
        style={{ objectFit: "cover", height: 20 }}
        src={`${FILE_URL}${data.value}`}
      />
    );
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            showBannerItemDetail();
          },
        };
      }
    });

    toolbarItems.unshift({
      location: "center",
      text: "Danh sách HardContent Items",
    });
  };

  const showBannerItemDetail = (hardContentItemId) => {
    setHardContentItemId(hardContentItemId);
    setShowItemPopup(true);
  };

  const hideItemDetailPopup = () => {
    setShowItemPopup(false);
    setHardContentItemId(null);
  };

  return (
    <React.Fragment>
      <DataGrid
        id="grid"
        showBorders={true}
        dataSource={branchContactsData}
        repaintChangesOnly={true}
        selection={{ mode: "single" }}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Grouping autoExpandAll={true} />
        <Editing useIcons={true} allowAdding={true} allowDeleting={true} />

        <Scrolling rowRenderingMode="virtual" />

        <Column
          dataField={`name-${currentLanguage}`}
          caption={formatMessage("name")}
          dataType="string"
        />
        <Column
          dataField={`description-${currentLanguage}`}
          caption={formatMessage("description")}
          dataType="string"
        />
        <Column
          dataField="image"
          caption={formatMessage("image")}
          allowSorting={false}
          cellRender={imgCellRender}
        />
        <Column
          dataField="url"
          caption={formatMessage("url")}
          dataType="string"
        />
        <Column
          dataField={`alt-${currentLanguage}`}
          caption={formatMessage("alt")}
          dataType="string"
        />
        <Column
          dataField="order"
          caption={formatMessage("order")}
          dataType="number"
        />
        <Column
          dataField="isActive"
          caption={formatMessage("isActive")}
          dataType="boolean"
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => showBannerItemDetail(e.row.key)}
          />
        </Column>
      </DataGrid>

      <Popup
        visible={showItemPopup}
        onHiding={() => setShowItemPopup(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showCloseButton={false}
        showTitle={false}
        container=".dx-viewport"
        width={800}
        height={600}
      >
        <HardContentItemDetail
          accessToken={accessToken}
          currentLanguage={currentLanguage}
          currentWebsite={currentWebsite}
          websiteLanguages={languages}
          hardContentId={hardContentId}
          id={hardContentItemId}
          hidePopup={hideItemDetailPopup}
        />
      </Popup>
    </React.Fragment>
  );
};

HardContentItemsList.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentWebsite: PropTypes.string,
  currentLanguage: PropTypes.string,
  hardContentId: PropTypes.string,
};

export default HardContentItemsList;
