import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TreeList, Column, Paging, Selection, Editing, Pager, Button } from 'devextreme-react/tree-list';
import { connect } from 'react-redux';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import dictionary from '../../data/dictionary';
import { useHistory } from 'react-router-dom';
import { WEBSITE_URL } from '../../utils/constants';

loadMessages(dictionary);

const parseJSON = (str, languageId) => {
    let _strObj;
    try {
        _strObj = JSON.parse(str);
    } catch (e) {
        return '';
    }
    return _strObj ? _strObj[languageId] || '' : '';
};

const RecuimentVM = (data, currentLanguage) => {
    const returnData = Object.assign({}, data);

    returnData['jobsName'] = data.jobs.map(x => parseJSON(x.name, currentLanguage)).join(', ');

    return returnData;
};

const pageSizes = [10, 20, 50, 100];

const RecruitmentContainer = ({ accessToken, websiteLanguages, currentLanguage, currentWebsite }) => {
    locale(currentLanguage.substring(0, 2));

    const URL = `${WEBSITE_URL}Recruitments/${currentWebsite}`;
    const [keyword, setKeyword] = useState('');
    const history = useHistory();

    const loadRequest = (url) => {
        return fetch(`${url}?${keyword ? `keyword=${keyword}&` : ''}&page=1&pageSize=1000`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    return { data: json.data.map((data) => RecuimentVM(data, websiteLanguages, currentLanguage)) };
                }
            })
        );
    };

    const deleteRequest = (url) => {
        return fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'Accept-Language': currentLanguage
            }
        })
            .then(result => result.json()
                .then(json => {
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                }));
    };

    const onToolbarPreparing = (e) => {
        const toolbarItems = e.toolbarOptions.items;
        // Modifies an existing item
        toolbarItems.forEach(function (item) {
            if (item.name === 'addRowButton') {
                item.options = {
                    icon: 'plus',
                    onClick: function () {
                        // Implement custom save logic here
                        history.push('/recruitment/add');
                    }
                };
            }
        });

        toolbarItems.unshift(
            {
                location: 'before',
                widget: 'dxTextBox',
                options: {
                    placeholder: 'Nhập từ khóa tìm kiếm',
                    value: keyword,
                    width: 200,
                    onValueChanged: (e) => setKeyword(e.value)
                }
            },
            {
                location: 'center',
                text: 'Danh sách đăng ký tuyển dụng'
            });
    };

    const recruimentSource = new CustomStore({
        key: 'id',
        load: (loadOptions) => loadRequest(`${URL}`, loadOptions),
        remove: (key) => deleteRequest(`${URL}/${key}`)
    });

    const gotoDetailPage = (recruimentId) => {
        history.push(`/recruitment/${recruimentId}`);
    };

    return (
        <React.Fragment>
            <TreeList
                dataSource={recruimentSource}
                autoExpandAll={true}
                showRowLines={true}
                showBorders={true}
                keyExpr="id"
                parentIdExpr="parentId"
                onToolbarPreparing={onToolbarPreparing}
            >
                <Editing
                    allowAdding={true}
                    allowDeleting={true}
                    useIcons={true} />
                <Selection mode="single" />

                <Column dataField="fullName" dataType="string" caption={formatMessage('fullName')} />
                <Column dataField="email" dataType="string" caption={formatMessage('email')} />
                <Column dataField="phoneNumber" dataType="string" caption={formatMessage('phoneNumber')} />
                <Column dataField="jobsName" dataType="string" caption={formatMessage('jobs')} />

                <Column dataField="description" dataType="number" caption={formatMessage('description')} />
                <Column dataField="isView" dataType="boolean" caption={formatMessage('isView')} />

                <Column type="buttons" caption={formatMessage('actionCol')}>
                    <Button name="delete" />
                    <Button hint="Info" icon="info" onClick={(e) => gotoDetailPage(e.row.key)} />
                </Column>

                <Pager visible={true} displayMode={'full'}
                    allowedPageSizes={pageSizes} showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} />
                <Paging defaultPageSize={20} />
            </TreeList>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.data.token,
        currentLanguage: state.setting.currentLanguage,
        languages: state.setting.languages,
        currentWebsite: state.website.data.currentWebsite,
        websiteLanguages: state.website.data.languages
    };
};

RecruitmentContainer.propTypes = {
    accessToken: PropTypes.string,
    websiteLanguages: PropTypes.array,
    currentLanguage: PropTypes.string,
    currentWebsite: PropTypes.string
};

export default connect(mapStateToProps, null)(RecruitmentContainer);
