import Form, {
  SimpleItem,
  GroupItem,
  TabbedItem,
  ButtonItem,
  Label,
  Tab,
  RequiredRule,
  EmptyItem,
} from "devextreme-react/form";
import React, { useState, useEffect } from "react";
import dictionary from "../../data/dictionary";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { useHistory, useParams } from "react-router-dom";
import "devextreme-react/text-area";
import "devextreme-react/tag-box";
import notify from "devextreme/ui/notify";
import useJSON from "../../hook/useJSON";
import FileSelectPopup from "../../components/file/FileSelectPopup";
import { WEBSITE_URL } from "../../utils/constants";

loadMessages(dictionary);

const tabsMock = [
  {
    id: 0,
    text: "vi-VN",
    icon: "globe",
    content: "Vietnamese",
  },
];

const notesEditorOptions = { height: 100 };

const CustomerFeedbackVM = (data, languages) => {
  const returnData = useJSON(data, languages);
  return returnData;
};

const CustomerFeedbackMV = (data, languages) => {
  const nameObj = {};
  const descriptionObj = {};
  const contentObj = {};
  const positionObj = {};
  const altObj = {};

  languages.forEach((language) => {
    nameObj[[language.languageId]] = data[`name-${language.languageId}`];
    contentObj[[language.languageId]] = data[`content-${language.languageId}`];
    descriptionObj[[language.languageId]] =
      data[`description-${language.languageId}`];
    positionObj[[language.languageId]] =
      data[`position-${language.languageId}`];
    altObj[[language.languageId]] = data[`alt-${language.languageId}`];
  });

  return JSON.stringify({
    name: JSON.stringify(nameObj),
    seoLink: data.seoLink,
    avata: data.avata,
    phoneNumber: data.phoneNumber,
    content: JSON.stringify(contentObj),
    description: JSON.stringify(descriptionObj),
    thumbnail: data.thumbnail,
    url: data.url,
    alt: JSON.stringify(altObj),
    rate: data.rate,
    order: data.order,
    concurrencyStamp: data.concurrencyStamp,
    isActive: data.isActive,
    position: JSON.stringify(positionObj),
  });
};

const FeedbackDetailContainer = ({
  accessToken,
  currentLanguage,
  currentWebsite,
  websiteLanguages,
}) => {
  locale(currentLanguage.substring(0, 2));
  const [tabs, setTabs] = useState(tabsMock);
  const [customerFeedback, setCustomerFeedback] = useState({});
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (!params.customerFeedbackId) return;

    fetch(
      `${WEBSITE_URL}CustomerFeedbacks/${currentWebsite}/${params.customerFeedbackId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setCustomerFeedback(
              CustomerFeedbackVM(json.data, websiteLanguages)
            );
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [
    params.feedbackId,
    history,
    accessToken,
    currentWebsite,
    websiteLanguages,
  ]);

  const updateCustomerFeedback = () => {
    const _method = customerFeedback.id ? "PUT" : "POST";
    const _url = `${WEBSITE_URL}CustomerFeedbacks/${currentWebsite}${
      customerFeedback.id ? `/${customerFeedback.id}` : ""
    }`;

    return fetch(_url, {
      method: _method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
      body: CustomerFeedbackMV(customerFeedback, websiteLanguages),
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            history.push("/customerFeedback");
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateCustomerFeedback();
  };

  useEffect(() => {
    const _tabs = websiteLanguages.map((item, index) => ({
      id: index,
      languageId: item.languageId,
      text: item.name,
      icon: "globe",
      content: "User tab content",
    }));
    setTabs(_tabs);
  }, [websiteLanguages]);

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.goBack();
    },
  };

  const saveButtonOptions = {
    text: "Update",
    type: "success",
    useSubmitBehavior: true,
  };

  const updateAvata = (e) => {
    setCustomerFeedback((prevState) => ({ ...prevState, avata: e }));
  };

  const updateThumbnail = (e) => {
    setCustomerFeedback((prevState) => ({ ...prevState, thumbnail: e }));
  };

  return (
    <React.Fragment>
      <Toolbar>
        <Item location="before" widget="dxButton" options={backButtonOptions} />
        <Item location="center" text={"Chi tiết Customer Feedback"} />
      </Toolbar>

      <form action="your-action" onSubmit={handleSubmit}>
        <Form
          formData={customerFeedback}
          readOnly={false}
          showColonAfterLabel={true}
          colCount={3}
          showValidationSummary={true}
          validationGroup="customerFeedbackData"
        >
          <GroupItem caption="Đa ngôn ngữ" colSpan={2}>
            <TabbedItem>
              {tabs.map((item) => {
                return (
                  <Tab key={item.id} title={item.text} icon={item.icon}>
                    <SimpleItem
                      dataField={`name-${item.languageId}`}
                      editorType="dxTextBox"
                    >
                      <Label text={formatMessage("name")} />
                      <RequiredRule />
                    </SimpleItem>
                    <SimpleItem
                      dataField={`content-${item.languageId}`}
                      editorType="dxTextArea"
                      editorOptions={notesEditorOptions}
                    >
                      <Label text={formatMessage("content")} />
                    </SimpleItem>
                    <SimpleItem
                      dataField={`description-${item.languageId}`}
                      editorType="dxTextArea"
                      editorOptions={notesEditorOptions}
                    >
                      <Label text={formatMessage("description")} />
                    </SimpleItem>

                    <SimpleItem
                      dataField={`position-${item.languageId}`}
                      editorType="dxTextArea"
                    >
                      <Label text={formatMessage("position")} />
                      <RequiredRule />
                    </SimpleItem>
                  </Tab>
                );
              })}
            </TabbedItem>
          </GroupItem>

          <GroupItem caption="Thông tin khác">
            <SimpleItem dataField={"seoLink"} editorType="dxTextBox">
              <Label text={formatMessage("seoLink")} />
              <RequiredRule />
            </SimpleItem>
            <SimpleItem dataField={"url"} editorType="dxTextBox">
              <Label text={formatMessage("url")} />
            </SimpleItem>
            <SimpleItem dataField={"rate"} editorType="dxNumberBox">
              <Label text={formatMessage("rate")} />
            </SimpleItem>
            <SimpleItem dataField={"order"} editorType="dxNumberBox">
              <Label text={formatMessage("order")} />
            </SimpleItem>
            <SimpleItem dataField={"isActive"} editorType="dxCheckBox">
              <Label text={formatMessage("isActive")} />
            </SimpleItem>
          </GroupItem>

          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem>
              <Label text={formatMessage("avatar")} />
              <FileSelectPopup
                value={customerFeedback.avata}
                imgHeight={100}
                onValueChanged={updateAvata}
              />
            </SimpleItem>
            <SimpleItem>
              <Label text={formatMessage("thumbnail")} />
              <FileSelectPopup
                value={customerFeedback.thumbnail}
                imgHeight={100}
                onValueChanged={updateThumbnail}
              />
            </SimpleItem>
            <SimpleItem dataField={"alt"} editorType="dxTextArea" colSpan={2}>
              <Label text={formatMessage("alt")} />
            </SimpleItem>
          </GroupItem>

          <EmptyItem />
          <ButtonItem
            colSpan={3}
            horizontalAlignment="center"
            validationGroup="customerFeedbackData"
            buttonOptions={saveButtonOptions}
          />
        </Form>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

FeedbackDetailContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
};

export default connect(mapStateToProps, null)(FeedbackDetailContainer);
