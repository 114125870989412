import { connect } from "react-redux";
import DataGrid, {
  Editing,
  Column,
  Button,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import React, { useRef } from "react";
import PropTypes from "prop-types";
import CustomStore from "devextreme/data/custom_store";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import { useHistory } from "react-router-dom";
import notify from "devextreme/ui/notify";
import { FILE_URL, WEBSITE_URL } from "../../utils/constants";
import { ServiceVM } from "../../model/Service";

loadMessages(dictionary);
const pageSizes = [10, 25, 50, 100];

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
const searchParams = { page: 1, pageSize: 20 };

const WebsiteServicesContainer = ({
  accessToken,
  languages,
  currentLanguage,
  currentWebsite,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const dataGridRef = useRef(null);
  const URL = `${WEBSITE_URL}Services/${currentWebsite}`;

  const loadRequest = (url, loadOptions) => {
    const _page =
      isNotEmpty(loadOptions.skip) && isNotEmpty(loadOptions.take)
        ? loadOptions.skip / loadOptions.take + 1
        : searchParams.page;
    const _pageSize = isNotEmpty(loadOptions.take)
      ? loadOptions.take
      : searchParams.pageSize;

    return fetch(`${url}?page=${_page}&pageSize=${_pageSize}&type=0`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            return {
              data: json.data.map((data) =>
                ServiceVM(data, languages, currentLanguage)
              ),
              totalCount: json.totalRows,
            };
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const sendRequest = (url, method, data) => {
    method = method || "GET";
    data = data || {};

    if (method === "GET") {
      return fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
          "Accept-Language": currentLanguage,
        },
      }).then((result) =>
        result
          .json()
          .then((json) => {
            if (result.ok)
              return json.data.map((data) =>
                ServiceVM(data, languages, currentLanguage)
              );
            throw json.Message;
          })
          .catch(() => {
            if (result.status === 401) {
              history.push("/login");
            }
          })
      );
    }

    return fetch(url, {
      method: method,
      body: JSON.stringify(data.values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          notify(
            {
              message: JSON.parse(text).message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return text && { ...data.values, id: JSON.parse(text).data };
        });
      } else {
        return result.json().then((json) => {
          throw json.Message;
        });
      }
    });
  };

  const serviceData = new CustomStore({
    key: "id",
    load: (loadOptions) => loadRequest(`${URL}`, loadOptions),
    remove: (key) =>
      sendRequest(`${URL}/${key}?type=0`, "DELETE", {
        key: key,
      }),
  });

  const gotoDetailPage = (id) => {
    history.push(`/specialization/${id}`);
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            // Implement custom save logic here
            history.push("/specialization/add");
          },
        };
      }
    });

    toolbarItems.push(
      {
        location: "center",
        text: "Danh sách Chuyên khoa",
      },
      {
        location: "before",
        widget: "dxButton",
        options: {
          icon: "refresh",
          onClick: () => dataGridRef.current.instance.refresh(),
        },
      }
    );
  };

  const addMenuItems = (e) => {
    if (e.target === "content") {
      // e.items can be undefined
      if (!e.items) e.items = [];

      // Add a custom menu item
      e.items.push({
        text: "Chi tiết Tin tức",
        icon: "info",
        onItemClick: () => {
          history.push(`/service-website/${e.row.key}`);
        },
      });
    }
  };

  const imgCellRender = (data) => {
    return (
      <img
        alt="GHM Image"
        src={`${FILE_URL}/${data.value}`}
        style={{ height: 50 }}
      />
    );
  };

  const onRowDblClick = (e) => {
    history.push(`/service-website/${e.key}`);
  };

  return (
    <React.Fragment>
      <DataGrid
        ref={dataGridRef}
        keyExpr="id"
        dataSource={serviceData}
        allowColumnReordering={true}
        remoteOperations={true}
        selection={{ mode: "single" }}
        showBorders={true}
        onRowDblClick={onRowDblClick}
        onContextMenuPreparing={addMenuItems}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Editing
          mode="row"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
        />
        <SearchPanel visible={true} highlightCaseSensitive={true} onTextChange={(e) => console.log(e)} />

        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`title-${item.languageId}`}
            dataType="string"
            caption={formatMessage("title")}
            visible={item.languageId === currentLanguage}
          />
        ))}

        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`subTitle-${item.languageId}`}
            dataType="string"
            caption={formatMessage("subTitle")}
            visible={item.languageId === currentLanguage}
          />
        ))}

        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`description-${item.languageId}`}
            dataType="string"
            caption={formatMessage("description")}
            visible={item.languageId === currentLanguage}
          />
        ))}
        <Column dataField="image" cellRender={imgCellRender} />
        <Column dataField="thumbnail" cellRender={imgCellRender} />
        <Column
          dataField="seoLink"
          dataType="string"
          caption={formatMessage("seoLink")}
        />
        <Column
          dataField="isActive"
          dataType="boolean"
          caption={formatMessage("isActive")}
        />
        <Column
          dataField="isHomePage"
          dataType="boolean"
          caption={formatMessage("isHomePage")}
        />
        <Column
          dataField="isHot"
          dataType="boolean"
          caption={formatMessage("isHot")}
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => gotoDetailPage(e.row.key)}
          />
        </Column>

        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
      </DataGrid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

WebsiteServicesContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
  currentWebsiteLanguage: PropTypes.string,
};

export default connect(mapStateToProps, null)(WebsiteServicesContainer);
