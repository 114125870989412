import useJSON from "../hook/useJSON";
import Transformer from "../utils/transformer";

const SocialNetworkVM = (data, languages) => {
  const returnData = useJSON(data, languages);

  languages.forEach((element) => {
    returnData[`name-${element.languageId}`] = Transformer.parseJSON(
      data.name,
      element.languageId
    );
  });

  return returnData;
};

const SocialNetworkMV = (data, languages) => {
  const nameObj = {};
  const descriptionObj = {};
  languages.forEach((language) => {
    nameObj[[language.languageId]] = data[`name-${language.languageId}`];
    descriptionObj[[language.languageId]] =
      data[`description-${language.languageId}`];
  });

  return JSON.stringify({
    name: JSON.stringify(nameObj),
    description: JSON.stringify(descriptionObj),
    url: data.url,
    image: data.image,
    icon: data.icon,
    order: data.order,
    isActive: data.isActive,
    concurrencyStamp: data.concurrencyStamp,
  });
};

export { SocialNetworkVM, SocialNetworkMV };
