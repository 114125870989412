import Transformer from "../utils/transformer";

export const parseJSON = (str, languageId) => {
  let _strObj;
  try {
    _strObj = JSON.parse(str);
  } catch (e) {
    return str || "";
  }
  return _strObj ? _strObj[languageId] || "" : "";
};

function useJSON(data, languages, jsonField = []) {
  const convertData = {};

  Object.keys(data).forEach((element) => {
    if (
      jsonField.includes(element) ||
      (data[element] && Transformer.IsJsonString(data[element]))
    ) {
      languages.forEach((language) => {
        convertData[`${element}-${language.languageId}`] = parseJSON(
          data[element],
          language.languageId
        );
      });
    } else {
      convertData[element] = data[element];
    }
  });

  return convertData;
}

export default useJSON;
