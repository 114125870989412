import React, { useEffect } from 'react';
import Box, {
    Item
} from 'devextreme-react/box';
import SeriesChart from '../components/dashboard/SeriesChart';
import DoughnutPieChart from '../components/dashboard/DoughnutPieChart';
import PaletteCircularGauge from '../components/dashboard/PaletteCircularGauge';
import TickCircularGauge from '../components/dashboard/TickCircularGauge';
import { requestPermission, vapidKey } from '../firebase-config';
import { getToken, getMessaging } from "firebase/messaging";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import notify from "devextreme/ui/notify";
import { FIREAPI_URL, CORE_URL } from "../utils/constants";

function DashboardContainer({ accessToken }) {
    const messaging = getMessaging();

    useEffect(() => {
        getToken(messaging, { vapidKey }).then((currentToken) => {
            if (currentToken) {
                getCurrentUser().then(currentUser => {
                    if (currentUser) {
                        get(currentToken, currentUser)
                    }
                })
                
            } else {
                requestPermission();
                console.log('No registration token available. Request permission to generate one.');
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
    }, [])

    const get = (token, userId) => {
        return fetch(`${FIREAPI_URL}UserTokens`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userId,
                isActive: true,
                token
            }),
        })
            .then(result => result.json())
            .then((json) => {
                if (json) {
                    console.log(json)
                } else {
                    console.log(json)
                    throw json.Message;
                }
            })
            .catch((err) => {
                notify(
                    {
                        message: err.message || err,
                        position: {
                            my: "right top",
                            at: "right top",
                        },
                    },
                    "error",
                    3000
                );
                throw (err.message || err);
            })
    }

    const getCurrentUser = () => {
        return fetch(`${CORE_URL}AppSettings`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Access-Control-Allow-Origin": "*",
                "Accept-Language": 'vi-VN',
            }
        })
            .then(result => result.json())
            .then((json) => {
                if (json) {
                    return json.currentUser.id;
                } else {
                    console.log(json)
                    throw json.Message;
                }
            })
            .catch((err) => {
                notify(
                    {
                        message: err.message || err,
                        position: {
                            my: "right top",
                            at: "right top",
                        },
                    },
                    "error",
                    3000
                );
                throw (err.message || err);
            })
    }

    return (
        <React.Fragment>
            <Box
                direction="row"
                width="100%">
                <Item ratio={1}>
                    <DoughnutPieChart />
                </Item>
                <Item ratio={1}>
                    <TickCircularGauge />
                </Item>
                <Item ratio={1}>
                    <PaletteCircularGauge />
                </Item>
            </Box>

            <Box
                direction="row"
                width="100%">
                <Item ratio={1}>
                    <SeriesChart />
                </Item>
            </Box>

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.data.token,
        currentLanguage: state.setting.currentLanguage,
        languages: state.setting.languages,
        currentWebsite: state.website.data.currentWebsite,
        websiteLanguages: state.website.data.languages,
    };
};

DashboardContainer.propTypes = {
    accessToken: PropTypes.string,
    // languages: PropTypes.array,
    // currentLanguage: PropTypes.string,
    // websiteId: PropTypes.string,
    // websiteLanguages: PropTypes.array,
    // currentWebsite: PropTypes.string,
};

export default connect(mapStateToProps, null)(DashboardContainer);
