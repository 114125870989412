import notify from "devextreme/ui/notify";

export default class Transformer {
  static convertCurrency(numStr) {
    const x = parseFloat(numStr);

    const currencyString = x.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    });

    return currencyString.replace('$', '') + ' ₫';
  }

  static convertMobileLink(imgUrl, size) {
    const position = imgUrl.lastIndexOf(".");
    return [
      imgUrl.slice(0, position),
      "_",
      size,
      "x",
      imgUrl.slice(position),
    ].join("");
  }

  static IsJsonString(str) {
    const typeOfStr = typeof str;
    if (typeOfStr !== "string") {
      return false;
    }
    try {
      const json = JSON.parse(str);
      if (!json || typeof json !== "object") {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  }

  static parseJSON(str, languageId) {
    let _strObj;
    try {
      _strObj = JSON.parse(str);
    } catch (e) {
      return str || "";
    }
    return _strObj ? _strObj[languageId] || "" : "";
  }

  static convertUnsignedChars(str) {
    str = str.replace(/\s+/g, " ");
    str.trim();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    str = str.replace(
      /!|@|#|%|&|=|<|>|`|~|\?|\(|\)|\$|\^|\*|\+|\.|\/|\\/g,
      "-"
    );
    str = str.split(" ").join("-");
    str = str.replace(/-+/g, "-");
    return str.toLowerCase();
  }

  static fetchUpdate(url, { token, body, callback }) {
    fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            callback();
            notify(
              {
                message: "Updated successfully",
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
          } else {
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "error",
              3000
            );
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
    );
  }

  static fetchCreate(url, { token, body, callback }) {
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            callback(json);
            notify(
              {
                message: "Added successfully",
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
          } else {
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "error",
              3000
            );
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
    );
  }
}
