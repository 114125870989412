import { MENU_POSITION } from "../utils/constants";
import Transformer from "../utils/transformer";

const MenuVM = (data, languages, currentLanguage) => {
  const returnData = Object.assign({}, data);
  returnData["positionName"] = Transformer.parseJSON(
    MENU_POSITION[data.position].name,
    currentLanguage
  );

  languages.forEach((element) => {
    returnData[`name-${element.languageId}`] = Transformer.parseJSON(
      data.name,
      element.languageId
    );
    returnData[`description-${element.languageId}`] = Transformer.parseJSON(
      data.description,
      element.languageId
    );
  });

  return returnData;
};

export { MenuVM };
