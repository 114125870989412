import Transformer from "../utils/transformer";

const HardContentVM = (data, languages) => {
  const returnData = Object.assign({}, data);

  languages.forEach((element) => {
    returnData[`name-${element.languageId}`] = Transformer.parseJSON(
      data.name,
      element.languageId
    );
    returnData[`description-${element.languageId}`] = Transformer.parseJSON(
      data.description,
      element.languageId
    );
    returnData[`alt-${element.languageId}`] = Transformer.parseJSON(
      data.alt,
      element.languageId
    );
  });

  return returnData;
};

const HardContentItemVM = (data, languages) => {
  const returnData = Object.assign({}, data);

  languages.forEach((element) => {
    returnData[`name-${element.languageId}`] = Transformer.parseJSON(
      data.name,
      element.languageId
    );
    returnData[`description-${element.languageId}`] = Transformer.parseJSON(
      data.description,
      element.languageId
    );
    returnData[`alt-${element.languageId}`] = Transformer.parseJSON(
      data.alt,
      element.languageId
    );
  });
  return returnData;
};

export { HardContentVM, HardContentItemVM };
