import Form, {
    SimpleItem, GroupItem, TabbedItem, ButtonItem,
    Label, Tab, RangeRule, RequiredRule
} from 'devextreme-react/form';
import React, { useState, useEffect } from 'react';
import dictionary from '../../data/dictionary';
import PropTypes from 'prop-types';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useHistory } from 'react-router-dom';
import FileSelectPopup from '../../components/file/FileSelectPopup';
import 'devextreme-react/text-area';
import 'devextreme-react/tag-box';
import notify from 'devextreme/ui/notify';
import useJSON from '../../hook/useJSON';
import { WEBSITE_URL } from '../../utils/constants';
loadMessages(dictionary);

const tabsMock = [{
    id: 0,
    text: 'vi-VN',
    icon: 'globe',
    content: 'Vietnamese'
}];

const notesEditorOptions = { height: 100 };

const BannerItemVM = (data, languages) => {
    const returnData = useJSON(data, languages);
    return returnData;
};

const BannerItemMV = (data, languages) => {
    const nameObj = {}; const descriptionObj = {};
    languages.forEach(language => {
        nameObj[[language.languageId]] = data[`name-${language.languageId}`];
        descriptionObj[[language.languageId]] = data[`description-${language.languageId}`];
    });
    return JSON.stringify({
        name: JSON.stringify(nameObj),
        description: JSON.stringify(descriptionObj),
        images: data.images,
        imagesMobile: data.imagesMobile,
        alt: data.alt,
        url: data.url,
        order: data.order,
        concurrencyStamp: data.concurrencyStamp,
        isActive: data.isActive
    });
};

const BannerItemDetail = ({ accessToken, currentLanguage, currentWebsite, websiteLanguages, bannerId, id, hidePopup }) => {
    locale(currentLanguage.substring(0, 2));
    const [tabs, setTabs] = useState(tabsMock);
    const [bannerItem, setBannerItem] = useState({ order: 0, isActive: true });
    const history = useHistory();

    useEffect(() => {
        if (!id) {
            setBannerItem({ order: 0, isActive: true });
            return;
        }

        fetch(`${WEBSITE_URL}Banners/item/${currentWebsite}/${bannerId}/${id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setBannerItem(BannerItemVM(json.data, websiteLanguages));
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    }, [id, websiteLanguages, currentWebsite, bannerId, accessToken, history]);

    const updateBannerItem = () => {
        const _method = bannerItem.id ? 'PUT' : 'POST';
        const _url = `${WEBSITE_URL}Banners/item/${currentWebsite}/${bannerId}${bannerItem.id ? `/${bannerItem.id}` : ''}`;

        return fetch(_url, {
            method: _method,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': currentLanguage
            },
            body: BannerItemMV(bannerItem, websiteLanguages)
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                    setBannerItem({ order: 0, isActive: true });
                    hidePopup();
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateBannerItem();
    };

    useEffect(() => {
        const _tabs = websiteLanguages.map((item, index) => ({
            id: index,
            languageId: item.languageId,
            text: item.name,
            icon: 'globe',
            content: 'User tab content'
        }));
        setTabs(_tabs);
    }, [websiteLanguages]);

    const updateImages = e => {
        setBannerItem(prevState => ({ ...prevState, images: e }));
    };

    const updateImagesMobile = e => {
        setBannerItem(prevState => ({ ...prevState, imagesMobile: e }));
    };

    const saveButtonOptions = {
        text: 'Update',
        type: 'success',
        useSubmitBehavior: true
    };

    return (
        <React.Fragment>
            <Toolbar>
                <Item location="center"
                    text={'Chi tiết Banner Item'} />
            </Toolbar>

            <form action="your-action" onSubmit={handleSubmit}>
                <Form
                    formData={bannerItem}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                    validationGroup="bannerItemData"
                >
                    <GroupItem colCount={2}>
                        <TabbedItem colCount={2} colSpan={2}>
                            {tabs.map((item) => {
                                return (
                                    <Tab key={item.id} title={item.text} icon={item.icon}>
                                        <SimpleItem dataField={`name-${item.languageId}`} editorType="dxTextBox">
                                            <Label text={formatMessage('name')} />
                                            <RequiredRule />
                                        </SimpleItem>
                                        <SimpleItem dataField={`description-${item.languageId}`} editorType="dxTextArea" editorOptions={notesEditorOptions}>
                                            <Label text={formatMessage('description')} />
                                        </SimpleItem>
                                    </Tab>
                                );
                            })}
                        </TabbedItem>

                        <SimpleItem >
                            <Label text={formatMessage('image')} />
                            <FileSelectPopup value={bannerItem.images} imgHeight={100}
                                onValueChanged={updateImages} />
                            <RequiredRule />
                        </SimpleItem>

                        <SimpleItem >
                            <Label text={formatMessage('imageMobile')} />
                            <FileSelectPopup value={bannerItem.imagesMobile} imgHeight={100}
                                onValueChanged={updateImagesMobile} />
                            <RequiredRule />
                        </SimpleItem>

                        <SimpleItem dataField={'alt'} editorType="dxTextBox">
                            <Label text={formatMessage('alt')} />
                        </SimpleItem>

                        <SimpleItem dataField={'url'} editorType="dxTextBox">
                            <Label text={formatMessage('url')} />
                        </SimpleItem>

                        <SimpleItem dataField={'order'} editorType="dxNumberBox">
                            <Label text={formatMessage('order')} />
                            <RangeRule min={0} message={`${formatMessage('order')} ${formatMessage('errMesInvalidNum')}`} />
                        </SimpleItem>
                        <SimpleItem dataField={'isActive'} editorType="dxCheckBox">
                            <Label text={formatMessage('isActive')} />
                        </SimpleItem>
                    </GroupItem>

                    <ButtonItem colSpan={3} horizontalAlignment="center"
                        validationGroup="bannerItemData"
                        buttonOptions={saveButtonOptions}
                    />
                </Form>
            </form>
        </React.Fragment>
    );
};

BannerItemDetail.propTypes = {
    accessToken: PropTypes.string,
    languages: PropTypes.array,
    currentLanguage: PropTypes.string,
    websiteId: PropTypes.string,
    websiteLanguages: PropTypes.array,
    currentWebsite: PropTypes.string,
    bannerId: PropTypes.string,
    id: PropTypes.string,
    hidePopup: PropTypes.func
};

export default BannerItemDetail;
