import Transformer from "../utils/transformer";

const ServiceTitleVM = (data, languages) => {
  const returnData = Object.assign({}, data);

  languages.forEach((element) => {
    returnData[`title-${element.languageId}`] = Transformer.parseJSON(
      data.title,
      element.languageId
    );
    returnData[`description-${element.languageId}`] = Transformer.parseJSON(
      data.description,
      element.languageId
    );
    returnData[`content-${element.languageId}`] = Transformer.parseJSON(
      data.content,
      element.languageId
    );
  });

  return returnData;
};

const ServiceTitleMV = (data, languages) => {
  const titleObj = {};
  const descriptionObj = {};
  const contentObj = {};

  languages.forEach((language) => {
    titleObj[[language.languageId]] = data[`title-${language.languageId}`];
    descriptionObj[[language.languageId]] =
      data[`description-${language.languageId}`];
    contentObj[[language.languageId]] = data[`content-${language.languageId}`];
  });

  return {
    title: JSON.stringify(titleObj),
    description: JSON.stringify(descriptionObj),
    content: JSON.stringify(contentObj),
    image: data.image,
    url: data.url,
    alt: data.alt,
    order: data.order,
    unitPrice: data.unitPrice,
    referenceId: data.referenceId,
    isActive: data.isActive,
    concurrencyStamp: data.concurrencyStamp,
  };
};

export { ServiceTitleVM, ServiceTitleMV };
