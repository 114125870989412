import Form, {
    SimpleItem, GroupItem, ButtonItem,
    Label, RequiredRule
} from 'devextreme-react/form';
import React, { useState, useEffect } from 'react';
import dictionary from '../../data/dictionary';
import PropTypes from 'prop-types';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useHistory } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
import useJSON from '../../hook/useJSON';
import { CONTACT_TYPE, WEBSITE_URL } from '../../utils/constants';

loadMessages(dictionary);

const BranchContactItemVM = (data, languages) => {
    const returnData = useJSON(data, languages);
    return returnData;
};

const BranchContactItemMV = (data) => {
    return JSON.stringify({
        contactType: data.contactType,
        contactValue: data.contactValue
    });
};

const parseJSON = (str, languageId) => {
    let _strObj;
    try {
        _strObj = JSON.parse(str);
    } catch (e) {
        return str || '';
    }
    return _strObj ? _strObj[languageId] || '' : '';
};

const ContactTypeVM = (data, currentLanguage) => {
    return {
        id: data.id,
        name: parseJSON(data.name, currentLanguage)
    };
};

const BranchContactItemDetail = ({ accessToken, currentLanguage, currentWebsite, websiteLanguages, branchContactId, id, hidePopup }) => {
    locale(currentLanguage.substring(0, 2));
    const [branchContactItem, setBranchContactItem] = useState({});
    const history = useHistory();

    useEffect(() => {
        if (!id) return;

        fetch(`${WEBSITE_URL}BranchContacts/detail/${currentWebsite}/${branchContactId}/${id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setBranchContactItem(BranchContactItemVM(json.data, websiteLanguages));
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    }, [id, history, currentWebsite, branchContactId, websiteLanguages, accessToken]);

    const updateBranchContactItem = () => {
        const _method = branchContactItem.id ? 'PUT' : 'POST';
        const _url = `${WEBSITE_URL}BranchContacts/detail/${currentWebsite}/${branchContactId}${branchContactItem.id ? `/${branchContactItem.id}` : ''}`;

        return fetch(_url, {
            method: _method,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': currentLanguage
            },
            body: BranchContactItemMV(branchContactItem)
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                    hidePopup();
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateBranchContactItem();
    };

    const saveButtonOptions = {
        text: 'Update',
        type: 'success',
        useSubmitBehavior: true
    };

    const contactTypesSelectOptions = {
        items: CONTACT_TYPE.map((type) => ContactTypeVM(type, currentLanguage)),
        displayExpr: 'name',
        searchEnabled: true,
        valueExpr: 'id'
    };

    return (
        <React.Fragment>
            <Toolbar>
                <Item location="center"
                    text={'Chi tiết BranchContact Item'} />
            </Toolbar>

            <form action="your-action" onSubmit={handleSubmit}>
                <Form
                    formData={branchContactItem}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                >
                    <GroupItem>
                        <SimpleItem dataField="contactType" editorType="dxSelectBox" editorOptions={contactTypesSelectOptions} >
                            <Label text={formatMessage('contactType')} />
                            <RequiredRule message={`${formatMessage('contactType')} ${formatMessage('errMesRequire')}`} />
                        </SimpleItem>

                        <SimpleItem dataField="contactValue" editorType="dxTextBox">
                            <Label text={formatMessage('contactValue')} />
                            <RequiredRule message={`${formatMessage('contactValue')} ${formatMessage('errMesRequire')}`} />
                        </SimpleItem>
                    </GroupItem>

                    <ButtonItem colSpan={3} horizontalAlignment="center"
                        buttonOptions={saveButtonOptions}
                    />
                </Form>
            </form>
        </React.Fragment>
    );
};

BranchContactItemDetail.propTypes = {
    accessToken: PropTypes.string,
    languages: PropTypes.array,
    currentLanguage: PropTypes.string,
    websiteId: PropTypes.string,
    websiteLanguages: PropTypes.array,
    currentWebsite: PropTypes.string,
    branchContactId: PropTypes.string,
    id: PropTypes.string,
    hidePopup: PropTypes.func
};

export default BranchContactItemDetail;
