import Form, {
    SimpleItem, GroupItem, TabbedItem, ButtonItem,
    Label, Tab, RequiredRule
} from 'devextreme-react/form';
import React, { useState, useEffect } from 'react';
import dictionary from '../../data/dictionary';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useHistory, useParams } from 'react-router-dom';
import 'devextreme-react/text-area';
import 'devextreme-react/tag-box';
import notify from 'devextreme/ui/notify';
import useJSON from '../../hook/useJSON';
import { WEBSITE_URL } from '../../utils/constants';

loadMessages(dictionary);

const tabsMock = [{
    id: 0,
    text: 'vi-VN',
    icon: 'globe',
    content: 'Vietnamese'
}];

const notesEditorOptions = { height: 100 };

const FeedbackVM = (data, languages) => {
    const returnData = useJSON(data, languages, ['content', 'title', 'description', 'serviceName']);
    return returnData;
};

const FeedbackMV = (data, languages) => {
    const titleObj = {}; const descriptionObj = {}; const contentObj = {};
    languages.forEach(language => {
        titleObj[[language.languageId]] = data[`title-${language.languageId}`];
        contentObj[[language.languageId]] = data[`content-${language.languageId}`];
        descriptionObj[[language.languageId]] = data[`description-${language.languageId}`];
    });

    return JSON.stringify({
        email: data.email,
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        title: JSON.stringify(titleObj),
        content: JSON.stringify(contentObj),
        description: JSON.stringify(descriptionObj),
        concurrencyStamp: data.concurrencyStamp,
        isView: data.isView
    });
};

const FeedbackDetailContainer = ({ accessToken, currentLanguage, currentWebsite, websiteLanguages }) => {
    locale(currentLanguage.substring(0, 2));
    const [tabs, setTabs] = useState(tabsMock);
    const [feedback, setFeedback] = useState({});
    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        if (!params.feedbackId) return;

        fetch(`${WEBSITE_URL}Feedbacks/${currentWebsite}/${params.feedbackId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setFeedback(FeedbackVM(json.data, websiteLanguages));
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    }, [params.feedbackId, history, accessToken, currentWebsite, websiteLanguages]);

    const updateFeedback = () => {
        const _method = feedback.id ? 'PUT' : 'POST';
        const _url = `${WEBSITE_URL}Feedbacks/${currentWebsite}${feedback.id ? `/${feedback.id}` : ''}`;

        return fetch(_url, {
            method: _method,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': currentLanguage
            },
            body: FeedbackMV(feedback, websiteLanguages)
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    history.push('/feedback');
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateFeedback();
    };

    useEffect(() => {
        const _tabs = websiteLanguages.map((item, index) => ({
            id: index,
            languageId: item.languageId,
            text: item.name,
            icon: 'globe',
            content: 'User tab content'
        }));
        setTabs(_tabs);
    }, [websiteLanguages]);

    const backButtonOptions = {
        type: 'back',
        onClick: () => {
            history.goBack();
        }
    };

    const saveButtonOptions = {
        text: 'Update',
        type: 'success',
        useSubmitBehavior: true
    };

    return (
        <React.Fragment>
            <Toolbar>
                <Item location="before"
                    widget="dxButton"
                    options={backButtonOptions} />
                <Item location="center"
                    text={'Chi tiết Feedback'} />
            </Toolbar>

            <form action="your-action" onSubmit={handleSubmit}>
                <Form
                    formData={feedback}
                    readOnly={false}
                    showColonAfterLabel={true}
                    colCount={3}
                    showValidationSummary={true}
                    validationGroup="customerData"
                >
                    <GroupItem caption="Đa ngôn ngữ" colSpan={2}>
                        <TabbedItem>
                            {tabs.map((item) => {
                                return (
                                    <Tab key={item.id} title={item.text} icon={item.icon}>
                                        <SimpleItem dataField={`title-${item.languageId}`} editorType="dxTextBox">
                                            <Label text={formatMessage('title')} />
                                            <RequiredRule />
                                        </SimpleItem>
                                        <SimpleItem dataField={`serviceName-${item.languageId}`} editorType="dxTextBox">
                                            <Label text={'Dịch vụ'} />
                                        </SimpleItem>
                                        <SimpleItem dataField={`content-${item.languageId}`} editorType="dxTextArea" editorOptions={notesEditorOptions}>
                                            <Label text={formatMessage('content')} />
                                        </SimpleItem>
                                        <SimpleItem dataField={`description-${item.languageId}`} editorType="dxTextArea" editorOptions={notesEditorOptions}>
                                            <Label text={formatMessage('description')} />
                                        </SimpleItem>
                                    </Tab>
                                );
                            })}
                        </TabbedItem>
                    </GroupItem>

                    <GroupItem caption="Thông tin khác">
                        <SimpleItem dataField={'email'} editorType="dxTextBox">
                            <Label text={formatMessage('email')} />
                            <RequiredRule />
                        </SimpleItem>
                        <SimpleItem dataField={'fullName'} editorType="dxTextBox">
                            <Label text={formatMessage('fullName')} />
                            <RequiredRule />
                        </SimpleItem>
                        <SimpleItem dataField={'phoneNumber'} editorType="dxTextBox">
                            <Label text={formatMessage('phoneNumber')} />
                            <RequiredRule />
                        </SimpleItem>
                        <SimpleItem dataField={'isView'} editorType="dxCheckBox">
                            <Label text={formatMessage('isView')} />
                        </SimpleItem>
                    </GroupItem>

                    <ButtonItem colSpan={3} horizontalAlignment="center"
                        buttonOptions={saveButtonOptions}
                    />
                </Form>
            </form>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.data.token,
        currentLanguage: state.setting.currentLanguage,
        languages: state.setting.languages,
        currentWebsite: state.website.data.currentWebsite,
        websiteLanguages: state.website.data.languages
    };
};

FeedbackDetailContainer.propTypes = {
    accessToken: PropTypes.string,
    languages: PropTypes.array,
    currentLanguage: PropTypes.string,
    websiteId: PropTypes.string,
    websiteLanguages: PropTypes.array,
    currentWebsite: PropTypes.string
};

export default connect(mapStateToProps, null)(FeedbackDetailContainer);
