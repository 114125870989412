import React, { useState } from "react";
import DataGrid, {
  Editing,
  Column,
  Button,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import PropTypes from "prop-types";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import notify from "devextreme/ui/notify";
import { Popup } from "devextreme-react/popup";
import BannerItemDetail from "./BannerItemDetail";
import { FILE_URL, WEBSITE_URL } from "../../utils/constants";
import { BannerItemVM } from "../../model/Banner";

loadMessages(dictionary);

const pageSizes = [5, 10, 20, 50];

const BannerItemsList = ({
  bannerId,
  currentWebsite,
  currentLanguage,
  accessToken,
  languages,
}) => {
  locale(currentLanguage.substring(0, 2));
  const URL = `${WEBSITE_URL}Banners`;
  const [showItemPopup, setShowItemPopup] = useState(false);
  const [bannerItemId, setBannerItemId] = useState(null);

  const loadRequest = (url) => {
    return fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return {
            data: json.data.bannerItems.map((image) =>
              BannerItemVM(image, languages)
            ),
          };
        }
        throw json.Message;
      })
    );
  };

  const removeRequest = (url) => {
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          if (text) {
            const _response = JSON.parse(text);
            notify(
              {
                message: _response.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );

            return _response;
          }
        });
      } else {
        return result.json().then((json) => {
          throw json.Message;
        });
      }
    });
  };

  const bannerItemsData = new CustomStore({
    key: "id",
    load: () => loadRequest(`${URL}/${currentWebsite}/${bannerId}`),
    remove: (key) =>
      removeRequest(`${URL}/item/${currentWebsite}/${bannerId}/${key}`),
  });

  const imgCellRender = (data) => {
    return (
      <img
        alt="GHM Image"
        src={`${FILE_URL}/${data.value}`}
        style={{ height: 50 }}
      />
    );
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            showBannerItemDetail();
          },
        };
      }
    });

    toolbarItems.push({
      location: "center",
      text: "Danh sách Banner Items",
    });
  };

  const showBannerItemDetail = (_bannerItemId = null) => {
    setBannerItemId(_bannerItemId);
    setShowItemPopup(true);
  };

  const hideItemDetailPopup = () => {
    setShowItemPopup(false);
    setBannerItemId(null);
  };

  return (
    <React.Fragment>
      <DataGrid
        dataSource={bannerItemsData}
        showBorders={true}
        columnAutoWidth={true}
        onToolbarPreparing={onToolbarPreparing}
        selection={{ mode: "single" }}
      >
        <Editing
          mode="form"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
        />
        <Column dataField="images" cellRender={imgCellRender} />
        <Column dataField="imagesMobile" cellRender={imgCellRender} />
        <Column dataField="url" />
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`name-${item.languageId}`}
            dataType="string"
            caption={formatMessage("name")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`description-${item.languageId}`}
            dataType="string"
            caption={formatMessage("description")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`alt-${item.languageId}`}
            dataType="string"
            caption={formatMessage("alt")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        <Column
          dataField="order"
          dataType="string"
          caption={formatMessage("order")}
        />
        <Column
          dataField="isActive"
          dataType="boolean"
          caption={formatMessage("isActive")}
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => showBannerItemDetail(e.row.key)}
          />
        </Column>

        <Pager
          visible={true}
          displayMode={"full"}
          allowedPageSizes={pageSizes}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Paging defaultPageSize={10} />
      </DataGrid>

      <Popup
        visible={showItemPopup}
        onHiding={() => setShowItemPopup(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showCloseButton={false}
        showTitle={false}
        container=".dx-viewport"
        width={800}
        height={600}
      >
        <BannerItemDetail
          accessToken={accessToken}
          currentLanguage={currentLanguage}
          currentWebsite={currentWebsite}
          websiteLanguages={languages}
          bannerId={bannerId}
          id={bannerItemId}
          hidePopup={hideItemDetailPopup}
        />
      </Popup>
    </React.Fragment>
  );
};

BannerItemsList.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  currentWebsite: PropTypes.string,
  items: PropTypes.array,
  bannerId: PropTypes.string,
};

export default BannerItemsList;
