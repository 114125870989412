export const mock = {
    LANGUAGES: [
        { languageId: "vi-VN", name: "Tiếng Việt" }
    ],
    CURRENT_LANGUAGE: 'vi-VN',
    WEBSITES: [
        { id: 'cfdc8723-56dd-46e9-a6b5-68e88a34c8d8', name: "Mori-Mori Website" },
        { id: 'cfdc8723-56dd-46e9-a6b5-68e88a34c8d9', name: "JSPA Website" }
    ],
    CURRENT_WEBSITE: '4388393b-6b61-4400-a1da-69ae9aad726d',
    WEBSITE_LANGUAGES: [
        { languageId: "vi-VN", name: "Tiếng Việt" }
    ],
}