import React, { useState, useRef, useEffect } from "react";
import DropDownBox from "devextreme-react/drop-down-box";
import TreeView from "devextreme-react/tree-view";
import PropTypes from "prop-types";
import { WEBSITE_URL } from "../../utils/constants";

const parseJSON = (str, languageId) => {
  let _strObj;
  try {
    _strObj = JSON.parse(str);
  } catch (e) {
    return "";
  }
  return _strObj ? _strObj[languageId] || "" : "";
};

const CategoryVM = (data, currentLanguage) => {
  const returnData = Object.assign({}, data);

  returnData["name"] = parseJSON(data.title, currentLanguage);

  return returnData;
};

const ServicesDropDown = ({
  currentWebsite,
  accessToken,
  currentLanguage,
  value,
  onValueChanged,
  selectionMode = null,
  updateSeoLink = null,
}) => {
  const treeView = useRef(null);
  const [treeBoxValue, setTreeBoxValue] = useState([]);
  const [listService, setListService] = useState([]);

  useEffect(() => {
    if (value) {
      if (selectionMode === "single") {
        setTreeBoxValue([value]);
      } else {
        setTreeBoxValue(value);
      }
    }
  }, [value, selectionMode]);

  useEffect(() => {
    fetch(`${WEBSITE_URL}Services/get-all-qa/${currentWebsite}?type=0`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) =>
      response.json().then((response) => {
        // if (response) {
        setListService(
          response.map((data) => CategoryVM(data, currentLanguage))
        );
        // }
      })
    );
  }, [currentWebsite, currentLanguage, accessToken]);

  const treeViewItemSelectionChanged = (e) => {
    const _value = e.component.getSelectedNodeKeys();

    setTreeBoxValue(_value);
    onValueChanged(_value);

    if (updateSeoLink !== null) {
      if (_value.length > 0) {
        const _selectedCategoryNews = listService.find(
          (x) => x.id === _value[0]
        );
        updateSeoLink(_selectedCategoryNews.seoLink);
      } else {
        updateSeoLink("");
      }
    }
  };

  const treeViewRender = () => {
    return (
      <TreeView
        dataSource={listService}
        ref={treeView}
        dataStructure="plain"
        keyExpr="id"
        parentIdExpr="parentId"
        selectionMode={selectionMode || "multiple"}
        showCheckBoxesMode="normal"
        selectNodesRecursive={false}
        displayExpr="name"
        selectByClick={true}
        onContentReady={syncTreeViewSelection}
        onItemSelectionChanged={treeViewItemSelectionChanged}
      />
    );
  };

  const syncTreeViewSelection = (e) => {
    const _treeView =
      (e.component.selectItem && e.component) || (treeView && treeView.current);

    if (_treeView) {
      if (e.value === null) {
        _treeView.instance.unselectAll();
      } else {
        const values = e.value || treeBoxValue;
        values &&
          values.forEach(function (value) {
            _treeView.selectItem(value);
          });
      }
    }

    if (e.value !== undefined) {
      setTreeBoxValue(e.value);
      onValueChanged(e.value || []);
    }
  };

  return (
    <React.Fragment>
      <DropDownBox
        value={treeBoxValue}
        valueExpr="id"
        displayExpr="name"
        placeholder="Select a value..."
        showClearButton={true}
        dataSource={listService}
        onValueChanged={syncTreeViewSelection}
        contentRender={treeViewRender}
      />
    </React.Fragment>
  );
};

ServicesDropDown.propTypes = {
  accessToken: PropTypes.string,
  currentLanguage: PropTypes.string,
  currentWebsite: PropTypes.string,
  value: PropTypes.string,
  onValueChanged: PropTypes.func,
  selectionMode: PropTypes.string,
  updateSeoLink: PropTypes.func,
};

export default ServicesDropDown;
