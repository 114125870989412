import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getNewToken } from '../actions/authActions';
import PropsType from 'prop-types';
import { useHistory } from 'react-router-dom';

const ExpiredTokenContainer = (props) => {
    const history = useHistory();

    useEffect(() => {
        if (props.refreshToken) {
            props.getNewToken({
                grantType: "refreshToken",
                refreshToken: props.refreshToken
            })
        }
    }, []);

    useEffect(() => {
        if (props.isAuthenticated) {
            history.goBack();
        }
    }, [props.isAuthenticated]);

    return (
        <div>
            Your token has been expired! Please go to login page! <br />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.data.isAuthenticated,
        refreshToken: state.auth.data.refreshToken
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNewToken: (payload) => {
            return dispatch(getNewToken(payload))
        }
    };
};

ExpiredTokenContainer.propTypes = {
    clearToken: PropsType.func,
    getNewToken: PropsType.func,
    isAuthenticated: PropsType.bool,
    refreshToken: PropsType.string
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpiredTokenContainer);
