import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  AUTH_CHECK_OK,
  AUTH_CHECK_FAIL,
  AUTH_CLEAR_TOKEN,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  REFRESH_REQUEST,
  REFRESH_SUCCESS,
  REFRESH_FAILURE,
} from "../actions/authActions";

var cookie = require("cookie-cutter");
const now = Date.now();

const INITIAL_STATE = {
  data: {
    isAuthenticated: false,
    token: "",
    refreshToken: "",
  },
  error: null,
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        data: {
          isAuthenticated: false,
          token: "",
          refreshToken: "",
        },
        error: null,
        loading: true,
      };
    }
    case LOGIN_SUCCESS: {
      const { access_token, refresh_token, expires_in } = action.payload;

      cookie.set("access_token", access_token, {
        expires: new Date(expires_in * 1000 + now),
        path: "/",
      });

      cookie.set("refresh_token", refresh_token, {
        path: "/",
      });

      return {
        ...state,
        data: {
          isAuthenticated: true,
          token: action.payload.access_token,
          refreshToken: action.payload.refresh_token,
        },
        error: null,
        loading: false,
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        data: {
          isAuthenticated: false,
          token: "",
          refreshToken: "",
        },
        error: action.payload.error,
        loading: false,
      };
    }

    case AUTH_CHECK_OK: {
      return {
        ...state,
        data: {
          isAuthenticated: true,
          token: action.payload.access_token,
          refreshToken: action.payload.refresh_token,
        },
      };
    }

    case AUTH_CHECK_FAIL: {
      return {
        ...state,
        data: {
          isAuthenticated: false,
          token: null,
          refreshToken: null,
        },
      };
    }

    case AUTH_CLEAR_TOKEN: {
      cookie.set("access_token", "", { expires: new Date(0) });
      cookie.set("refresh_token", "", { expires: new Date(0) });
      localStorage.clear();

      return {
        ...state,
        data: {
          isAuthenticated: false,
          token: "",
          refreshToken: "",
        },
        error: null,
        loading: false,
      };
    }

    case LOGOUT_REQUEST: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case LOGOUT_SUCCESS: {
      cookie.set("access_token", "", { expires: new Date(0) });
      cookie.set("refresh_token", "", { expires: new Date(0) });
      localStorage.clear();
      return {
        ...state,
        data: {
          isAuthenticated: false,
          token: "",
          refreshToken: "",
        },
        error: null,
        loading: false,
      };
    }
    case LOGOUT_FAILURE: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case REFRESH_REQUEST: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case REFRESH_SUCCESS: {
      localStorage.setItem("token", action.payload.data.token);
      localStorage.setItem("refreshToken", action.payload.data.refreshToken);

      return {
        ...state,
        data: {
          isAuthenticated: true,
          token: action.payload.data.token,
          refreshToken: action.payload.data.refreshToken,
        },
        error: null,
        loading: false,
      };
    }
    case REFRESH_FAILURE: {
      return {
        ...state,
        data: {
          isAuthenticated: false,
          token: "",
          refreshToken: "",
        },
        error: action.payload.error,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
