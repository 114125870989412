import React, { useCallback, useState, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { stringify } from 'querystring'
// devextreme
import DataGrid, {
  Column,
  Pager,
  Paging,
  MasterDetail,
  Export
} from "devextreme-react/data-grid";
import { Button } from 'devextreme-react/button';
import notify from "devextreme/ui/notify";
import Form, { ButtonItem, SimpleItem, Label, RequiredRule } from "devextreme-react/form";
import CustomStore from "devextreme/data/custom_store";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import { Popup } from "devextreme-react/popup";
// 
import useWindowDimensions from "../../hook/useWindowDimensions";
import dictionary from "../../data/dictionary";
import { WEBSITE_URL } from "../../utils/constants";
import PaymentForm from "./paymentForm";
import moment from 'moment';

loadMessages(dictionary);
const pageSizes = [10, 25, 50, 100];
const searchParams = { page: 1, pageSize: 10 };

const bankCellRender = ({ data }) => {
  if (data.bankAccountName) {
    return (
      <span>{data.bankAccountName} - {data.bankingNumber} - {data.bankName}</span>
    );
  }
};

const statusNames = ['Chờ phê duyệt', 'Đang hoạt động', 'Từ chối']
const EnumStatus = {
  Pending: 1,
  Active: 2,
  Reject: 3
}

const statusCellRender = ({ data: { approvedStatus, isActive } }) => {
  if (statusNames[approvedStatus - 1]) {
    return approvedStatus === EnumStatus.Active && !isActive ? 'Đã khoá' : statusNames[approvedStatus - 1]
  }
};

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";

const contentReady = (e) => {
  if (!e.component.getSelectedRowKeys().length) {
    e.component.selectRowsByIndexes(0);
  }
};
const selectionChanged = (e) => {
  e.component.collapseAll(-1);
  e.component.expandRow(e.currentSelectedRowKeys[0]);
}

const TeamList = ({ accessToken, currentLanguage }) => {
  locale(currentLanguage.substring(0, 2));
  const dataGridRef = useRef(null);
  const [status, setStatus] = useState()
  const [keyword, setKeyword] = useState();
  const [selectedId, setSelectedId] = useState(null);
  const [showDeclinePopup, setShowDeclinePopup] = useState(false);
  const [declineForm, setDeclineForm] = useState({ declineReason: "" });
  const { height } = useWindowDimensions();
  const [totalRows, setTotalRows] = useState(0);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [userId, setUserId] = useState(null);

  const getList = useCallback((loadOptions) => {
    const page =
      isNotEmpty(loadOptions.skip) && isNotEmpty(loadOptions.take)
        ? loadOptions.skip / loadOptions.take + 1
        : searchParams.page;
    const pageSize = isNotEmpty(loadOptions.take)
      ? loadOptions.take
      : searchParams.pageSize;

    return fetch(`${WEBSITE_URL}DoiNhom?${stringify({
      keyword,
      approvedStatus: status,
      page,
      pageSize
    })}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    })
      .then(result => result.json())
      .then((json) => {
        if (json.data) {
          setTotalRows(json.totalRows)
          return {
            data: json.data,
            totalCount: json.totalRows
          }
        } else {
          notify(
            {
              message: json?.message ?? "Lỗi server",
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "error",
            3000
          );
          throw json.Message;
        }
      })
      .catch((err) => {
        notify(
          {
            message: err.message || err,
            position: {
              my: "center top",
              at: "center top",
            },
          },
          "error",
          3000
        );
        throw (err.message || err);
      })
  }, [accessToken, currentLanguage, keyword, status]);

  const data = useMemo(() => {
    return new CustomStore({
      key: "id",
      load: getList
    })
  }, [getList]);

  const approveRequest = useCallback((id, data) => {
    if (showDeclinePopup) {
      setShowDeclinePopup(false);
    }

    return fetch(`${WEBSITE_URL}DoiNhom/status/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) => result.json())
      .then((json) => {
        if (json.code === 1) {
          dataGridRef.current.instance.refresh();
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
        } else {
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "error",
            3000
          );
          throw json.Message;
        }
      });
  }, [dataGridRef, showDeclinePopup, setShowDeclinePopup, data]);

  const inactiveRequest = useCallback((id, isActive) => {
    return fetch(`${WEBSITE_URL}DoiNhom/isActive/${id}/${isActive}`, {
      method: "PUT",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) => result.json())
      .then((json) => {
        if (json.code === 1) {
          dataGridRef.current.instance.refresh();
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
        } else {
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "error",
            3000
          );
          throw json.Message;
        }
      });
  }, [dataGridRef, data]);

  const onToolbarPreparing = useCallback((e) => {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.push(
      {
        location: "after",
        widget: "dxTextBox",
        options: {
          placeholder: "Nhập từ khóa tìm kiếm",
          value: keyword,
          width: 200,
          onValueChanged: (e) => setKeyword(e.value),
        },
      },
      {
        location: "after",
        widget: "dxSelectBox",
        options: {
          placeholder: "Trạng thái phê duyệt",
          value: status,
          width: 200,
          displayExpr: "text",
          valueExpr: "value",
          showClearButton: true,
          items: [{ value: 1, text: 'Chờ phê duyệt' }, { value: 2, text: 'Đang hoạt động' }, { value: 3, text: 'Từ chối' }],
          onValueChanged: (e) => setStatus(e.value),
        },
      },
      {
        location: "center",
        text: "Đội nhóm",
      }
    );
  }, [setKeyword, setStatus]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    approveRequest(selectedId, {
      status: 3,
      declineReason: declineForm.declineReason,
    });
    setShowDeclinePopup(false)
  }, [selectedId, approveRequest, declineForm]);

  const rejectHandler = useCallback((id) => {
    return () => {
      // e.preventDefault();
      setSelectedId(id)
      setDeclineForm({ declineReason: '' })
      setShowDeclinePopup(true);
    }
  }, [setSelectedId, setShowDeclinePopup, setShowDeclinePopup])

  const renderDetail = useCallback(({ data }) => {
    const { id, fullName, bankName, bankingNumber, bankAccountName, phoneNumber, approvedStatus, totalPatientSuccess, totalPatient, totalPoint, isActive } = data;
    return (
      <div className="employee-info">
        <h4>{fullName}</h4>
        <div className="item">
          <i className="dx-icon dx-icon-check"></i>
          <span>Trạng thái: {statusNames[approvedStatus - 1]}</span>
        </div>
        {bankAccountName && <div className="item">
          <i className="dx-icon dx-icon-money"></i>
          <span>Tài khoản ngân hàng: {bankAccountName} {bankingNumber} {bankName}</span>
        </div>}
        <div className="item">
          <i className="dx-icon dx-icon-share"></i>
          <span>Điện thoại: {phoneNumber}</span>
        </div>
        {(totalPatient > 0) && <div className="item">
          <i className="dx-icon dx-icon-runner"></i>
          <span>Khách mời thành công: {totalPatientSuccess} / {totalPatient}</span>
        </div>}
        {(totalPoint > 0) && <div className="item">
          <i className="dx-icon dx-icon-favorites"></i>
          <span>Tổng điểm: {totalPoint}</span>
        </div>}
        {approvedStatus === EnumStatus.Active && isActive && <div className="item">
          <Button text='Thanh toán' type="success" onClick={() => {
            setUserId(id);
            setShowPaymentPopup(true)
          }} />
        </div>}
        {approvedStatus === EnumStatus.Pending && <div className="btn-group">
          <Button icon='check' type='success' text='Phê duyệt' onClick={() => approveRequest(id, { status: EnumStatus.Active, declineReason: '' })} />
          <Button icon='close' text='Từ chối' onClick={rejectHandler(id)} />
        </div>}
        {approvedStatus === EnumStatus.Active && <div className="btn-group">
          <Button type={isActive ? 'danger' : 'default'} text={isActive ? 'Khoá tài khoản' : 'Mở tài khoản'} onClick={() => inactiveRequest(id, !isActive)} />
        </div>}
      </div>
    );
  }, [rejectHandler, data])

  const memoTotalPage = useMemo(() => {
    if (totalRows) return totalRows > pageSizes[pageSizes.length - 1] ? [...pageSizes, totalRows] : pageSizes
    return pageSizes
  }, [totalRows])

  const birthdayRender = ({ value }) => {
    const data = value ? moment(value).format('DD-MM-YYYY') : "";
    return (
      <div>{data}</div>
    );
  };

  return (
    <div
      className="form-container"
      style={{ overflow: "auto", height: height - 56, paddingBottom: 20 }}
    >
      <DataGrid
        keyExpr="id"
        ref={dataGridRef}
        dataSource={data}
        wordWrapEnabled={true}
        allowColumnReordering={false}
        selection={{ mode: "single" }}
        showBorders={true}
        remoteOperations={true}
        onToolbarPreparing={onToolbarPreparing}
        onSelectionChanged={selectionChanged}
        onContentReady={contentReady}
        errorRowEnabled={false}
      >
        <Export enabled={true} />
        <Column
          dataField="fullName"
          dataType="string"
          caption={formatMessage("name")}
        />

        <Column
          dataField="phoneNumber"
          dataType="string"
          caption={formatMessage("phoneNumber")}
        />

        <Column
          dataField="birthday"
          dataType="string"
          caption={"Ngày sinh"}
          cellRender={birthdayRender}
        />

        <Column
          dataField="bankName"
          dataType="string"
          caption={formatMessage("bank")}
          cellRender={bankCellRender}
        />

        <Column
          dataField="objectName"
          dataType="string"
          caption={"Đối tượng"}
        />

        <Column
          dataField="createTime"
          dataType="string"
          caption={"Ngày đăng ký tài khoản"}
          cellRender={birthdayRender}
        />
        <Column
          dataField="approvedStatus"
          dataType="string"
          caption={formatMessage("approvedStatus")}
          cellRender={statusCellRender}
        />

        <Pager allowedPageSizes={memoTotalPage} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
        <MasterDetail enabled={false} render={renderDetail} approveHandler='test' />
      </DataGrid>

      <Popup
        visible={showDeclinePopup}
        onHiding={() => setShowDeclinePopup(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        title="Lý do từ chối"
        container=".dx-viewport"
        width={400}
        height={200}
      >
        <form action="your-action" onSubmit={handleSubmit}>
          <Form
            formData={declineForm}
            readOnly={false}
            showColonAfterLabel={true}
            showValidationSummary={true}
          >
            <SimpleItem dataField={"declineReason"} editorType="dxTextArea">
              <Label text="Lý do" />
              <RequiredRule message="Lý do là trường bắt buộc" />
            </SimpleItem>

            <ButtonItem
              horizontalAlignment="center"
              buttonOptions={{
                text: "Gửi",
                icon: "email",
                type: "default",
                stylingMode: "contained",
                useSubmitBehavior: true,
              }}
            />
          </Form>
        </form>
      </Popup>

      {showPaymentPopup &&
        <PaymentForm
          selectedId={userId}
          accessToken={accessToken}
          currentLanguage={currentLanguage}
          setShowPaymentPopup={setShowPaymentPopup} />}
    </div>
  );
}



const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
  };
};

TeamList.propTypes = {
  accessToken: PropTypes.string,
  currentLanguage: PropTypes.string,
};

export default connect(mapStateToProps, null)(TeamList);