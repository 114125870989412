const VideoVM = (data) => {
  const returnData = Object.assign({}, data);

  returnData["urlTxt"] = data.url;
  returnData["typeTxt"] = data.type;
  return returnData;
};

const VideoMV = (data) => {
  return {
    url: data.url,
    type: data.type,
  };
};

export { VideoVM, VideoMV };
