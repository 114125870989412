import React, { useEffect, useState } from "react";
import Form, {
  ButtonItem,
  SimpleItem,
  GroupItem,
  Label,
  TabbedItem,
  TabPanelOptions,
  Tab,
  EmptyItem,
  RequiredRule,
} from "devextreme-react/form";
import "devextreme-react/text-area";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../../data/dictionary";
import FileSelectPopup from "../../file/FileSelectPopup";
import WebsiteServiceTitleItemsList from "./WebsiteServiceTitleItemsList";
import { HTML_OPTIONS, WEBSITE_URL, WEBSITE_CLIENT_URL } from "../../../utils/constants";
import { ServiceTitleMV, ServiceTitleVM } from "../../../model/ServiceTitle";
import Transformer from "../../../utils/transformer";

loadMessages(dictionary);

const tabsMock = [
  {
    id: 0,
    text: "vi-VN",
    icon: "globe",
    content: "Vietnamese",
  },
];

const WebsiteServiceTitleDetail = ({
  accessToken,
  currentLanguage,
  currentWebsite,
  websiteLanguages,
  serviceId,
  id,
  hidePopup,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const [serviceTitle, setServiceTitle] = useState({ isActive: true });
  const [tabs, setTabs] = useState(tabsMock);
  const [references, setReferences] = useState([]);

  useEffect(() => {
    fetch(
      `${WEBSITE_CLIENT_URL}Category/get-all-service`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          setReferences(json);
          return;
        }
        throw json.Message;
      })
    );
  }, []);

  useEffect(() => {
    const _tabs = websiteLanguages.map((item, index) => ({
      id: index,
      languageId: item.languageId,
      text: item.name,
      icon: "globe",
      content: "User tab content",
    }));
    setTabs(_tabs);
  }, [websiteLanguages]);

  useEffect(() => {
    if (!currentWebsite || !id) {
      return;
    }
    fetch(`${WEBSITE_URL}Services/title/${currentWebsite}/${serviceId}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setServiceTitle(ServiceTitleVM(json.data, websiteLanguages));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [currentWebsite, id, accessToken, serviceId, websiteLanguages, history]);

  const updateService = () => {
    Transformer.fetchUpdate(
      `${WEBSITE_URL}Services/title/${currentWebsite}/${serviceId}/${id}`,
      {
        token: accessToken,
        body: ServiceTitleMV(serviceTitle, websiteLanguages),
        callback: () => {
          hidePopup();
        },
      }
    );
  };

  const addService = () => {
    Transformer.fetchCreate(
      `${WEBSITE_URL}Services/title/${currentWebsite}/${serviceId}`,
      {
        token: accessToken,
        body: ServiceTitleMV(serviceTitle, websiteLanguages),
        callback: () => {
          hidePopup();
        },
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      updateService();
    } else {
      addService();
    }
  };

  const saveButtonOptions = {
    text: formatMessage("save"),
    icon: "save",
    type: "default",
    stylingMode: "contained",
    useSubmitBehavior: true,
  };

  const updateImage = (e) => {
    setServiceTitle((prevState) => ({ ...prevState, image: e }));
  };

  const contentHtmlOptions = { ...HTML_OPTIONS, height: 300 };

  const referenceSelectOptions = {
    items: references,
    displayExpr: "serviceName",
    searchEnabled: true,
    valueExpr: "serviceId",
  };

  return (
    <React.Fragment>
      <Toolbar>
        <Item
          location="center"
          text={`${formatMessage("info")} ${formatMessage(
            "of"
          )} ${formatMessage("title")}`}
        />
      </Toolbar>
      <form action="your-action-title" onSubmit={handleSubmit}>
        <Form
          formData={serviceTitle}
          readOnly={false}
          showColonAfterLabel={true}
          colCount={3}
          showValidationSummary={true}
          validationGroup="customerDataTitle"
        >
          <GroupItem caption="Đa ngôn ngữ" colSpan={2}>
            <TabbedItem>
              <TabPanelOptions deferRendering={false} />
              {tabs.map((item) => {
                return (
                  <Tab key={item.id} title={item.text} icon={item.icon}>
                    <SimpleItem
                      dataField={`title-${item.languageId}`}
                      editorType="dxTextBox"
                    >
                      <Label text={formatMessage("title")} />
                      <RequiredRule
                        message={`${formatMessage("title")} ${formatMessage(
                          item.languageId
                        )} ${formatMessage("errMesRequire")}`}
                      />
                    </SimpleItem>

                    <SimpleItem
                      dataField={`description-${item.languageId}`}
                      editorType="dxTextArea"
                      editorOptions={{ height: 200 }}
                    >
                      <Label text={formatMessage("description")} />
                    </SimpleItem>

                    <SimpleItem
                      dataField={`content-${item.languageId}`}
                      editorType="dxHtmlEditor"
                      editorOptions={contentHtmlOptions}
                    >
                      <Label text={formatMessage("content")} />
                    </SimpleItem>
                  </Tab>
                );
              })}
            </TabbedItem>
          </GroupItem>

          <GroupItem caption="Nội dung khác">
            <SimpleItem>
              <Label text={formatMessage("image")} />
              <FileSelectPopup
                value={serviceTitle.image}
                imgHeight={100}
                onValueChanged={updateImage}
              />
              <RequiredRule />
            </SimpleItem>

            <SimpleItem dataField={"alt"} editorType="dxTextBox" colSpan={2}>
              <Label text={formatMessage("alt")} />
            </SimpleItem>

            <SimpleItem dataField="url" editorType="dxTextBox">
              <Label text={formatMessage("url")} />
            </SimpleItem>

            <SimpleItem
              dataField="referenceId"
              editorType="dxSelectBox"
              editorOptions={referenceSelectOptions}
            >
              <Label text={formatMessage("referenceId ")} />
            </SimpleItem>

            <SimpleItem dataField="unitPrice" editorType="dxTextBox">
              <Label text={formatMessage("unitPrice")} />
            </SimpleItem>

            <SimpleItem dataField="order" editorType="dxNumberBox">
              <Label text={formatMessage("order")} />
            </SimpleItem>

            <SimpleItem dataField="isActive" editorType="dxCheckBox">
              <Label text={formatMessage("isActive")} />
            </SimpleItem>
          </GroupItem>

          <EmptyItem />
          <GroupItem horizontalAlignment="center" colSpan={3}>
            <ButtonItem
              horizontalAlignment="center"
              validationGroup="customerDataTitle"
              buttonOptions={saveButtonOptions}
            />
          </GroupItem>
        </Form>
      </form>

      <hr />
      <WebsiteServiceTitleItemsList
        serviceId={serviceId}
        titleId={id}
        accessToken={accessToken}
        languages={websiteLanguages}
        currentWebsite={currentWebsite}
        currentLanguage={currentLanguage}
      />
    </React.Fragment>
  );
};

WebsiteServiceTitleDetail.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
  serviceId: PropTypes.string,
  id: PropTypes.string,
  hidePopup: PropTypes.func,
};

export default WebsiteServiceTitleDetail;
