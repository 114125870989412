import React from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import NavigationDrawer from "../layout/NavigationDrawer";
import DashboardContainer from "../containers/DashboardContainer";
import ExpiredTokenContainer from "../containers/ExpiredTokenContainer";
import WebsiteListContainer from "../containers/website/WebsiteListContainer";
import WebsiteDetailContainer from "../containers/website/WebsiteDetailContainer";
import NewsContainer from "../containers/website/NewsContainer";
import NewsDetailContainer from "../containers/website/NewsDetailContainer";
import NewsCategoryContainer from "../containers/website/NewsCategoryContainer";
import NewsCategoryDetailContainer from "../containers/website/NewsCategoryDetailContainer";
import SettingContainer from "../containers/website/SettingContainer";
import MenuContainer from "../containers/website/MenuContainer";
import MenuDetailContainer from "../containers/website/MenuDetailContainer";
import BannerContainer from "../containers/website/BannerContainer";
import BannerDetailContainer from "../containers/website/BannerDetailContainer";
import FAQContainer from "../containers/website/FAQContainer";
import FAQDetailContainer from "../containers/website/FAQDetailContainer";
import FAQGroupContainer from "../containers/website/FAQGroupContainer";
import FAQGroupDetailContainer from "../containers/website/FAQGroupDetailContainer";
import HardContentDetailContainer from "../containers/website/HardContentDetailContainer";
import BranchContactDetailContainer from "../containers/website/BranchContactDetailContainer";
import SocialNetworkDetailContainer from "../containers/website/SocialNetworkDetailContainer";
import WebsiteServiceDetailContainer from "../containers/website/WebsiteServiceDetailContainer";
import WebisteSpecialization from '../containers/website/WebisteSpecializationDetail';
import WebsiteServicesContainer from "../containers/website/WebsiteServicesContainer";
import GroupServiceContainer from '../containers/website/GroupServiceContainer';
import GroupServiceContainerDetail from '../containers/website/GroupServiceDetail';

import SpecializationContainer from '../containers/website/SpecializationContainer';
import PartnerContainer from "../containers/website/PartnerContainer";
import PartnerDetailContainer from "../containers/website/PartnerDetailContainer";
import FeedbackContainer from "../containers/website/FeedbackContainer";
import FeedbackDetailContainer from "../containers/website/FeedbackDetailContainer";
import CustomerFeedbackContainer from "../containers/website/CustomerFeedbackContainer";
import ExpertQAContainerContainer from "../containers/website/ExpertQAContainer";
import CustomerFeedbackDetailContainer from "../containers/website/CustomerFeedbackDetailContainer";
import WebsiteJobContainer from "../containers/website/WebsiteJobContainer";
import WebsiteJobDetailContainer from "../containers/website/WebsiteJobDetailContainer";
import RecruitmentContainer from "../containers/website/RecruitmentContainer";
import RecruitmentDetailContainer from "../containers/website/RecruitmentDetailContainer";
import WebsiteBookingContainer from "../containers/website/WebsiteBookingContainer";
import WebsiteBookingDetailContainer from "../containers/website/WebsiteBookingDetailContainer";
import AlbumContainer from "../containers/website/AlbumContainer";
import AlbumDetailContainer from "../containers/website/AlbumDetailContainer";
import FileMgrContainer from "../containers/FileMgrContainer";
import VideoDetailContainer from "../containers/VideoDetailContainer";
import AdvertisementContainer from "../containers/AdvertisementContainer";
import AdvertisementDetailContainer from "../containers/AdvertisementDetailContainer";
import SickContainer from "../containers/SickContainer";
import SickDetailContainer from "../containers/SickDetailContainer";
import TeamContainer from "../containers/team/List";
import CustomerContainer from "../containers/team/customer";

import ExpertQADetailContainer from "../containers/website/ExpertQADetailContainer";

import NganhNgheContainer from "../containers/website/NganhNgheContainer";
import NganhNgheDetail from "../containers/website/NganhNgheDetail";
import LevelContainer from "../containers/website/LevelContainer";
import LevelDetail from "../containers/website/LevelDetail";
import JobTypeContainer from "../containers/website/JobTypeContainer";
import JobTypeDetail from "../containers/website/JobTypeDetail";
const Content = () => {
  return (
    <NavigationDrawer title={"GHM"}>
      <Switch>
        <PrivateRoute exact path="/" component={DashboardContainer} />
        <PrivateRoute exact path="/website" component={WebsiteListContainer} />
        <PrivateRoute
          exact
          path="/website/add"
          component={WebsiteDetailContainer}
        />
        <PrivateRoute
          exact
          path="/website/:websiteId"
          component={WebsiteDetailContainer}
        />

        <PrivateRoute exact path="/album" component={AlbumContainer} />
        <PrivateRoute
          exact
          path="/album/add"
          component={AlbumDetailContainer}
        />
        <PrivateRoute
          exact
          path="/album/:albumId"
          component={AlbumDetailContainer}
        />

        <PrivateRoute exact path="/news" component={NewsContainer} />
        <PrivateRoute exact path="/news/add" component={NewsDetailContainer} />
        <PrivateRoute
          exact
          path="/news/category"
          component={NewsCategoryContainer}
        />
        <PrivateRoute
          exact
          path="/news/category/add"
          component={NewsCategoryDetailContainer}
        />

        <PrivateRoute exact path="/news/:id" component={NewsDetailContainer} />

        <PrivateRoute
          exact
          path="/news/category/:categoryId"
          component={NewsCategoryDetailContainer}
        />

        <PrivateRoute exact path="/menu" component={MenuContainer} />
        <PrivateRoute exact path="/menu/add" component={MenuDetailContainer} />
        <PrivateRoute
          exact
          path="/menu/:menuId"
          component={MenuDetailContainer}
        />

        <PrivateRoute exact path="/banner" component={BannerContainer} />
        <PrivateRoute
          exact
          path="/banner/add"
          component={BannerDetailContainer}
        />
        <PrivateRoute
          exact
          path="/banner/:bannerId"
          component={BannerDetailContainer}
        />

        <PrivateRoute exact path="/file" component={FileMgrContainer} />

        <PrivateRoute exact path="/faq" component={FAQContainer} />
        <PrivateRoute exact path="/faq/add" component={FAQDetailContainer} />
        <PrivateRoute exact path="/faq/:faqId" component={FAQDetailContainer} />

        <PrivateRoute exact path="/career" component={NganhNgheContainer} />
        <PrivateRoute exact path="/career/:id" component={NganhNgheDetail} />
        <PrivateRoute
          exact
          path="/career/add"
          component={NganhNgheDetail}
        />
        <PrivateRoute exact path="/job-type" component={JobTypeContainer} />
        <PrivateRoute exact path="/job-type/:id" component={JobTypeDetail} />
        <PrivateRoute
          exact
          path="/career/add"
          component={JobTypeDetail}
        />
        <PrivateRoute exact path="/level" component={LevelContainer} />
        <PrivateRoute exact path="/level/:id" component={LevelDetail} />
        <PrivateRoute
          exact
          path="/level/add"
          component={LevelDetail}
        />
        <PrivateRoute exact path="/faq-group" component={FAQGroupContainer} />
        <PrivateRoute
          exact
          path="/faq-group/add"
          component={FAQGroupDetailContainer}
        />
        <PrivateRoute
          exact
          path="/faq-group/:faqGroupId"
          component={FAQGroupDetailContainer}
        />

        <PrivateRoute
          exact
          path="/service-website"
          component={WebsiteServicesContainer}
        />
        <PrivateRoute
          exact
          path="/service-website/add"
          component={WebsiteServiceDetailContainer}
        />
        <PrivateRoute
          exact
          path="/service-website/:serviceId"
          component={WebsiteServiceDetailContainer}
        />
        <PrivateRoute
          exact
          path="/group-service"
          component={GroupServiceContainer}
        />
        <PrivateRoute
          exact
          path="/group-service/add"
          component={GroupServiceContainerDetail}
        />
        <PrivateRoute
          exact
          path="/group-service/:serviceId"
          component={GroupServiceContainerDetail}
        />
        <PrivateRoute
          exact
          path="/specialization"
          component={SpecializationContainer}
        />
        <PrivateRoute
          exact
          path="/specialization/add"
          component={WebisteSpecialization}
        />
        <PrivateRoute
          exact
          path="/specialization/:serviceId"
          component={WebisteSpecialization}
        />
        <PrivateRoute exact path="/partner" component={PartnerContainer} />
        <PrivateRoute
          exact
          path="/partner/add"
          component={PartnerDetailContainer}
        />
        <PrivateRoute
          exact
          path="/partner/:partnerId"
          component={PartnerDetailContainer}
        />

        <PrivateRoute exact path="/feedback" component={FeedbackContainer} />
        <PrivateRoute
          exact
          path="/feedback/add"
          component={FeedbackDetailContainer}
        />
        <PrivateRoute
          exact
          path="/feedback/:feedbackId"
          component={FeedbackDetailContainer}
        />

        <PrivateRoute
          exact
          path="/customerFeedback"
          component={CustomerFeedbackContainer}
        />
        <PrivateRoute
          exact
          path="/expert-question-and-answer"
          component={ExpertQAContainerContainer}
        />
        <PrivateRoute
          exact
          path="/expert-question-and-answer/:questionId"
          component={ExpertQADetailContainer}
        />
        <PrivateRoute
          exact
          path="/customerFeedback/add"
          component={CustomerFeedbackDetailContainer}
        />
        <PrivateRoute
          exact
          path="/customerFeedback/:customerFeedbackId"
          component={CustomerFeedbackDetailContainer}
        />

        <PrivateRoute exact path="/job" component={WebsiteJobContainer} />
        <PrivateRoute
          exact
          path="/job/add"
          component={WebsiteJobDetailContainer}
        />
        <PrivateRoute
          exact
          path="/job/:jobId"
          component={WebsiteJobDetailContainer}
        />

        <PrivateRoute
          exact
          path="/recruitment"
          component={RecruitmentContainer}
        />
        <PrivateRoute
          exact
          path="/recruitment/add"
          component={RecruitmentDetailContainer}
        />
        <PrivateRoute
          exact
          path="/recruitment/:recruitmentId"
          component={RecruitmentDetailContainer}
        />

        <PrivateRoute
          exact
          path="/booking"
          component={WebsiteBookingContainer}
        />
        <PrivateRoute
          exact
          path="/booking/add"
          component={WebsiteBookingDetailContainer}
        />
        <PrivateRoute
          exact
          path="/booking/:id"
          component={WebsiteBookingDetailContainer}
        />

        <PrivateRoute exact path="/info-website" component={SettingContainer} />
        <PrivateRoute
          exact
          path="/hardContent/add"
          component={HardContentDetailContainer}
        />
        <PrivateRoute
          exact
          path="/hardContent/:hardContentId"
          component={HardContentDetailContainer}
        />
        <PrivateRoute
          exact
          path="/branchContact/add"
          component={BranchContactDetailContainer}
        />
        <PrivateRoute
          exact
          path="/branchContact/:branchContactId"
          component={BranchContactDetailContainer}
        />
        <PrivateRoute
          exact
          path="/socialNetwork/add"
          component={SocialNetworkDetailContainer}
        />
        <PrivateRoute
          exact
          path="/socialNetwork/:socialNetworkId"
          component={SocialNetworkDetailContainer}
        />

        <PrivateRoute exact path="/video" component={VideoDetailContainer} />
        <PrivateRoute
          exact
          path="/advertisement"
          component={AdvertisementContainer}
        />
        <PrivateRoute
          exact
          path="/advertisement/add"
          component={AdvertisementDetailContainer}
        />
        <PrivateRoute
          exact
          path="/advertisement/:id"
          component={AdvertisementDetailContainer}
        />

        <PrivateRoute exact path="/sick" component={SickContainer} />
        <PrivateRoute exact path="/sick/add" component={SickDetailContainer} />
        <PrivateRoute exact path="/sick/:id" component={SickDetailContainer} />

        <PrivateRoute exact path="/team" component={TeamContainer} />
        <PrivateRoute exact path="/customer" component={CustomerContainer} />

        <PrivateRoute path="/expiredToken" component={ExpiredTokenContainer} />
        <Redirect to={"/"} />
      </Switch>
    </NavigationDrawer>
  );
};

export default Content;
