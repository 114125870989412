import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Form, {
    ButtonItem,
    SimpleItem,
    GroupItem,
    Label,
    TabbedItem, TabPanelOptions, Tab, RequiredRule
} from 'devextreme-react/form';
import 'devextreme-react/text-area';
import 'devextreme-react/html-editor';
import notify from 'devextreme/ui/notify';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import dictionary from '../../data/dictionary';
import useJSON from '../../hook/useJSON';
import BannerItemsList from '../../components/website/BannerItemsList';
import { BANNER_POSITION, BANNER_TYPE, WEBSITE_URL } from '../../utils/constants';

loadMessages(dictionary);

const parseJSON = (str, languageId) => {
    let _strObj;
    try {
        _strObj = JSON.parse(str);
    } catch (e) {
        return str || '';
    }
    return _strObj ? _strObj[languageId] || '' : '';
};

const tabsMock = [{
    id: 0,
    text: 'vi-VN',
    icon: 'globe',
    content: 'Vietnamese'
}];

const BannerVM = (data, languages) => {
    const returnData = useJSON(data, languages);

    return returnData;
};

const BannerMV = (data, languages) => {
    const nameObj = {}; const descriptionObj = {};
    languages.forEach(language => {
        nameObj[[language.languageId]] = data[`name-${language.languageId}`];
        descriptionObj[[language.languageId]] = data[`description-${language.languageId}`];
    });

    return JSON.stringify({
        name: JSON.stringify(nameObj),
        description: JSON.stringify(descriptionObj),
        type: data.type,
        url: data.url,
        order: data.order,
        isActive: data.isActive,
        position: data.position,
        concurrencyStamp: data.concurrencyStamp
    });
};

const TypeVM = (data, currentLanguage) => {
    return {
        id: data.id,
        name: parseJSON(data.name, currentLanguage)
    };
};

const PositionVM = (data, currentLanguage) => {
    return {
        id: data.id,
        name: parseJSON(data.name, currentLanguage)
    };
};

const BannerDetailContainer = ({ accessToken, currentLanguage, currentWebsite, websiteLanguages }) => {
    locale(currentLanguage.substring(0, 2));
    const history = useHistory();
    const params = useParams();
    const [banner, setBanner] = useState({});
    const [tabs, setTabs] = useState(tabsMock);

    useEffect(() => {
        const _tabs = websiteLanguages.map((item, index) => ({
            id: index,
            languageId: item.languageId,
            text: item.name,
            icon: 'globe',
            content: 'User tab content'
        }));
        setTabs(_tabs);
    }, [websiteLanguages]);

    useEffect(() => {
        if (!currentWebsite || !params.bannerId) {
            return;
        }
        fetch(`${WEBSITE_URL}Banners/${currentWebsite}/${params.bannerId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setBanner(BannerVM(json.data, websiteLanguages));
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    }, [currentWebsite, websiteLanguages, accessToken, params.bannerId, history]);

    const updateBanner = () => {
        return fetch(`${WEBSITE_URL}Banners/${currentWebsite}/${banner.id}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: BannerMV(banner, websiteLanguages)
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    history.push('/banner');
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const addBanner = () => {
        return fetch(`${WEBSITE_URL}Banners?websiteId=${currentWebsite}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: BannerMV(banner, websiteLanguages)
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    history.push('/banner');
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (params.bannerId) {
            updateBanner(false);
        } else {
            addBanner(false);
        }
    };

    const saveButtonOptions = {
        text: formatMessage('save'),
        icon: 'save',
        type: 'default',
        stylingMode: 'contained',
        useSubmitBehavior: true,
        onClick: () => params.bannerId ? updateBanner() : addBanner()
    };

    const backButtonOptions = {
        type: 'back',
        onClick: () => {
            history.push('/banner');
        }
    };

    const bannerTypesSelectOptions = {
        items: BANNER_TYPE.map((type) => TypeVM(type, currentLanguage)),
        displayExpr: 'name',
        searchEnabled: true,
        valueExpr: 'id',
        readOnly: !!banner.id
    };

    const bannerPositionsSelectOptions = {
        items: BANNER_POSITION.map((type) => PositionVM(type, currentLanguage)),
        displayExpr: 'name',
        searchEnabled: true,
        valueExpr: 'id'
    };

    return (
        <React.Fragment>
            <Toolbar>
                <Item location="before"
                    widget="dxButton"
                    options={backButtonOptions} />
                <Item location="center"
                    text={`${formatMessage('info')} ${formatMessage('of')} ${formatMessage('banner')}`} />
            </Toolbar>
            <form action="your-action" onSubmit={handleSubmit}>
                <Form
                    formData={banner}
                    readOnly={false}
                    showColonAfterLabel={true}
                    colCount={2}
                    showValidationSummary={true}
                >

                    <GroupItem caption="Đa ngôn ngữ">
                        <TabbedItem>
                            <TabPanelOptions deferRendering={false} />
                            {tabs.map((item) => {
                                return (
                                    <Tab key={item.id} title={item.text} icon={item.icon}>
                                        <SimpleItem dataField={`name-${item.languageId}`} editorType="dxTextBox">
                                            <Label text={formatMessage('name')} />
                                            <RequiredRule message={`${formatMessage('name')} ${formatMessage(item.languageId)} ${formatMessage('errMesRequire')}`} />
                                        </SimpleItem>
                                        <SimpleItem dataField={`description-${item.languageId}`} editorType="dxTextArea">
                                            <Label text={formatMessage('description')} />
                                        </SimpleItem>
                                    </Tab>
                                );
                            })}
                        </TabbedItem>
                    </GroupItem>

                    <GroupItem caption="Nội dung khác" colCount={2}>
                        <SimpleItem dataField={'url'} editorType="dxTextBox" colSpan={2}>
                            <Label text={formatMessage('url')} />
                        </SimpleItem>
                        <SimpleItem dataField="type" editorType="dxSelectBox" editorOptions={bannerTypesSelectOptions} colSpan={2}>
                            <Label text={formatMessage('type')} />
                            <RequiredRule message={`${formatMessage('type')} ${formatMessage('errMesRequire')}`} />
                        </SimpleItem>
                        <SimpleItem dataField="position" editorType="dxSelectBox" editorOptions={bannerPositionsSelectOptions} colSpan={2}>
                            <Label text={formatMessage('position')} />
                            <RequiredRule message={`${formatMessage('position')} ${formatMessage('errMesRequire')}`} />
                        </SimpleItem>
                        <SimpleItem dataField={'order'} editorType="dxNumberBox">
                            <Label text={formatMessage('order')} />
                        </SimpleItem>
                        <SimpleItem dataField={'isActive'} editorType="dxCheckBox">
                            <Label text={formatMessage('isActive')} />
                        </SimpleItem>
                    </GroupItem>

                    <ButtonItem horizontalAlignment="center" colSpan={2}
                        buttonOptions={saveButtonOptions}
                    />
                </Form>
            </form>

            <hr />
            {banner.id && <BannerItemsList
                bannerId={banner.id}
                items={banner.bannerItems}
                currentWebsite={currentWebsite}
                currentLanguage={currentLanguage}
                accessToken={accessToken}
                languages={websiteLanguages} />}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.data.token,
        currentLanguage: state.setting.currentLanguage,
        languages: state.setting.languages,
        currentWebsite: state.website.data.currentWebsite,
        websiteLanguages: state.website.data.languages
    };
};

BannerDetailContainer.propTypes = {
    accessToken: PropTypes.string,
    languages: PropTypes.array,
    currentLanguage: PropTypes.string,
    websiteId: PropTypes.string,
    websiteLanguages: PropTypes.array,
    currentWebsite: PropTypes.string
};

export default connect(mapStateToProps, null)(BannerDetailContainer);
