import Form, {
    SimpleItem, GroupItem, TabbedItem, ButtonItem,
    Label, Tab, RangeRule, RequiredRule
} from 'devextreme-react/form';
import React, { useState, useEffect } from 'react';
import dictionary from '../../data/dictionary';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useHistory, useParams } from 'react-router-dom';
import FileSelectPopup from '../../components/file/FileSelectPopup';
import 'devextreme-react/text-area';
import 'devextreme-react/tag-box';
import notify from 'devextreme/ui/notify';
import useJSON from '../../hook/useJSON';
import { WEBSITE_URL } from '../../utils/constants';

loadMessages(dictionary);

const parseJSON = (str, languageId) => {
    let _strObj;
    try {
        _strObj = JSON.parse(str);
    } catch (e) {
        return str || '';
    }
    return _strObj ? _strObj[languageId] || '' : '';
};

const tabsMock = [{
    id: 0,
    text: 'vi-VN',
    icon: 'globe',
    content: 'Vietnamese'
}];

const notesEditorOptions = { height: 100 };

const FAQVM = (data, languages) => {
    const returnData = useJSON(data, languages);
    return returnData;
};

const FAQMV = (data, languages) => {
    const questionObj = {}; const answerObj = {};
    languages.forEach(language => {
        questionObj[[language.languageId]] = data[`question-${language.languageId}`];
        answerObj[[language.languageId]] = data[`answer-${language.languageId}`];
    });

    return JSON.stringify({
        faqGroupId: data.faqGroupId,
        question: JSON.stringify(questionObj),
        answer: JSON.stringify(answerObj),
        photo: data.photo,
        alt: data.alt,
        order: data.order,
        concurrencyStamp: data.concurrencyStamp,
        isActive: data.isActive
    });
};

const FAQGroupVM = (data, languageId) => {
    return {
        id: data.id,
        name: parseJSON(data.name, languageId)
    };
};

const FAQDetailContainer = ({ accessToken, currentLanguage, currentWebsite, websiteLanguages }) => {
    locale(currentLanguage.substring(0, 2));
    const [tabs, setTabs] = useState(tabsMock);
    const [faq, setFAQ] = useState({});
    const [faqGroups, setFAQGroups] = useState([]);
    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        fetch(`${WEBSITE_URL}FAQGroups/get-all/${currentWebsite}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setFAQGroups(json.map(group => FAQGroupVM(group, currentLanguage)));
                    return;
                }
                throw json.Message;
            })
        );
    }, [accessToken, currentLanguage, currentWebsite]);

    useEffect(() => {
        if (!params.faqId) return;

        fetch(`${WEBSITE_URL}FAQs/${currentWebsite}/${params.faqId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setFAQ(FAQVM(json.data, websiteLanguages));
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    }, [params.faqId, history, accessToken, currentWebsite, websiteLanguages]);

    const updateFAQ = () => {
        const _method = faq.id ? 'PUT' : 'POST';
        const _url = `${WEBSITE_URL}FAQs/${currentWebsite}${faq.id ? `/${faq.id}` : ''}`;

        return fetch(_url, {
            method: _method,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': currentLanguage
            },
            body: FAQMV(faq, websiteLanguages)
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    history.push('/faq');
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateFAQ();
    };

    useEffect(() => {
        const _tabs = websiteLanguages.map((item, index) => ({
            id: index,
            languageId: item.languageId,
            text: item.name,
            icon: 'globe',
            content: 'User tab content'
        }));
        setTabs(_tabs);
    }, [websiteLanguages]);

    const backButtonOptions = {
        type: 'back',
        onClick: () => {
            history.goBack();
        }
    };

    const updatePhoto = e => {
        setFAQ(prevState => ({ ...prevState, photo: e }));
    };

    const saveButtonOptions = {
        text: 'Update',
        type: 'success',
        useSubmitBehavior: true
    };

    return (
        <React.Fragment>
            <Toolbar>
                <Item location="before"
                    widget="dxButton"
                    options={backButtonOptions} />
                <Item location="center"
                    text={'Chi tiết FAQ'} />
            </Toolbar>

            <form action="your-action" onSubmit={handleSubmit}>
                <Form
                    formData={faq}
                    readOnly={false}
                    showColonAfterLabel={true}
                    colCount={3}
                    showValidationSummary={true}
                    validationGroup="faqData"
                >
                    <GroupItem caption="Đa ngôn ngữ" colSpan={2}>
                        <TabbedItem>
                            {tabs.map((item) => {
                                return (
                                    <Tab key={item.id} title={item.text} icon={item.icon}>
                                        <SimpleItem dataField={`question-${item.languageId}`} editorType="dxTextArea" editorOptions={notesEditorOptions}>
                                            <Label text={formatMessage('question')} />
                                            <RequiredRule message={`${formatMessage('question')} ${formatMessage('errMesRequire')}`} />
                                        </SimpleItem>
                                        <SimpleItem dataField={`answer-${item.languageId}`} editorType="dxTextArea" editorOptions={notesEditorOptions}>
                                            <Label text={formatMessage('answer')} />
                                            <RequiredRule message={`${formatMessage('answer')} ${formatMessage('errMesRequire')}`} />
                                        </SimpleItem>
                                    </Tab>
                                );
                            })}
                        </TabbedItem>
                    </GroupItem>

                    <GroupItem caption="Thông tin khác">
                        <SimpleItem dataField="faqGroupId" editorType="dxSelectBox" editorOptions={{
                            valueExpr: 'id',
                            searchEnabled: true,
                            displayExpr: 'name',
                            items: faqGroups
                        }}>
                            <Label text={formatMessage('faqGroup')} />
                            <RequiredRule message={`${formatMessage('faqGroup')} ${formatMessage('errMesRequire')}`} />
                        </SimpleItem>
                        <SimpleItem >
                            <Label text={formatMessage('photo')} />
                            <FileSelectPopup value={faq.photo} imgHeight={100}
                                onValueChanged={updatePhoto} />
                        </SimpleItem>
                        <SimpleItem dataField={'alt'} editorType="dxTextArea">
                            <Label text={formatMessage('alt')} />
                        </SimpleItem>
                        <SimpleItem dataField={'order'} editorType="dxNumberBox">
                            <Label text={'Thứ tự'} />
                            <RangeRule min={0} message={'Thứ tự phải đúng định dạng'} />
                        </SimpleItem>
                        <SimpleItem dataField={'isActive'} editorType="dxCheckBox">
                            <Label text={'Kích hoạt'} />
                        </SimpleItem>
                    </GroupItem>

                    <ButtonItem colSpan={3} horizontalAlignment="center"
                        buttonOptions={saveButtonOptions}
                    />
                </Form>
            </form>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.data.token,
        currentLanguage: state.setting.currentLanguage,
        languages: state.setting.languages,
        currentWebsite: state.website.data.currentWebsite,
        websiteLanguages: state.website.data.languages
    };
};

FAQDetailContainer.propTypes = {
    accessToken: PropTypes.string,
    languages: PropTypes.array,
    currentLanguage: PropTypes.string,
    websiteId: PropTypes.string,
    websiteLanguages: PropTypes.array,
    currentWebsite: PropTypes.string
};

export default connect(mapStateToProps, null)(FAQDetailContainer);
