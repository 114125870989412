import { BANNER_POSITION, BANNER_TYPE } from "../utils/constants";
import Transformer from "../utils/transformer";

const BannerVM = (data, languages, currentLanguage) => {
  const returnData = Object.assign({}, data);

  returnData["positionName"] = Transformer.parseJSON(
    BANNER_POSITION[data.position].name,
    currentLanguage
  );
  returnData["typeName"] = Transformer.parseJSON(
    BANNER_TYPE[data.type].name,
    currentLanguage
  );

  languages.forEach((element) => {
    returnData[`name-${element.languageId}`] = Transformer.parseJSON(
      data.name,
      element.languageId
    );
    returnData[`description-${element.languageId}`] = Transformer.parseJSON(
      data.description,
      element.languageId
    );
  });

  return returnData;
};

const BannerItemVM = (data, languages) => {
  const returnData = Object.assign({}, data);

  languages.forEach((element) => {
    returnData[`name-${element.languageId}`] = Transformer.parseJSON(
      data.name,
      element.languageId
    );
    returnData[`description-${element.languageId}`] = Transformer.parseJSON(
      data.description,
      element.languageId
    );
    returnData[`alt-${element.languageId}`] = Transformer.parseJSON(
      data.alt,
      element.languageId
    );
  });

  return returnData;
};

export { BannerVM, BannerItemVM };
