import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ isAuthenticated, component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !isAuthenticated ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.data.isAuthenticated
    };
};

PublicRoute.propTypes = {
    component: PropTypes.any,
    isAuthenticated: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(PublicRoute);
