import React, { useEffect, useState } from 'react';
import Form, {
    SimpleItem,
    ButtonItem,
    Label,
    GroupItem,
    TabbedItem, TabPanelOptions, Tab
} from 'devextreme-react/form';
import FileSelectPopup from '../file/FileSelectPopup';
import PropTypes from 'prop-types';
import notify from 'devextreme/ui/notify';
import { useHistory } from 'react-router-dom';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import dictionary from '../../data/dictionary';
import { WEBSITE_URL } from '../../utils/constants';

loadMessages(dictionary);

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];

const tabsMock = [{
    id: 0,
    text: 'vi-VN',
    icon: 'globe',
    name: 'Vietnamese'
}];

const parseJSON = (str, languageId) => {
    let _strObj;
    try {
        _strObj = JSON.parse(str);
    } catch (e) {
        return '';
    }
    return _strObj ? _strObj[languageId] || '' : '';
};

const WebsiteInfoVM = (data, languages) => {
    const returnData = Object.assign({}, data);

    languages.forEach(element => {
        returnData[`brand-${element.languageId}`] = parseJSON(data.brand, element.languageId);
        returnData[`instruction-${element.languageId}`] = parseJSON(data.instruction, element.languageId);
        returnData[`metaTitle-${element.languageId}`] = parseJSON(data.metaTitle, element.languageId);
        returnData[`metaKeyword-${element.languageId}`] = parseJSON(data.metaKeyword, element.languageId);
        returnData[`metaDescription-${element.languageId}`] = parseJSON(data.metaDescription, element.languageId);
    });

    return returnData;
};

const WebsiteInfoMV = (data, languages) => {
    const multiLangData = {
        brand: {},
        instruction: {},
        metaTitle: {},
        metaKeyword: {},
        metaDescription: {}
    };

    languages.forEach(element => {
        multiLangData['brand'] = { ...multiLangData['brand'], [element.languageId]: data[`brand-${element.languageId}`] };
        multiLangData['instruction'] = { ...multiLangData['instruction'], [element.languageId]: data[`instruction-${element.languageId}`] };
        multiLangData['metaTitle'] = { ...multiLangData['metaTitle'], [element.languageId]: data[`metaTitle-${element.languageId}`] };
        multiLangData['metaKeyword'] = { ...multiLangData['metaKeyword'], [element.languageId]: data[`metaKeyword-${element.languageId}`] };
        multiLangData['metaDescription'] = { ...multiLangData['metaDescription'], [element.languageId]: data[`metaDescription-${element.languageId}`] };
    });

    return {
        brand: JSON.stringify(multiLangData.brand),
        instruction: JSON.stringify(multiLangData.instruction),
        metaTitle: JSON.stringify(multiLangData.metaTitle),
        metaKeyword: JSON.stringify(multiLangData.metaKeyword),
        metaDescription: JSON.stringify(multiLangData.metaDescription),
        logo: data.logo,
        favicon: data.favicon,
        logoMobile: data.logoMobile,
        logoFooter: data.logoFooter,
        zaloId: data.zaloId,
        ipBlocking: data.ipBlocking,
        fanpage: data.fanpage,
        googleMap: data.googleMap,
        hotline: data.hotline,
        trackingHeader: data.trackingHeader,
        trackingBody: data.trackingBody,
        googleAnalytics: data.googleAnalytics,
        commentNotAllow: data.commentNotAllow,
        fanPageFacebookId: data.fanPageFacebookId
    };
};

const WebsiteInformation = ({ currentWebsite, accessToken, languages, currentLanguage }) => {
    locale(currentLanguage.substring(0, 2));
    const history = useHistory();
    const [websiteInfo, setWebsiteInfo] = useState({});
    const [tabs, setTabs] = useState(tabsMock);

    useEffect(() => {
        const _tabs = languages.map((item, index) => ({
            id: index,
            text: item.languageId,
            icon: 'globe',
            name: item.name
        }));
        setTabs(_tabs);
    }, [languages]);

    useEffect(() => {
        fetch(`${WEBSITE_URL}Settings/settings/${currentWebsite}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setWebsiteInfo(WebsiteInfoVM(json.data, languages));
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    }, [currentWebsite, languages, accessToken, history]);

    const handleSubmit = (e) => {
        updateWebsiteInfo();
        e.preventDefault();
    };

    const updateWebsiteInfo = () => {
        return fetch(`${WEBSITE_URL}Settings/settings/${currentWebsite}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(WebsiteInfoMV(websiteInfo, languages))
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const buttonOptions = {
        text: 'Update',
        icon: 'save',
        type: 'default',
        stylingMode: 'contained',
        useSubmitBehavior: true
    };

    const htmlOptions = {
        toolbar: {
            multiline: false,
            items: [
                { name: 'size', acceptedValues: sizeValues },
                { name: 'font', acceptedValues: fontValues },
                'separator', 'bold', 'italic', 'strike', 'underline', 'link',
                'separator', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify',
                'separator', 'orderedList', 'bulletList'
            ]
        },
        height: 250
    };

    const updateLogo = e => {
        setWebsiteInfo(prevState => ({ ...prevState, logo: e }));
    };

    const updateLogoFooter = e => {
        setWebsiteInfo(prevState => ({ ...prevState, logoFooter: e }));
    };

    const updateLogoMobile = e => {
        setWebsiteInfo(prevState => ({ ...prevState, logoMobile: e }));
    };

    const updateFavicon = e => {
        setWebsiteInfo(prevState => ({ ...prevState, favicon: e }));
    };

    return (
        <React.Fragment>
            <form className="basic-info-form" action="your-action" onSubmit={handleSubmit}>
                <Form
                    formData={websiteInfo}
                    readOnly={false}
                    showColonAfterLabel={true}
                    colCount={2}
                    showValidationSummary={true}
                >
                    <GroupItem caption="Đa ngôn ngữ">
                        <TabbedItem>
                            <TabPanelOptions deferRendering={false} />
                            {tabs.map((item) => {
                                return (
                                    <Tab key={item.id} title={item.name} icon={item.icon}>
                                        <SimpleItem dataField={`brand-${item.text}`} editorType="dxTextBox" >
                                            <Label text={formatMessage('brand')} />
                                        </SimpleItem>

                                        <SimpleItem dataField={`instruction-${item.text}`} editorType="dxHtmlEditor" editorOptions={htmlOptions}>
                                            <Label text={formatMessage('instruction')} />
                                        </SimpleItem>
                                    </Tab>
                                );
                            })}
                        </TabbedItem>
                    </GroupItem>

                    <GroupItem caption="Ảnh" colCount={2}>
                        <SimpleItem>
                            <Label text="Logo" />
                            <FileSelectPopup value={websiteInfo.logo} onValueChanged={updateLogo} />
                        </SimpleItem>

                        <SimpleItem>
                            <Label text="logoFooter" />
                            <FileSelectPopup value={websiteInfo.logoFooter} onValueChanged={updateLogoFooter} />
                        </SimpleItem>

                        <SimpleItem>
                            <Label text="logoMobile" />
                            <FileSelectPopup value={websiteInfo.logoMobile} onValueChanged={updateLogoMobile} />
                        </SimpleItem>

                        <SimpleItem>
                            <Label text="favicon" />
                            <FileSelectPopup value={websiteInfo.favicon} onValueChanged={updateFavicon} />
                        </SimpleItem>
                    </GroupItem>

                    <GroupItem caption="Meta">
                        <TabbedItem>
                            <TabPanelOptions deferRendering={false} />
                            {tabs.map((item) => {
                                return (
                                    <Tab key={item.id} title={item.name} icon={item.icon}>
                                        <SimpleItem dataField={`metaTitle-${item.text}`} editorType="dxTextBox" >
                                            <Label text={formatMessage('metaTitle')} />
                                        </SimpleItem>

                                        <SimpleItem dataField={`metaKeyword-${item.text}`} editorType="dxTextBox">
                                            <Label text={formatMessage('metaKeyword')} />
                                        </SimpleItem>

                                        <SimpleItem dataField={`metaDescription-${item.text}`} editorType="dxTextArea">
                                            <Label text={formatMessage('metaDescription')} />
                                        </SimpleItem>
                                    </Tab>
                                );
                            })}
                        </TabbedItem>
                    </GroupItem>

                    <GroupItem caption="Social Network" colCount={2}>
                        <SimpleItem dataField="fanPageFacebookId" editorType="dxTextBox">
                        </SimpleItem>

                        <SimpleItem dataField="fanpage" editorType="dxTextBox">
                        </SimpleItem>

                        <SimpleItem dataField="googleMap" editorType="dxTextBox">
                        </SimpleItem>

                        <SimpleItem dataField="googleAnalytics" editorType="dxTextBox">
                        </SimpleItem>

                        <SimpleItem dataField="commentNotAllow" editorType="dxTextBox">
                        </SimpleItem>

                        <SimpleItem dataField="zaloId" editorType="dxTextBox">
                        </SimpleItem>

                        <SimpleItem dataField="ipBlocking" editorType="dxTextBox">
                        </SimpleItem>

                        <SimpleItem dataField="hotline" editorType="dxTextBox">
                        </SimpleItem>

                        <SimpleItem dataField="trackingHeader" editorType="dxTextBox">
                        </SimpleItem>

                        <SimpleItem dataField="trackingBody" editorType="dxTextBox">
                        </SimpleItem>
                    </GroupItem>

                    <ButtonItem horizontalAlignment="center" colSpan={2}
                        buttonOptions={buttonOptions}
                    />
                </Form>
            </form>
        </React.Fragment>
    );
};

WebsiteInformation.propTypes = {
    accessToken: PropTypes.string,
    currentWebsite: PropTypes.string,
    languages: PropTypes.array,
    currentLanguage: PropTypes.string
};

export default WebsiteInformation;
