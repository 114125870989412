import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Button,
  Paging,
  Pager
} from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import React from "react";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import CustomStore from "devextreme/data/custom_store";
import { useHistory } from "react-router-dom";
import dictionary from "../../data/dictionary";
import notify from "devextreme/ui/notify";
import { FILE_URL, WEBSITE_URL } from "../../utils/constants";
import { HardContentVM } from "../../model/HardContent";

loadMessages(dictionary);
const pageSizes = [10, 25, 50, 100];

const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';
const searchParams = { page: 1, pageSize: 10000 };

const HardContentsList = ({
  currentLanguage,
  currentWebsite,
  accessToken,
  websiteLanguages,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const URL = `${WEBSITE_URL}HardContents/${currentWebsite}`;

  const loadRequest = (url, loadOptions) => {
    const _page = (isNotEmpty(loadOptions.skip) && isNotEmpty(loadOptions.take)) ? (loadOptions.skip / loadOptions.take + 1) : searchParams.page;
    const _pageSize = isNotEmpty(loadOptions.take) ? loadOptions.take : searchParams.pageSize;

    return fetch(`${url}?page=${_page}&pageSize=${_pageSize}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok)
            return json.data.map((data) =>
              HardContentVM(data, websiteLanguages)
            );
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const deleteRequest = (url) => {
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        notify(
          {
            message: json.message,
            position: {
              my: "center top",
              at: "center top",
            },
          },
          "success",
          3000
        );
      })
    );
  };

  const hardContentsData = new CustomStore({
    key: "id",
    load: (loadOptions) => loadRequest(`${URL}`, loadOptions),
    remove: (key) => deleteRequest(`${URL}/${key}`),
  });

  const imgCellRender = (data) => {
    return (
      <img
        alt="GHM Image"
        style={{ objectFit: "cover", height: 20 }}
        src={`${FILE_URL}${data.value}`}
      />
    );
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            // Implement custom save logic here
            history.push("/hardContent/add");
          },
        };
      }
    });

    toolbarItems.unshift({
      location: "center",
      text: "Danh sách HardContents",
    });
  };

  const gotoDetailPage = (hardContentId) => {
    history.push(`/hardContent/${hardContentId}`);
  };

  const onRowDblClick = (e) => {
    history.push(`/hardContent/${e.key}`);
  };

  return (
    <React.Fragment>
      <DataGrid
        id="id"
        showBorders={true}
        dataSource={hardContentsData}
        repaintChangesOnly={true}
        selection={{ mode: "single" }}
        onRowDblClick={onRowDblClick}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Editing
          refreshMode={"reshape"}
          mode="form"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
        />

        <Scrolling rowRenderingMode="virtual" />

        {websiteLanguages.map((item, index) => (
          <Column
            key={index}
            dataField={`name-${item.languageId}`}
            dataType="string"
            caption={formatMessage("name")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}

        <Column
          dataField="code"
          caption={formatMessage("code")}
          dataType="string"
        />

        {websiteLanguages.map((item, index) => (
          <Column
            key={index}
            dataField={`description-${item.languageId}`}
            dataType="string"
            caption={formatMessage("description")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        <Column
          dataField="image"
          caption={formatMessage("image")}
          allowSorting={false}
          cellRender={imgCellRender}
        />
        {websiteLanguages.map((item, index) => (
          <Column
            key={index}
            dataField={`alt-${item.languageId}`}
            dataType="string"
            caption={formatMessage("alt")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        <Column
          dataField="order"
          caption={formatMessage("order")}
          dataType="number"
        />
        <Column
          dataField="isActive"
          caption={formatMessage("isActive")}
          dataType="boolean"
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => gotoDetailPage(e.row.key)}
          />
        </Column>
        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={false} />
        <Paging defaultPageSize={10} />
      </DataGrid>
    </React.Fragment>
  );
};

HardContentsList.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentWebsite: PropTypes.string,
  currentLanguage: PropTypes.string,
  websiteLanguages: PropTypes.array,
};

export default HardContentsList;
