import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TreeList, Column, Paging, Selection, Editing, Pager, Button } from 'devextreme-react/tree-list';
import { connect } from 'react-redux';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import dictionary from '../../data/dictionary';
import { useHistory } from 'react-router-dom';
import { BOOKING_STATUS, WEBSITE_URL } from '../../utils/constants'

loadMessages(dictionary);

const parseJSON = (str, languageId) => {
    let _strObj;
    try {
        _strObj = JSON.parse(str);
    } catch (e) {
        return '';
    }
    return _strObj ? _strObj[languageId] || '' : '';
};

const CategoryVM = (data, languages, currentLanguage) => {
    const returnData = Object.assign({}, data);

    languages.forEach(element => {
        returnData[`servicePackageName-${element.languageId}`] = parseJSON(data.servicePackageName, element.languageId);
        returnData[`customerAddress-${element.languageId}`] = parseJSON(data.customerAddress, element.languageId);
    });

    returnData['approvedStatusName'] = parseJSON(BOOKING_STATUS.find(x => x.id === data.approvedStatus).name, currentLanguage);

    return returnData;
};

const pageSizes = [10, 20, 50, 100];
const now = new Date();

const WebsiteBookingContainer = ({ accessToken, websiteLanguages, currentLanguage, currentWebsite, currentBranch }) => {
    locale(currentLanguage.substring(0, 2));

    const URL = `${WEBSITE_URL}Bookings/${currentWebsite}`;
    const [keyword, setKeyword] = useState('');
    const history = useHistory();
    const [startDate, setStartDate] = useState(now);
    const [endDate, setEndDate] = useState(new Date(now.getFullYear(), now.getMonth() + 1, 1));

    const loadRequest = (url) => {
        return fetch(`${url}?branchId=${currentBranch}&${keyword ? `keyword=${keyword}&` : ''}&page=1&pageSize=1000&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    return { data: json.data.map((data) => CategoryVM(data, websiteLanguages, currentLanguage)) };
                }
            })
        );
    };

    const deleteRequest = (url) => {
        return fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'Accept-Language': currentLanguage
            }
        })
            .then(result => result.json()
                .then(json => {
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                }));
    };

    const startDateBoxOption = {
        value: startDate,
        width: 120,
        onValueChanged: (e) => setStartDate(e.value)
    };
    const endDateBoxOption = {
        value: endDate,
        width: 120,
        onValueChanged: (e) => setEndDate(e.value)
    };

    const onToolbarPreparing = (e) => {
        const toolbarItems = e.toolbarOptions.items;

        toolbarItems.unshift(
            {
                location: 'before',
                widget: 'dxTextBox',
                options: {
                    placeholder: 'Nhập từ khóa tìm kiếm',
                    value: keyword,
                    width: 200,
                    onValueChanged: (e) => setKeyword(e.value)
                }
            },
            {
                location: 'before',
                widget: 'dxDateBox',
                options: startDateBoxOption
            },
            {
                location: 'before',
                widget: 'dxDateBox',
                options: endDateBoxOption
            },
            {
                location: 'center',
                text: 'Danh sách Đặt hàng từ Website'
            });
    };

    const categoriesSource = new CustomStore({
        key: 'id',
        load: (loadOptions) => loadRequest(`${URL}`, loadOptions),
        remove: (key) => deleteRequest(`${URL}/${key}`)
    });

    const gotoDetailPage = (categoryId) => {
        history.push(`/booking/edit/${categoryId}`);
    };

    return (
        <React.Fragment>
            <TreeList
                dataSource={categoriesSource}
                autoExpandAll={true}
                showRowLines={true}
                showBorders={true}
                keyExpr="id"
                parentIdExpr="parentId"
                onToolbarPreparing={onToolbarPreparing}
            >
                <Editing
                    allowDeleting={true}
                    useIcons={true} />
                <Selection mode="single" />

                <Column dataField={'customerName'} dataType="string" caption={formatMessage('customerName')} />
                <Column dataField={'customerEmail'} dataType="string" caption={formatMessage('customerEmail')} />
                <Column dataField={'customerPhone'} dataType="string" caption={formatMessage('customerPhone')} />
                <Column dataField={'appointmentDate'} dataType="date" caption={formatMessage('appointmentDate')} />
                <Column dataField={'approvedStatusName'} dataType="date" caption={formatMessage('approvedStatus')} />
                {websiteLanguages.map((item, index) => (
                    <Column key={index} dataField={`servicePackageName-${item.languageId}`}
                        dataType="string" caption={formatMessage('servicePackageName')}
                        visible={item.languageId === currentLanguage} />
                ))}
                {websiteLanguages.map((item, index) => (
                    <Column key={index} dataField={`customerAddress-${item.languageId}`}
                        dataType="string" caption={formatMessage('customerAddress')}
                        visible={item.languageId === currentLanguage} />
                ))}

                <Column type="buttons" caption={formatMessage('actionCol')}>
                    <Button name="delete" />
                    <Button hint="Info" icon="info" onClick={(e) => gotoDetailPage(e.row.key)} />
                </Column>

                <Pager visible={true} displayMode={'full'}
                    allowedPageSizes={pageSizes} showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} />
                <Paging defaultPageSize={20} />
            </TreeList>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.data.token,
        currentLanguage: state.setting.currentLanguage,
        languages: state.setting.languages,
        currentWebsite: state.website.data.currentWebsite,
        websiteLanguages: state.website.data.languages
    };
};

WebsiteBookingContainer.propTypes = {
    accessToken: PropTypes.string,
    websiteLanguages: PropTypes.array,
    currentLanguage: PropTypes.string,
    currentWebsite: PropTypes.string,
    currentBranch: PropTypes.string
};

export default connect(mapStateToProps, null)(WebsiteBookingContainer);
