import React, { useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation } from '../data';
import { useHistory } from 'react-router-dom';

const NavigationList = () => {
    const history = useHistory();

    function normalizePath() {
        return navigation.map((item) => {
            if (item.path && !(/^\//.test(item.path))) {
                item.path = `/${item.path}`;
            }
            return { ...item };
        });
    }

    const items = useMemo(
        normalizePath,
        []
    );

    const selectedItemChanged = ({ itemData: { path }, event, node }) => {
        if (!path || node.selected) {
            event.preventDefault();
            return;
        }

        history.push(path);
    };

    return (
        <div className={'menu-container'} style={{ width: '200px' }}>
            <TreeView
                // ref={treeViewRef}
                items={items}
                keyExpr={'path'}
                selectionMode={'single'}
                focusStateEnabled={false}
                expandEvent={'click'}
                onItemClick={selectedItemChanged}
                // onContentReady={onMenuReady}
                elementAttr={{ class: 'panel-list dx-theme-accent-as-background-color' }}
                width={'100%'}
            />
        </div>
    );
};

export default NavigationList;
