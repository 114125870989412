import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ALPHABET, WEBSITE_URL } from "../utils/constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Form, {
  ButtonItem,
  SimpleItem,
  GroupItem,
  Label,
  RequiredRule,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from "devextreme-react/form";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import FileSelectPopup from "../components/file/FileSelectPopup";
import useWindowDimensions from "../hook/useWindowDimensions";
import Toolbar, { Item } from "devextreme-react/toolbar";
import notify from "devextreme/ui/notify";
import { SickMV, SickVM } from "../model/Sick";
import SickNewsContainer from "./SickNewsContainer";
loadMessages(dictionary);

const tabsMock = [
  {
    id: 0,
    text: "vi-VN",
    icon: "globe",
    content: "Vietnamese",
  },
];

function SickDetailContainer({
  currentWebsite,
  accessToken,
  websiteLanguages,
  currentLanguage,
  isPopup,
  hidePopup,
}) {
  const [tabs, setTabs] = useState(tabsMock);
  const [sick, setSick] = useState({});
  const [sickIdUpdate, setSickIdUpdate] = useState(null);
  const params = useParams();
  const history = useHistory();
  locale(currentLanguage.substring(0, 2));
  const { height } = useWindowDimensions();
  useEffect(() => {
    const _tabs = websiteLanguages.map((item, index) => ({
      id: index,
      languageId: item.languageId,
      text: item.name,
      icon: "globe",
      content: "User tab content",
    }));
    setTabs(_tabs);
  }, [websiteLanguages]);

  useEffect(() => {
    if (!currentWebsite || !params.id) {
      return;
    }
    fetch(`${WEBSITE_URL}Sicks/${currentWebsite}/${params.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setSick(SickVM(json.data, websiteLanguages));
            setSickIdUpdate(json.data?.isActive ? json.data.id : null)
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [params.id]);

  const updateImage = (e) => {
    setSick((prevState) => ({ ...prevState, image: e }));
  };

  const updateSick = () => {
    fetch(`${WEBSITE_URL}Sicks/${currentWebsite}/${params.id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Accept-Language": currentLanguage,
      },
      body: JSON.stringify(SickMV(sick, websiteLanguages)),
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
        }
        throw json.Message;
      })
    );
  };

  const insertSick = () => {
    fetch(`${WEBSITE_URL}Sicks/${currentWebsite}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Accept-Language": currentLanguage,
      },
      body: JSON.stringify(SickMV(sick, websiteLanguages)),
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          if (isPopup) hidePopup(json.data);
          else history.goBack();
        }
        throw json.Message;
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (params.id) {
      updateSick();
    } else {
      insertSick();
    }
  };

  const saveButtonOptions = {
    text: `${formatMessage("save")}`,
    icon: "save",
    type: "default",
    stylingMode: "contained",
    useSubmitBehavior: true,
  };

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.goBack();
    },
  };
  const onChangeSickNew = (data) => {
    const value = [];
    data.forEach((element) => {
      value.push(element.id);
    });
    setSick((pre) => ({ ...pre, sickNews: value, listNews: data }));
  };
  return (
    <div
      className="form-container"
      style={{ overflow: "auto", height: height - 56, paddingBottom: 20 }}
    >
      <Toolbar>
        {!isPopup && (
          <Item
            location="before"
            widget="dxButton"
            options={backButtonOptions}
          />
        )}
        <Item location="center" text={"Chi tiết Danh mục Bệnh"} />
      </Toolbar>

      <form action="your-action" onSubmit={handleSubmit}>
        <Form
          formData={sick}
          readOnly={false}
          showColonAfterLabel={true}
          colCount={2}
          showValidationSummary={true}
          onSubmit={handleSubmit}
          // validationGroup="customerData"
        >
          <GroupItem caption="Đa ngôn ngữ" colSpan={1}>
            <TabbedItem>
              <TabPanelOptions deferRendering={false} />
              {tabs.map((item) => {
                return (
                  <Tab key={item.id} title={item.text} icon={item.icon}>
                    <SimpleItem
                      dataField={`sickTranslations-${item.languageId}-alphabet`}
                      editorType="dxSelectBox"
                      editorOptions={{
                        items: ALPHABET[item.languageId],
                        searchEnabled: true,
                      }}
                    >
                      <Label text={formatMessage("alphabet")} />
                      <RequiredRule
                        message={`${formatMessage("title")} ${formatMessage(
                          item.languageId
                        )} ${formatMessage("errMesRequire")}`}
                      />
                    </SimpleItem>

                    <SimpleItem
                      dataField={`sickTranslations-${item.languageId}-name`}
                      editorType="dxTextBox"
                    >
                      <Label text={formatMessage("name")} />
                    </SimpleItem>

                    <SimpleItem
                      dataField={`sickTranslations-${item.languageId}-description`}
                      editorType="dxTextArea"
                    >
                      <Label text={formatMessage("description")} />
                    </SimpleItem>

                    <SimpleItem
                      dataField={`sickTranslations-${item.languageId}-content`}
                      editorType="dxTextArea"
                    >
                      <Label text={formatMessage("content")} />
                    </SimpleItem>

                    <SimpleItem
                      dataField={`sickTranslations-${item.languageId}-seoLink`}
                      editorType="dxTextArea"
                    >
                      <Label text={formatMessage("seoLink")} />
                      <RequiredRule
                        message={`${formatMessage("title")} ${formatMessage(
                          item.languageId
                        )} ${formatMessage("errMesRequire")}`}
                      />
                    </SimpleItem>

                    <SimpleItem
                      dataField={"isPrioritize"}
                      editorType="dxCheckBox"
                    >
                      <Label text={formatMessage("isPrioritize")} />
                    </SimpleItem>
                  </Tab>
                );
              })}
            </TabbedItem>
          </GroupItem>

          <GroupItem caption="Thông tin chung">
            <SimpleItem>
              <Label text={formatMessage("image")} />
              <FileSelectPopup
                value={sick.image}
                imgHeight={100}
                onValueChanged={updateImage}
              />
            </SimpleItem>

            <SimpleItem dataField={"seoLink"} editorType="dxTextBox">
              <Label text={formatMessage("seoLink")} />
            </SimpleItem>

            <SimpleItem dataField={"url"} editorType="dxTextBox">
              <Label text={formatMessage("url")} />
            </SimpleItem>

            <SimpleItem dataField={"isActive"} editorType="dxCheckBox">
              <Label text={formatMessage("isActive")} />
            </SimpleItem>
          </GroupItem>

          {!isPopup && (
            <GroupItem
              horizontalAlignment="center"
              caption="Tin tức"
              colSpan={2}
            >
              <SickNewsContainer
                updateId={sickIdUpdate}
                dataSource={sick.listNews || []}
                onChangeValue={onChangeSickNew}
              />
            </GroupItem>
          )}

          <GroupItem horizontalAlignment="center" colSpan={2}>
            <ButtonItem
              horizontalAlignment="center"
              buttonOptions={saveButtonOptions}
            />
          </GroupItem>
        </Form>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

SickDetailContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
  isPopup: PropTypes.bool,
  hidePopup: PropTypes.func,
};

export default connect(mapStateToProps, null)(SickDetailContainer);
