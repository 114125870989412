import { connect } from "react-redux";
import DataGrid, {
  Editing,
  Column,
  Button,
  Pager,
  Paging,
  SearchPanel,
  MasterDetail,
} from "devextreme-react/data-grid";
import React from "react";
import PropTypes from "prop-types";
import CustomStore from "devextreme/data/custom_store";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import { useHistory } from "react-router-dom";
import notify from "devextreme/ui/notify";
import BannerImagesMasterView from "../../components/website/BannerItemsMasterView";
import { WEBSITE_URL } from "../../utils/constants";
import { BannerVM } from "../../model/Banner";

loadMessages(dictionary);
const pageSizes = [10, 25, 50, 100];

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
const searchParams = { page: 1, pageSize: 20 };

const BannerContainer = ({
  accessToken,
  languages,
  currentLanguage,
  currentWebsite,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const URL = `${WEBSITE_URL}Banners/${currentWebsite}`;

  const loadRequest = (url, loadOptions) => {
    const _page =
      isNotEmpty(loadOptions.skip) && isNotEmpty(loadOptions.take)
        ? loadOptions.skip / loadOptions.take + 1
        : searchParams.page;
    const _pageSize = isNotEmpty(loadOptions.take)
      ? loadOptions.take
      : searchParams.pageSize;

    return fetch(`${url}?page=${_page}&pageSize=${_pageSize}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            return {
              data: json.data.map((data) =>
                BannerVM(data, languages, currentLanguage)
              ),
              totalCount: json.totalRows,
            };
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const deleteRequest = (url) => {
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          notify(
            {
              message: JSON.parse(text).message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
        });
      } else {
        return result.json().then((json) => {
          throw json.Message;
        });
      }
    });
  };

  const bannerData = new CustomStore({
    key: "id",
    load: (loadOptions) => loadRequest(`${URL}`, loadOptions),
    remove: (key) => deleteRequest(`${URL}/${key}`),
  });

  const gotoDetailPage = (bannerId) => {
    history.push(`/banner/${bannerId}`);
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            // Implement custom save logic here
            history.push("/banner/add");
          },
        };
      }
    });

    toolbarItems.push({
      location: "center",
      text: "Danh sách Banner",
    });
  };

  const addMenuItems = (e) => {
    if (e.target === "content") {
      // e.items can be undefined
      if (!e.items) e.items = [];

      // Add a custom menu item
      e.items.push({
        text: "Chi tiết Banner",
        icon: "info",
        onItemClick: () => {
          history.push(`/banner/${e.row.key}`);
        },
      });
    }
  };

  return (
    <React.Fragment>
      <DataGrid
        keyExpr="id"
        dataSource={bannerData}
        allowColumnReordering={true}
        remoteOperations={true}
        selection={{ mode: "single" }}
        showBorders={true}
        onContextMenuPreparing={addMenuItems}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Editing
          mode="row"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
        />
        <SearchPanel visible={true} highlightCaseSensitive={true} />

        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`name-${item.languageId}`}
            dataType="string"
            caption={formatMessage("name")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`description-${item.languageId}`}
            dataType="string"
            caption={formatMessage("description")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}

        <Column
          dataField="url"
          dataType="string"
          caption={formatMessage("url")}
        />
        <Column
          dataField="positionName"
          dataType="string"
          caption={formatMessage("position")}
        />
        <Column
          dataField="typeName"
          dataType="string"
          caption={formatMessage("type")}
        />

        <Column
          dataField="order"
          dataType="string"
          caption={formatMessage("order")}
        />
        <Column
          dataField="isActive"
          dataType="boolean"
          caption={formatMessage("isActive")}
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => gotoDetailPage(e.row.key)}
          />
        </Column>

        <MasterDetail
          enabled={true}
          component={(props) => (
            <BannerImagesMasterView
              masterData={props}
              currentWebsite={currentWebsite}
              currentLanguage={currentLanguage}
              accessToken={accessToken}
              languages={languages}
            />
          )}
        />

        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
      </DataGrid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
  };
};

BannerContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
};

export default connect(mapStateToProps, null)(BannerContainer);
