export const LANGUAGE_CHANGE = '@@setting/LANGUAGE_CHANGE';
export const LANGUAGE_INIT = '@@setting/LANGUAGE_INIT';

export const changeLanguage = (lang) => {
    return { type: LANGUAGE_CHANGE, payload: { lang } };
};

export const initLanguage = () => {
    return { type: LANGUAGE_INIT };
};
