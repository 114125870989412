import React, { useEffect, useState } from "react";
import Form, {
  ButtonItem,
  SimpleItem,
  GroupItem,
  Label,
  TabbedItem,
  TabPanelOptions,
  Tab,
  RequiredRule,
} from "devextreme-react/form";
import "devextreme-react/text-area";
import notify from "devextreme/ui/notify";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../../data/dictionary";
import useJSON from "../../../hook/useJSON";
import FileSelectPopup from "../../file/FileSelectPopup";
import { WEBSITE_URL } from "../../../utils/constants";

loadMessages(dictionary);

const tabsMock = [
  {
    id: 0,
    text: "vi-VN",
    icon: "globe",
    content: "Vietnamese",
  },
];

const ServiceTitleItemVM = (data, languages) => {
  const returnData = useJSON(data, languages);

  return returnData;
};

const ServiceTitleItemMV = (data, languages) => {
  const nameObj = {};
  const descriptionObj = {};
  languages.forEach((language) => {
    nameObj[[language.languageId]] = data[`name-${language.languageId}`];
    descriptionObj[[language.languageId]] =
      data[`description-${language.languageId}`];
  });

  return JSON.stringify({
    name: JSON.stringify(nameObj),
    description: JSON.stringify(descriptionObj),
    image: data.image,
    url: data.url,
    alt: data.alt,
    order: data.order,
    isActive: data.isActive,
    concurrencyStamp: data.concurrencyStamp,
  });
};

const WebsiteServiceTitleItemDetail = ({
  accessToken,
  currentLanguage,
  currentWebsite,
  websiteLanguages,
  serviceId,
  id,
  titleId,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const [serviceTitleItem, setServiceTitleItem] = useState({});
  const [tabs, setTabs] = useState(tabsMock);

  useEffect(() => {
    const _tabs = websiteLanguages.map((item, index) => ({
      id: index,
      languageId: item.languageId,
      text: item.name,
      icon: "globe",
      content: "User tab content",
    }));
    setTabs(_tabs);
  }, [websiteLanguages]);

  useEffect(() => {
    if (!currentWebsite || !id) {
      return;
    }
    fetch(
      `${WEBSITE_URL}Services/item/${currentWebsite}/${serviceId}/${titleId}/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setServiceTitleItem(
              ServiceTitleItemVM(json.data, websiteLanguages)
            );
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [
    currentWebsite,
    id,
    accessToken,
    websiteLanguages,
    titleId,
    serviceId,
    history,
  ]);

  const updateService = () => {
    return fetch(
      `${WEBSITE_URL}Services/item/${currentWebsite}/${serviceId}/${titleId}/${id}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: ServiceTitleItemMV(serviceTitleItem, websiteLanguages),
      }
    ).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            // history.push('/service-website');
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const addService = () => {
    return fetch(
      `${WEBSITE_URL}Services/item/${currentWebsite}/${serviceId}/${titleId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: ServiceTitleItemMV(serviceTitleItem, websiteLanguages),
      }
    ).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            history.push("/service-website");
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      updateService();
    } else {
      addService();
    }
  };

  const saveButtonOptions = {
    text: formatMessage("save"),
    icon: "save",
    type: "default",
    stylingMode: "contained",
    useSubmitBehavior: true,
  };

  const updateImage = (e) => {
    setServiceTitleItem((prevState) => ({ ...prevState, image: e }));
  };

  return (
    <React.Fragment>
      <Toolbar>
        <Item
          location="center"
          text={`${formatMessage("info")} ${formatMessage(
            "of"
          )} ${formatMessage("item")}`}
        />
      </Toolbar>

      <form action="your-action-item" onSubmit={handleSubmit}>
        <Form
          formData={serviceTitleItem}
          readOnly={false}
          showColonAfterLabel={true}
          showValidationSummary={true}
          validationGroup="customerDataItem"
        >
          <GroupItem caption="Đa ngôn ngữ">
            <TabbedItem>
              <TabPanelOptions deferRendering={false} />
              {tabs.map((item) => {
                return (
                  <Tab key={item.id} title={item.text} icon={item.icon}>
                    <SimpleItem
                      dataField={`name-${item.languageId}`}
                      editorType="dxTextBox"
                    >
                      <Label text={formatMessage("name")} />
                      <RequiredRule
                        message={`${formatMessage("name")} ${formatMessage(
                          item.languageId
                        )} ${formatMessage("errMesRequire")}`}
                      />
                    </SimpleItem>
                    <SimpleItem
                      dataField={`description-${item.languageId}`}
                      editorType="dxTextArea"
                    >
                      <Label text={formatMessage("description")} />
                    </SimpleItem>
                  </Tab>
                );
              })}
            </TabbedItem>
          </GroupItem>

          <GroupItem caption="Nội dung khác" colCount={2}>
            <SimpleItem>
              <Label text={formatMessage("image")} />
              <FileSelectPopup
                value={serviceTitleItem.image}
                imgHeight={100}
                onValueChanged={updateImage}
              />
              <RequiredRule />
            </SimpleItem>
            <GroupItem>
              <SimpleItem dataField={"alt"} editorType="dxTextBox" colSpan={2}>
                <Label text={formatMessage("alt")} />
              </SimpleItem>
              <SimpleItem dataField="url" editorType="dxTextBox">
                <Label text={formatMessage("url")} />
              </SimpleItem>
              <SimpleItem dataField="order" editorType="dxNumberBox">
                <Label text={formatMessage("order")} />
              </SimpleItem>
              <SimpleItem dataField="isActive" editorType="dxCheckBox">
                <Label text={formatMessage("isActive")} />
              </SimpleItem>
            </GroupItem>
          </GroupItem>
          <GroupItem horizontalAlignment="center">
            <ButtonItem
              horizontalAlignment="center"
              validationGroup="customerDataItem"
              buttonOptions={saveButtonOptions}
            />
          </GroupItem>
        </Form>
      </form>
    </React.Fragment>
  );
};

WebsiteServiceTitleItemDetail.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
  serviceId: PropTypes.string,
  titleId: PropTypes.string,
  id: PropTypes.string,
};

export default WebsiteServiceTitleItemDetail;
