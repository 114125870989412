export const navigation = [
  {
    text: "Dashboard",
    path: "/",
    icon: "home",
  },
  {
    text: "Danh sách Websites",
    path: "/website",
    icon: "bulletlist",
  },
  {
    text: "Quản lý cấu hình",
    icon: "preferences",
    items: [
      {
        text: "Thông tin Website",
        icon: "info",
        path: "/info-website",
      },
      {
        text: "Đối tác",
        icon: "tags",
        path: "/partner",
      },
    ],
  },
  {
    text: "Câu hỏi thường gặp",
    icon: "image",
    items: [
      {
        text: "FAQ Group",
        icon: "background",
        path: "/faq-group",
      },
      {
        text: "FAQ",
        icon: "bookmark",
        path: "/faq",
      },
    ],
  },
  {
    text: "Quản lý nội dung",
    icon: "filter",
    items: [
      {
        text: "Tin tức",
        icon: "insertcolumnright",
        items: [
          {
            text: "DS Tin tức",
            path: "/news",
            icon: "insertrowabove",
          },
          {
            text: "Chuyên mục",
            path: "/news/category",
            icon: "insertrowbelow",
          },
        ],
      },
      {
        text: "Menu",
        icon: "image",
        path: "/menu",
      },
      {
        text: "Banner",
        icon: "video",
        path: "/banner",
      },
      {
        text: "Album",
        icon: "photo",
        path: "/album",
      },
      {
        text: "Chuyên khoa",
        icon: "pinright",
        path: "/specialization",
      },
      {
        text: "Phản hồi",
        icon: "chart",
        path: "/feedback",
      },
      {
        text: "Phản hồi KH",
        icon: "toolbox",
        path: "/customerFeedback",
      },
      {
        text: "Hỏi đáp chuyên gia",
        icon: "toolbox",
        path: "/expert-question-and-answer",
      },
      {
        text: "Dịch vụ",
        icon: "insertcolumnright",
        items: [
          {
            text: "DS Dich vụ",
            path: "/service-website",
            icon: "pinright",
          },
          {
            text: "Nhóm dịch vụ",
            path: "/group-service",
            icon: "product",
          },
        ],
      },
    ],
  },
  {
    text: "Tuyển dụng",
    icon: "event",
    items: [
      {
        text: "Vị trí tuyển dụng",
        icon: "user",
        path: "/job",
      },
      {
        text: "Đăng ký tuyển dụng",
        icon: "product",
        path: "/recruitment",
      },
      {
        text: "Ngành nghề",
        icon: "user",
        path: "/career",
      },
      {
        text: "Cấp bậc",
        icon: "user",
        path: "/level",
      },
      {
        text: "Hình thức",
        icon: "user",
        path: "/job-type",
      },
    ],
  },
  {
    text: "Đặt hàng",
    path: "/booking",
    icon: "checklist",
  },
  {
    text: "Quản lý File",
    path: "/file",
    icon: "doc",
  },
  {
    text: "Video",
    path: "/video",
    icon: "video",
  },
  {
    text: "Màn hình chờ",
    path: "/advertisement",
    icon: "product",
  },
  {
    text: "Danh mục Bệnh",
    path: "/sick",
    icon: "like",
  },
  {
    text: "Đội nhóm",
    icon: "group",
    items: [
      {
        text: "CTV",
        icon: "user",
        path: "/team",
      },
      {
        text: "Khách hàng",
        icon: "user",
        path: "/customer",
      },
    ],
  }
];

export const customer = {
  Email: "",
  Password: "",
  Name: "Peter",
  Date: null,
  Country: "",
  City: "",
  Address: "",
  Phone: "",
  Accepted: false,
};

export const pageViews = [
  {
    month: "January",
    views: 36,
  },
  {
    month: "February",
    views: 21,
  },
  {
    month: "March",
    views: 32,
  },
  {
    month: "April",
    views: 44,
  },
  {
    month: "May",
    views: 61,
  },
  {
    month: "June",
    views: 11,
  },
  {
    month: "July",
    views: 49,
  },
  {
    month: "August",
    views: 32,
  },
  {
    month: "September",
    views: 44,
  },
  {
    month: "October",
    views: 61,
  },
  {
    month: "November",
    views: 11,
  },
  {
    month: "December",
    views: 49,
  },
];

export const populationByRegions = [
  {
    region: "Asia",
    val: 4119626293,
  },
  {
    region: "Africa",
    val: 1012956064,
  },
  {
    region: "Northern America",
    val: 344124520,
  },
  {
    region: "Latin America and the Caribbean",
    val: 590946440,
  },
  {
    region: "Europe",
    val: 727082222,
  },
  {
    region: "Oceania",
    val: 35104756,
  },
];
