import { combineReducers } from 'redux';
import authReducer from './authReducer';
import settingReducer from './settingReducer';
import websiteReducer from './websiteReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    setting: settingReducer,
    website: websiteReducer
});

export default rootReducer;
