import {
    LANGUAGE_CHANGE, LANGUAGE_INIT
} from '../actions/settingActions';
import { mock } from '../data/mock';

const INITIAL_STATE = {
    currentLanguage: 'vi',
    languages: []
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LANGUAGE_CHANGE: {
            localStorage.setItem('language', action.payload.lang);

            return {
                ...state,
                currentLang: action.payload.lang
            };
        }

        case LANGUAGE_INIT: {
            const currentLanguage = localStorage.getItem('currentLanguage');
            const languages = localStorage.getItem('languages');

            return {
                ...state,
                currentLanguage: currentLanguage || mock.CURRENT_LANGUAGE,
                languages: languages ? JSON.parse(languages) : mock.LANGUAGES
            };
        }

        default:
            return state;
    }
};

export default reducer;
