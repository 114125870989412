import { createAction } from "redux-api-middleware";
import { WEBSITE_URL } from "../utils/constants";

var cookie = require("cookie-cutter");

export const WEBSITE_CHANGE = "@@website/WEBSITE_CHANGE";
export const WEBSITE_INIT = "@@website/WEBSITE_INIT";

export const changeWebsite = (websiteId) => {
  return { type: WEBSITE_CHANGE, payload: { websiteId } };
};

export const initWebsite = () => {
  const websites = localStorage.getItem("websites");

  if (websites) {
    return { type: WEBSITE_INIT };
  } else {
    const access_token = cookie.get("access_token");
    return getAllWebsite(access_token);
  }
};

export const WEBSITE_GETALL_REQUEST = "@@auth/WEBSITE_GETALL_REQUEST";
export const WEBSITE_GETALL_SUCCESS = "@@auth/WEBSITE_GETALL_SUCCESS";
export const WEBSITE_GETALL_FAILURE = "@@auth/WEBSITE_GETALL_FAILURE";

export const getAllWebsite = (token) =>
  createAction({
    endpoint: `${WEBSITE_URL}Websites/get-all`,
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    types: [
      WEBSITE_GETALL_REQUEST,
      WEBSITE_GETALL_SUCCESS,
      WEBSITE_GETALL_FAILURE,
    ],
  });
