import React, { useState, useEffect } from "react";
import Form, {
  SimpleItem,
  GroupItem,
  TabbedItem,
  ButtonItem,
  Label,
  Tab,
  RequiredRule,
} from "devextreme-react/form";
import FileSelectPopup from "../../components/file/FileSelectPopup";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import notify from "devextreme/ui/notify";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { useHistory, useParams } from "react-router-dom";
import { WEBSITE_URL } from "../../utils/constants";
import { SocialNetworkMV, SocialNetworkVM } from "../../model/SocialNetwork";

loadMessages(dictionary);
const notesEditorOptions = { height: 150 };

const tabsMock = [
  {
    id: 0,
    text: "vi-VN",
    icon: "globe",
    content: "Vietnamese",
  },
];

const SocialNetworkDetailContainer = ({
  accessToken,
  websiteLanguages,
  currentLanguage,
  currentWebsite,
}) => {
  locale(currentLanguage.substring(0, 2));
  const [tabs, setTabs] = useState(tabsMock);
  const [socialNetwork, setSocialNetwork] = useState({});
  const history = useHistory();
  const params = useParams();
  const URL = `${WEBSITE_URL}SocialNetworks/${currentWebsite}`;

  useEffect(() => {
    if (!params.socialNetworkId) return;

    fetch(
      `${WEBSITE_URL}SocialNetworks/${currentWebsite}/${params.socialNetworkId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setSocialNetwork(SocialNetworkVM(json.data, websiteLanguages));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [
    websiteLanguages,
    history,
    accessToken,
    params.socialNetworkId,
    currentWebsite,
  ]);

  const updateImage = (e) => {
    setSocialNetwork((prevState) => ({ ...prevState, image: e }));
  };

  useEffect(() => {
    const _tabs = websiteLanguages.map((item, index) => ({
      id: index,
      languageId: item.languageId,
      text: item.name,
      icon: "globe",
      content: "User tab content",
    }));
    setTabs(_tabs);
  }, [websiteLanguages]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateSocialNetwork();
  };

  const updateSocialNetwork = () => {
    const _method = socialNetwork.id ? "PUT" : "POST";
    const _url = `${URL}${socialNetwork.id ? `/${socialNetwork.id}` : ""}`;

    return fetch(_url, {
      method: _method,
      body: SocialNetworkMV(socialNetwork, websiteLanguages),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          notify(
            {
              message: JSON.parse(text).message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          history.push("/info-website");
          history.push({
            pathname: "/info-website",
            state: { activeTab: 3 },
          });
        });
      } else {
        return result.json().then((json) => {
          throw json.Message;
        });
      }
    });
  };

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.goBack();
    },
  };

  const saveButtonOptions = {
    text: "Update",
    type: "success",
    useSubmitBehavior: true,
  };

  return (
    <React.Fragment>
      <Toolbar>
        <Item location="before" widget="dxButton" options={backButtonOptions} />
        <Item location="center" text={"Chi tiết Social Network"} />
      </Toolbar>

      <form action="your-action" onSubmit={handleSubmit}>
        <Form colCount={2} formData={socialNetwork}>
          <GroupItem caption="Thông tin cơ bản">
            <TabbedItem itemType="tabbed" colCount={2} colSpan={2}>
              {tabs.map((item) => {
                return (
                  <Tab key={item.id} title={item.text} icon={item.icon}>
                    <SimpleItem
                      dataField={`name-${item.languageId}`}
                      editorType="dxTextBox"
                    >
                      <Label text={formatMessage("name")} />
                      <RequiredRule />
                    </SimpleItem>
                    <SimpleItem
                      dataField={`description-${item.languageId}`}
                      editorType="dxTextArea"
                      editorOptions={notesEditorOptions}
                    >
                      <Label text={formatMessage("description")} />
                    </SimpleItem>
                  </Tab>
                );
              })}
            </TabbedItem>
          </GroupItem>

          <GroupItem itemType="group" caption="Thông tin khác" colCount={2}>
            <GroupItem>
              <SimpleItem>
                <Label text={formatMessage("image")} />
                <FileSelectPopup
                  value={socialNetwork.image}
                  imgHeight={150}
                  onValueChanged={updateImage}
                />
              </SimpleItem>
            </GroupItem>

            <GroupItem>
              <SimpleItem dataField={"url"} editorType="dxTextBox">
                <Label text={formatMessage("url")} />
              </SimpleItem>
              <SimpleItem dataField={"icon"} editorType="dxTextBox">
                <Label text={formatMessage("icon")} />
              </SimpleItem>

              <SimpleItem dataField={"order"} editorType="dxNumberBox">
                <Label text={formatMessage("order")} />
              </SimpleItem>
              <SimpleItem dataField={"isActive"} editorType="dxCheckBox">
                <Label text={formatMessage("isActive")} />
              </SimpleItem>
            </GroupItem>
          </GroupItem>

          <ButtonItem
            colSpan={2}
            horizontalAlignment="center"
            buttonOptions={saveButtonOptions}
          />
        </Form>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    websiteLanguages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
  };
};

SocialNetworkDetailContainer.propTypes = {
  accessToken: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
  currentLanguage: PropTypes.string,
};

export default connect(mapStateToProps, null)(SocialNetworkDetailContainer);
