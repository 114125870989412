// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB0drUdYcCbyvE6TCzyWEfGoGJ3Is3WbbM",
  authDomain: "neo-medic.firebaseapp.com",
  projectId: "neo-medic",
  storageBucket: "neo-medic.appspot.com",
  messagingSenderId: "661486035780",
  appId: "1:661486035780:web:a08ba3a74e90a2921dad11",
  measurementId: "G-JHSM9HKY2K"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging();
export const vapidKey = "BJg83oyFLlwgqHqovbRZi8yrnAMwda43dZpiPKnnbkUYCfvpa65oRs1NQyYapU3njUdgJmR9oc2hZc2lZEdIMnw";
// const analytics = getAnalytics(app);

export const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const requestPermission = () => {
  console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    }
  }
  )
}