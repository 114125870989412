import Form, {
    SimpleItem, GroupItem, TabbedItem, ButtonItem,
    Label, Tab, RangeRule, RequiredRule
} from 'devextreme-react/form';
import React, { useState, useEffect } from 'react';
import dictionary from '../../data/dictionary';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useHistory, useParams } from 'react-router-dom';
import FileSelectPopup from '../../components/file/FileSelectPopup';
import 'devextreme-react/text-area';
import 'devextreme-react/tag-box';
import notify from 'devextreme/ui/notify';
import useJSON from '../../hook/useJSON';
import { WEBSITE_URL } from '../../utils/constants';

loadMessages(dictionary);

const tabsMock = [{
    id: 0,
    text: 'vi-VN',
    icon: 'globe',
    content: 'Vietnamese'
}];

const notesEditorOptions = { height: 100 };

const FAQGroupVM = (data, languages) => {
    const returnData = useJSON(data, languages);
    return returnData;
};

const FAQGroupMV = (data, languages) => {
    const nameObj = {}; const descriptionObj = {}; const metaTitleObj = {}; const metaDescriptionObj = {}; const metaKeywordObj = {};
    languages.forEach(language => {
        nameObj[[language.languageId]] = data[`name-${language.languageId}`];
        descriptionObj[[language.languageId]] = data[`description-${language.languageId}`];
        metaTitleObj[[language.languageId]] = data[`metaTitle-${language.languageId}`];
        metaDescriptionObj[[language.languageId]] = data[`metaDescription-${language.languageId}`];
        metaKeywordObj[[language.languageId]] = data[`metaKeyword-${language.languageId}`];
    });

    return JSON.stringify({
        name: JSON.stringify(nameObj),
        description: JSON.stringify(descriptionObj),
        seoLink: data.seoLink,
        metaTitle: JSON.stringify(metaTitleObj),
        metaDescription: JSON.stringify(metaDescriptionObj),
        metaKeyword: JSON.stringify(metaDescriptionObj),
        thumbnail: data.thumbnail,
        alt: data.alt,
        order: data.order,
        concurrencyStamp: data.concurrencyStamp,
        isActive: data.isActive,
        isCustomer: data.isCustomer,
        code: data.code
    });
};

const FAQGroupDetailContainer = ({ accessToken, currentLanguage, currentWebsite, websiteLanguages }) => {
    locale(currentLanguage.substring(0, 2));
    const [tabs, setTabs] = useState(tabsMock);
    const [faqGroup, setFAQGroup] = useState({});
    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        if (!params.faqGroupId) return;

        fetch(`${WEBSITE_URL}FAQGroups/${currentWebsite}/${params.faqGroupId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setFAQGroup(FAQGroupVM(json.data, websiteLanguages));
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    }, [params.faqGroupId, history, accessToken, currentWebsite, websiteLanguages]);

    const updateFAQGroup = () => {
        const _method = faqGroup.id ? 'PUT' : 'POST';
        const _url = `${WEBSITE_URL}FAQGroups/${currentWebsite}${faqGroup.id ? `/${faqGroup.id}` : ''}`;

        return fetch(_url, {
            method: _method,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': currentLanguage
            },
            body: FAQGroupMV(faqGroup, websiteLanguages)
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    history.push('/faq-group');
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateFAQGroup();
    };

    useEffect(() => {
        const _tabs = websiteLanguages.map((item, index) => ({
            id: index,
            languageId: item.languageId,
            text: item.name,
            icon: 'globe',
            content: 'User tab content'
        }));
        setTabs(_tabs);
    }, [websiteLanguages]);

    const backButtonOptions = {
        type: 'back',
        onClick: () => {
            history.goBack();
        }
    };

    const updateThumbnail = e => {
        setFAQGroup(prevState => ({ ...prevState, thumbnail: e }));
    };

    const saveButtonOptions = {
        text: 'Update',
        type: 'success',
        useSubmitBehavior: true
    };

    return (
        <React.Fragment>
            <Toolbar>
                <Item location="before"
                    widget="dxButton"
                    options={backButtonOptions} />
                <Item location="center"
                    text={'Chi tiết Nhóm FAQ'} />
            </Toolbar>

            <form action="your-action" onSubmit={handleSubmit}>
                <Form
                    formData={faqGroup}
                    readOnly={false}
                    showColonAfterLabel={true}
                    colCount={3}
                    showValidationSummary={true}
                    validationGroup="customerData"
                >
                    <GroupItem colSpan={2} caption="Thông tin cơ bản">
                        <GroupItem caption="Đa ngôn ngữ">
                            <TabbedItem>
                                {tabs.map((item) => {
                                    return (
                                        <Tab key={item.id} title={item.text} icon={item.icon}>
                                            <SimpleItem dataField={`name-${item.languageId}`} editorType="dxTextBox">
                                                <Label text={formatMessage('name')} />
                                                <RequiredRule message={`${formatMessage('name')} ${formatMessage('errMesRequire')}`} />
                                            </SimpleItem>
                                            <SimpleItem dataField={`description-${item.languageId}`} editorType="dxTextArea" editorOptions={notesEditorOptions}>
                                                <Label text={formatMessage('description')} />
                                            </SimpleItem>
                                        </Tab>
                                    );
                                })}
                            </TabbedItem>
                        </GroupItem>

                        <GroupItem caption="Thông tin khác">
                            <SimpleItem dataField={'seoLink'} editorType="dxTextBox" colSpan={2}>
                                <Label text={formatMessage('seoLink')} />
                            </SimpleItem>
                            <SimpleItem dataField={'code'} editorType="dxTextBox" colSpan={2}>
                                <Label text={formatMessage('code')} />
                            </SimpleItem>
                            <SimpleItem dataField={'order'} editorType="dxNumberBox">
                                <Label text={'Thứ tự'} />
                                <RangeRule min={0} message={'Thứ tự phải đúng định dạng'} />
                            </SimpleItem>
                            <SimpleItem dataField={'isActive'} editorType="dxCheckBox">
                                <Label text={'Kích hoạt'} />
                            </SimpleItem>
                            <SimpleItem dataField={'isCustomer'} editorType="dxCheckBox">
                                <Label text={'Dành cho App KH'} />
                            </SimpleItem>
                        </GroupItem>
                    </GroupItem>

                    <GroupItem caption="SEO">
                        <TabbedItem>
                            {tabs.map((item) => {
                                return (
                                    <Tab key={item.id} title={item.text} icon={item.icon}>
                                        <SimpleItem dataField={`metaTitle-${item.languageId}`} editorType="dxTextBox">
                                            <Label text={formatMessage('metaTitle')} />
                                        </SimpleItem>
                                        <SimpleItem dataField={`metaDescription-${item.languageId}`} editorType="dxTextArea" editorOptions={notesEditorOptions}>
                                            <Label text={formatMessage('metaDescription')} />
                                        </SimpleItem>
                                        <SimpleItem dataField={`metaKeyword-${item.languageId}`} editorType="dxTextBox" >
                                            <Label text={formatMessage('metaKeyword')} />
                                        </SimpleItem>
                                    </Tab>
                                );
                            })}
                        </TabbedItem>

                        <GroupItem caption="Ảnh Feature">
                            <SimpleItem >
                                <Label text={formatMessage('thumbnail')} />
                                <FileSelectPopup value={faqGroup.thumbnail} imgHeight={100}
                                    onValueChanged={updateThumbnail} />
                            </SimpleItem>
                            <SimpleItem dataField={'altImages'} editorType="dxTextArea">
                                <Label text={formatMessage('alt')} />
                            </SimpleItem>
                        </GroupItem>
                    </GroupItem>

                    <ButtonItem colSpan={3} horizontalAlignment="center"
                        buttonOptions={saveButtonOptions}
                    />
                </Form>
            </form>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.data.token,
        currentLanguage: state.setting.currentLanguage,
        languages: state.setting.languages,
        currentWebsite: state.website.data.currentWebsite,
        websiteLanguages: state.website.data.languages
    };
};

FAQGroupDetailContainer.propTypes = {
    accessToken: PropTypes.string,
    languages: PropTypes.array,
    currentLanguage: PropTypes.string,
    websiteId: PropTypes.string,
    websiteLanguages: PropTypes.array,
    currentWebsite: PropTypes.string
};

export default connect(mapStateToProps, null)(FAQGroupDetailContainer);
