import { DataGrid, Column, Editing, Scrolling, Grouping, Button } from 'devextreme-react/data-grid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import CustomStore from 'devextreme/data/custom_store';
import { useHistory } from 'react-router-dom';
import dictionary from '../../data/dictionary';
import { Popup } from 'devextreme-react/popup';
import BranchContactItemDetail from './BranchContactItemDetail';
import { CONTACT_TYPE, WEBSITE_URL } from '../../utils/constants';
import notify from "devextreme/ui/notify";

loadMessages(dictionary);
const parseJSON = (str, languageId) => {
    let _strObj;
    try {
        _strObj = JSON.parse(str);
    } catch (e) {
        return '';
    }
    return _strObj ? _strObj[languageId] || '' : '';
};

const BranchContactItemVM = (data, currentLanguage) => {
    const returnData = Object.assign({}, data);
    returnData['contactTypeName'] = parseJSON(CONTACT_TYPE[data.contactType].name, currentLanguage);

    return returnData;
};

const BranchContactItemsList = ({ currentLanguage, currentWebsite, languages, accessToken, branchContactId }) => {
    locale(currentLanguage.substring(0, 2));
    const history = useHistory();
    const URL = `${WEBSITE_URL}BranchContacts/detail/get-all/${currentWebsite}/${branchContactId}`;
    const [showItemPopup, setShowItemPopup] = useState(false);
    const [branchContactItemId, setBranchContactItemId] = useState(null);

    const loadRequest = () => {
        return fetch(`${URL}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) return json.map((data) => BranchContactItemVM(data, currentLanguage));
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const removeRequest = (url) => {
        return fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                "Accept-Language": currentLanguage,
            },
        }).then((result) => {
            if (result.ok) {
                return result.text().then((text) => {
                    if (text) {
                        const _response = JSON.parse(text);
                        notify(
                            {
                                message: _response.message,
                                position: {
                                    my: "center top",
                                    at: "center top",
                                },
                            },
                            "success",
                            3000
                        );

                        return _response;
                    }
                });
            } else {
                return result.json().then((json) => {
                    throw json.Message;
                });
            }
        });
    };

    const branchContactsData = new CustomStore({
        key: 'id',
        load: () => loadRequest(),
        remove: (key) => removeRequest(`${WEBSITE_URL}BranchContacts/detail/${currentWebsite}/${branchContactId}/${key}`),
    });

    const onToolbarPreparing = (e) => {
        const toolbarItems = e.toolbarOptions.items;
        // Modifies an existing item
        toolbarItems.forEach(function (item) {
            if (item.name === 'addRowButton') {
                item.options = {
                    icon: 'plus',
                    onClick: function () {
                        showBannerItemDetail();
                    }
                };
            }
        });

        toolbarItems.unshift(
            {
                location: 'center',
                text: 'Danh sách BranchContact Items'
            });
    };

    const showBannerItemDetail = (branchContactItemId) => {
        setBranchContactItemId(branchContactItemId);
        setShowItemPopup(true);
    };

    const hideItemDetailPopup = () => {
        setShowItemPopup(false);
        setBranchContactItemId(null);
    };

    return (
        <React.Fragment>
            <DataGrid
                id="grid"
                showBorders={true}
                dataSource={branchContactsData}
                repaintChangesOnly={true}
                selection={{ mode: 'single' }}
                onToolbarPreparing={onToolbarPreparing}
            >
                <Grouping autoExpandAll={true} />
                <Editing
                    useIcons={true}
                    allowAdding={true}
                    allowDeleting={true}
                />

                <Scrolling
                    rowRenderingMode="virtual"
                />

                <Column dataField="contactTypeName" caption={formatMessage('contactType')} dataType="string" />
                <Column dataField="contactValue" caption={formatMessage('contactValue')} dataType="string" />

                <Column type="buttons" caption={formatMessage('actionCol')}>
                    <Button name="delete" />
                    <Button hint="Info" icon="info" onClick={(e) => showBannerItemDetail(e.row.key)} />
                </Column>

            </DataGrid>

            <Popup
                visible={showItemPopup}
                onHiding={() => setShowItemPopup(false)}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={false}
                showTitle={false}
                container=".dx-viewport"
                width={600}
                height={300}
            >
                <BranchContactItemDetail
                    accessToken={accessToken} currentLanguage={currentLanguage}
                    currentWebsite={currentWebsite} websiteLanguages={languages}
                    branchContactId={branchContactId}
                    id={branchContactItemId} hidePopup={hideItemDetailPopup}
                />
            </Popup>
        </React.Fragment>
    );
};

BranchContactItemsList.propTypes = {
    accessToken: PropTypes.string,
    languages: PropTypes.array,
    currentWebsite: PropTypes.string,
    currentLanguage: PropTypes.string,
    branchContactId: PropTypes.string
};

export default BranchContactItemsList;
