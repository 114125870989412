import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Grouping,
  Selection,
} from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import React from "react";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import { useHistory } from "react-router-dom";
import { FILE_URL, WEBSITE_URL } from "../../../utils/constants";
import dictionary from "../../../data/dictionary";
import FileSelectPopup from "../../file/FileSelectPopup";
import CustomStore from "devextreme/data/custom_store";

loadMessages(dictionary);

const parseJSON = (str, languageId) => {
  let _strObj;
  try {
    _strObj = JSON.parse(str);
  } catch (e) {
    return str || "";
  }
  return _strObj ? _strObj[languageId] || "" : "";
};

const VideoVM = (data, languages) => {
  const returnData = Object.assign({}, data);

  languages.forEach((element) => {
    returnData[`title-${element.languageId}`] = parseJSON(
      data.title,
      element.languageId
    );
    returnData[`description-${element.languageId}`] = parseJSON(
      data.description,
      element.languageId
    );
    returnData[`alt-${element.languageId}`] = parseJSON(
      data.alt,
      element.languageId
    );
  });

  return returnData;
};

const VideoMV = (data, languages) => {
  console.log("data", data);
  const multiLangData = {
    title: {},
    description: {},
  };

  languages.forEach((element) => {
    multiLangData["description"] = {
      ...multiLangData["description"],
      [element.languageId]: data[`description-${element.languageId}`],
    };
    multiLangData["title"] = {
      ...multiLangData["title"],
      [element.languageId]: data[`title-${element.languageId}`],
    };
  });

  return {
    title: JSON.stringify(multiLangData.title),
    description: JSON.stringify(multiLangData.description),
    url: data.url,
    thumbnail: data.thumbnail,
    type: data.type,
    videoLinkId: data.videoLinkId,
    order: data.order,
    isActive: data.isActive,
    isHomePage: data.isHomePage,
    isDefault: data.isDefault,
    concurrencyStamp: data.concurrencyStamp,
  };
};

const VideosList = ({
  accessToken,
  currentLanguage,
  websiteId,
  albumId,
  languages,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();

  const imgCellRender = (data) => {
    return (
      <img
        style={{ objectFit: "cover", height: 100 }}
        src={`${FILE_URL}${data.value}`}
      />
    );
  };
  const sendRequest = (url, method, data) => {
    method = method || "GET";
    data = data || {};
    console.log(url, method, data);

    if (method === "GET") {
      return fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      }).then((result) =>
        result
          .json()
          .then((json) => {
            if (result.ok) return json.map((data) => VideoVM(data, languages));
            throw json.Message;
          })
          .catch(() => {
            if (result.status === 401) {
              history.push("/login");
            }
          })
      );
    } else if (method === "PUT") {
      return fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      }).then((result) =>
        result
          .json()
          .then((json) => {
            if (result.ok) {
              return fetch(url, {
                method: method,
                body: JSON.stringify(
                  VideoMV(
                    { ...VideoVM(json.data, languages), ...data.values },
                    languages
                  )
                ),
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
                },
              }).then((result) => {
                if (result.ok) {
                  return result.text().then(
                    (text) =>
                      text && {
                        ...VideoVM(json.data, languages),
                        ...data.values,
                      }
                  );
                } else {
                  return result.json().then((json) => {
                    throw json.Message;
                  });
                }
              });
            }
          })
          .catch(() => {
            if (result.status === 401) {
              history.push("/login");
            }
          })
      );
    } else if (method === "POST") {
      return fetch(url, {
        method: method,
        body: JSON.stringify(VideoMV(data.values, languages)),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }).then((result) => {
        if (result.ok) {
          return result
            .text()
            .then(
              (text) => text && { ...data.values, id: JSON.parse(text).data }
            );
        } else {
          return result.json().then((json) => {
            throw json.Message;
          });
        }
      });
    } else {
      return fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }).then((result) => {
        if (result.ok) {
          return result.text().then((text) => text);
        } else {
          return result.json().then((json) => {
            throw json.Message;
          });
        }
      });
    }
  };

  const videosData = new CustomStore({
    key: "id",
    load: () =>
      sendRequest(`${WEBSITE_URL}Albums/video/get-all/${websiteId}/${albumId}`),
    insert: (values) =>
      sendRequest(
        `${WEBSITE_URL}Albums/video/${websiteId}/${albumId}`,
        "POST",
        {
          values: values,
        }
      ),
    update: (key, values) =>
      sendRequest(
        `${WEBSITE_URL}Albums/video/${websiteId}/${albumId}/${key}`,
        "PUT",
        {
          values: values,
        }
      ),
    remove: (key) =>
      sendRequest(
        `${WEBSITE_URL}Albums/video/${websiteId}/${albumId}/${key}`,
        "DELETE",
        {
          key: key,
        }
      ),
  });

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "center",
      text: "Danh sách Video",
    });
  };

  const imgEditCellRender = (cell) => {
    return (
      <FileSelectPopup
        value={cell.value}
        onValueChanged={(e) => cell.setValue(e)}
      />
    );
  };

  return (
    <React.Fragment>
      <DataGrid
        id="id"
        showBorders={true}
        dataSource={videosData}
        repaintChangesOnly={true}
        selection={{ mode: "single" }}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Selection mode="single" />
        <Grouping autoExpandAll={true} />
        <Editing
          refreshMode={"reshape"}
          mode="form"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
        />

        <Scrolling rowRenderingMode="virtual" />
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`title-${item.languageId}`}
            dataType="string"
            caption={formatMessage("title")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`description-${item.languageId}`}
            dataType="string"
            caption={formatMessage("description")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}

        <Column
          dataField="videoLinkId"
          caption={formatMessage("videoLinkId")}
          dataType="string"
        />

        <Column
          dataField="url"
          caption={formatMessage("url")}
          dataType="string"
        />

        <Column
          dataField="thumbnail"
          caption={formatMessage("image")}
          allowSorting={false}
          cellRender={imgCellRender}
          editCellRender={imgEditCellRender}
        />
        <Column
          dataField="isActive"
          caption={formatMessage("isActive")}
          dataType="boolean"
        />
        <Column
          dataField="order"
          caption={formatMessage("order")}
          dataType="number"
        />
      </DataGrid>
    </React.Fragment>
  );
};

VideosList.propTypes = {
  accessToken: PropTypes.string,
  websiteId: PropTypes.string,
  currentLanguage: PropTypes.string,
  albumId: PropTypes.string,
  languages: PropTypes.array,
};

export default VideosList;
