import PropTypes from "prop-types";
import React, { useRef } from "react";
import {
  FILE_URL,
  WEBSITE_URL,
  ADVERTISEMENT_FILE_TYPE,
} from "../../utils/constants";
import DataGrid, {
  Editing,
  Column,
  Button,
  Pager,
  Paging,
  Grouping,
  Lookup,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import {
  AdvertisementFileVM,
  AdvertisementFileMV,
} from "../../model/Advertisement";
import VideoElement from "../VideoElement";
import VideoSelectPopup from "../file/VideoSelectPopup";
import FileSelectPopup from "../file/FileSelectPopup";
import notify from "devextreme/ui/notify";

loadMessages(dictionary);
const pageSizes = [20, 25, 50, 100];

export default function AdvertisementFileList({
  floor,
  area,
  currentWebsite,
  accessToken,
  currentLanguage,
  languages,
}) {
  locale(currentLanguage.substring(0, 2));
  const dataGridRef = useRef(null);

  const getList = () => {
    if (!floor || !area) {
      return;
    }
    return fetch(
      `${WEBSITE_URL}Advertisements/file/get-all/${currentWebsite}?floor=${floor}&area=${area}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return {
            data: json.map((data) =>
              AdvertisementFileVM(data, languages, currentLanguage)
            ),
          };
        }
        throw json.Message;
      })
    );
  };

  const insertAdvertisementFile = (values) => {
    return fetch(`${WEBSITE_URL}Advertisements/file/${currentWebsite}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Accept-Language": currentLanguage,
      },
      body: JSON.stringify(AdvertisementFileMV({ ...values, area, floor })),
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return json.data;
        }
        throw json.Message;
      })
    );
  };

  const updateAdvertisementFile = (key, values) => {
    return fetch(`${WEBSITE_URL}Advertisements/file/${key}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return fetch(
            `${WEBSITE_URL}Advertisements/file/${currentWebsite}/${key}`,
            {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Accept-Language": currentLanguage,
              },
              body: JSON.stringify(
                AdvertisementFileMV({ ...json.data, ...values })
              ),
            }
          ).then((result2) =>
            result2.json().then((json2) => {
              if (result2.ok) {
                notify(
                  {
                    message: json2.message,
                    position: {
                      my: "center top",
                      at: "center top",
                    },
                  },
                  "success",
                  3000
                );

                return { ...json.data, values };
              }
              throw json2.Message;
            })
          );
        }
        throw json.Message;
      })
    );
  };

  const deleteAdvertisementFile = (key) => {
    return fetch(`${WEBSITE_URL}Advertisements/file/${currentWebsite}/${key}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return {};
        }
        throw json.Message;
      })
    );
  };

  const advertisementFileStore = new CustomStore({
    key: "id",
    load: getList,
    remove: deleteAdvertisementFile,
    update: updateAdvertisementFile,
    insert: insertAdvertisementFile,
  });

  const urlCellRender = (data) => {
    if (data.data.type === 0) {
      return (
        <img
          alt="GHM Image"
          src={`${FILE_URL}/${data.value}`}
          style={{ height: 80 }}
        />
      );
    }

    return (
      <VideoElement
        src={`${FILE_URL}${data.value}`}
        height={data.data.type === 2 ? "50px" : "200px"}
      />
    );
  };

  const urlEditCellRender = (cell) => {
    if (cell.data.type === 0) {
      return (
        <FileSelectPopup
          value={cell.value}
          onValueChanged={(value) => {
            cell.setValue(value);
          }}
        />
      );
    }

    return (
      <VideoSelectPopup
        value={cell.value}
        onValueChanged={(value) => cell.setValue(value)}
        type={cell.data.type}
        maxFileSize={200 * 1024 * 1024}
      />
    );
  };

  const urlTxtCellRender = (data) => {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={`${FILE_URL}${data.value}`}
      >{`${FILE_URL}${data.value}`}</a>
    );
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: "center",
      text: "Danh sách Files",
    });
  };

  const setTypeCellValue = (newData, value) => {
    newData.type = value;
  };

  return (
    <>
      <DataGrid
        ref={dataGridRef}
        keyExpr="id"
        dataSource={advertisementFileStore}
        allowColumnReordering={true}
        selection={{ mode: "single" }}
        showBorders={true}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Grouping autoExpandAll={false} />
        <Editing
          mode="form"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
        />

        <Column
          dataField="type"
          setCellValue={setTypeCellValue}
          dataType="number"
          caption={formatMessage("type")}
          groupIndex={0}
        >
          <Lookup
            dataSource={ADVERTISEMENT_FILE_TYPE}
            valueExpr="id"
            displayExpr={`name-${currentLanguage}`}
          />
        </Column>

        <Column
          dataField="url"
          dataType="string"
          caption={formatMessage("url")}
          cellRender={urlCellRender}
          editCellRender={urlEditCellRender}
        />

        <Column
          dataField="urlTxt"
          dataType="string"
          caption={formatMessage("url")}
          formItem={{ visible: false }}
          cellRender={urlTxtCellRender}
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button name="edit" />
        </Column>

        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={20} />
      </DataGrid>
    </>
  );
}

AdvertisementFileList.propTypes = {
  floor: PropTypes.number,
  area: PropTypes.number,
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
};
