import React, { useState } from "react";
import {
  TreeList,
  Column,
  Editing,
  Button,
  Selection,
} from "devextreme-react/tree-list";
import CustomStore from "devextreme/data/custom_store";
import PropTypes from "prop-types";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import notify from "devextreme/ui/notify";
import { Popup } from "devextreme-react/popup";
import MenuItemDetail from "./MenuItemDetail";
import { FILE_URL, WEBSITE_URL } from "../../utils/constants";

loadMessages(dictionary);

const parseJSON = (str, languageId) => {
  let _strObj;
  try {
    _strObj = JSON.parse(str);
  } catch (e) {
    return "";
  }
  return _strObj ? _strObj[languageId] || "" : "";
};

const MenuItemVM = (data, languages) => {
  const returnData = Object.assign({}, data);

  languages.forEach((element) => {
    returnData[`name-${element.languageId}`] = parseJSON(
      data.name,
      element.languageId
    );
    returnData[`description-${element.languageId}`] = parseJSON(
      data.description,
      element.languageId
    );
    // returnData[`alt-${element.languageId}`] = parseJSON(
    //   data.alt,
    //   element.languageId
    // );
  });

  // console.log("returnData", returnData);
  return returnData;
};

const MenuItemsList = ({
  currentWebsite,
  currentLanguage,
  accessToken,
  languages,
  menuId,
}) => {
  locale(currentLanguage.substring(0, 2));
  const URL = `${WEBSITE_URL}Menus/item/${currentWebsite}/${menuId}`;
  const [showItemPopup, setShowItemPopup] = useState(false);
  const [menuItemId, setMenuItemId] = useState(null);

  const loadRequest = (url) => {
    return fetch(`${url}?page=1&pageSize=50`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return {
            data: json.data.map((image) => MenuItemVM(image, languages)),
            totalCount: json.data.totalRows,
          };
        }
        throw json.Message;
      })
    );
  };

  const removeRequest = (url) => {
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          if (text) {
            const _response = JSON.parse(text);
            notify(
              {
                message: _response.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );

            return _response;
          }
        });
      } else {
        return result.json().then((json) => {
          throw json.Message;
        });
      }
    });
  };

  const bannerItemsData = new CustomStore({
    key: "id",
    load: () => loadRequest(`${URL}`),
    remove: (key) => removeRequest(`${URL}/${key}`),
  });

  const imgCellRender = (data) => {
    return (
      <img
        alt="GHM Image"
        src={`${FILE_URL}/${data.value}`}
        style={{ height: 30 }}
      />
    );
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            showMenuItemDetail();
          },
        };
      }
    });

    toolbarItems.push({
      location: "center",
      text: "Danh sách Menu Items",
    });
  };

  const showMenuItemDetail = (_menuItemId = null) => {
    setMenuItemId(_menuItemId);
    setShowItemPopup(true);
  };

  const hideItemDetailPopup = () => {
    setShowItemPopup(false);
    setMenuItemId(null);
  };

  return (
    <React.Fragment>
      <TreeList
        keyExpr="id"
        parentIdExpr="parentId"
        dataSource={bannerItemsData}
        showBorders={true}
        columnAutoWidth={true}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Selection mode="single" />
        <Editing
          mode="form"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
        />
        <Column dataField="images" cellRender={imgCellRender} />

        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`name-${item.languageId}`}
            dataType="string"
            caption={formatMessage("name")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`description-${item.languageId}`}
            dataType="string"
            caption={formatMessage("description")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        <Column
          dataField="seoLink"
          dataType="string"
          caption={formatMessage("seoLink")}
        />
        <Column
          dataField="order"
          dataType="string"
          caption={formatMessage("order")}
        />
        <Column
          dataField="isActive"
          dataType="boolean"
          caption={formatMessage("isActive")}
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => showMenuItemDetail(e.row.key)}
          />
        </Column>
      </TreeList>

      <Popup
        visible={showItemPopup}
        onHiding={() => setShowItemPopup(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showCloseButton={false}
        showTitle={false}
        container=".dx-viewport"
        width={1000}
        height={850}
      >
        <MenuItemDetail
          accessToken={accessToken}
          currentLanguage={currentLanguage}
          currentWebsite={currentWebsite}
          websiteLanguages={languages}
          menuId={menuId}
          id={menuItemId}
          hidePopup={hideItemDetailPopup}
        />
      </Popup>
    </React.Fragment>
  );
};

MenuItemsList.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  currentWebsite: PropTypes.string,
  items: PropTypes.array,
  menuId: PropTypes.string,
};

export default MenuItemsList;
