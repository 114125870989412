import React from "react";
import { connect } from "react-redux";
import DataGrid, {
  Editing,
  Column,
  Button,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import CustomStore from "devextreme/data/custom_store";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import { WEBSITE_URL } from "../../utils/constants";
import { WebsiteVM } from "../../model/Website";

loadMessages(dictionary);
const pageSizes = [10, 25, 50, 100];

const WebsitesListContainer = ({ accessToken, languages, currentLanguage }) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const URL = `${WEBSITE_URL}Websites`;

  const sendRequest = (url, method, data) => {
    method = method || "GET";
    data = data || {};

    if (method === "GET") {
      return fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      }).then((result) =>
        result
          .json()
          .then((json) => {
            if (result.ok)
              return json.data.map((data) => WebsiteVM(data, languages));
            throw json.Message;
          })
          .catch(() => {
            if (result.status === 401) {
              history.push("/login");
            }
          })
      );
    }

    return fetch(url, {
      method: method,
      body: JSON.stringify(data.values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((result) => {
      if (result.ok) {
        return result
          .text()
          .then(
            (text) => text && { ...data.values, id: JSON.parse(text).data }
          );
      } else {
        return result.json().then((json) => {
          throw json.Message;
        });
      }
    });
  };

  const ordersData = new CustomStore({
    key: "id",
    load: () => sendRequest(`${URL}`),
    insert: (values) =>
      sendRequest(`${URL}`, "POST", {
        values: values,
      }),
    update: (key, values) =>
      sendRequest(`${URL}/${key}`, "PUT", {
        values: values,
      }),
    remove: (key) =>
      sendRequest(`${URL}/${key}`, "DELETE", {
        key: key,
      }),
  });

  const gotoDetailPage = (websiteId) => {
    history.push(`/website/${websiteId}`);
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: "center",
      text: "Danh sách Website",
    });
  };

  return (
    <React.Fragment>
      <DataGrid
        keyExpr="id"
        dataSource={ordersData}
        allowColumnReordering={true}
        showBorders={true}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Editing mode="row" useIcons={true} allowDeleting={true} />
        <SearchPanel visible={true} highlightCaseSensitive={true} />

        <Column
          dataField={`name-${currentLanguage}`}
          dataType="string"
          caption={formatMessage("name")}
        />
        <Column
          dataField="phoneNumber"
          dataType="string"
          caption={formatMessage("phoneNumber")}
        />
        <Column
          dataField={`address-${currentLanguage}`}
          dataType="string"
          caption={formatMessage("address")}
        />
        <Column
          dataField="languagesList"
          dataType="string"
          caption={formatMessage("languagesList")}
        />
        <Column
          dataField={`note-${currentLanguage}`}
          dataType="string"
          caption={formatMessage("note")}
        />

        <Column
          dataField="isActive"
          dataType="boolean"
          caption={formatMessage("isActive")}
        />
        <Column
          dataField="isDefault"
          dataType="boolean"
          caption={formatMessage("isDefault")}
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => gotoDetailPage(e.row.key)}
          />
        </Column>

        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
      </DataGrid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
  };
};

WebsitesListContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
};

export default connect(mapStateToProps, null)(WebsitesListContainer);
