import React from "react";
import { connect } from "react-redux";
import PropsType from "prop-types";
import { loadMessages, locale } from "devextreme/localization";
import FileMgr from "../components/file/FileMgr";
import dictionary from "../data/dictionary";

loadMessages(dictionary);

const FileMgrContainer = ({ currentLanguage, accessToken }) => {
  locale(currentLanguage.substring(0, 2));

  return (
    <React.Fragment>
      <FileMgr accessToken={accessToken} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
  };
};

FileMgrContainer.propTypes = {
  currentLanguage: PropsType.string,
  accessToken: PropsType.string,
};

export default connect(mapStateToProps, null)(FileMgrContainer);
