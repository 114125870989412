import useJSON from "../hook/useJSON";

const JobMV = (data, languages) => {
  const nameObj = {};
  const descriptionObj = {};
  const requirementObj = {};
  const benefitObj = {};
  languages.forEach((language) => {
    nameObj[[language.languageId]] = data[`name-${language.languageId}`];
    descriptionObj[[language.languageId]] =
      data[`description-${language.languageId}`];
    requirementObj[[language.languageId]] =
      data[`requirement-${language.languageId}`];
    benefitObj[[language.languageId]] = data[`benefit-${language.languageId}`];
  });

  return JSON.stringify({
    name: JSON.stringify(nameObj),
    description: JSON.stringify(descriptionObj),
    requirement: JSON.stringify(requirementObj),
    benefit: JSON.stringify(benefitObj),
    seoLink: data.seoLink,
    image: data.image,
    url: data.url,
    location: data.location,
    salaryRange: data.salaryRange,
    quantity: data.quantity,
    order: data.order,
    concurrencyStamp: data.concurrencyStamp,
    isActive: data.isActive,
    nameJD: data.nameJD,
    type: data.type,
    position: data.position,
    workingTime: data.workingTime,
    experience: data.experience,
    industryId: data.industryId,
    levelId: data.levelId,
    formId: data.formId
  });
};

const JobVM = (data, languages) => {
  const returnData = useJSON(data, languages);
  return returnData;
};

export { JobMV, JobVM };
