const parseJSON = (str, languageId) => {
  let _strObj;
  try {
    _strObj = JSON.parse(str);
  } catch (e) {
    return "";
  }
  return _strObj ? _strObj[languageId] || "" : "";
};

const ServiceVM = (data, languages) => {
  const returnData = Object.assign({}, data);
  languages.forEach((element) => {
    returnData[`title-${element.languageId}`] = parseJSON(
      data.title,
      element.languageId
    );
    returnData[`subTitle-${element.languageId}`] = parseJSON(
      data.subTitle,
      element.languageId
    );
    returnData[`description-${element.languageId}`] = parseJSON(
      data.description,
      element.languageId
    );
    returnData[`serviceGroupName-${element.languageId}`] = parseJSON(
      data.serviceGroupName,
      element.languageId
    );
  });
  return returnData;
};

export { ServiceVM };
