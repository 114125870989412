import React, { useState, useEffect } from "react";
import Form, {
  SimpleItem,
  GroupItem,
  TabbedItem,
  ButtonItem,
  Label,
  Tab,
  RequiredRule,
} from "devextreme-react/form";
import FileSelectPopup from "../../components/file/FileSelectPopup";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import notify from "devextreme/ui/notify";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { useHistory, useParams } from "react-router-dom";
import BranchContactItemsList from "../../components/website/BranchContactItemsList";
import TabPanel, { Item as TabItem } from "devextreme-react/tab-panel";
import BranchContactImagesList from "../../components/website/BranchContactImagesList";
import { WEBSITE_URL } from "../../utils/constants";
import { BranchContactVM, BranchContactMV } from "../../model/BranchContact";
import CKHtmlEditor from "../../components/html/CKHtmlEditor";

loadMessages(dictionary);
const notesEditorOptions = { height: 150 };

const tabsMock = [
  {
    id: 0,
    text: "vi-VN",
    icon: "globe",
    content: "Vietnamese",
  },
];

const BranchContactDetailContainer = ({
  accessToken,
  websiteLanguages,
  currentLanguage,
  currentWebsite,
}) => {
  locale(currentLanguage.substring(0, 2));
  const [tabs, setTabs] = useState(tabsMock);
  const [branchContact, setBranchContact] = useState({});
  const history = useHistory();
  const params = useParams();
  const URL = `${WEBSITE_URL}BranchContacts/${currentWebsite}`;
  const [editor, setEditor] = useState(null);

  useEffect(() => {
    if (!params.branchContactId) return;

    fetch(
      `${WEBSITE_URL}BranchContacts/${currentWebsite}/${params.branchContactId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setBranchContact(BranchContactVM(json.data, websiteLanguages));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [
    params.branchContactId,
    currentWebsite,
    websiteLanguages,
    accessToken,
    history,
  ]);

  const updateLogo = (e) => {
    setBranchContact((prevState) => ({ ...prevState, logo: e }));
  };

  useEffect(() => {
    const _tabs = websiteLanguages.map((item, index) => ({
      id: index,
      languageId: item.languageId,
      text: item.name,
      icon: "globe",
      content: "User tab content",
    }));
    setTabs(_tabs);
  }, [websiteLanguages]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateBranchContact();
  };

  const updateBranchContact = () => {
    const _method = branchContact.id ? "PUT" : "POST";
    const _url = `${URL}${branchContact.id ? `/${branchContact.id}` : ""}`;

    const editorData = editor?.getData();
    const _branchContact = Object.assign({}, branchContact);

    websiteLanguages.forEach((language) => {
      _branchContact[`workTime-${language.languageId}`] = editorData;
    });

    return fetch(_url, {
      method: _method,
      body: BranchContactMV(_branchContact, websiteLanguages),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          notify(
            {
              message: JSON.parse(text).message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          history.push("/info-website");
          history.push({
            pathname: "/info-website",
            state: { activeTab: 3 },
          });
        });
      } else {
        return result.json().then((json) => {
          throw json.Message;
        });
      }
    });
  };

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.goBack();
    },
  };

  const saveButtonOptions = {
    text: "Update",
    type: "success",
    useSubmitBehavior: true,
  };

  return (
    <React.Fragment>
      <Toolbar>
        <Item location="before" widget="dxButton" options={backButtonOptions} />
        <Item location="center" text={"Chi tiết BranchContact"} />
      </Toolbar>

      <form action="your-action" onSubmit={handleSubmit}>
        <Form colCount={2} formData={branchContact}>
          <GroupItem caption="Thông tin cơ bản">
            <TabbedItem itemType="tabbed" colCount={2} colSpan={2}>
              {tabs.map((item) => {
                return (
                  <Tab key={item.id} title={item.text} icon={item.icon}>
                    <SimpleItem
                      dataField={`name-${item.languageId}`}
                      editorType="dxTextBox"
                    >
                      <Label text={formatMessage("name")} />
                      <RequiredRule />
                    </SimpleItem>
                    <SimpleItem
                      dataField={`workTime-${item.languageId}`}
                    >
                      <Label text={formatMessage("workTime")} />
                      <RequiredRule />
                      <CKHtmlEditor
                        value={branchContact[`workTime-${item.languageId}`]}
                        onInit={setEditor}
                        initHeight="100px"
                      />
                    </SimpleItem>
                    <SimpleItem
                      dataField={`description-${item.languageId}`}
                      editorType="dxTextArea"
                      editorOptions={notesEditorOptions}
                    >
                      <Label text={formatMessage("description")} />
                    </SimpleItem>
                    <SimpleItem
                      dataField={`address-${item.languageId}`}
                      editorType="dxTextArea"
                      editorOptions={notesEditorOptions}
                    >
                      <Label text={formatMessage("address")} />
                    </SimpleItem>
                  </Tab>
                );
              })}
            </TabbedItem>
          </GroupItem>

          <GroupItem itemType="group" caption="Thông tin khác" colCount={2}>
            <GroupItem>
              <SimpleItem>
                <Label text={formatMessage("logo")} />
                <FileSelectPopup
                  value={branchContact.logo}
                  imgHeight={150}
                  onValueChanged={updateLogo}
                />
              </SimpleItem>
            </GroupItem>

            <GroupItem>
              <SimpleItem dataField={"link"} editorType="dxTextBox">
                <Label text={formatMessage("link")} />
              </SimpleItem>
              <SimpleItem dataField={"website"} editorType="dxTextBox">
                <RequiredRule />
                <Label text={formatMessage("website")} />
              </SimpleItem>
              <SimpleItem dataField={"googleMap"} editorType="dxTextBox">
                <Label text={formatMessage("googleMap")} />
              </SimpleItem>
              <SimpleItem dataField={"order"} editorType="dxNumberBox">
                <Label text={formatMessage("order")} />
              </SimpleItem>
              <SimpleItem dataField={"isDefault"} editorType="dxCheckBox">
                <Label text={formatMessage("isDefault")} />
              </SimpleItem>
              <SimpleItem dataField={"isActive"} editorType="dxCheckBox">
                <Label text={formatMessage("isActive")} />
              </SimpleItem>
              <SimpleItem dataField={"isOffice"} editorType="dxCheckBox">
                <Label text={formatMessage("isOffice")} />
              </SimpleItem>
            </GroupItem>
          </GroupItem>

          <ButtonItem
            colSpan={2}
            horizontalAlignment="center"
            buttonOptions={saveButtonOptions}
          />
        </Form>
      </form>

      <hr />
      {params.branchContactId && (
        <TabPanel>
          <TabItem title="Items" icon="smalliconslayout">
            <BranchContactItemsList
              branchContactId={params.branchContactId}
              accessToken={accessToken}
              languages={websiteLanguages}
              currentWebsite={currentWebsite}
              currentLanguage={currentLanguage}
            />
          </TabItem>
          <TabItem title="Images" icon="photo">
            <BranchContactImagesList
              branchContactId={params.branchContactId}
              accessToken={accessToken}
              languages={websiteLanguages}
              currentWebsite={currentWebsite}
              currentLanguage={currentLanguage}
            />
          </TabItem>
        </TabPanel>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

BranchContactDetailContainer.propTypes = {
  accessToken: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
  currentLanguage: PropTypes.string,
};

export default connect(mapStateToProps, null)(BranchContactDetailContainer);
