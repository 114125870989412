import Form, {
  SimpleItem,
  GroupItem,
  TabbedItem,
  ButtonItem,
  Label,
  Tab,
  RangeRule,
  RequiredRule,
} from "devextreme-react/form";
import React, { useState, useEffect } from "react";
import dictionary from "../../data/dictionary";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { useHistory, useParams } from "react-router-dom";
import FileSelectPopup from "../../components/file/FileSelectPopup";
import "devextreme-react/text-area";
import "devextreme-react/tag-box";
import NewsCategoryDropDown from "../../components/website/NewsCategoryDropDown";
import notify from "devextreme/ui/notify";
import useJSON from "../../hook/useJSON";
import { WEBSITE_URL } from "../../utils/constants";

loadMessages(dictionary);

const tabsMock = [
  {
    id: 0,
    text: "vi-VN",
    icon: "globe",
    content: "Vietnamese",
  },
];

const notesEditorOptions = { height: 100 };
const tagsEditorOptions = { acceptCustomValue: true };

const NewsCategoryVM = (data, languages) => {
  const returnData = useJSON(data, languages);
  returnData["tags"] = data.subjectTags.map((x) => x.name);
  return returnData;
};

const NewsCategoryMV = (data, languages) => {
  const nameObj = {};
  const descriptionObj = {};
  const metaTitleObj = {};
  const metaDescriptionObj = {};
  languages.forEach((language) => {
    nameObj[[language.languageId]] = data[`name-${language.languageId}`];
    descriptionObj[[language.languageId]] =
      data[`description-${language.languageId}`];
    metaTitleObj[[language.languageId]] =
      data[`metaTitle-${language.languageId}`];
    metaDescriptionObj[[language.languageId]] =
      data[`metaDescription-${language.languageId}`];
  });
  const _tags = data.tags || [];

  return JSON.stringify({
    parentId: data.parentId,
    name: JSON.stringify(nameObj),
    description: JSON.stringify(descriptionObj),
    metaTitle: JSON.stringify(metaTitleObj),
    metaDescription: JSON.stringify(metaDescriptionObj),
    seoLink: data.seoLink,
    bannerImages: data.bannerImages,
    bannerImagesMobile: data.bannerImagesMobile,
    altImages: data.altImages,
    isHomePage: data.isHomePage,
    isHot: data.isHot,
    order: data.order,
    concurrencyStamp: data.concurrencyStamp,
    isActive: data.isActive,
    tags: _tags.map((x) => {
      return { name: x };
    }),
  });
};

const NewsCategoryDetailContainer = ({
  accessToken,
  currentLanguage,
  currentWebsite,
  websiteLanguages,
}) => {
  locale(currentLanguage.substring(0, 2));
  const [tabs, setTabs] = useState(tabsMock);
  const [newsCategory, setNewsCategory] = useState({});
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (!params.categoryId) return;

    fetch(`${WEBSITE_URL}Categorys/${currentWebsite}/${params.categoryId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setNewsCategory(NewsCategoryVM(json.data, websiteLanguages));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [
    accessToken,
    websiteLanguages,
    currentWebsite,
    params.categoryId,
    history,
  ]);

  const updateNewsCategory = () => {
    const _method = newsCategory.id ? "PUT" : "POST";
    const _url = `${WEBSITE_URL}Categorys/${currentWebsite}${
      newsCategory.id ? `/${newsCategory.id}` : ""
    }`;

    return fetch(_url, {
      method: _method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
      body: NewsCategoryMV(newsCategory, websiteLanguages),
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            history.push("/news/category");
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateNewsCategory();
  };

  useEffect(() => {
    const _tabs = websiteLanguages.map((item, index) => ({
      id: index,
      languageId: item.languageId,
      text: item.name,
      icon: "globe",
      content: "User tab content",
    }));
    setTabs(_tabs);
  }, [websiteLanguages]);

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.push("/news/category");
    },
  };

  const updateBannerImages = (e) => {
    setNewsCategory((prevState) => ({ ...prevState, bannerImages: e }));
  };

  const updateBannerImagesMobile = (e) => {
    setNewsCategory((prevState) => ({ ...prevState, bannerImagesMobile: e }));
  };

  const updateCategorys = (e) => {
    setNewsCategory((prevState) => ({ ...prevState, parentId: e[0] || null }));
  };

  const saveButtonOptions = {
    text: "Update",
    type: "success",
    useSubmitBehavior: true,
  };

  return (
    <React.Fragment>
      <Toolbar>
        <Item location="before" widget="dxButton" options={backButtonOptions} />
        <Item location="center" text={"Chi tiết Chuyên mục Tin tức"} />
      </Toolbar>

      <form action="your-action" onSubmit={handleSubmit}>
        <Form
          formData={newsCategory}
          readOnly={false}
          showColonAfterLabel={true}
          colCount={3}
          showValidationSummary={true}
          validationGroup="customerData"
        >
          <GroupItem colSpan={2} caption="Thông tin cơ bản">
            <GroupItem caption="Đa ngôn ngữ">
              <TabbedItem>
                {tabs.map((item) => {
                  return (
                    <Tab key={item.id} title={item.text} icon={item.icon}>
                      <SimpleItem
                        dataField={`name-${item.languageId}`}
                        editorType="dxTextBox"
                      >
                        <Label text={formatMessage("name")} />
                        <RequiredRule />
                      </SimpleItem>
                      <SimpleItem
                        dataField={`description-${item.languageId}`}
                        editorType="dxTextArea"
                        editorOptions={notesEditorOptions}
                      >
                        <Label text={formatMessage("description")} />
                      </SimpleItem>
                    </Tab>
                  );
                })}
              </TabbedItem>
            </GroupItem>

            <GroupItem caption="Thông tin khác">
              <SimpleItem colSpan={2}>
                <Label text={formatMessage("category")} />
                <NewsCategoryDropDown
                  accessToken={accessToken}
                  currentWebsite={currentWebsite}
                  currentLanguage={currentLanguage}
                  value={newsCategory.parentId}
                  onValueChanged={updateCategorys}
                  selectionMode={"single"}
                />
              </SimpleItem>
              <SimpleItem
                dataField="seoLink"
                editorType="dxTextBox"
                colSpan={3}
              >
                <Label text={formatMessage("seoLink")} />
                <RequiredRule
                  message={`${formatMessage("seoLink")} ${formatMessage(
                    "errMesRequire"
                  )}`}
                />
              </SimpleItem>
              <SimpleItem
                dataField={"tags"}
                editorType="dxTagBox"
                editorOptions={tagsEditorOptions}
              >
                <Label text={"Tags"} />
              </SimpleItem>
              <SimpleItem dataField={"order"} editorType="dxNumberBox">
                <Label text={"Thứ tự"} />
                <RangeRule min={0} message={"Thứ tự phải đúng định dạng"} />
              </SimpleItem>
              <SimpleItem dataField={"isActive"} editorType="dxCheckBox">
                <Label text={"Kích hoạt"} />
              </SimpleItem>
              <SimpleItem dataField={"isHomePage"} editorType="dxCheckBox">
                <Label text={"HomePage"} />
              </SimpleItem>
              <SimpleItem dataField={"isHot"} editorType="dxCheckBox">
                <Label text={"Hot"} />
              </SimpleItem>
            </GroupItem>
          </GroupItem>

          <GroupItem caption="SEO">
            <TabbedItem>
              {tabs.map((item) => {
                return (
                  <Tab key={item.id} title={item.text} icon={item.icon}>
                    <SimpleItem
                      dataField={`metaTitle-${item.languageId}`}
                      editorType="dxTextBox"
                    >
                      <Label text={formatMessage("metaTitle")} />
                    </SimpleItem>
                    <SimpleItem
                      dataField={`metaDescription-${item.languageId}`}
                      editorType="dxTextArea"
                      editorOptions={notesEditorOptions}
                    >
                      <Label text={formatMessage("metaDescription")} />
                    </SimpleItem>
                  </Tab>
                );
              })}
            </TabbedItem>
            <GroupItem caption="Ảnh Feature" colCount={2}>
              <SimpleItem>
                <Label text={formatMessage("website")} />
                <FileSelectPopup
                  value={newsCategory.bannerImages}
                  imgHeight={100}
                  onValueChanged={updateBannerImages}
                />
              </SimpleItem>
              <SimpleItem>
                <Label text={formatMessage("mobile")} />
                <FileSelectPopup
                  value={newsCategory.bannerImagesMobile}
                  imgHeight={100}
                  onValueChanged={updateBannerImagesMobile}
                />
              </SimpleItem>
              <SimpleItem
                dataField={"altImages"}
                editorType="dxTextArea"
                colSpan={2}
              >
                <Label text={formatMessage("alt")} />
              </SimpleItem>
            </GroupItem>
          </GroupItem>

          <ButtonItem
            colSpan={3}
            horizontalAlignment="center"
            buttonOptions={saveButtonOptions}
          />
        </Form>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

NewsCategoryDetailContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
};

export default connect(mapStateToProps, null)(NewsCategoryDetailContainer);
