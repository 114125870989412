import WebsiteServiceFAQsList from "./WebsiteServiceFAQsList";
import WebsiteServiceTitlesList from "./WebsiteServiceTitlesList";
import React from "react";
import PropTypes from "prop-types";

function WebsiteServiceDetailTabs({
  serviceId,
  accessToken,
  languages,
  currentWebsite,
  currentLanguage,
}) {
  const _props = {
    serviceId,
    accessToken,
    languages,
    currentWebsite,
    currentLanguage,
  };

  return (
    <React.Fragment>
      <WebsiteServiceFAQsList {..._props} />

      <hr />

      <WebsiteServiceTitlesList {..._props} />
    </React.Fragment>
  );
}

WebsiteServiceDetailTabs.propTypes = {
  serviceId: PropTypes.string,
  languages: PropTypes.array,
  accessToken: PropTypes.string,
  currentWebsite: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentLanguage: PropTypes.string,
};

export default WebsiteServiceDetailTabs;
