import Form, {
    SimpleItem, GroupItem, TabbedItem, ButtonItem,
    Label, Tab, RangeRule, RequiredRule
} from 'devextreme-react/form';
import React, { useState, useEffect } from 'react';
import dictionary from '../../data/dictionary';
import PropTypes from 'prop-types';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useHistory } from 'react-router-dom';
import FileSelectPopup from '../../components/file/FileSelectPopup';
import 'devextreme-react/text-area';
import 'devextreme-react/tag-box';
import notify from 'devextreme/ui/notify';
import useJSON from '../../hook/useJSON';
import MenuDropDown from './MenuDropDown';
import NewsCategoryDropDown from './NewsCategoryDropDown';
import SelectBox from 'devextreme-react/select-box';
import { MENU_OBJECT_TYPES, WEBSITE_URL } from '../../utils/constants';

loadMessages(dictionary);

const tabsMock = [{
    id: 0,
    text: 'vi-VN',
    icon: 'globe',
    content: 'Vietnamese'
}];

const parseJSON = (str, languageId) => {
    let _strObj;
    try {
        _strObj = JSON.parse(str);
    } catch (e) {
        return str || '';
    }
    return _strObj ? _strObj[languageId] || '' : '';
};

const notesEditorOptions = { height: 100 };

const MenuItemVM = (data, languages) => {
    const returnData = useJSON(data, languages);
    return returnData;
};

const ObjectTypeVM = (data, currentLanguage) => {
    return {
        id: data.id,
        name: parseJSON(data.name, currentLanguage)
    };
};

const NewsVM = (data, languageId) => {
    return {
        id: data.id,
        seoLink: data.seoLink,
        name: parseJSON(data.title, languageId)
    };
};

const ServiceVM = (data, languageId) => {
    return {
        id: data.id,
        seoLink: data.seoLink,
        name: parseJSON(data.title, languageId)
    };
};

const MenuItemMV = (data, languages) => {
    const nameObj = {}; const descriptionObj = {}; const metaTitleObj = {}; const metaDescriptionObj = {}; const metaKeywordObj = {};
    languages.forEach(language => {
        nameObj[[language.languageId]] = data[`name-${language.languageId}`];
        descriptionObj[[language.languageId]] = data[`description-${language.languageId}`];
        metaTitleObj[[language.languageId]] = data[`metaTitle-${language.languageId}`];
        metaDescriptionObj[[language.languageId]] = data[`metaDescription-${language.languageId}`];
        metaKeywordObj[[language.languageId]] = data[`metaKeyword-${language.languageId}`];
    });
    return JSON.stringify({
        parentId: data.parentId,
        name: JSON.stringify(nameObj),
        description: JSON.stringify(descriptionObj),
        metaTitle: JSON.stringify(metaTitleObj),
        metaDescription: JSON.stringify(metaDescriptionObj),
        metaKeyword: JSON.stringify(metaKeywordObj),
        seoLink: data.seoLink,
        icon: data.icon,
        images: data.images,
        url: data.url,
        objectType: data.objectType,
        objectId: data.objectId,
        order: data.order,
        concurrencyStamp: data.concurrencyStamp,
        isActive: data.isActive,
        alt: data.alt
    });
};

const MenuItemDetail = ({ accessToken, currentLanguage, currentWebsite, websiteLanguages, menuId, id, hidePopup }) => {
    locale(currentLanguage.substring(0, 2));
    const [tabs, setTabs] = useState(tabsMock);
    const [menuItem, setMenuItem] = useState({ order: 0, isActive: true });
    const history = useHistory();
    const [news, setNews] = useState([]);
    const [services, setServices] = useState([]);

    useEffect(() => {
        fetch(`${WEBSITE_URL}News/${currentWebsite}/${currentLanguage}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setNews(json.data.map(news => NewsVM(news, currentLanguage)));
                    return;
                }
                throw json.Message;
            })
        );
    }, [accessToken, currentLanguage, currentWebsite]);

    useEffect(() => {
        fetch(`${WEBSITE_URL}services/get-all/${currentWebsite}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setServices(json.map(service => ServiceVM(service, currentLanguage)));
                    return;
                }
                throw json.Message;
            })
        );
    }, [accessToken, currentLanguage, currentWebsite]);

    useEffect(() => {
        if (!id) {
            setMenuItem({ order: 0, isActive: true });
            return;
        }

        fetch(`${WEBSITE_URL}Menus/item/${currentWebsite}/${menuId}/${id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setMenuItem(MenuItemVM(json.data, websiteLanguages));
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    }, [id, websiteLanguages, currentWebsite, menuId, accessToken, history]);

    const updateMenuItem = () => {
        const _method = menuItem.id ? 'PUT' : 'POST';
        const _url = `${WEBSITE_URL}Menus/item/${currentWebsite}/${menuId}${menuItem.id ? `/${menuItem.id}` : ''}`;

        return fetch(_url, {
            method: _method,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': currentLanguage
            },
            body: MenuItemMV(menuItem, websiteLanguages)
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                    setMenuItem({ order: 0, isActive: true });
                    hidePopup();
                } else {
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'error', 3000);
                }
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateMenuItem();
    };

    useEffect(() => {
        const _tabs = websiteLanguages.map((item, index) => ({
            id: index,
            languageId: item.languageId,
            text: item.name,
            icon: 'globe',
            content: 'User tab content'
        }));
        setTabs(_tabs);
    }, [websiteLanguages]);

    const updateImages = e => {
        setMenuItem(prevState => ({ ...prevState, images: e }));
    };

    const saveButtonOptions = {
        text: 'Update',
        type: 'success',
        useSubmitBehavior: true
    };

    const updateParentId = e => {
        setMenuItem(prevState => ({ ...prevState, parentId: e[0] || null }));
    };

    const objectTypeSelectOptions = {
        items: MENU_OBJECT_TYPES.map((type) => ObjectTypeVM(type, currentLanguage)),
        displayExpr: 'name',
        searchEnabled: true,
        valueExpr: 'id',
        value: menuItem.objectType,
        readOnly: id !== null,
        onValueChanged: (e) => setMenuItem(prevState => ({ ...prevState, objectType: e.value }))
    };

    const updateObjectId = e => {
        setMenuItem(prevState => ({ ...prevState, objectId: e[0] || null }));
    };

    const updateSeoLink = e => {
        setMenuItem(prevState => ({ ...prevState, seoLink: e }));
    };
    
    return (
        <React.Fragment>
            <Toolbar>
                <Item location="center"
                    text={'Chi tiết Menu Item'} />
            </Toolbar>

            <form action="your-action" onSubmit={handleSubmit}>
                <Form
                    formData={menuItem}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                    validationGroup="menuItemData"
                >
                    <GroupItem colCount={3}>
                        <TabbedItem colSpan={2} > 
                            {tabs.map((item) => {
                                return (
                                    <Tab key={item.id} title={item.text} icon={item.icon} sc>
                                        <SimpleItem dataField={`name-${item.languageId}`} editorType="dxTextBox">
                                            <Label text={formatMessage('name')} />
                                            <RequiredRule />
                                        </SimpleItem>
                                        <SimpleItem dataField={`description-${item.languageId}`} editorType="dxTextArea" editorOptions={notesEditorOptions}>
                                            <Label text={formatMessage('description')} />
                                        </SimpleItem>
                                        <SimpleItem dataField={`metaTitle-${item.languageId}`} editorType="dxTextBox">
                                            <Label text={formatMessage('metaTitle')} />
                                        </SimpleItem>
                                        <SimpleItem dataField={`metaDescription-${item.languageId}`} editorType="dxTextBox" editorOptions={notesEditorOptions}>
                                            <Label text={formatMessage('metaDescription')} />
                                        </SimpleItem>                                      
                                        <SimpleItem dataField={`metaKeyword-${item.languageId}`} editorType="dxTextBox" editorOptions={notesEditorOptions}>
                                            <Label text={formatMessage('metaKeyword')} />
                                        </SimpleItem>
                                    </Tab>
                                );
                            })}
                        </TabbedItem>

                        <SimpleItem >
                            <Label text={formatMessage('images')} />
                            <FileSelectPopup value={menuItem.images} imgHeight={100}
                                onValueChanged={updateImages} />
                        </SimpleItem>

                    </GroupItem>
                    <GroupItem colCount={2}>
                        <SimpleItem>
                            <Label text={`${formatMessage('menu')} ${formatMessage('parent')}`} />
                            <MenuDropDown accessToken={accessToken} currentWebsite={currentWebsite}
                                currentLanguage={currentLanguage} value={menuItem.parentId}
                                menuId={menuId}
                                onValueChanged={updateParentId} selectionMode={'single'} />
                        </SimpleItem>

                        <SimpleItem dataField="objectType" editorType="dxSelectBox" editorOptions={objectTypeSelectOptions}>
                            <Label text={formatMessage('objectType')} />
                            <RequiredRule message={`${formatMessage('objectType')} ${formatMessage('errMesRequire')}`} />
                        </SimpleItem>

                        {menuItem.objectType === 0 && <SimpleItem colSpan={2} dataField={'url'} editorType="dxTextBox">
                            <Label text={formatMessage('url')} />
                        </SimpleItem>}

                        {menuItem.objectType === 1 && <SimpleItem colSpan={2} >
                            <Label text={formatMessage('category')} />
                            <NewsCategoryDropDown accessToken={accessToken} currentWebsite={currentWebsite}
                                currentLanguage={currentLanguage} value={menuItem.objectId}
                                onValueChanged={updateObjectId} selectionMode={'single'} updateSeoLink={updateSeoLink} />
                        </SimpleItem>}

                        {menuItem.objectType === 2 && <SimpleItem colSpan={2} dataField="objectId">
                            <Label text={formatMessage('news')} />
                            <SelectBox
                                valueExpr={'id'}
                                value={menuItem.objectId}
                                searchEnabled={true}
                                onValueChanged={(args) => {
                                    let _seoLink = '';
                                    if (args.value) {
                                        const _selectedNews = news.find(x => x.id === args.value);
                                        _seoLink = _selectedNews.seoLink;
                                    }
                                    setMenuItem({ ...menuItem, objectId: args.value, seoLink: _seoLink });
                                }}
                                displayExpr={'name'}
                                items={news} />
                        </SimpleItem>}

                        {menuItem.objectType === 5 && <SimpleItem colSpan={2} dataField="objectId">
                            <Label text={formatMessage('services')} />
                            <SelectBox
                                valueExpr={'id'}
                                value={menuItem.objectId}
                                searchEnabled={true}
                                onValueChanged={(args) => {
                                    let _seoLink = '';
                                    if (args.value) {
                                        const _selecteService = services.find(x => x.id === args.value);
                                        _seoLink = _selecteService.seoLink;
                                    }
                                    setMenuItem({ ...menuItem, objectId: args.value, seoLink: _seoLink });
                                }}
                                displayExpr={'name'}
                                items={services} />
                        </SimpleItem>}
                        <SimpleItem dataField={'alt'} editorType="dxTextBox">
                            <Label text={formatMessage('alt')} />
                        </SimpleItem>
                        <SimpleItem dataField={'seoLink'} editorType="dxTextBox">
                            <Label text={formatMessage('seoLink')} />
                        </SimpleItem>
                        <SimpleItem dataField={'icon'} editorType="dxTextBox">
                            <Label text={formatMessage('icon')} />
                        </SimpleItem>

                        <SimpleItem dataField={'order'} editorType="dxNumberBox">
                            <Label text={formatMessage('order')} />
                            <RangeRule min={0} message={`${formatMessage('order')} ${formatMessage('errMesInvalidNum')}`} />
                        </SimpleItem>
                        <SimpleItem dataField={'isActive'} editorType="dxCheckBox">
                            <Label text={formatMessage('isActive')} />
                        </SimpleItem>
                    </GroupItem>

                    <ButtonItem colSpan={3} horizontalAlignment="center"
                        buttonOptions={saveButtonOptions}
                    />
                </Form>
            </form>
        </React.Fragment>
    );
};

MenuItemDetail.propTypes = {
    accessToken: PropTypes.string,
    languages: PropTypes.array,
    currentLanguage: PropTypes.string,
    websiteId: PropTypes.string,
    websiteLanguages: PropTypes.array,
    currentWebsite: PropTypes.string,
    menuId: PropTypes.string,
    id: PropTypes.string,
    hidePopup: PropTypes.func
};

export default MenuItemDetail;
