import Transformer from "../utils/transformer";

const WebsiteVM = (data, languages = []) => {
  const returnData = Object.assign({}, data);

  languages.forEach((element) => {
    returnData[`name-${element.languageId}`] = Transformer.parseJSON(
      data.name,
      element.languageId
    );
    returnData[`address-${element.languageId}`] = Transformer.parseJSON(
      data.address,
      element.languageId
    );
    returnData[`note-${element.languageId}`] = Transformer.parseJSON(
      data.note,
      element.languageId
    );
  });

  returnData.languagesList = data.websiteLanguages
    .map((x) => x.name)
    .join(", ");

  return returnData;
};

const WebsiteMV = () => {
  return {};
};

export { WebsiteVM, WebsiteMV };
