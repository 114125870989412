import React from 'react'
import Form, { ButtonItem, SimpleItem, Label, RequiredRule } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import CustomerDetailData from './enhance';
import PropTypes from "prop-types";

const CustomerDetail = ({ setShowDeclinePopup, selectedId, accessToken, currentLanguage, onRefresh }) => {
    const { handleSubmit, declineForm, statusOptions } = CustomerDetailData({ selectedId, accessToken, currentLanguage, setShowDeclinePopup, onRefresh });

    return (
        <Popup
            visible={true}
            onHiding={() => setShowDeclinePopup(false)}
            dragEnabled={false}
            closeOnOutsideClick={true}
            showCloseButton={false}
            showTitle={true}
            title="Chi tiết bệnh nhân"
            container=".dx-viewport"
            width={600}
            height={400}
        >
            {<form action="your-action" onSubmit={handleSubmit}>
                <Form
                    formData={declineForm}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                >
                    <SimpleItem dataField={"fullName"} editorType="dxTextBox">
                        <Label text="Tên" />
                        <RequiredRule message="Tên là trường bắt buộc" />
                    </SimpleItem>

                    <SimpleItem dataField={"phoneNumber"} editorType="dxTextBox">
                        <Label text="Số điện thoại" />
                        <RequiredRule message="Số điện thoại là trường bắt buộc" />
                    </SimpleItem>

                    <SimpleItem dataField={"appointmentDate"} editorType={'dxDateBox'} editorOptions={{ readOnly: declineForm ? declineForm.status === 1 ? true : false : false }}>
                        <Label text="Ngày hẹn khám" />
                    </SimpleItem>

                    <SimpleItem dataField={"description"} editorType="dxTextArea">
                        <Label text="Mô tả" />
                    </SimpleItem>

                    <SimpleItem dataField={"status"} editorType={declineForm ? declineForm.status === 1 ? 'dxTextBox' : 'dxSelectBox' : 'dxSelectBox'} editorOptions={statusOptions}>
                        <Label text="Trạng thái" />
                    </SimpleItem>

                    <ButtonItem
                        horizontalAlignment="center"
                        buttonOptions={{
                            text: "Lưu",
                            type: "default",
                            stylingMode: "contained",
                            useSubmitBehavior: true,
                        }}
                    />
                </Form>
            </form>}
        </Popup>
    )
}

CustomerDetail.propTypes = {
    selectedId: PropTypes.any,
    setShowDeclinePopup: PropTypes.func,
    accessToken: PropTypes.string,
    currentLanguage: PropTypes.string,
    onRefresh: PropTypes.func
};

export default CustomerDetail;