import Form, {
    SimpleItem, GroupItem, ButtonItem,
    Label, RequiredRule, RangeRule
} from 'devextreme-react/form';
import React, { useState, useEffect } from 'react';
import dictionary from '../../data/dictionary';
import PropTypes from 'prop-types';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useHistory } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
import useJSON from '../../hook/useJSON';
import FileSelectPopup from '../file/FileSelectPopup';
import { WEBSITE_URL } from '../../utils/constants';

loadMessages(dictionary);

const BranchContactImageVM = (data, languages) => {
    const returnData = useJSON(data, languages);
    return returnData;
};

const BranchContactImageMV = (data) => {
    return JSON.stringify({
        images: data.images,
        url: data.url,
        alt: data.alt,
        order: data.order
    });
};

const BranchContactImageDetail = ({ accessToken, currentLanguage, currentWebsite, websiteLanguages, branchContactId, id, hidePopup }) => {
    locale(currentLanguage.substring(0, 2));
    const [branchContactImage, setBranchContactImage] = useState({});
    const history = useHistory();

    useEffect(() => {
        if (!id) return;

        fetch(`${WEBSITE_URL}BranchContacts/image/${currentWebsite}/${branchContactId}/${id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setBranchContactImage(BranchContactImageVM(json.data, websiteLanguages));
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    }, [id, websiteLanguages, branchContactId, currentWebsite, accessToken, history]);

    const updateBranchContactImage = () => {
        const _method = branchContactImage.id ? 'PUT' : 'POST';
        const _url = `${WEBSITE_URL}BranchContacts/image/${currentWebsite}/${branchContactId}${branchContactImage.id ? `/${branchContactImage.id}` : ''}`;

        return fetch(_url, {
            method: _method,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': currentLanguage
            },
            body: BranchContactImageMV(branchContactImage)
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                    hidePopup();
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateBranchContactImage();
    };

    const saveButtonOptions = {
        text: 'Update',
        type: 'success',
        useSubmitBehavior: true
    };

    const updateImages = e => {
        setBranchContactImage(prevState => ({ ...prevState, images: e }));
    };

    return (
        <React.Fragment>
            <Toolbar>
                <Item location="center"
                    text={'Chi tiết BranchContact Image'} />
            </Toolbar>

            <form action="your-action" onSubmit={handleSubmit}>
                <Form
                    formData={branchContactImage}
                    readOnly={false}
                    showColonAfterLabel={true}
                    showValidationSummary={true}
                >
                    <GroupItem colCount={2}>

                        <SimpleItem >
                            <Label text={formatMessage('images')} />
                            <FileSelectPopup value={branchContactImage.images} imgHeight={100}
                                onValueChanged={updateImages} />
                            <RequiredRule />
                        </SimpleItem>

                        <GroupItem >
                            <SimpleItem dataField={'alt'} editorType="dxTextBox">
                                <Label text={formatMessage('alt')} />
                            </SimpleItem>

                            <SimpleItem dataField={'url'} editorType="dxTextBox">
                                <Label text={formatMessage('url')} />
                            </SimpleItem>

                            <SimpleItem dataField={'order'} editorType="dxNumberBox">
                                <Label text={formatMessage('order')} />
                                <RangeRule min={0} message={`${formatMessage('order')} ${formatMessage('errMesInvalidNum')}`} />
                            </SimpleItem>
                        </GroupItem>
                    </GroupItem>

                    <ButtonItem colSpan={3} horizontalAlignment="center"
                        buttonOptions={saveButtonOptions}
                    />
                </Form>
            </form>
        </React.Fragment>
    );
};

BranchContactImageDetail.propTypes = {
    accessToken: PropTypes.string,
    languages: PropTypes.array,
    currentLanguage: PropTypes.string,
    websiteId: PropTypes.string,
    websiteLanguages: PropTypes.array,
    currentWebsite: PropTypes.string,
    branchContactId: PropTypes.string,
    id: PropTypes.string,
    hidePopup: PropTypes.func
};

export default BranchContactImageDetail;
