import Form, {
    SimpleItem, GroupItem, ButtonItem,
    Label, RequiredRule, EmptyItem
} from 'devextreme-react/form';
import React, { useState, useEffect } from 'react';
import dictionary from '../../data/dictionary';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useHistory, useParams } from 'react-router-dom';
import 'devextreme-react/text-area';
import 'devextreme-react/tag-box';
import notify from 'devextreme/ui/notify';
import useJSON from '../../hook/useJSON';
import { WEBSITE_URL } from '../../utils/constants';

loadMessages(dictionary);

const NewsCategoryVM = (data, languages) => {
    const returnData = useJSON(data, languages);
    return returnData;
};

const RecruitmentMV = (data) => {
    return JSON.stringify({
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        description: data.description,
        address: data.address,
        birthday: data.birthday
    });
};

const RecruitmentDetailContainer = ({ accessToken, currentLanguage, currentWebsite, websiteLanguages }) => {
    locale(currentLanguage.substring(0, 2));
    const [recruitment, setRecruitment] = useState({});
    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        if (!params.recruitmentId) return;

        fetch(`${WEBSITE_URL}Recruitments/${currentWebsite}/${params.recruitmentId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    setRecruitment(NewsCategoryVM(json.data, websiteLanguages));
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    }, [accessToken, websiteLanguages, currentWebsite, params.categoryId, history]);

    const updateNewsCategory = () => {
        const _method = recruitment.id ? 'PUT' : 'POST';
        const _url = `${WEBSITE_URL}Recruitments/${currentWebsite}${recruitment.id ? `/${recruitment.id}` : ''}`;

        return fetch(_url, {
            method: _method,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept-Language': currentLanguage
            },
            body: RecruitmentMV(recruitment, websiteLanguages)
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    history.push('/recruitment');
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                    return;
                }
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateNewsCategory();
    };

    const backButtonOptions = {
        type: 'back',
        onClick: () => {
            history.push('/recruitment');
        }
    };

    const saveButtonOptions = {
        text: 'Update',
        type: 'success',
        useSubmitBehavior: true
    };

    return (
        <React.Fragment>
            <Toolbar>
                <Item location="before"
                    widget="dxButton"
                    options={backButtonOptions} />
                <Item location="center"
                    text={'Chi tiết đăng ký tuyển dụng'} />
            </Toolbar>

            <form action="your-action" onSubmit={handleSubmit}>
                <Form
                    formData={recruitment}
                    readOnly={false}
                    showColonAfterLabel={true}
                    colCount={3}
                    showValidationSummary={true}
                    validationGroup="customerData"
                >
                    <GroupItem colSpan={2} caption="Thông tin cơ bản">

                        <SimpleItem dataField="fullName" editorType="dxTextBox" colSpan={3}>
                            <Label text={formatMessage('fullName')} />
                            <RequiredRule message={`${formatMessage('fullName')} ${formatMessage('errMesRequire')}`} />
                        </SimpleItem>
                        <SimpleItem dataField="phoneNumber" editorType="dxTextBox" colSpan={3}>
                            <Label text={formatMessage('phoneNumber')} />
                            <RequiredRule message={`${formatMessage('phoneNumber')} ${formatMessage('errMesRequire')}`} />
                        </SimpleItem>
                        <SimpleItem dataField="email" editorType="dxTextBox" colSpan={3}>
                            <Label text={formatMessage('email')} />
                        </SimpleItem>
                        <SimpleItem dataField="description" editorType="dxTextArea" colSpan={3}>
                            <Label text={formatMessage('description')} />
                        </SimpleItem>

                        <SimpleItem dataField={'isView'} editorType="dxCheckBox">
                            <Label text={formatMessage('isView')} />
                        </SimpleItem>
                    </GroupItem>

                    <EmptyItem />

                    <ButtonItem colSpan={3} horizontalAlignment="center"
                        buttonOptions={saveButtonOptions}
                    />
                </Form>
            </form>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.data.token,
        currentLanguage: state.setting.currentLanguage,
        languages: state.setting.languages,
        currentWebsite: state.website.data.currentWebsite,
        websiteLanguages: state.website.data.languages
    };
};

RecruitmentDetailContainer.propTypes = {
    accessToken: PropTypes.string,
    languages: PropTypes.array,
    currentLanguage: PropTypes.string,
    websiteId: PropTypes.string,
    websiteLanguages: PropTypes.array,
    currentWebsite: PropTypes.string
};

export default connect(mapStateToProps, null)(RecruitmentDetailContainer);
