import { connect } from "react-redux";
import DataGrid, {
  Editing,
  Column,
  Button,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import CustomStore from "devextreme/data/custom_store";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import { useHistory } from "react-router-dom";
import notify from "devextreme/ui/notify";
// import { Popup } from "devextreme-react/popup";
// import Form, { ButtonItem, SimpleItem, Label } from "devextreme-react/form";
import { FILE_URL, WEBSITE_URL } from "../../utils/constants";
// import { NewsVM } from "../../model/News";
import { parseJSON } from "../../hook/useJSON";
import { NewsVM } from "../../model/News";

loadMessages(dictionary);
const pageSizes = [10, 25, 50, 100];

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
const searchParams = { page: 1, pageSize: 20 };

const ExpertQAContainer = ({
  accessToken,
  languages,
  currentLanguage,
  currentWebsite,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const dataGridRef = useRef(null);
  const URL = `${WEBSITE_URL}QAExperts/${currentWebsite}`;
  // const [showDeclinePopup, setShowDeclinePopup] = useState(false);
  // const [selectNewsId, setSelectNewsId] = useState(null);
  // const [declineForm, setDeclineForm] = useState({ declineReason: "" });
  const [keyword, setKeyword] = useState("");
  const [isAnswer, setIsAnswer] = useState("");

  const loadRequest = (url, loadOptions) => {
    const _page =
      isNotEmpty(loadOptions.skip) && isNotEmpty(loadOptions.take)
        ? loadOptions.skip / loadOptions.take + 1
        : searchParams.page;
    const _pageSize = isNotEmpty(loadOptions.take)
      ? loadOptions.take
      : searchParams.pageSize;

    return fetch(
      `${url}?${
        keyword ? `keyword=${keyword}&` : ""
      }isAnswer=${isAnswer}&page=${_page}&pageSize=${_pageSize}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
          "Accept-Language": currentLanguage,
        },
      }
    ).then((result) =>
      result
        .json()
        .then((res) => {
          if (res.code === 1) {
            return {
              data: res.data.map(
                (item) => item
                // NewsVM(data, languages, currentLanguage)
              ),
              totalCount: res.totalRows,
            };
          }
          throw res.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const sendRequest = (url, method, data) => {
    method = method || "GET";
    data = data || {};

    if (method === "GET") {
      return fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
          "Accept-Language": currentLanguage,
        },
      }).then((result) =>
        result
          .json()
          .then((json) => {
            if (result.ok)
              return json.data.map((data) =>
                NewsVM(data, languages, currentLanguage)
              );
            throw json.Message;
          })
          .catch(() => {
            if (result.status === 401) {
              history.push("/login");
            }
          })
      );
    }

    return fetch(url, {
      method: method,
      body: JSON.stringify(data.values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          notify(
            {
              message: JSON.parse(text).message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return text && { ...data.values, id: JSON.parse(text).data };
        });
      } else {
        return result.json().then((json) => {
          throw json.Message;
        });
      }
    });
  };

  const newsData = new CustomStore({
    key: "id",
    load: (loadOptions) => loadRequest(`${URL}`, loadOptions),
    insert: (values) =>
      sendRequest(`${URL}`, "POST", {
        values: values,
      }),
    update: (key, values) =>
      sendRequest(`${URL}/${key}`, "PUT", {
        values: values,
      }),
    remove: (key) =>
      sendRequest(`${URL}/${key}`, "DELETE", {
        key: key,
      }),
  });

  const gotoDetailPage = (questionId) => {
    history.push(`/expert-question-and-answer/${questionId}`);
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            // Implement custom save logic here
            history.push("/news/add");
          },
        };
      }
    });

    toolbarItems.push(
      {
        location: "before",
        widget: "dxTextBox",
        options: {
          placeholder: "Nhập từ khóa tìm kiếm",
          value: keyword,
          width: 200,
          onValueChanged: (e) => setKeyword(e.value),
        },
      },
      {
        location: "before",
        widget: "dxSelectBox",
        options: {
          placeholder: "Chọn lọc câu hỏi",
          width: 200,
          value: isAnswer,
          displayExpr: "Name",
          valueExpr: "ID",
          dataSource: [
            {
              ID: "",
              Name: "Chọn lọc câu hỏi",
            },
            {
              ID: true,
              Name: "Đã trả lời",
            },
            {
              ID: false,
              Name: "Chưa trả lời",
            },
          ],
          onValueChanged: (e) => {
            setIsAnswer(e.value);
          },
        },
      },
      {
        location: "center",
        text: "Danh sách Câu hỏi",
      },
      {
        location: "before",
        widget: "dxButton",
        options: {
          icon: "refresh",
          onClick: () => dataGridRef.current.instance.refresh(),
        },
      }
    );
  };

  // const approveRequest = (newsId, data) => {
  //   if (showDeclinePopup) {
  //     setShowDeclinePopup(false);
  //   }

  //   fetch(`${WEBSITE_URL}News/status/${currentWebsite}/${newsId}`, {
  //     method: "PUT",
  //     body: JSON.stringify(data),
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${accessToken}`,
  //       "Accept-Language": currentLanguage,
  //     },
  //   }).then((result) => {
  //     if (result.ok) {
  //       newsData.load().then(() => {
  //         dataGridRef.current.instance.refresh();
  //       });
  //       return result.text().then((text) => {
  //         notify(
  //           {
  //             message: JSON.parse(text).message,
  //             position: {
  //               my: "center top",
  //               at: "center top",
  //             },
  //           },
  //           "success",
  //           3000
  //         );
  //       });
  //     } else {
  //       return result.json().then((json) => {
  //         notify(
  //           {
  //             message: json.message,
  //             position: {
  //               my: "center top",
  //               at: "center top",
  //             },
  //           },
  //           "error",
  //           3000
  //         );
  //         throw json.Message;
  //       });
  //     }
  //   });
  // };

  const addMenuItems = (e) => {
    if (e.target === "content") {
      // e.items can be undefined
      if (!e.items) e.items = [];

      // Add a custom menu item
      e.items.push(
        {
          text: "Chi tiết Tin tức",
          icon: "info",
          onItemClick: () => {
            history.push(`/expert-question-and-answer/${e.row.key}`);
          },
        }
        // {
        //   text: "Phê duyệt",
        //   icon: "check",
        //   disabled: e.row.data.approvedStatus !== 1,
        //   onItemClick: () => {
        //     approveRequest(e.row.key, {
        //       status: 2,
        //       declineReason: "string",
        //     });
        //   },
        // },
        // {
        //   text: "Từ chối",
        //   icon: "close",
        //   disabled: e.row.data.approvedStatus !== 1,
        //   onItemClick: () => {
        //     setSelectNewsId(e.row.key);
        //     setDeclineForm({ declineReason: "" });
        //     setShowDeclinePopup(true);
        //   },
        // }
      );
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   approveRequest(selectNewsId, {
  //     status: 3,
  //     declineReason: declineForm.declineReason,
  //   });
  // };

  // const buttonOptions = {
  //   text: "Gửi",
  //   icon: "email",
  //   type: "default",
  //   stylingMode: "contained",
  //   useSubmitBehavior: true,
  // };

  const imgCellRender = (data) => {
    return (
      <img
        alt="GHM Image"
        src={`${FILE_URL}/${data.value}`}
        style={{ height: 50 }}
      />
    );
  };

  const jsonCellRender = (data) => {
    return parseJSON(data.value, currentLanguage);
  };

  const onRowDblClick = (e) => {
    history.push(`/expert-question-and-answer/${e.key}`);
  };

  return (
    <React.Fragment>
      <DataGrid
        ref={dataGridRef}
        keyExpr="id"
        dataSource={newsData}
        allowColumnReordering={true}
        remoteOperations={true}
        selection={{ mode: "single" }}
        showBorders={true}
        onRowDblClick={onRowDblClick}
        onContextMenuPreparing={addMenuItems}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Editing
          mode="row"
          useIcons={true}
          // allowAdding={true}
          allowDeleting={true}
        />
        <Column
          dataField="fullName"
          dataType="string"
          caption={formatMessage("name")}
        />
        <Column
          dataField="email"
          dataType="string"
          caption={formatMessage("email")}
        />
        <Column
          dataField="phoneNumber"
          dataType="string"
          caption={formatMessage("phoneNumber")}
        />
        <Column
          dataField="serviceName"
          dataType="string"
          cellRender={jsonCellRender}
          caption={formatMessage("serviceName")}
        />
        <Column
          dataField="image"
          dataType="string"
          cellRender={imgCellRender}
          // caption={formatMessage("image")}
        />
        <Column
          dataField="title"
          dataType="string"
          caption={formatMessage("title")}
        />
        <Column
          dataField="content"
          dataType="string"
          caption={formatMessage("content")}
        />
        <Column
          dataField="answer"
          dataType="string"
          caption={formatMessage("answer")}
        />
        <Column
          dataField="seoLink"
          dataType="string"
          caption={formatMessage("seoLink")}
        />

        {/* <Column
          dataField="categoriesNewsName"
          dataType="string"
          caption={formatMessage("category")}
        />
        <Column
          dataField="viewCount"
          dataType="number"
          caption={formatMessage("viewCount")}
        />
        <Column
          dataField="description"
          dataType="string"
          caption={formatMessage("description")}
        />

        <Column
          dataField="creatorFullName"
          dataType="string"
          caption={formatMessage("creatorFullName")}
        />
        <Column
          dataField="approvedStatusName"
          dataType="string"
          caption={formatMessage("approvedStatus")}
        />

        <Column
          dataField="isMobile"
          dataType="boolean"
          caption={formatMessage("isMobile")}
        />
        <Column
          dataField="isActive"
          dataType="boolean"
          caption={formatMessage("isActive")}
        />
        <Column
          dataField="isHomePage"
          dataType="boolean"
          caption={formatMessage("isHomePage")}
        />
        <Column
          dataField="isHot"
          dataType="boolean"
          caption={formatMessage("isHot")}
        /> */}

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => gotoDetailPage(e.row.key)}
          />
        </Column>

        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
      </DataGrid>

      {/* <Popup
        visible={showDeclinePopup}
        onHiding={() => setShowDeclinePopup(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        title="Lý do từ chối"
        container=".dx-viewport"
        width={400}
        height={200}
      >
        <form action="your-action" onSubmit={handleSubmit}>
          <Form
            formData={declineForm}
            readOnly={false}
            showColonAfterLabel={true}
            showValidationSummary={true}
          >
            <SimpleItem dataField={"declineReason"} editorType="dxTextArea">
              <Label text={"Lý do: "} />
            </SimpleItem>

            <ButtonItem
              horizontalAlignment="center"
              buttonOptions={buttonOptions}
            />
          </Form>
        </form>
      </Popup> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

ExpertQAContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
  currentWebsiteLanguage: PropTypes.string,
};

export default connect(mapStateToProps, null)(ExpertQAContainer);
