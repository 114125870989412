import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Form, {
  ButtonItem,
  SimpleItem,
  GroupItem,
  Label,
  TabbedItem,
  TabPanelOptions,
  Tab,
  EmptyItem,
  RequiredRule,
} from "devextreme-react/form";
import "devextreme-react/text-area";
import notify from "devextreme/ui/notify";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import useJSON from "../../hook/useJSON";

import "devextreme-react/html-editor";
import { WEBSITE_URL } from "../../utils/constants";
import useWindowDimensions from "../../hook/useWindowDimensions";
import FileSelectPopup from "../../components/file/FileSelectPopup";

loadMessages(dictionary);

const tabsMock = [
  {
    id: 0,
    text: "vi-VN",
    icon: "globe",
    content: "Vietnamese",
  },
];

const ServiceVM = (data, languages) => {
  const returnData = useJSON(data, languages);

  return returnData;
};

const ServiceMV = (data, languages) => {
  const titleObj = {};
  const nameObj = {};
  const descriptionObj = {};
  const contentObj = {};

  languages.forEach((language) => {
    titleObj[[language.languageId]] = data[`title-${language.languageId}`];
    nameObj[[language.languageId]] =
      data[`name-${language.languageId}`];
    contentObj[[language.languageId]] = data[`content-${language.languageId}`];
    descriptionObj[[language.languageId]] =
      data[`description-${language.languageId}`];
  });

  return JSON.stringify({
    title: JSON.stringify(titleObj),
    name: JSON.stringify(nameObj),
    description: JSON.stringify(descriptionObj),
    content: JSON.stringify(contentObj),
    seoLink: data.seoLink,
    order: data.order ?? 0,
    isActive: data.isActive,
    value: data.value,
    concurrencyStamp: data.concurrencyStamp,
    thumbnail: data.thumbnail,
    alt: data.alt,
  });
};

const GroupServiceDetail = ({
  accessToken,
  currentLanguage,
  currentWebsite,
  websiteLanguages,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const params = useParams();
  const [service, setService] = useState({});
  const [tabs, setTabs] = useState(tabsMock);
  const { height } = useWindowDimensions();

  useEffect(() => {
    const _tabs = websiteLanguages.map((item, index) => ({
      id: index,
      languageId: item.languageId,
      text: item.name,
      icon: "globe",
      content: "User tab content",
    }));
    setTabs(_tabs);
  }, [websiteLanguages]);

  useEffect(() => {
    console.log(params)
    if (!currentWebsite || !params.id || params.id === 'add') {
      return;
    }
    fetch(`${WEBSITE_URL}listCategorys/${currentWebsite}/2/${params.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setService(ServiceVM(json.data, websiteLanguages));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [
    currentWebsite,
    websiteLanguages,
    params.id,
    accessToken,
    history,
  ]);

  const updateService = () => {
    return fetch(`${WEBSITE_URL}listCategorys/${currentWebsite}/2/${service.id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: ServiceMV(service, websiteLanguages),
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            history.push("/level");
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const addService = () => {
    return fetch(`${WEBSITE_URL}listCategorys/${currentWebsite}/2`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: ServiceMV(service, websiteLanguages),
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            history.push("/level");
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (service.id) {
      updateService();
    } else {
      addService();
    }
  };

  const saveButtonOptions = {
    text: formatMessage("save"),
    icon: "save",
    type: "default",
    stylingMode: "contained",
    disabled: service.approvedStatus === 1,
    useSubmitBehavior: true,
  };

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.push("/level");
    },
  };

  const updateThumbnail = (e) => {
    setService((prevState) => ({ ...prevState, thumbnail: e }));
  };

  return (
    <div
      className="form-container"
      style={{ overflow: "auto", height: height - 56, paddingBottom: 20 }}
    >
      <Toolbar>
        <Item location="before" widget="dxButton" options={backButtonOptions} />
        <Item
          location="center"
          text={`${formatMessage("info")} ${formatMessage(
            "of"
          )} ${formatMessage("service")}`}
        />
      </Toolbar>
      <form action="your-action" onSubmit={handleSubmit}>
        <Form
          formData={service}
          readOnly={false}
          showColonAfterLabel={true}
          colCount={3}
          showValidationSummary={true}
          validationGroup="customerData"
        >
          <GroupItem caption="Nội dung cấp bậc" colSpan={2}>
            <GroupItem caption="Đa ngôn ngữ">
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                {tabs.map((item) => {
                  return (
                    <Tab key={item.id} title={item.text} icon={item.icon}>
                      <SimpleItem
                        dataField={`name-${item.languageId}`}
                        editorType="dxTextArea"
                        editorOptions={{
                          autoResizeEnabled: true,
                        }}
                      >
                        <Label text={formatMessage("name")} />
                        <RequiredRule
                          message={`${formatMessage("name")} ${formatMessage(
                            item.languageId
                          )} ${formatMessage("errMesRequire")}`}
                        />
                      </SimpleItem>

                      <SimpleItem
                        dataField={`title-${item.languageId}`}
                        editorType="dxTextBox"
                      >
                        <Label text={formatMessage("title")} />
                      </SimpleItem>

                      <SimpleItem
                        dataField={`content-${item.languageId}`}
                        editorType="dxTextBox"
                      >
                        <Label text={formatMessage("content")} />

                      </SimpleItem>

                      <SimpleItem
                        dataField={`description-${item.languageId}`}
                        editorType="dxTextBox"
                      >
                        <Label text={formatMessage("description")} />

                      </SimpleItem>
                    </Tab>
                  );
                })}
              </TabbedItem>
            </GroupItem>
          </GroupItem>

          <GroupItem caption="SEO" colSpan={1}>

            <GroupItem caption="Nội dung khác">

              <SimpleItem dataField="seoLink" editorType="dxTextBox">
                <Label text={formatMessage("seoLink")} />
                <RequiredRule
                  message={`${formatMessage("seoLink")} ${formatMessage(
                    "errMesRequire"
                  )}`}
                />
              </SimpleItem>

              <SimpleItem dataField="order" editorType="dxNumberBox">
                <Label text={formatMessage("order")} />
                <RequiredRule
                  message={`${formatMessage("order")}`}
                />
              </SimpleItem>

              <SimpleItem dataField="isActive" editorType="dxCheckBox">
                <Label text={formatMessage("isActive")} />
              </SimpleItem>

              <SimpleItem>
                <Label text={formatMessage("thumbnail")} />
                <FileSelectPopup
                  value={service.thumbnail}
                  imgHeight={100}
                  onValueChanged={updateThumbnail}
                />
              </SimpleItem>
              <SimpleItem dataField={"alt"} editorType="dxTextBox" colSpan={2}>
                <Label text={formatMessage("alt")} />
              </SimpleItem>
            </GroupItem>
          </GroupItem>

          <EmptyItem />
          <GroupItem horizontalAlignment="center" colSpan={3}>
            <ButtonItem
              horizontalAlignment="center"
              buttonOptions={saveButtonOptions}
            />
          </GroupItem>
        </Form>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

GroupServiceDetail.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
};

export default connect(mapStateToProps, null)(GroupServiceDetail);
