import React from 'react';
import { connect } from 'react-redux';
import Content from './routes/Content';
import UnAuthenticatedContent from './routes/UnAuthenticatedContent';
import PropsType from 'prop-types';
import { onMessageListener } from './firebase-config';
import { Toast } from 'devextreme-react/toast';

function App({ isAuthenticated }) {
    const [toastConfig, setToastConfig] = React.useState({
        isVisible: false,
        type: 'success',
        message: '',
    });
    onMessageListener()
        .then((payload) => {
            console.log(payload)
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'success',
                message: payload.data.title,
            });
        })
        .catch((err) => console.log('failed: ', err));

    function onHiding() {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }

    if (isAuthenticated) {
        return <>
            <Content />
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={10000}
                width={500}
                position={'bottom right'}
            />
        </>;
    }

    return <UnAuthenticatedContent />;
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.data.isAuthenticated
    };
};

App.propTypes = {
    isAuthenticated: PropsType.bool
};

export default connect(mapStateToProps, null)(App);
