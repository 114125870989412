import React from 'react';
import { Chart, Series } from 'devextreme-react/chart';
import { pageViews } from '../../data';

const SeriesChart = () => {
    return (
        <Chart id="chart" dataSource={pageViews}>
            <Series
                valueField="views"
                argumentField="month"
                name="Page View"
                type="bar"
                color="#ffaa66" />
        </Chart>
    );
};

export default SeriesChart;
