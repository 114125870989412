const dictionary = {
  en: {
    address: "Address",
    alt: "Alt",
    answer: "Answer",
    approve: "Approve",
    approvedStatus: "Approved Status",
    appointmentDate: "Appointment Date",
    area: "Area",
    alphabet: "Alphabet",

    brand: "Brand",
    background: "Background",

    content: "Content",
    category: "Category",
    code: "Code",
    creatorFullName: "Creator Full Name",
    customerName: "Customer Name",
    customerEmail: "Customer Email",
    customerPhone: "Customer Phone",
    categorySick: "Category Sick",

    description: "Description",
    draft: "Draft",

    email: "Email",
    excerpt: "Excerpt",

    faqGroup: "FAQ Group",
    faq: "Faq",
    floor: "Floor",

    group: "Group",
    googleMap: "Google Map",

    hotline: "Hotline",

    isDefault: "is Default",
    isActive: "is Active",
    image: "Image",
    instruction: "Instruction",
    isMobile: "Mobile",
    isHomePage: "Home Page",
    isHot: "Hot",
    icon: "Icon",
    info: "Info",
    isPrioritize: "is Prioritize",

    key: "Key",

    value: "Value",

    meta: "Meta",
    metaTitle: "Meta Title",
    metaKeyword: "Meta Keyword",
    metaDescription: "Meta Description",
    mobile: "Mobile",

    news: "News",

    link: "Link",
    logo: "Logo",
    lang: "Language",
    languagesList: "Languages List",

    order: "Order",
    of: "Of",

    page: "Page",
    position: "Position",
    price: "Price",
    parent: "Parent",
    phoneNumber: "Phone Number",

    question: "Question",

    name: "Name",
    note: "Note",

    title: "Title",
    thumbnail: "Thumbnail",
    type: "Type",

    slug: "Slug",
    save: "Save",
    salePrice: "Sale Price",
    sku: "SKU",
    selectFile: "Select File",
    seoLink: "SEO link",
    subTitle: "Sub Title",

    updatedAt: "updated at",
    url: "URL",

    viewCount: "View Count",
    videoLinkId: "Video LinkId",

    website: "Website",
    workTime: "Work Time",
    bank: "Bank Account",
  },
  vi: {
    address: "Địa chỉ",
    alt: "Alt",
    answer: "Câu trả lời",
    approve: "Duyệt",
    approvedStatus: "Trạng thái phê duyệt",
    appointmentDate: "Ngày hẹn",
    area: "Khu vực",
    alphabet: "Chữ cái",

    brand: "Thương hiệu",
    background: "Background",

    category: "Danh mục",
    content: "Nội dung",
    code: "Mã",
    creatorFullName: "Người lập",
    customerName: "Tên khách hàng",
    customerEmail: "Email khách hàng",
    customerPhone: "SĐT KH",
    categorySick: "Danh mục bệnh",

    description: "Mô tả",
    draft: "Nháp",

    email: "Email",
    excerpt: "Trích đoạn",

    faqGroup: "Nhóm FAQ",
    faq: "Faq",
    floor: "Tầng",

    group: "Nhóm",
    googleMap: "Google Map",

    hotline: "Hotline",
    serviceName: "Chuyên Khoa",

    isDefault: "Mặc định",
    image: "Ảnh",
    isActive: "Kích hoạt",
    instruction: "Chỉ dẫn",
    isMobile: "Điện thoại",
    isHomePage: "Trang chủ",
    isHot: "Hot",
    icon: "Icon",
    info: "Thông tin",
    isPrioritize: "Ưu tiên",

    key: "Từ khóa",

    value: "Giá trị",

    meta: "Meta",
    metaTitle: "Meta Title",
    metaKeyword: "Meta Keyword",
    metaDescription: "Meta Description",
    mobile: "Mobile",

    news: "Tin tức",

    link: "Link",
    logo: "Logo",
    lang: "Ngôn ngữ",
    languagesList: "DS Ngôn ngữ",

    order: "Thứ tự",
    of: "",

    page: "Trang",
    position: "Vị trí",
    price: "Giá",
    parent: "Cha",
    phoneNumber: "Số điện thoại",

    question: "Câu hỏi",

    name: "Tên",
    note: "Ghi chú",

    title: "Tiêu đề",
    thumbnail: "Thumbnail",
    type: "Loại",

    slug: "Slug",
    save: "Lưu",
    salePrice: "Giá Sale",
    sku: "SKU",
    selectFile: "Chọn File",
    seoLink: "SEO link",
    subTitle: "Tiêu đề phụ",

    updatedAt: "Cập nhật",
    url: "URL",

    viewCount: "Lượt xem",
    videoLinkId: "Video LinkId",

    website: "Website",
    workTime: "Thời gian làm việc",
    bank: "Tài khoản ngân hàng",
  },
};

export default dictionary;
