const parseJSON = (str, languageId) => {
  let _strObj;
  try {
    _strObj = JSON.parse(str);
  } catch (e) {
    return str || "";
  }
  return _strObj ? _strObj[languageId] || "" : "";
};

const FAQVM = (data, languages) => {
  const returnData = Object.assign({}, data);

  languages.forEach((element) => {
    returnData[`question-${element.languageId}`] = parseJSON(
      data.question,
      element.languageId
    );
    returnData[`answer-${element.languageId}`] = parseJSON(
      data.answer,
      element.languageId
    );
    returnData[`faqGroupName-${element.languageId}`] = parseJSON(
      data.faqGroupName,
      element.languageId
    );
  });

  return returnData;
};

const FAQGroupVM = (data, languages) => {
  const returnData = Object.assign({}, data);

  languages.forEach((element) => {
    returnData[`name-${element.languageId}`] = parseJSON(
      data.name,
      element.languageId
    );
    returnData[`description-${element.languageId}`] = parseJSON(
      data.description,
      element.languageId
    );
  });

  return returnData;
};

export { FAQVM, FAQGroupVM };
