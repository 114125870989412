import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Form, {
  ButtonItem,
  SimpleItem,
  GroupItem,
  Label,
  TabbedItem,
  TabPanelOptions,
  Tab,
  EmptyItem,
  RequiredRule,
} from "devextreme-react/form";
import "devextreme-react/text-area";
import notify from "devextreme/ui/notify";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import useJSON from "../../hook/useJSON";
import FileSelectPopup from "../../components/file/FileSelectPopup";

import "devextreme-react/html-editor";
import { HTML_OPTIONS, WEBSITE_URL, WEBSITE_CLIENT_URL } from "../../utils/constants";
import WebsiteServiceImagesList from "../../components/website/service/WebsiteServiceImagesList";
import useWindowDimensions from "../../hook/useWindowDimensions";

loadMessages(dictionary);

const tabsMock = [
  {
    id: 0,
    text: "vi-VN",
    icon: "globe",
    content: "Vietnamese",
  },
];

const tagsEditorOptions = { acceptCustomValue: true };

const ServiceVM = (data, languages) => {
  const returnData = useJSON(data, languages);

  return returnData;
};

const ServiceMV = (data, languages) => {
  const titleObj = {};
  const subTitleObj = {};
  const metaTitleObj = {};
  const descriptionObj = {};
  const metaDescriptionObj = {};
  const metaKeywordObj = {};
  const contentObj = {};

  languages.forEach((language) => {
    titleObj[[language.languageId]] = data[`title-${language.languageId}`];
    subTitleObj[[language.languageId]] =
      data[`subTitle-${language.languageId}`];
    metaTitleObj[[language.languageId]] =
      data[`metaTitle-${language.languageId}`];
    descriptionObj[[language.languageId]] =
      data[`description-${language.languageId}`];
    metaDescriptionObj[[language.languageId]] =
      data[`metaDescription-${language.languageId}`];
    metaKeywordObj[[language.languageId]] =
      data[`metaKeyword-${language.languageId}`];
    contentObj[[language.languageId]] = data[`content-${language.languageId}`];
  });
  const _tags = data.tags || [];

  return JSON.stringify({
    title: JSON.stringify(titleObj),
    subTitle: JSON.stringify(subTitleObj),
    metaTitle: JSON.stringify(metaTitleObj),
    description: JSON.stringify(descriptionObj),
    metaDescription: JSON.stringify(metaDescriptionObj),
    metaKeyword: JSON.stringify(metaKeywordObj),
    content: JSON.stringify(contentObj),
    seoLink: data.seoLink,
    image: data.image,
    alt: data.alt,
    unitPrice: data.unitPrice,
    referenceId: data.referenceId,
    videoUrl: data.videoUrl,
    thumbnail: data.thumbnail,
    isHomePage: data.isHomePage,
    isHot: data.isHot,
    order: data.order,
    isActive: data.isActive,
    tags: _tags.map((x) => {
      return { name: x };
    }),
    concurrencyStamp: data.concurrencyStamp,
  });
};

const WebsiteServiceDetailContainer = ({
  accessToken,
  currentLanguage,
  currentWebsite,
  websiteLanguages,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const params = useParams();
  const [service, setService] = useState({});
  const [tabs, setTabs] = useState(tabsMock);
  const { height } = useWindowDimensions();
  const [references, setReferences] = useState([]);

  useEffect(() => {
    fetch(
      `${WEBSITE_CLIENT_URL}Category/get-all-department`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          setReferences(json);
          return;
        }
        throw json.Message;
      })
    );
  }, []);

  useEffect(() => {
    const _tabs = websiteLanguages.map((item, index) => ({
      id: index,
      languageId: item.languageId,
      text: item.name,
      icon: "globe",
      content: "User tab content",
    }));
    setTabs(_tabs);
  }, [websiteLanguages]);

  useEffect(() => {
    if (!currentWebsite || !params.serviceId) {
      return;
    }
    fetch(`${WEBSITE_URL}Services/${currentWebsite}/${params.serviceId}?type=0`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setService(ServiceVM(json.data, websiteLanguages));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [
    currentWebsite,
    websiteLanguages,
    params.serviceId,
    accessToken,
    history,
  ]);

  const updateService = () => {
    return fetch(`${WEBSITE_URL}Services/${currentWebsite}/${service.id}?type=0`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: ServiceMV(service, websiteLanguages),
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            history.push("/specialization");
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const addService = () => {
    return fetch(`${WEBSITE_URL}Services/${currentWebsite}?type=0`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: ServiceMV(service, websiteLanguages),
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            history.push("/specialization");
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (params.serviceId) {
      updateService();
    } else {
      addService();
    }
  };

  const saveButtonOptions = {
    text: formatMessage("save"),
    icon: "save",
    type: "default",
    stylingMode: "contained",
    disabled: service.approvedStatus === 1,
    useSubmitBehavior: true,
  };

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.push("/specialization");
    },
  };

  const updateImage = (e) => {
    setService((prevState) => ({ ...prevState, image: e }));
  };

  const updateThumbnail = (e) => {
    setService((prevState) => ({ ...prevState, thumbnail: e }));
  };

  const htmlOptions = HTML_OPTIONS;
  const contentHtmlOptions = { ...HTML_OPTIONS, height: 350 };

  const referenceSelectOptions = {
    items: references,
    displayExpr: "departmentName",
    searchEnabled: true,
    valueExpr: "departmentId",
  };

  return (
    <div
      className="form-container"
      style={{ overflow: "auto", height: height - 56, paddingBottom: 20 }}
    >
      <Toolbar>
        <Item location="before" widget="dxButton" options={backButtonOptions} />
        <Item
          location="center"
          text={`${formatMessage("info")} ${formatMessage(
            "of"
          )} ${formatMessage("service")}`}
        />
      </Toolbar>
      <form action="your-action" onSubmit={handleSubmit}>
        <Form
          formData={service}
          readOnly={false}
          showColonAfterLabel={true}
          colCount={3}
          showValidationSummary={true}
          validationGroup="customerData"
        >
          <GroupItem caption="Nội dung Dịch vụ" colSpan={2}>
            <GroupItem caption="Đa ngôn ngữ">
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                {tabs.map((item) => {
                  return (
                    <Tab key={item.id} title={item.text} icon={item.icon}>
                      <SimpleItem
                        dataField={`title-${item.languageId}`}
                        editorType="dxTextBox"
                      >
                        <Label text={formatMessage("title")} />
                        <RequiredRule
                          message={`${formatMessage("title")} ${formatMessage(
                            item.languageId
                          )} ${formatMessage("errMesRequire")}`}
                        />
                      </SimpleItem>

                      <SimpleItem
                        dataField={`subTitle-${item.languageId}`}
                        editorType="dxTextArea"
                        editorOptions={{
                          autoResizeEnabled: true,
                        }}
                      >
                        <Label text={formatMessage("subTitle")} />
                      </SimpleItem>

                      <SimpleItem
                        dataField={`description-${item.languageId}`}
                        editorType="dxHtmlEditor"
                        editorOptions={htmlOptions}
                      >
                        <Label text={formatMessage("description")} />
                        <RequiredRule
                          message={`${formatMessage(
                            "description"
                          )} ${formatMessage(item.languageId)} ${formatMessage(
                            "errMesRequire"
                          )}`}
                        />
                      </SimpleItem>

                      <SimpleItem
                        dataField={`content-${item.languageId}`}
                        editorType="dxHtmlEditor"
                        editorOptions={contentHtmlOptions}
                      >
                        <Label text={formatMessage("content")} />
                      </SimpleItem>
                    </Tab>
                  );
                })}
              </TabbedItem>
            </GroupItem>
          </GroupItem>

          <GroupItem caption="SEO" colSpan={1}>
            <GroupItem caption="SEO Content">
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                {tabs.map((item) => {
                  return (
                    <Tab key={item.id} title={item.text} icon={item.icon}>
                      <SimpleItem
                        dataField={`metaTitle-${item.languageId}`}
                        editorType="dxTextArea"
                      >
                        <Label text={formatMessage("metaTitle")} />
                      </SimpleItem>
                      <SimpleItem
                        dataField={`metaKeyword-${item.languageId}`}
                        editorType="dxTextArea"
                      >
                        <Label text={formatMessage("metaKeyword")} />
                      </SimpleItem>
                      <SimpleItem
                        dataField={`metaDescription-${item.languageId}`}
                        editorType="dxTextArea"
                      >
                        <Label text={formatMessage("metaDescription")} />
                      </SimpleItem>
                    </Tab>
                  );
                })}
              </TabbedItem>
            </GroupItem>

            <GroupItem caption="Ảnh" colCount={2}>
              <SimpleItem>
                <Label text={formatMessage("image")} />
                <FileSelectPopup
                  value={service.image}
                  imgHeight={100}
                  onValueChanged={updateImage}
                />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem>
                <Label text={formatMessage("thumbnail")} />
                <FileSelectPopup
                  value={service.thumbnail}
                  imgHeight={100}
                  onValueChanged={updateThumbnail}
                />
              </SimpleItem>
              <SimpleItem dataField={"alt"} editorType="dxTextBox" colSpan={2}>
                <Label text={formatMessage("alt")} />
              </SimpleItem>
            </GroupItem>

            <GroupItem caption="Nội dung khác">
              <SimpleItem
                dataField={"tags"}
                editorType="dxTagBox"
                editorOptions={tagsEditorOptions}
              >
                <Label text={"Tags"} />
              </SimpleItem>

              <SimpleItem dataField="seoLink" editorType="dxTextBox">
                <Label text={formatMessage("seoLink")} />
                <RequiredRule
                  message={`${formatMessage("seoLink")} ${formatMessage(
                    "errMesRequire"
                  )}`}
                />
              </SimpleItem>

              <SimpleItem dataField="videoUrl" editorType="dxTextBox">
                <Label text={formatMessage("videoUrl")} />
              </SimpleItem>

              <SimpleItem dataField="order" editorType="dxNumberBox">
                <Label text={formatMessage("order")} />
              </SimpleItem>

              <SimpleItem dataField="isActive" editorType="dxCheckBox">
                <Label text={formatMessage("isActive")} />
              </SimpleItem>

              <SimpleItem dataField="isHomePage" editorType="dxCheckBox">
                <Label text={formatMessage("isHomePage")} />
              </SimpleItem>

              <SimpleItem dataField="isHot" editorType="dxCheckBox">
                <Label text={formatMessage("isHot")} />
              </SimpleItem>

              <SimpleItem
                dataField="referenceId"
                editorType="dxSelectBox"
                editorOptions={referenceSelectOptions}
              >
                <Label text={formatMessage("referenceId ")} />
              </SimpleItem>

              <SimpleItem dataField="unitPrice" editorType="dxTextBox">
                <Label text={formatMessage("unitPrice ")} />
              </SimpleItem>
            </GroupItem>
          </GroupItem>

          <EmptyItem />
          <GroupItem horizontalAlignment="center" colSpan={3}>
            <ButtonItem
              horizontalAlignment="center"
              buttonOptions={saveButtonOptions}
            />
          </GroupItem>
        </Form>
      </form>

      <hr />
      {params.serviceId && (
        <WebsiteServiceImagesList
          serviceId={params.serviceId}
          accessToken={accessToken}
          languages={websiteLanguages}
          currentWebsite={currentWebsite}
          currentLanguage={currentLanguage}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

WebsiteServiceDetailContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
};

export default connect(mapStateToProps, null)(WebsiteServiceDetailContainer);
