import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TreeList, Column, Paging, Selection, Editing, Pager, Button } from 'devextreme-react/tree-list';
import { connect } from 'react-redux';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import dictionary from '../../data/dictionary';
import { useHistory } from 'react-router-dom';
import { FILE_URL, WEBSITE_URL } from '../../utils/constants';

loadMessages(dictionary);

const parseJSON = (str, languageId) => {
    let _strObj;
    try {
        _strObj = JSON.parse(str);
    } catch (e) {
        return '';
    }
    return _strObj ? _strObj[languageId] || '' : '';
};

const CategoryVM = (data, languages) => {
    const returnData = Object.assign({}, data);

    languages.forEach(element => {
        returnData[`name-${element.languageId}`] = parseJSON(data.name, element.languageId);
        returnData[`description-${element.languageId}`] = parseJSON(data.description, element.languageId);
    });

    return returnData;
};

const pageSizes = [10, 20, 50, 100];

const PartnerContainer = ({ accessToken, websiteLanguages, currentLanguage, currentWebsite }) => {
    locale(currentLanguage.substring(0, 2));

    const URL = `${WEBSITE_URL}Partners/${currentWebsite}`;
    const [keyword, setKeyword] = useState('');
    const history = useHistory();

    const loadRequest = (url) => {
        return fetch(`${url}?${keyword ? `keyword=${keyword}&` : ''}&page=1&pageSize=1000`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) {
                    return { data: json.data.map((data) => CategoryVM(data, websiteLanguages)) };
                }
            })
        );
    };

    const deleteRequest = (url) => {
        return fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'Accept-Language': currentLanguage
            }
        })
            .then(result => result.json()
                .then(json => {
                    notify({
                        message: json.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 3000);
                }));
    };

    const onToolbarPreparing = (e) => {
        const toolbarItems = e.toolbarOptions.items;
        // Modifies an existing item
        toolbarItems.forEach(function (item) {
            if (item.name === 'addRowButton') {
                item.options = {
                    icon: 'plus',
                    onClick: function () {
                        // Implement custom save logic here
                        history.push('/partner/add');
                    }
                };
            }
        });

        toolbarItems.unshift(
            {
                location: 'before',
                widget: 'dxTextBox',
                options: {
                    placeholder: 'Nhập từ khóa tìm kiếm',
                    value: keyword,
                    width: 200,
                    onValueChanged: (e) => setKeyword(e.value)
                }
            },
            {
                location: 'center',
                text: 'Danh sách Partners'
            });
    };

    const categoriesSource = new CustomStore({
        key: 'id',
        load: (loadOptions) => loadRequest(`${URL}`, loadOptions),
        remove: (key) => deleteRequest(`${URL}/${key}`)
    });

    const gotoDetailPage = (categoryId) => {
        history.push(`/partner/${categoryId}`);
    };

    const imgCellRender = (data) => {
        return <img alt="GHM Image" src={`${FILE_URL}/${data.value}`} style={{ height: 50 }} />;
    };

    return (
        <React.Fragment>
            <TreeList
                dataSource={categoriesSource}
                autoExpandAll={true}
                showRowLines={true}
                showBorders={true}
                keyExpr="id"
                parentIdExpr="parentId"
                onToolbarPreparing={onToolbarPreparing}
            >
                <Editing
                    allowAdding={true}
                    allowDeleting={true}
                    useIcons={true} />
                <Selection mode="single" />

                {websiteLanguages.map((item, index) => (
                    <Column key={index} dataField={`name-${item.languageId}`}
                        dataType="string" caption={formatMessage('name')}
                        visible={item.languageId === currentLanguage} />
                ))}
                {websiteLanguages.map((item, index) => (
                    <Column key={index} dataField={`description-${item.languageId}`}
                        dataType="string" caption={formatMessage('description')}
                        visible={item.languageId === currentLanguage} />
                ))}
                <Column dataField="thumbnail" cellRender={imgCellRender} />
                <Column dataField="url" dataType="string" caption={formatMessage('url')} />
                <Column dataField="alt" dataType="string" caption={formatMessage('alt')} />
                <Column dataField="order" dataType="number" caption={formatMessage('order')} />
                <Column dataField="isActive" dataType="boolean" caption={formatMessage('isActive')} />

                <Column type="buttons" caption={formatMessage('actionCol')}>
                    <Button name="delete" />
                    <Button hint="Info" icon="info" onClick={(e) => gotoDetailPage(e.row.key)} />
                </Column>

                <Pager visible={true} displayMode={'full'}
                    allowedPageSizes={pageSizes} showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} />
                <Paging defaultPageSize={20} />
            </TreeList>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.data.token,
        currentLanguage: state.setting.currentLanguage,
        languages: state.setting.languages,
        currentWebsite: state.website.data.currentWebsite,
        websiteLanguages: state.website.data.languages
    };
};

PartnerContainer.propTypes = {
    accessToken: PropTypes.string,
    websiteLanguages: PropTypes.array,
    currentLanguage: PropTypes.string,
    currentWebsite: PropTypes.string
};

export default connect(mapStateToProps, null)(PartnerContainer);
