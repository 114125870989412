import Form, {
  SimpleItem,
  GroupItem,
  TabbedItem,
  ButtonItem,
  Label,
  Tab,
  RangeRule,
  RequiredRule,
} from "devextreme-react/form";
import React, { useState, useEffect, useMemo } from "react";
import dictionary from "../../data/dictionary";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { useHistory, useParams } from "react-router-dom";
import FileSelectPopup from "../../components/file/FileSelectPopup";
import "devextreme-react/text-area";
import "devextreme-react/tag-box";
import notify from "devextreme/ui/notify";
import { HTML_OPTIONS, WEBSITE_URL } from "../../utils/constants";
import { JobMV, JobVM } from "../../model/Job";
import useWindowDimensions from "../../hook/useWindowDimensions";

loadMessages(dictionary);

const tabsMock = [
  {
    id: 0,
    text: "vi-VN",
    icon: "globe",
    content: "Vietnamese",
  },
];

const parseJSON = (str, languageId) => {
  let _strObj;
  try {
    _strObj = JSON.parse(str);
  } catch (e) {
    return str || '';
  }
  return _strObj ? _strObj[languageId] || '' : '';
};

const GroupVM = (data, currentLanguage) => {
  return {
    id: data.id,
    name: parseJSON(data.name, currentLanguage)
  };
};

const WebsiteJobDetailContainer = ({
  accessToken,
  currentLanguage,
  currentWebsite,
  websiteLanguages,
}) => {
  locale(currentLanguage.substring(0, 2));
  const [tabs, setTabs] = useState(tabsMock);
  const [job, setJob] = useState({});
  const history = useHistory();
  const params = useParams();
  const { height } = useWindowDimensions();

  const [careerList, setCareerList] = useState([]);
  const [levelList, setLevelList] = useState([]);
  const [formList, setFormList] = useState([]);

  useEffect(() => {
    if (!params.jobId) return;

    fetch(`${WEBSITE_URL}Jobs/${currentWebsite}/${params.jobId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setJob(JobVM(json.data, websiteLanguages));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [accessToken, websiteLanguages, currentWebsite, params.jobId, history]);

  const updateJob = () => {
    const _method = job.id ? "PUT" : "POST";
    const _url = `${WEBSITE_URL}Jobs/${currentWebsite}${job.id ? `/${job.id}` : ""
      }`;

    return fetch(_url, {
      method: _method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
      body: JobMV(job, websiteLanguages),
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            history.push("/job");
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateJob();
  };

  useEffect(() => {
    const _tabs = websiteLanguages.map((item, index) => ({
      id: index,
      languageId: item.languageId,
      text: item.name,
      icon: "globe",
      content: "User tab content",
    }));
    setTabs(_tabs);
  }, [websiteLanguages]);

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.push("/job");
    },
  };

  const updateImage = (e) => {
    setJob((prevState) => ({ ...prevState, image: e }));
  };

  const saveButtonOptions = {
    text: "Update",
    type: "success",
    useSubmitBehavior: true,
  };

  const htmlOptions = HTML_OPTIONS;

  const careerListSelectOptions = useMemo(() => ({
    items: careerList.map((type) => GroupVM(type, currentLanguage)),
    displayExpr: "name",
    searchEnabled: true,
    valueExpr: "id",
  }), [careerList])

  const levelListSelectOptions = useMemo(() => ({
    items: levelList.map((type) => GroupVM(type, currentLanguage)),
    displayExpr: "name",
    searchEnabled: true,
    valueExpr: "id",
  }), [levelList])

  const formListSelectOptions = useMemo(() => ({
    items: formList.map((type) => GroupVM(type, currentLanguage)),
    displayExpr: "name",
    searchEnabled: true,
    valueExpr: "id",
  }), [formList])

  useEffect(() => {
    fetchNganhNghe();
    fetchCapBac();
    fetchHinhThuc();
  }, []);

  const fetchNganhNghe = () => {
    fetch(
      `${WEBSITE_URL}ListCategorys/get-all/${currentWebsite}/1`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          setCareerList(json);
          return;
        }
        throw json.Message;
      })
    );
  }

  const fetchCapBac = () => {
    fetch(
      `${WEBSITE_URL}ListCategorys/get-all/${currentWebsite}/2`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          setLevelList(json);
          return;
        }
        throw json.Message;
      })
    );
  }

  const fetchHinhThuc = () => {
    fetch(
      `${WEBSITE_URL}ListCategorys/get-all/${currentWebsite}/3`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          setFormList(json);
          return;
        }
        throw json.Message;
      })
    );
  }

  return (
    <div
      className="form-container"
      style={{ overflow: "auto", height: height - 56, paddingBottom: 20 }}
    >
      <Toolbar>
        <Item location="before" widget="dxButton" options={backButtonOptions} />
        <Item location="center" text={"Chi tiết vị trí tuyển dụng"} />
      </Toolbar>

      <form action="your-action" onSubmit={handleSubmit}>
        <Form
          formData={job}
          readOnly={false}
          showColonAfterLabel={true}
          colCount={3}
          showValidationSummary={true}
          validationGroup="customerData"
        >
          <GroupItem colSpan={2} caption="Đa ngôn ngữ">
            <TabbedItem>
              {tabs.map((item) => {
                return (
                  <Tab key={item.id} title={item.text} icon={item.icon}>
                    <SimpleItem
                      dataField={`name-${item.languageId}`}
                      editorType="dxTextBox"
                    >
                      <Label text={formatMessage("name")} />
                      <RequiredRule />
                    </SimpleItem>
                    <SimpleItem
                      dataField={`description-${item.languageId}`}
                      editorType="dxHtmlEditor"
                      editorOptions={htmlOptions}
                    >
                      <Label text={formatMessage("description")} />
                    </SimpleItem>
                    <SimpleItem
                      dataField={`requirement-${item.languageId}`}
                      editorType="dxHtmlEditor"
                      editorOptions={htmlOptions}
                    >
                      <Label text={formatMessage("requirement")} />
                    </SimpleItem>
                    <SimpleItem
                      dataField={`benefit-${item.languageId}`}
                      editorType="dxHtmlEditor"
                      editorOptions={htmlOptions}
                    >
                      <Label text={formatMessage("benefit")} />
                    </SimpleItem>
                  </Tab>
                );
              })}
            </TabbedItem>
          </GroupItem>

          <GroupItem caption="Thông tin khác">
            <GroupItem caption="Ảnh Feature" colCount={2}>
              <SimpleItem>
                <Label text={formatMessage("image")} />
                <FileSelectPopup
                  value={job.image}
                  imgHeight={100}
                  onValueChanged={updateImage}
                />
              </SimpleItem>
              <SimpleItem
                dataField={"altImages"}
                editorType="dxTextArea"
                colSpan={2}
              >
                <Label text={formatMessage("alt")} />
              </SimpleItem>
            </GroupItem>

            <SimpleItem dataField={"seoLink"} editorType="dxTextBox">
              <Label text={formatMessage("seoLink")} />
              <RequiredRule
                message={`${formatMessage("seoLink")} ${formatMessage(
                  "errMesRequire"
                )}`}
              />
            </SimpleItem>

            <SimpleItem
              dataField="salaryRange"
              editorType="dxTextBox"
              colSpan={3}
            >
              <Label text={formatMessage("salaryRange")} />
              <RequiredRule
                message={`${formatMessage("salaryRange")} ${formatMessage(
                  "errMesRequire"
                )}`}
              />
            </SimpleItem>

            <SimpleItem dataField={"quantity"} editorType="dxNumberBox">
              <Label text={formatMessage("quantity")} />
              <RangeRule min={0} message={"Thứ tự phải đúng định dạng"} />
            </SimpleItem>

            <SimpleItem
              dataField="industryId"
              editorType="dxSelectBox"
              editorOptions={careerListSelectOptions}
            >
              <Label text={'Ngành nghề'} />
            </SimpleItem>

            <SimpleItem
              dataField="levelId"
              editorType="dxSelectBox"
              editorOptions={levelListSelectOptions}
            >
              <Label text={'Cấp bậc'} />
            </SimpleItem>

            <SimpleItem
              dataField={`experience`}
              editorType="dxTextBox"
            >
              <Label text={"Kinh nghiệm"} />
            </SimpleItem>

            <SimpleItem
              dataField="formId"
              editorType="dxSelectBox"
              editorOptions={formListSelectOptions}
            >
              <Label text={'Hình thức '} />
            </SimpleItem>


            <SimpleItem dataField={"order"} editorType="dxNumberBox">
              <Label text={formatMessage("order")} />
              <RangeRule min={0} message={"Thứ tự phải đúng định dạng"} />
            </SimpleItem>
            <SimpleItem dataField={"isActive"} editorType="dxCheckBox">
              <Label text={"Kích hoạt"} />
            </SimpleItem>
          </GroupItem>

          <ButtonItem
            colSpan={3}
            horizontalAlignment="center"
            buttonOptions={saveButtonOptions}
          />
        </Form>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

WebsiteJobDetailContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
};

export default connect(mapStateToProps, null)(WebsiteJobDetailContainer);
