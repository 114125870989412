import { connect } from "react-redux";
import DataGrid, {
  Editing,
  Column,
  Button,
  Pager,
  Paging,
  Grouping,
} from "devextreme-react/data-grid";
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import { FILE_URL, WEBSITE_URL } from "../utils/constants";
import useWindowDimensions from "../hook/useWindowDimensions";
import CustomStore from "devextreme/data/custom_store";
import { useHistory } from "react-router-dom";
import FileSelectPopup from "../components/file/FileSelectPopup";
import notify from "devextreme/ui/notify";
import { SickVM } from "../model/Sick";

loadMessages(dictionary);
const pageSizes = [20, 25, 50, 100];

const SickContainer = ({ accessToken, currentLanguage, currentWebsite }) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const dataGridRef = useRef(null);
  const URL = `${WEBSITE_URL}Sicks`;
  const { height } = useWindowDimensions();

  const getList = () => {
    return fetch(`${URL}/${currentWebsite}?languageId=${currentLanguage}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return {
            data: json.data.map((data) => SickVM(data)),
          };
        }
        throw json.Message;
      })
    );
  };

  const deleteSick = (key) => {
    return fetch(`${URL}/${currentWebsite}/${key}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return {};
        }
        throw json.Message;
      })
    );
  };

  const sickStore = new CustomStore({
    key: "id",
    load: getList,
    remove: deleteSick,
  });

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            // Implement custom save logic here
            history.push("/sick/add");
          },
        };
      }
    });

    toolbarItems.push(
      {
        location: "center",
        text: "Danh sách Danh mục Bệnh",
      },
      {
        location: "before",
        widget: "dxButton",
        options: {
          icon: "refresh",
          onClick: () => dataGridRef.current.instance.refresh(),
        },
      }
    );
  };

  const bgCellRender = (data) => {
    return (
      <img
        alt="GHM Image"
        src={`${FILE_URL}/${data.value}`}
        style={{ height: 50 }}
      />
    );
  };

  const bgEditCellRender = (cell) => {
    return (
      <FileSelectPopup
        value={cell.value}
        onValueChanged={(value) => {
          cell.setValue(value);
        }}
      />
    );
  };

  const gotoDetailPage = (sickId) => {
    history.push(`/sick/${sickId}`);
  };

  return (
    <div
      className="form-container"
      style={{ overflow: "auto", height: height - 56, paddingBottom: 20 }}
    >
      <DataGrid
        ref={dataGridRef}
        keyExpr="id"
        dataSource={sickStore}
        allowColumnReordering={true}
        selection={{ mode: "single" }}
        showBorders={true}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Grouping autoExpandAll={false} />
        <Editing
          mode="form"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
        />

        <Column
          dataField="alphabet"
          dataType="string"
          caption={formatMessage("alphabet")}
          groupIndex={0}
        />

        <Column
          dataField="name"
          dataType="string"
          caption={formatMessage("name")}
        />

        <Column
          dataField="description"
          dataType="string"
          caption={formatMessage("description")}
        />

        <Column
          dataField="content"
          dataType="string"
          caption={formatMessage("content")}
        />

        <Column
          dataField="seoLink"
          dataType="string"
          caption={formatMessage("seoLink")}
        />

        <Column
          dataField="url"
          dataType="string"
          caption={formatMessage("url")}
        />

        <Column
          dataField="image"
          dataType="string"
          caption={formatMessage("image")}
          cellRender={bgCellRender}
          editCellRender={bgEditCellRender}
        />

        <Column
          dataField="isActive"
          dataType="boolean"
          caption={formatMessage("isActive")}
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => gotoDetailPage(e.row.key)}
          />
        </Column>

        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={20} />
      </DataGrid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

SickContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
  currentWebsiteLanguage: PropTypes.string,
};

export default connect(mapStateToProps, null)(SickContainer);
