import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  Grouping,
  Button,
} from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import React from "react";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import CustomStore from "devextreme/data/custom_store";
import { useHistory } from "react-router-dom";
import { FILE_URL, WEBSITE_URL } from "../../utils/constants";
import { SocialNetworkVM } from "../../model/SocialNetwork";

loadMessages(dictionary);

const SocialNetworksList = ({
  currentLanguage,
  accessToken,
  languages,
  currentWebsite,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();

  const loadRequest = () => {
    return fetch(`${WEBSITE_URL}SocialNetworks/${currentWebsite}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok)
            return json.data.map((data) => SocialNetworkVM(data, languages));
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const socialsData = new CustomStore({
    key: "id",
    load: () => loadRequest(),
  });

  const imgCellRender = (data) => {
    return (
      <img
        alt="GHM Image"
        style={{ objectFit: "cover", height: 50 }}
        src={`${FILE_URL}${data.value}`}
      />
    );
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;
    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            // Implement custom save logic here
            history.push("/socialNetwork/add");
          },
        };
      }
    });

    toolbarItems.unshift({
      location: "center",
      text: "Danh sách Social Network",
    });
  };

  const gotoDetailPage = (socialNetworkId) => {
    history.push(`/socialNetwork/${socialNetworkId}`);
  };

  return (
    <React.Fragment>
      <DataGrid
        id="grid"
        showBorders={true}
        dataSource={socialsData}
        repaintChangesOnly={true}
        selection={{ mode: "single" }}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Grouping autoExpandAll={true} />
        <Editing
          refreshMode={"reshape"}
          mode="form"
          useIcons={true}
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
        />

        <Scrolling rowRenderingMode="virtual" />

        <Column
          dataField="image"
          caption={formatMessage("image")}
          allowSorting={false}
          cellRender={imgCellRender}
        />
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`name-${item.languageId}`}
            dataType="string"
            caption={formatMessage("name")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`description-${item.languageId}`}
            dataType="string"
            caption={formatMessage("description")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        <Column
          dataField="url"
          caption={formatMessage("url")}
          dataType="string"
        />
        <Column
          dataField="icon"
          caption={formatMessage("icon")}
          dataType="string"
        />
        <Column
          dataField="order"
          caption={formatMessage("order")}
          dataType="number"
        />
        <Column
          dataField="isActive"
          caption={formatMessage("isActive")}
          dataType="boolean"
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => gotoDetailPage(e.row.key)}
          />
        </Column>
      </DataGrid>
    </React.Fragment>
  );
};

SocialNetworksList.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentWebsite: PropTypes.string,
  currentLanguage: PropTypes.string,
};

export default SocialNetworksList;
