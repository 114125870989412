import React, { useEffect, useState } from 'react';
import DataGrid, {
    Column,
    Editing,
    Lookup, RequiredRule
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CORE_URL, FILE_URL, WEBSITE_URL } from '../../utils/constants';

const LanguageVM = (data) => {
    return { ...data, icon: `${FILE_URL}/${data.icon}` };
};

const LanguagesList = ({ accessToken, websiteId }) => {
    const history = useHistory();
    const URL = `${WEBSITE_URL}Websites/${websiteId}/languages`;

    const [languagesList, setLanguagesList] = useState([]);

    useEffect(() => {
        fetch(`${CORE_URL}languages/all`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) setLanguagesList(json.map((data) => LanguageVM(data)));
            })
        );
    }, [accessToken]);

    const sendRequest = (url, method, data) => {
        method = method || 'GET';
        data = data || {};

        if (method === 'GET') {
            return fetch(url, {
                method: method,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Access-Control-Allow-Origin': '*'
                }
            }).then(result => result.json()
                .then(json => {
                    if (result.ok) return json.map((data) => LanguageVM(data));
                    throw json.Message;
                })
                .catch(() => {
                    if (result.status === 401) {
                        history.push('/login');
                    }
                })
            );
        }

        return fetch(url, {
            method: method,
            body: JSON.stringify({
                languageId: data.languageId,
                isActive: data.isActive || true,
                isDefault: data.isDefault || false
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        }).then(result => {
            if (result.ok) {
                return result.text().then(text => text && { ...data.values, id: JSON.parse(text).data });
            } else {
                return result.json().then(json => {
                    throw json.Message;
                });
            }
        });
    };

    const languages = new CustomStore({
        key: 'languageId',
        load: () => sendRequest(`${URL}`),
        insert: (values) => sendRequest(`${URL}`, 'POST', values),
        update: (key, values) => sendRequest(`${URL}/${key}`, 'PUT', {
            values: values
        }),
        remove: (key) => sendRequest(`${URL}/${key}`, 'DELETE', {
            key: key
        })
    });

    return (
        <React.Fragment>
            <DataGrid
                dataSource={languages}
                allowColumnReordering={true}
                showBorders={true}
            >
                <Editing
                    mode="cell"
                    useIcons={true}
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true} />
                <Column dataField="languageId" caption="Language" >
                    <Lookup dataSource={languagesList} displayExpr="name" valueExpr="id" />
                    <RequiredRule />
                </Column>
                <Column dataField="isActive" dataType="boolean" />
                <Column dataField="isDefault" dataType="boolean" />
            </DataGrid>
        </React.Fragment>
    );
};

LanguagesList.propTypes = {
    accessToken: PropTypes.string,
    websiteId: PropTypes.string
};

export default LanguagesList;
