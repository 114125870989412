import Transformer from "../utils/transformer";

const BranchContactVM = (data, languages) => {
  const returnData = Object.assign({}, data);

  languages.forEach((element) => {
    returnData[`name-${element.languageId}`] = Transformer.parseJSON(
      data.name,
      element.languageId
    );
    returnData[`address-${element.languageId}`] = Transformer.parseJSON(
      data.address,
      element.languageId
    );
    returnData[`description-${element.languageId}`] = Transformer.parseJSON(
      data.description,
      element.languageId
    );
    returnData[`workTime-${element.languageId}`] = Transformer.parseJSON(
      data.workTime,
      element.languageId
    );
  });

  return returnData;
};

const BranchContactMV = (data, languages) => {
  const nameObj = {};
  const workTimeObj = {};
  const addressObj = {};
  const descriptionObj = {};
  languages.forEach((language) => {
    nameObj[[language.languageId]] = data[`name-${language.languageId}`];
    workTimeObj[[language.languageId]] =
      data[`workTime-${language.languageId}`];
    addressObj[[language.languageId]] = data[`address-${language.languageId}`];
    descriptionObj[[language.languageId]] =
      data[`description-${language.languageId}`];
  });

  return JSON.stringify({
    name: JSON.stringify(nameObj),
    workTime: JSON.stringify(workTimeObj),
    logo: data.logo,
    link: data.link,
    website: data.website,
    googleMap: data.googleMap,
    address: JSON.stringify(addressObj),
    description: JSON.stringify(descriptionObj),
    order: data.order,
    isActive: data.isActive,
    isDefault: data.isDefault,
    isOffice: data.isOffice,
    concurrencyStamp: data.concurrencyStamp,
  });
};

export { BranchContactVM, BranchContactMV };
