import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { useScreenSizeClass } from './utils/media-query';

const routes = () => {
    const screenSizeClass = useScreenSizeClass();

    return (
        <BrowserRouter>
            <div className={`app ${screenSizeClass}`}>
                <App />
            </div>
        </BrowserRouter>
    );
};

export default routes;
