import { DataGrid, Column, Editing, Scrolling, Grouping, Button } from 'devextreme-react/data-grid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { loadMessages, formatMessage, locale } from 'devextreme/localization';
import CustomStore from 'devextreme/data/custom_store';
import { useHistory } from 'react-router-dom';
import dictionary from '../../data/dictionary';
import { Popup } from 'devextreme-react/popup';
import BranchContactImageDetail from './BranchContactImageDetail';
import { FILE_URL, WEBSITE_URL } from '../../utils/constants';

loadMessages(dictionary);

const BranchContactItemVM = (data) => {
    const returnData = Object.assign({}, data);

    return returnData;
};

const BranchContactImagesList = ({ currentLanguage, currentWebsite, languages, accessToken, branchContactId }) => {
    locale(currentLanguage.substring(0, 2));
    const history = useHistory();
    const URL = `${WEBSITE_URL}BranchContacts/image/get-all/${currentWebsite}/${branchContactId}`;
    const [showItemPopup, setShowItemPopup] = useState(false);
    const [branchContactItemId, setBranchContactItemId] = useState(null);

    const loadRequest = () => {
        return fetch(`${URL}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => result.json()
            .then(json => {
                if (result.ok) return json.map((data) => BranchContactItemVM(data, currentLanguage));
                throw json.Message;
            })
            .catch(() => {
                if (result.status === 401) {
                    history.push('/login');
                }
            })
        );
    };

    const branchContactsData = new CustomStore({
        key: 'id',
        load: () => loadRequest()
    });

    const onToolbarPreparing = (e) => {
        const toolbarItems = e.toolbarOptions.items;
        // Modifies an existing item
        toolbarItems.forEach(function (item) {
            if (item.name === 'addRowButton') {
                item.options = {
                    icon: 'plus',
                    onClick: function () {
                        showBannerItemDetail();
                    }
                };
            }
        });

        toolbarItems.unshift(
            {
                location: 'center',
                text: 'Danh sách BranchContact Images'
            });
    };

    const showBannerItemDetail = (branchContactItemId) => {
        setBranchContactItemId(branchContactItemId);
        setShowItemPopup(true);
    };

    const hideItemDetailPopup = () => {
        setShowItemPopup(false);
        setBranchContactItemId(null);
    };

    const imgCellRender = (data) => {
        return <img alt="GHM Image" src={`${FILE_URL}/${data.value}`} style={{ height: 100 }} />;
    };

    return (
        <React.Fragment>
            <DataGrid
                id="grid"
                showBorders={true}
                dataSource={branchContactsData}
                repaintChangesOnly={true}
                selection={{ mode: 'single' }}
                onToolbarPreparing={onToolbarPreparing}
            >
                <Grouping autoExpandAll={true} />
                <Editing
                    useIcons={true}
                    allowAdding={true}
                    allowDeleting={true} />

                <Scrolling
                    rowRenderingMode="virtual"
                />

                <Column dataField="images" cellRender={imgCellRender} />
                <Column dataField="alt" caption={formatMessage('alt')} dataType="string" />
                <Column dataField="url" caption={formatMessage('url')} dataType="string" />
                <Column dataField="order" caption={formatMessage('order')} dataType="number" />

                <Column type="buttons" caption={formatMessage('actionCol')}>
                    <Button name="delete" />
                    <Button hint="Info" icon="info" onClick={(e) => showBannerItemDetail(e.row.key)} />
                </Column>

            </DataGrid>

            <Popup
                visible={showItemPopup}
                onHiding={() => setShowItemPopup(false)}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={false}
                showTitle={false}
                container=".dx-viewport"
                width={800}
                height={300}
            >
                <BranchContactImageDetail
                    accessToken={accessToken} currentLanguage={currentLanguage}
                    currentWebsite={currentWebsite} websiteLanguages={languages}
                    branchContactId={branchContactId}
                    id={branchContactItemId} hidePopup={hideItemDetailPopup}
                />
            </Popup>
        </React.Fragment>
    );
};

BranchContactImagesList.propTypes = {
    accessToken: PropTypes.string,
    languages: PropTypes.array,
    currentWebsite: PropTypes.string,
    currentLanguage: PropTypes.string,
    branchContactId: PropTypes.string
};

export default BranchContactImagesList;
