import useJSON from "../hook/useJSON";
import { NEWS_STATUS } from "../utils/constants";
import moment from "moment";

const parseJSON = (str, languageId) => {
  let _strObj;
  try {
    _strObj = JSON.parse(str);
  } catch (e) {
    return str || "";
  }
  return _strObj ? _strObj[languageId] || "" : "";
};

const NewsVM = (data, languages, currentLanguage) => {
  const returnData = useJSON(data, languages);

  if (returnData.newsTranslations)
    returnData.newsTranslations.forEach((element) => {
      returnData[`newsTranslations-${element.languageId}-title`] =
        element.title;
      returnData[`newsTranslations-${element.languageId}-description`] =
        element.description;
      returnData[`newsTranslations-${element.languageId}-content`] =
        element.content;
      returnData[`newsTranslations-${element.languageId}-seoLink`] =
        element.seoLink;
      returnData[`newsTranslations-${element.languageId}-note`] = element.note;
      returnData[`newsTranslations-${element.languageId}-metaTitle`] =
        element.metaTitle;
      returnData[`newsTranslations-${element.languageId}-metaDescription`] =
        element.metaDescription;
      returnData[`newsTranslations-${element.languageId}-metaKeyword`] =
        element.metaKeyword;
    });

  returnData["approvedStatusName"] = parseJSON(
    NEWS_STATUS[data.approvedStatus].name,
    currentLanguage
  );
  returnData["categorys"] = data.categoriesNews.map((x) => x.categoryId);
  returnData["subjectTags"] = data.subjectTags.map((x) => x.name);
  
  returnData["categoriesNewsName"] = data.categoriesNews
    .map((x) => parseJSON(x.name, currentLanguage))
    .join(", ");
  returnData["sickNews"] = data.sickNews ? data.sickNews.map((x)=>x.sickId):[]
  returnData.createTime = data.createTime
    ? moment(data.createTime).format("YYYY-MM-DD")
    : null;
  returnData.startTime = data.startTime
    ? moment(data.startTime).format("YYYY-MM-DD")
    : null;

  return returnData;
};

const NewsMV = (data, languages) => {
  const _newsTranslations = [];
  languages.forEach((language) => {
    _newsTranslations.push({
      languageId: language.languageId,
      title: data[`newsTranslations-${language.languageId}-title`],
      description: data[`newsTranslations-${language.languageId}-description`],
      content: data[`newsTranslations-${language.languageId}-content`],
      seoLink: data[`newsTranslations-${language.languageId}-seoLink`],
      note: data[`newsTranslations-${language.languageId}-note`],
      metaTitle: data[`newsTranslations-${language.languageId}-metaTitle`],
      metaDescription:
        data[`newsTranslations-${language.languageId}-metaDescription`],
      metaKeyword: data[`newsTranslations-${language.languageId}-metaKeyword`],
    });
  });

  const _tags = data.subjectTags || [];
  const _sickNews = data.sickNews || [];

  return {
    newsTranslations: _newsTranslations,
    seoLink: data.seoLink,
    bannerImages: data.bannerImages,
    bannerImagesMobile: data.bannerImagesMobile,
    altImages: data.altImages,
    featureImage: data.featureImage,
    featureImageMobile: data.featureImageMobile,
    altFeatureImage: data.altFeatureImage,
    isMobile: data.isMobile,
    isHomePage: data.isHomePage,
    isHot: data.isHot,
    type: data.type,
    concurrencyStamp: data.concurrencyStamp,
    isActive: data.isActive,
    categorys: data.categorys,
    tags: _tags.map((x) => {
      return { name: x };
    }),
    sickNews:  _sickNews.map((x, index) => {
      return { 
        sickId: x,
        isDefault: index === 0 ? true : false
      };
    }),
  };
};

export { NewsMV, NewsVM };
