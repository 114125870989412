import { connect } from "react-redux";
import DataGrid, {
  Editing,
  Column,
  Button,
  Pager,
  Paging,
  SearchPanel,
  Form,
} from "devextreme-react/data-grid";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomStore from "devextreme/data/custom_store";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import { useHistory } from "react-router-dom";
import { Item, GroupItem, Label, Tab, RangeRule } from "devextreme-react/form";
import FileSelectPopup from "../../components/file/FileSelectPopup";
import notify from "devextreme/ui/notify";
import { WEBSITE_URL } from "../../utils/constants";
import dictionary from "../../data/dictionary";
import { AlbumMV, AlbumVM } from "../../model/Album";

loadMessages(dictionary);
const pageSizes = [10, 25, 50, 100];

const tabsMock = [
  {
    id: 0,
    text: "vi-VN",
    icon: "globe",
    content: "Vietnamese",
  },
];

const AlbumContainer = ({
  accessToken,
  languages,
  currentLanguage,
  currentWebsite,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const URL = `${WEBSITE_URL}Albums/${currentWebsite}`;
  const [tabs, setTabs] = useState(tabsMock);

  useEffect(() => {
    const _tabs = languages.map((item, index) => ({
      id: index,
      languageId: item.languageId,
      text: item.name,
      icon: "globe",
      content: "User tab content",
    }));
    setTabs(_tabs);
  }, [languages]);

  const loadRequest = (url) => {
    return fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok)
            return json.data.map((data) =>
              AlbumVM(data, languages, currentLanguage)
            );
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/expiredToken");
          }
        })
    );
  };

  const updateRequest = (url, datas) => {
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) =>
      result.json().then((json) => {
        const _updateData = { ...AlbumVM(json.data, languages), ...datas };
        return fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            "Accept-Language": currentLanguage,
          },
          body: JSON.stringify({
            ...AlbumMV(_updateData, languages),
            concurrencyStamp: _updateData.concurrencyStamp,
          }),
        }).then((result) => {
          if (result.ok) {
            return result.text().then((text) => {
              if (text) {
                const _response = JSON.parse(text);
                notify(
                  {
                    message: _response.message,
                    position: {
                      my: "center top",
                      at: "center top",
                    },
                  },
                  "success",
                  3000
                );

                return _response;
              }
            });
          } else {
            return result.json().then((json) => {
              throw json.Message;
            });
          }
        });
      })
    );
  };

  const deleteRequest = (url) => {
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": currentLanguage,
      },
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          notify(
            {
              message: JSON.parse(text).message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
        });
      } else {
        return result.json().then((json) => {
          throw json.Message;
        });
      }
    });
  };


  const albumsData = new CustomStore({
    key: "id",
    load: () => loadRequest(`${URL}`),
    // insert: (values) => sendRequest(`${URL}`, 'POST', {
    //     values: values
    // }),
    update: (key, values) => updateRequest(`${URL}/${key}`, values),
    remove: (key) => deleteRequest(`${URL}/${key}`, {
        key: key
    })
  });

  const gotoDetailPage = (albumId) => {
    history.push(`/album/${albumId}`);
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            // Implement custom save logic here
            history.push("/album/add");
          },
        };
      }
    });

    e.toolbarOptions.items.unshift({
      location: "center",
      text: "Danh sách Albums Website",
    });
  };

  const imgEditCellRender = (cell) => {
    return (
      <FileSelectPopup
        value={cell.value}
        onValueChanged={(e) => cell.setValue(e)}
      />
    );
  };

  const onRowDblClick = (e) => {
    history.push(`/album/${e.key}`);
  };

  return (
    <React.Fragment>
      <DataGrid
        keyExpr="id"
        dataSource={albumsData}
        allowColumnReordering={true}
        selection={{ mode: "single" }}
        showBorders={true}
        onRowDblClick={onRowDblClick}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Editing
          mode="form"
          useIcons={true}
          allowAdding={true}
          allowUpdating={true}
          allowDeleting={true}
        >
          <Form>
            <Item itemType="group" colCount={2} colSpan={2}>
              <GroupItem caption={"Đa ngôn ngữ"}>
                <Item itemType="tabbed" colCount={2} colSpan={2}>
                  {tabs.map((item) => {
                    return (
                      <Tab key={item.id} title={item.text} icon={item.icon}>
                        <Item
                          dataField={`name-${item.languageId}`}
                          editorType="dxTextBox"
                        >
                          <Label text={formatMessage("name")} />
                        </Item>
                        <Item
                          dataField={`description-${item.languageId}`}
                          editorType="dxTextArea"
                        >
                          <Label text={formatMessage("description")} />
                        </Item>
                        <Item
                          dataField={`metaTitle-${item.languageId}`}
                          editorType="dxTextBox"
                        >
                          <Label text={formatMessage("metaTitle")} />
                        </Item>
                        <Item
                          dataField={`metaKeyword-${item.languageId}`}
                          editorType="dxTextBox"
                        >
                          <Label text={formatMessage("metaKeyword")} />
                        </Item>
                        <Item
                          dataField={`metaDescription-${item.languageId}`}
                          editorType="dxTextArea"
                        >
                          <Label text={formatMessage("metaDescription")} />
                        </Item>
                      </Tab>
                    );
                  })}
                </Item>
              </GroupItem>
              <GroupItem caption={"Khác"}>
                <Item dataField={"thumbnail"}>
                  {/* <Label text="Logo" />
                                    <FileSelectPopup value={websiteInfo.logo} onValueChanged={updateLogo} /> */}
                </Item>
                <Item dataField={"order"} editorType="dxNumberBox">
                  <Label text={formatMessage("order")} />
                  <RangeRule
                    min={0}
                    message={`${formatMessage("order")} ${formatMessage(
                      "errMesInvalidNum"
                    )}`}
                  />
                </Item>
                <Item dataField={"isActive"} editorType="dxCheckBox">
                  <Label text={formatMessage("isActive")} />
                </Item>
                <Item dataField={"isHomePage"} editorType="dxCheckBox">
                  <Label text={formatMessage("isHomePage")} />
                </Item>
                <Item dataField={"isHot"} editorType="dxCheckBox">
                  <Label text={formatMessage("isHot")} />
                </Item>
              </GroupItem>
            </Item>
          </Form>
        </Editing>
        <SearchPanel visible={true} highlightCaseSensitive={true} />

        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`name-${item.languageId}`}
            dataType="string"
            caption={formatMessage("name")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`description-${item.languageId}`}
            dataType="string"
            caption={formatMessage("description")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`metaTitle-${item.languageId}`}
            dataType="string"
            caption={formatMessage("metaTitle")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`metaKeyword-${item.languageId}`}
            dataType="string"
            caption={formatMessage("metaKeyword")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}
        {languages.map((item, index) => (
          <Column
            key={index}
            dataField={`metaDescription-${item.languageId}`}
            dataType="string"
            caption={formatMessage("metaDescription")}
            visible={item.languageId === currentLanguage}
          ></Column>
        ))}

        <Column
          dataField="thumbnail"
          dataType="string"
          caption={formatMessage("thumbnail")}
          editCellRender={imgEditCellRender}
        />
        <Column
          dataField="seoLink"
          dataType="string"
          caption={formatMessage("seoLink")}
        />

        <Column
          dataField="typeName"
          dataType="string"
          caption={formatMessage("type")}
        />
        <Column
          dataField="order"
          dataType="string"
          caption={formatMessage("order")}
        />

        <Column
          dataField="isActive"
          dataType="boolean"
          caption={formatMessage("isActive")}
        />
        <Column
          dataField="isHomePage"
          dataType="boolean"
          caption={formatMessage("isHomePage")}
        />
        <Column
          dataField="isHot"
          dataType="boolean"
          caption={formatMessage("isHot")}
        />

        <Column type="buttons" caption={formatMessage("actionCol")}>
          <Button name="delete" />
          {/* <Button name="edit" /> */}
          <Button
            hint="Info"
            icon="info"
            onClick={(e) => gotoDetailPage(e.row.key)}
          />
        </Column>

        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={10} />
      </DataGrid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
  };
};

AlbumContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
};

export default connect(mapStateToProps, null)(AlbumContainer);
