import React from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { loadMessages, locale } from 'devextreme/localization';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import dictionary from '../../data/dictionary';
import WebsiteInformation from '../../components/website/WebsiteInformation';
import SocialNetworksList from '../../components/website/SocialNetworksList';
import BranchContactsList from '../../components/website/BranchContactsList';
import HardContentsList from '../../components/website/HardContentsList';
import LanguagesList from '../../components/website/LanguagesList';

loadMessages(dictionary);

const SettingContainer = ({ currentLanguage, languages, accessToken, currentWebsite, websiteLanguages }) => {
    locale(currentLanguage.substring(0, 2));
    const _props = { currentLanguage, languages, accessToken, currentWebsite, websiteLanguages };

    return (
        <React.Fragment>
            <TabPanel >
                <Item title="Information" icon="info">
                    <WebsiteInformation {..._props} />
                </Item>
                <Item title="Social Network" icon="share">
                    <SocialNetworksList {..._props} />
                </Item>
                <Item title="Branch Contact" icon="product">
                    <BranchContactsList {..._props} />
                </Item>
                <Item title="Hard Content" icon="bulletlist">
                    <HardContentsList {..._props} />
                </Item>
                <Item title="Languages" icon="globe">
                    <LanguagesList accessToken={accessToken} websiteId={currentWebsite} />
                </Item>
            </TabPanel>
        </React.Fragment>
    );
};

SettingContainer.propTypes = {
    currentLanguage: PropTypes.string,
    languages: PropTypes.array,
    accessToken: PropTypes.string,
    currentWebsite: PropTypes.string,
    websiteLanguages: PropTypes.array
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.data.token,
        currentLanguage: state.setting.currentLanguage,
        languages: state.setting.languages,
        currentWebsite: state.website.data.currentWebsite,
        websiteLanguages: state.website.data.languages
    };
};

export default connect(mapStateToProps, null)(SettingContainer);
