import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Form, {
  ButtonItem,
  SimpleItem,
  RequiredRule,
  GroupItem,
  Label,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from "devextreme-react/form";
import "devextreme-react/text-area";
import notify from "devextreme/ui/notify";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import LanguagesList from "../../components/website/LanguagesList";
import WebsiteUsersList from "../../components/website/WebsiteUsersList";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import { WEBSITE_URL } from "../../utils/constants";
import useJSON from "../../hook/useJSON";
import useWindowDimensions from "../../hook/useWindowDimensions";

loadMessages(dictionary);

const tabsMock = [
  {
    id: 0,
    text: "vi-VN",
    icon: "globe",
    content: "Vietnamese",
  },
];

const WebsiteVM = (data, languages) => {
  const returnData = useJSON(data, languages);
  return returnData;
};

const WebsiteMV = (data, languages) => {
  const nameObj = {};
  const addressObj = {};
  const noteObj = {};
  languages.forEach((language) => {
    nameObj[[language.languageId]] = data[`name-${language.languageId}`];
    addressObj[[language.languageId]] = data[`address-${language.languageId}`];
    noteObj[[language.languageId]] = data[`note-${language.languageId}`];
  });
  return JSON.stringify({
    name: JSON.stringify(nameObj),
    phoneNumber: data.phoneNumber,
    address: JSON.stringify(addressObj),
    note: JSON.stringify(noteObj),
    isDefault: data.isDefault,
    concurrencyStamp: data.concurrencyStamp,
    isActive: data.isActive,
  });
};

const renderLabel = () => {
  return (
    <div className="toolbar-label">
      <b>Thông tin Website</b>
    </div>
  );
};

const WebsiteDetailContainer = ({
  accessToken,
  languages,
  currentLanguage,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const params = useParams();
  const [website, setWebsite] = useState({});
  const [tabs, setTabs] = useState(tabsMock);
  const { height } = useWindowDimensions();

  useEffect(() => {
    const _tabs = languages.map((item, index) => ({
      id: index,
      languageId: item.languageId,
      text: item.name,
      icon: "user",
      content: "User tab content",
    }));
    setTabs(_tabs);
  }, [languages]);

  useEffect(() => {
    fetch(`${WEBSITE_URL}Websites/${params.websiteId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setWebsite(WebsiteVM(json.data, languages));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [accessToken, languages, history, params.websiteId]);

  const updateWebsite = () => {
    return fetch(`${WEBSITE_URL}Websites/${website.id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": currentLanguage,
      },
      body: WebsiteMV(website, languages),
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateWebsite();
  };

  const buttonOptions = {
    text: "Update",
    type: "success",
    useSubmitBehavior: true,
  };

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.goBack();
    },
  };

  return (
    <div
      className="form-container"
      style={{ overflow: "auto", height: height - 56, paddingBottom: 20 }}
    >
      <Toolbar>
        <Item location="before" widget="dxButton" options={backButtonOptions} />
        <Item location="center" locateInMenu="never" render={renderLabel} />
      </Toolbar>
      <form action="your-action" onSubmit={handleSubmit}>
        <Form
          formData={website}
          readOnly={false}
          showColonAfterLabel={true}
          colCount={2}
          showValidationSummary={true}
          validationGroup="customerData"
        >
          <GroupItem caption="Đa ngôn ngữ">
            <TabbedItem>
              <TabPanelOptions deferRendering={false} />
              {tabs.map((item) => {
                return (
                  <Tab key={item.id} title={item.text} icon={item.icon}>
                    <SimpleItem
                      dataField={`name-${item.languageId}`}
                      editorType="dxTextBox"
                    >
                      <Label text={formatMessage("name")} />
                      <RequiredRule />
                    </SimpleItem>

                    <SimpleItem
                      dataField={`address-${item.languageId}`}
                      editorType="dxTextArea"
                    >
                      <Label text={formatMessage("address")} />
                    </SimpleItem>

                    <SimpleItem
                      dataField={`note-${item.languageId}`}
                      editorType="dxTextArea"
                    >
                      <Label text={formatMessage("note")} />
                    </SimpleItem>
                  </Tab>
                );
              })}
            </TabbedItem>
          </GroupItem>

          <GroupItem caption="Other">
            <SimpleItem dataField="phoneNumber" editorType="dxTextBox">
              <Label text={formatMessage("phoneNumber")} />
            </SimpleItem>
            <SimpleItem dataField="isActive" editorType="dxCheckBox">
              <Label text={formatMessage("isActive")} />
            </SimpleItem>
            <SimpleItem dataField="isDefault" editorType="dxCheckBox">
              <Label text={formatMessage("isDefault")} />
            </SimpleItem>
          </GroupItem>

          <ButtonItem
            horizontalAlignment="right"
            colSpan={2}
            buttonOptions={buttonOptions}
          />
        </Form>

        <hr />

        <h3>Ngôn ngữ Website</h3>

        <LanguagesList accessToken={accessToken} websiteId={params.websiteId} />

        <hr />

        <h3>Nhân viên làm</h3>

        <WebsiteUsersList
          accessToken={accessToken}
          websiteId={params.websiteId}
        />
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
  };
};

WebsiteDetailContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
};

export default connect(mapStateToProps, null)(WebsiteDetailContainer);
