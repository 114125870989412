import React, { useEffect, useState } from "react";
import Form, {
  ButtonItem,
  SimpleItem,
  GroupItem,
  Label,
  EmptyItem,
  RequiredRule,
} from "devextreme-react/form";
import "devextreme-react/text-area";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../../data/dictionary";
import FileSelectPopup from "../../file/FileSelectPopup";
import { WEBSITE_URL } from "../../../utils/constants";
import Transformer from "../../../utils/transformer";

loadMessages(dictionary);

const ServiceTitleMV = (data) => {
  return {
    image: data.image,
    url: data.url,
    alt: data.alt,
    order: data.order,
    title: data.title,
    isActive: data.isActive,
    concurrencyStamp: data.concurrencyStamp,
  };
};

const WebsiteServiceTitleDetail = ({
  accessToken,
  currentLanguage,
  currentWebsite,
  websiteLanguages,
  serviceId,
  id,
  hidePopup,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const [serviceTitle, setServiceTitle] = useState({ isActive: true });

  useEffect(() => {
    if (!currentWebsite || !id) {
      return;
    }
    fetch(`${WEBSITE_URL}Services/title/${currentWebsite}/${serviceId}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setServiceTitle(json.data);
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [currentWebsite, id, accessToken, serviceId, websiteLanguages, history]);

  const updateService = () => {
    Transformer.fetchUpdate(
      `${WEBSITE_URL}Services/title/${currentWebsite}/${serviceId}/${id}`,
      {
        token: accessToken,
        body: ServiceTitleMV(serviceTitle),
        callback: () => {
          hidePopup();
        },
      }
    );
  };

  const addService = () => {
    Transformer.fetchCreate(
      `${WEBSITE_URL}Services/title/${currentWebsite}/${serviceId}`,
      {
        token: accessToken,
        body: ServiceTitleMV(serviceTitle),
        callback: () => {
          hidePopup();
        },
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      updateService();
    } else {
      addService();
    }
  };

  const saveButtonOptions = {
    text: formatMessage("save"),
    icon: "save",
    type: "default",
    stylingMode: "contained",
    useSubmitBehavior: true,
  };

  const updateImage = (e) => {
    setServiceTitle((prevState) => ({ ...prevState, image: e }));
  };

  return (
    <React.Fragment>
      <Toolbar>
        <Item
          location="center"
          text={`Thông tin ảnh`}
        />
      </Toolbar>
      <form action="your-action-title" onSubmit={handleSubmit}>
        <Form
          formData={serviceTitle}
          readOnly={false}
          showColonAfterLabel={true}
          colCount={1}
          showValidationSummary={true}
          validationGroup="customerDataTitle"
        >
          <GroupItem caption="Nội dung khác">
            <SimpleItem
              dataField={`title`}
              editorType="dxTextBox"
            >
              <Label text={formatMessage("title")} />
              <RequiredRule />
            </SimpleItem>
            <SimpleItem>
              <Label text={formatMessage("image")} />
              <FileSelectPopup
                value={serviceTitle.image}
                imgHeight={100}
                onValueChanged={updateImage}
              />
              <RequiredRule />
            </SimpleItem>

            <SimpleItem dataField={"alt"} editorType="dxTextBox" colSpan={2}>
              <Label text={formatMessage("alt")} />
            </SimpleItem>

            <SimpleItem dataField="order" editorType="dxNumberBox">
              <Label text={formatMessage("order")} />
            </SimpleItem>

            <SimpleItem dataField="isActive" editorType="dxCheckBox">
              <Label text={formatMessage("isActive")} />
            </SimpleItem>
          </GroupItem>

          <EmptyItem />
          <GroupItem horizontalAlignment="center" colSpan={3}>
            <ButtonItem
              horizontalAlignment="center"
              validationGroup="customerDataTitle"
              buttonOptions={saveButtonOptions}
            />
          </GroupItem>
        </Form>
      </form>
    </React.Fragment>
  );
};

WebsiteServiceTitleDetail.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
  serviceId: PropTypes.string,
  id: PropTypes.string,
  hidePopup: PropTypes.func,
};

export default WebsiteServiceTitleDetail;
