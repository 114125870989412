import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import React, { useState, useRef, useEffect } from "react";
import ScrollView from "devextreme-react/scroll-view";
import FileMgr from "./FileMgr";
import { Button } from "devextreme-react/button";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import notify from "devextreme/ui/notify";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import { DEFAULT_NO_IMG, FILE_URL } from "../../utils/constants";

loadMessages(dictionary);

const FileSelectPopup = ({
  accessToken,
  value,
  onValueChanged,
  getAllValueChanged,
  imgHeight,
  currentLanguage,
}) => {
  locale(currentLanguage.substring(0, 2));
  const [url, setUrl] = useState(DEFAULT_NO_IMG);
  const [popupVisible, setPopupVisible] = useState(false);
  const fileRef = useRef(null);

  useEffect(() => {
    if (value && value !== "") {
      setUrl(value);
    } else {
      setUrl(DEFAULT_NO_IMG);
    }
  }, [value]);

  const selectFiles = () => {
    const selectFiles = fileRef.current.instance.getSelectedItems();
    if (selectFiles && selectFiles.length > 0) {
      setPopupVisible(false);
      const _value = selectFiles[0].dataItem.url;
      setUrl(_value);
      onValueChanged(_value);
      if (getAllValueChanged) getAllValueChanged(selectFiles[0].dataItem)
    } else {
      notify("Please select file", "error", 500);
    }
  };

  const selectButtonOptions = {
    icon: "save",
    text: "Save",
    onClick: () => selectFiles(),
  };

  const closeButtonOptions = {
    text: "Close",
    onClick: () => hideInfo(),
  };

  const hideInfo = () => {
    setPopupVisible(false);
  };

  const showInfo = () => {
    setPopupVisible(true);
  };

  const renderPopup = () => {
    return (
      <React.Fragment>
        <ScrollView width="100%" height="100%" showScrollbar={"never"}>
          <FileMgr
            selectionMode={"single"}
            ref={fileRef}
            allowedFileExtensions={[".png", ".jpeg", ".jpg", ".webp", ".txt", ".pdf", ".doc", ".docx", ".xls", ".xlsx", ".gif"]}
            accessToken={accessToken}
          />
        </ScrollView>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
     {!getAllValueChanged && <img
        alt="GHM Image"
        style={{ height: imgHeight || "50px" }}
        src={`${FILE_URL}${url}`}
      />}
      <br />

      <Button
        className="button-info"
        text={formatMessage("selectFile")}
        icon="photo"
        onClick={showInfo}
      />
      {popupVisible && (
        <Popup
          visible={true}
          onHiding={() => console.log("on hidding")}
          dragEnabled={false}
          closeOnOutsideClick={false}
          showCloseButton={false}
          showTitle={true}
          title={"Select File"}
          container=".dx-viewport"
          width={800}
          height={670}
          // maxHeight={'80%'}
          // maxWidth={'80%'}
          contentRender={renderPopup}
        >
          <Position at="center" my="center" />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="before"
            options={selectButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={closeButtonOptions}
          />
        </Popup>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
  };
};

FileSelectPopup.propTypes = {
  accessToken: PropTypes.string,
  value: PropTypes.string,
  onValueChanged: PropTypes.func,
  getAllValueChanged: PropTypes.func,
  imgHeight: PropTypes.number,
  currentLanguage: PropTypes.string,
};

export default connect(mapStateToProps, null)(FileSelectPopup);
