import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Form, {
  ButtonItem,
  SimpleItem,
  GroupItem,
  Label,
  TabbedItem,
  TabPanelOptions,
  Tab,
  EmptyItem,
  RequiredRule,
} from "devextreme-react/form";
import "devextreme-react/text-area";
import "devextreme-react/html-editor";
import notify from "devextreme/ui/notify";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import FileSelectPopup from "../../components/file/FileSelectPopup";
import NewsCategoryDropDown from "../../components/website/NewsCategoryDropDown";
import { NEWS_TYPES, WEBSITE_URL } from "../../utils/constants";
import useWindowDimensions from "../../hook/useWindowDimensions";
import Transformer from "../../utils/transformer";
import { NewsMV, NewsVM } from "../../model/News";
import { Button } from "devextreme-react/button";
import NewsTreeDropDown from "../../components/website/NewsTreeDropDown";
import SickDetailContainer from "../SickDetailContainer";
import { Popup } from "devextreme-react/popup";
import CKHtmlEditor from "../../components/html/CKHtmlEditor";
loadMessages(dictionary);

const tabsMock = [
  {
    id: 0,
    text: "vi-VN",
    icon: "globe",
    content: "Vietnamese",
  },
];

const parseJSON = (str, languageId) => {
  let _strObj;
  try {
    _strObj = JSON.parse(str);
  } catch (e) {
    return str || "";
  }
  return _strObj ? _strObj[languageId] || "" : "";
};

const TypeVM = (data, currentLanguage) => {
  return {
    id: data.id,
    name: parseJSON(data.name, currentLanguage),
  };
};

const NewsDetailContainer = ({
  accessToken,
  currentLanguage,
  currentWebsite,
  websiteLanguages,
  isPopup = false,
  hidePopup,
  sickId,
}) => {
  locale(currentLanguage.substring(0, 2));
  const history = useHistory();
  const params = useParams();
  const [news, setNews] = useState({});
  const [tabs, setTabs] = useState(tabsMock);
  const [editor, setEditor] = useState(null);
  const { height } = useWindowDimensions();
  const [tags, setTags] = useState([]);
  const [listSicks, setListSicks] = useState([]);
  const [showItemPopup, setShowItemPopup] = useState(false);
  const [isSaveAsDraft, setIsSaveAsDraft] = useState(null);

  useEffect(() => {
    getListSick();

    fetch(`${WEBSITE_URL}Tags/get-all/${currentWebsite}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          setTags(json);
        }
      })
    );
  }, []);

  useEffect(() => {
    const _tabs = websiteLanguages.map((item, index) => ({
      id: index,
      languageId: item.languageId,
      text: item.name,
      icon: "globe",
      content: "User tab content",
    }));
    setTabs(_tabs);
  }, [websiteLanguages]);

  useEffect(() => {
    if (!currentWebsite || !params.id || isPopup) {
      return;
    }
    fetch(`${WEBSITE_URL}News/detail/${currentWebsite}/${params.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setNews(NewsVM(json.data, websiteLanguages));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [currentWebsite, history, websiteLanguages, params.id, accessToken]);

  useEffect(() => {
    if (sickId) setNews((prevState) => ({ ...prevState, sickNews: [sickId] }));
  }, [sickId]);

  const getListSick = () => {
    fetch(`${WEBSITE_URL}Sicks/get-all/${currentWebsite}/${currentLanguage}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          setListSicks(json);
        }
      })
    );
  };

  const updateNews = (isSend) => {
    const editorData = editor?.getData();

    const _news = Object.assign({}, news);

    websiteLanguages.forEach((language) => {
      _news[`newsTranslations-${language.languageId}-content`] = editorData;
    });

    Transformer.fetchUpdate(
      `${WEBSITE_URL}News/${currentWebsite}/${news.id}/${isSend}`,
      {
        token: accessToken,
        body: NewsMV(_news, websiteLanguages),
        callback: () => {
          history.goBack();
        },
      }
    );
  };

  const addNews = (isSend) => {
    const editorData = editor?.getData();

    const _news = Object.assign({}, news);

    websiteLanguages.forEach((language) => {
      _news[`newsTranslations-${language.languageId}-content`] = editorData;
    });

    Transformer.fetchCreate(`${WEBSITE_URL}News/${currentWebsite}/${isSend}`, {
      token: accessToken,
      body: NewsMV(_news, websiteLanguages),
      callback: (data) => {
        if (isPopup) hidePopup(data.data);
        else history.goBack();
      },
    });
  };

  const checkFormValidate = () => {
    if (!news.featureImage || !news.featureImageMobile) {
      notify(
        {
          message: `Ảnh Feature ${formatMessage("errMesRequire")}`,
          position: {
            my: "center top",
            at: "center top",
          },
        },
        "error",
        3000
      );
      return false;
    }

    return true;
  };

  const handleSubmitNews = (e) => {
    e.preventDefault();
    if (!checkFormValidate()) {
      return;
    }

    if (params.id && !isPopup) {
      updateNews(isSaveAsDraft);
    } else {
      addNews(isSaveAsDraft);
    }
  };

  const saveButtonOptions = {
    text: `${formatMessage("save")} ${formatMessage("draft")}`,
    icon: "save",
    type: "default",
    stylingMode: "contained",
    disabled: news.approvedStatus === 1,
    useSubmitBehavior: true,
    onClick: () => setIsSaveAsDraft(false),
  };

  const saveAndSendButtonOptions = {
    text: `${formatMessage("save")} ${formatMessage("approve")}`,
    icon: "email",
    type: "default",
    stylingMode: "contained",
    disabled: news.approvedStatus === 1,
    useSubmitBehavior: true,
    onClick: () => setIsSaveAsDraft(true),
  };

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.goBack();
    },
  };

  const updateFeatureImage = (e) => {
    setNews((prevState) => ({ ...prevState, featureImage: e }));
  };
  const updateFeatureImageMobile = (e) => {
    setNews((prevState) => ({ ...prevState, featureImageMobile: e }));
  };
  const updateBannerImages = (e) => {
    setNews((prevState) => ({ ...prevState, bannerImages: e }));
  };
  const updateBannerImagesMobile = (e) => {
    setNews((prevState) => ({ ...prevState, bannerImagesMobile: e }));
  };

  const updateCategorys = (e) => {
    setNews((prevState) => ({ ...prevState, categorys: e }));
  };
  const updateSickNews = (e) => {
    setNews((prevState) => ({ ...prevState, sickNews: e }));
  };
  const newsTypesSelectOptions = {
    items: NEWS_TYPES.map((type) => TypeVM(type, currentLanguage)),
    displayExpr: "name",
    searchEnabled: true,
    valueExpr: "id",
  };

  const tagsEditorOptions = {
    items: tags.map((x) => x.name),
    acceptCustomValue: true,
  };

  const onGoToSick = () => {
    setShowItemPopup(true);
  };
  const hideItemDetailPopup = (data) => {
    const sickNews = news.sickNews ? [...news.sickNews, data] : [data];
    setNews((prevState) => ({ ...prevState, sickNews: sickNews }));
    setShowItemPopup(false);
    getListSick();
  };
  return (
    <div
      className="form-container"
      style={{
        overflow: !isPopup ? "auto" : "initial",
        height: height - 56,
        paddingBottom: 20,
      }}
    >
      <Toolbar>
        {!isPopup && (
          <Item
            location="before"
            widget="dxButton"
            options={backButtonOptions}
          />
        )}
        <Item
          location="center"
          text={`${formatMessage("info")} ${formatMessage(
            "of"
          )} ${formatMessage("news")}`}
        />
      </Toolbar>

      <form action="your-action" onSubmit={handleSubmitNews}>
        <Form
          formData={news}
          readOnly={false}
          showColonAfterLabel={true}
          colCount={3}
          showValidationSummary={true}
          validationGroup="customerData"
        >
          <GroupItem caption="Nội dung tin tức" colSpan={2}>
            <GroupItem caption="Đa ngôn ngữ">
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                {tabs.map((item) => {
                  return (
                    <Tab key={item.id} title={item.text} icon={item.icon}>
                      <SimpleItem
                        dataField={`newsTranslations-${item.languageId}-title`}
                        editorType="dxTextBox"
                      >
                        <Label text={formatMessage("title")} />
                        <RequiredRule
                          message={`${formatMessage("title")} ${formatMessage(
                            item.languageId
                          )} ${formatMessage("errMesRequire")}`}
                        />
                      </SimpleItem>
                      <SimpleItem
                        dataField={`newsTranslations-${item.languageId}-description`}
                        editorType="dxTextArea"
                      >
                        <Label text={formatMessage("description")} />
                      </SimpleItem>
                      {/* <SimpleItem
                        dataField={`newsTranslations-${item.languageId}-content`}
                        editorType="dxHtmlEditor"
                        editorOptions={htmlOptions(item.languageId)}
                      >
                        <Label text={formatMessage("content")} />
                        <RequiredRule
                          message={`${formatMessage("content")} ${formatMessage(
                            item.languageId
                          )} ${formatMessage("errMesRequire")}`}
                        />
                      </SimpleItem> */}
                       <SimpleItem
                        dataField={`newsTranslations-${item.languageId}-content`}
                      >
                        <Label text={formatMessage("content")} />
                        <RequiredRule
                          message={`${formatMessage("content")} ${formatMessage(
                            item.languageId
                          )} ${formatMessage("errMesRequire")}`}
                        />
                        <CKHtmlEditor
                          value={
                            news[`newsTranslations-${item.languageId}-content`]
                          }
                          // onValueChanged={(text) =>
                          //   setNews({
                          //     ...news,
                          //     [`newsTranslations-${item.languageId}-content`]:
                          //       text,
                          //   })
                          // }
                          onInit={setEditor}
                        />
                      </SimpleItem>
                      <SimpleItem
                        dataField={`newsTranslations-${item.languageId}-note`}
                        editorType="dxTextArea"
                      >
                        <Label text={formatMessage("note")} />
                      </SimpleItem>
                    </Tab>
                  );
                })}
              </TabbedItem>
            </GroupItem>

            <GroupItem caption="Nội dung khác" colCount={3}>
              <SimpleItem
                dataField={"subjectTags"}
                editorType="dxTagBox"
                editorOptions={tagsEditorOptions}
                colSpan={3}
              >
                <Label text={"Tags"} />
              </SimpleItem>
              <SimpleItem
                dataField="seoLink"
                editorType="dxTextBox"
                colSpan={3}
              >
                <Label text={formatMessage("seoLink")} />
                <RequiredRule
                  message={`${formatMessage("seoLink")} ${formatMessage(
                    "errMesRequire"
                  )}`}
                />
              </SimpleItem>
              <SimpleItem colSpan={2}>
                <Label text={formatMessage("category")} />
                <NewsCategoryDropDown
                  accessToken={accessToken}
                  currentWebsite={currentWebsite}
                  currentLanguage={currentLanguage}
                  value={news.categorys}
                  onValueChanged={updateCategorys}
                />
              </SimpleItem>
              <SimpleItem
                dataField="type"
                editorType="dxSelectBox"
                editorOptions={newsTypesSelectOptions}
              >
                <Label text={formatMessage("type")} />
                <RequiredRule
                  message={`${formatMessage("type")} ${formatMessage(
                    "errMesRequire"
                  )}`}
                />
              </SimpleItem>
              <SimpleItem colSpan={3}>
                <Label text={formatMessage("categorySick")} />
                <div style={{ display: "flex" }}>
                  <div style={{ width: "calc(100% - 34px)" }}>
                    <NewsTreeDropDown
                      dataSource={listSicks}
                      value={news.sickNews}
                      onValueChanged={updateSickNews}
                    />
                  </div>
                  <Button text="+" type="normal" onClick={onGoToSick} />
                </div>
              </SimpleItem>
              <SimpleItem dataField="isMobile" editorType="dxCheckBox">
                <Label text={formatMessage("isMobile")} />
              </SimpleItem>
              <SimpleItem dataField="isActive" editorType="dxCheckBox">
                <Label text={formatMessage("isActive")} />
              </SimpleItem>
              <SimpleItem dataField="isHomePage" editorType="dxCheckBox">
                <Label text={formatMessage("isHomePage")} />
              </SimpleItem>
              <SimpleItem dataField="isHot" editorType="dxCheckBox">
                <Label text={formatMessage("isHot")} />
              </SimpleItem>
            </GroupItem>
          </GroupItem>

          <GroupItem caption="SEO" colSpan={1}>
            <GroupItem caption="SEO Content">
              <TabbedItem>
                <TabPanelOptions deferRendering={false} />
                {tabs.map((item) => {
                  return (
                    <Tab key={item.id} title={item.text} icon={item.icon}>
                      <SimpleItem
                        dataField={`newsTranslations-${item.languageId}-metaTitle`}
                        editorType="dxTextArea"
                      >
                        <Label text={formatMessage("metaTitle")} />
                      </SimpleItem>
                      <SimpleItem
                        dataField={`newsTranslations-${item.languageId}-metaKeyword`}
                        editorType="dxTextArea"
                      >
                        <Label text={formatMessage("metaKeyword")} />
                      </SimpleItem>
                      <SimpleItem
                        dataField={`newsTranslations-${item.languageId}-seoLink`}
                        editorType="dxTextArea"
                      >
                        <Label text={formatMessage("seoLink")} />
                        <RequiredRule
                          message={`${formatMessage("seoLink")} ${formatMessage(
                            item.languageId
                          )} ${formatMessage("errMesRequire")}`}
                        />
                      </SimpleItem>
                      <SimpleItem
                        dataField={`newsTranslations-${item.languageId}-metaDescription`}
                        editorType="dxTextArea"
                      >
                        <Label text={formatMessage("metaDescription")} />
                      </SimpleItem>
                    </Tab>
                  );
                })}
              </TabbedItem>
            </GroupItem>

            <GroupItem caption="Ảnh Feature">
              <SimpleItem>
                <Label text={formatMessage("website")} />
                <FileSelectPopup
                  value={news.featureImage}
                  imgHeight={100}
                  onValueChanged={updateFeatureImage}
                />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem>
                <Label text={formatMessage("mobile")} />
                <FileSelectPopup
                  value={news.featureImageMobile}
                  imgHeight={100}
                  onValueChanged={updateFeatureImageMobile}
                />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField={"altFeatureImage"}
                editorType="dxTextArea"
                colSpan={2}
              >
                <Label text={formatMessage("alt")} />
              </SimpleItem>
            </GroupItem>

            <GroupItem caption="Ảnh Banner">
              <SimpleItem>
                <Label text={formatMessage("website")} />
                <FileSelectPopup
                  value={news.bannerImages}
                  imgHeight={100}
                  onValueChanged={updateBannerImages}
                />
              </SimpleItem>
              <SimpleItem>
                <Label text={formatMessage("mobile")} />
                <FileSelectPopup
                  value={news.bannerImagesMobile}
                  imgHeight={100}
                  onValueChanged={updateBannerImagesMobile}
                />
              </SimpleItem>
              <SimpleItem
                dataField={"altImages"}
                editorType="dxTextArea"
                colSpan={2}
              >
                <Label text={formatMessage("alt")} />
              </SimpleItem>
            </GroupItem>
          </GroupItem>

          <EmptyItem />
          <GroupItem horizontalAlignment="center" colSpan={1} colCount={2}>
            <SimpleItem
              visible={news.approvedStatus === 1}
              helpText="* Tin tức đang ở trạng thái chờ, không thể chỉnh sửa"
              colSpan={2}
            />
            <ButtonItem
              horizontalAlignment="center"
              buttonOptions={saveAndSendButtonOptions}
            />
            <ButtonItem
              horizontalAlignment="center"
              buttonOptions={saveButtonOptions}
            />
          </GroupItem>
        </Form>
      </form>
      {showItemPopup && (
        <Popup
          visible={showItemPopup}
          onHiding={() => setShowItemPopup(false)}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showCloseButton={false}
          showTitle={false}
          container=".dx-viewport"
          width={800}
          height={600}
        >
          <SickDetailContainer
            accessToken={accessToken}
            currentLanguage={currentLanguage}
            currentWebsite={currentWebsite}
            websiteLanguages={websiteLanguages}
            isPopup={true}
            hidePopup={hideItemDetailPopup}
          />
        </Popup>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

NewsDetailContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
  isPopup: PropTypes.bool,
  hidePopup: PropTypes.func,
  sickId: PropTypes.string,
};

export default connect(mapStateToProps, null)(NewsDetailContainer);
