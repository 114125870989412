import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { WEBSITE_URL } from "../utils/constants";
import PropTypes from "prop-types";
import { AdvertisementVM, AdvertisementMV } from "../model/Advertisement";
import { connect } from "react-redux";
import Form, {
  ButtonItem,
  SimpleItem,
  GroupItem,
  Label,
  RequiredRule,
} from "devextreme-react/form";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import FileSelectPopup from "../components/file/FileSelectPopup";
import AdvertisementFileList from "../components/advertisement/AdvertisementFileList";
import useWindowDimensions from "../hook/useWindowDimensions";
import Toolbar, { Item } from "devextreme-react/toolbar";
import notify from "devextreme/ui/notify";

loadMessages(dictionary);

function AdvertisementDetailContainer({
  currentWebsite,
  accessToken,
  websiteLanguages,
  currentLanguage,
  languages,
}) {
  const [advertisement, setAdvertisement] = useState({});
  const params = useParams();
  const history = useHistory();
  locale(currentLanguage.substring(0, 2));
  const { height } = useWindowDimensions();

  useEffect(() => {
    if (!currentWebsite || !params.id) {
      return;
    }
    fetch(`${WEBSITE_URL}Advertisements/title/${params.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setAdvertisement(AdvertisementVM(json.data, websiteLanguages));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/login");
          }
        })
    );
  }, [params.id]);

  const updateBackground = (e) => {
    setAdvertisement((prevState) => ({ ...prevState, background: e }));
  };

  const updateAdvertisement = () => {
    fetch(`${WEBSITE_URL}Advertisements/title/${currentWebsite}/${params.id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Accept-Language": currentLanguage,
      },
      body: JSON.stringify(AdvertisementMV(advertisement)),
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
        }
        throw json.Message;
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    updateAdvertisement();
  };

  const saveButtonOptions = {
    text: `${formatMessage("save")}`,
    icon: "save",
    type: "default",
    stylingMode: "contained",
    useSubmitBehavior: true,
  };

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.goBack();
    },
  };

  return (
    <div
      className="form-container"
      style={{ overflow: "auto", height: height - 56, paddingBottom: 20 }}
    >
      <Toolbar>
        <Item location="before" widget="dxButton" options={backButtonOptions} />
        <Item location="center" text={"Chi tiết Màn hình chờ"} />
      </Toolbar>

      <form action="your-action" onSubmit={handleSubmit}>
        <Form
          formData={advertisement}
          readOnly={false}
          showColonAfterLabel={true}
          colCount={2}
          showValidationSummary={true}
          validationGroup="customerData"
        >
          <GroupItem caption="Nội dung tin tức">
            <SimpleItem dataField="title" editorType="dxTextBox">
              <Label text={formatMessage("title")} />
            </SimpleItem>
            <SimpleItem dataField="area" editorType="dxNumberBox">
              <Label text={formatMessage("area")} />
              <RequiredRule />
            </SimpleItem>
            <SimpleItem dataField="floor" editorType="dxNumberBox">
              <Label text={formatMessage("floor")} />
              <RequiredRule />
            </SimpleItem>
          </GroupItem>

          <GroupItem caption="Ảnh Banner">
            <SimpleItem>
              <Label text={formatMessage("background")} />
              <FileSelectPopup
                value={advertisement.background}
                imgHeight={100}
                onValueChanged={updateBackground}
              />
            </SimpleItem>
          </GroupItem>

          <GroupItem horizontalAlignment="center" colSpan={2}>
            <ButtonItem
              horizontalAlignment="center"
              buttonOptions={saveButtonOptions}
            />
          </GroupItem>
        </Form>
      </form>

      {advertisement.floor && advertisement.area && (
        <AdvertisementFileList
          floor={advertisement.floor}
          area={advertisement.area}
          accessToken={accessToken}
          currentWebsite={currentWebsite}
          currentLanguage={currentLanguage}
          languages={languages}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.data.token,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
    currentWebsite: state.website.data.currentWebsite,
    websiteLanguages: state.website.data.languages,
  };
};

AdvertisementDetailContainer.propTypes = {
  accessToken: PropTypes.string,
  languages: PropTypes.array,
  currentLanguage: PropTypes.string,
  websiteId: PropTypes.string,
  websiteLanguages: PropTypes.array,
  currentWebsite: PropTypes.string,
};

export default connect(mapStateToProps, null)(AdvertisementDetailContainer);
