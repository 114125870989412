import React, { useState } from "react";
import "./NavigationDrawer.scss";
import PropsType from "prop-types";
import { Drawer } from "devextreme-react/drawer";
import NavigationList from "./NavigationList.js";
// import ScrollView from "devextreme-react/scroll-view";
// import Footer from "./footer";
import Headers from "./header";

const NavigationDrawer = (props) => {
  //   const scrollViewRef = useRef();
  const [opened, setOpened] = useState(false);

  const onOutsideClick = () => {
    setOpened(false);
  };

  return (
    <div className={"side-nav-outer-toolbar"}>
      <Headers
        className={"layout-header"}
        menuToggleEnabled
        toggleMenu={() => setOpened(!opened)}
        title={"GHM Website"}
      />
      <Drawer
        opened={opened}
        openedStateMode={"push"}
        position={"left"}
        closeOnOutsideClick={onOutsideClick}
        revealMode={"slide"}
        component={NavigationList}
      >
        <div className={"container"}>
          {/* <ScrollView ref={scrollViewRef} className={"layout-body with-footer"}> */}
          <div className={"content"}>{props.children}</div>
          {/* <div className={"content-block"}>
            <Footer>
              Copyright © 2011-{new Date().getFullYear()} {"GHM"} Inc.
              <br />
              All trademarks or registered trademarks are property of their
              respective owners.
            </Footer>
          </div> */}
          {/* </ScrollView> */}
        </div>
      </Drawer>
    </div>
  );
};

NavigationDrawer.propTypes = {
  children: PropsType.any,
  title: PropsType.string,
};

export default NavigationDrawer;
