import React, { useState, useEffect } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import "./header.scss";
import PropTypes from "prop-types";
import UserPanel from "./user-panel";
import { Template } from "devextreme-react/core/template";
import { THEMES_LIST } from "../utils/constants";
import { connect } from "react-redux";
import { changeWebsite, initWebsite } from "../actions/websiteActions";
import { WebsiteVM } from "../model/Website";

const Headers = ({
  menuToggleEnabled,
  title,
  toggleMenu,
  websites,
  currentLanguage,
  languages,
  changeWebsite,
  currentWebsite,
  initWebsite,
}) => {
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    let themeId = localStorage.getItem("_theme");
    if (!themeId) {
      themeId = "dx.dark.css";
      localStorage.setItem("_theme", themeId);
    }

    setTheme(themeId);
  }, []);

  useEffect(() => {
    initWebsite();
  }, []);

  const renderLabel = () => {
    return (
      <div className="toolbar-label">
        <b>{title}&apos;s</b> CMS
      </div>
    );
  };

  const changeTheme = (args) => {
    localStorage.setItem("_theme", args.value);
    window.location.reload();
  };

  const onChangeWebsite = (args) => {
    changeWebsite(args.value);
  };

  const themesOptions = {
    items: THEMES_LIST,
    value: theme,
    displayExpr: "name",
    valueExpr: "value",
    onValueChanged: changeTheme,
  };

  const websitesOptions = {
    items: websites.map((website) => WebsiteVM(website, languages)),
    displayExpr: `name-${currentLanguage}`,
    valueExpr: "id",
    value: currentWebsite,
    onValueChanged: onChangeWebsite,
  };

  return (
    <header className={"header-component"}>
      <Toolbar className={"header-toolbar"}>
        <Item
          visible={menuToggleEnabled}
          location={"before"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>

        <Item location="center" locateInMenu="never" render={renderLabel} />

        <Item
          location="after"
          widget="dxSelectBox"
          options={themesOptions}
          locateInMenu="auto"
        />

        <Item
          location="after"
          widget="dxSelectBox"
          options={websitesOptions}
          locateInMenu="auto"
        />

        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"userPanelTemplate"}
        >
          <Button
            className={"user-button authorization"}
            width={210}
            height={"100%"}
            stylingMode={"text"}
          >
            <UserPanel menuMode={"context"} />
          </Button>
        </Item>
        <Template name={"userPanelTemplate"}>
          <UserPanel menuMode={"list"} />
        </Template>
      </Toolbar>
    </header>
  );
};

Headers.propTypes = {
  menuToggleEnabled: PropTypes.bool,
  toggleMenu: PropTypes.func,
  title: PropTypes.string,
  lang: PropTypes.string,
  token: PropTypes.string,
  websites: PropTypes.array,
  currentLanguage: PropTypes.string,
  languages: PropTypes.array,
  changeWebsite: PropTypes.func,
  currentWebsite: PropTypes.string,
  initWebsite: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
    lang: state.setting.currentLanguage,
    websites: state.website.data.websites,
    currentWebsite: state.website.data.currentWebsite,
    currentLanguage: state.setting.currentLanguage,
    languages: state.setting.languages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeWebsite: (websiteId) => {
      return dispatch(changeWebsite(websiteId));
    },
    initWebsite: (token) => {
      return dispatch(initWebsite(token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Headers);
